import { useCallback, useEffect, useState } from 'react';

import { FromMapWorkerMessage } from 'app/workers/MapWorker/MapWorkerProtocol';

import { useMapWorkerPostMessage, useOnMapWorkerMessage } from 'app/contexts/mapWorkerContext';

import { defaultCustomHierarchyFilter, WorkerManagedState } from 'app/models';

export const getInitialWorkerManagedState = (): WorkerManagedState => ({
  selectedGeoIds: [],
  selectedRuleIds: [],
  sourceGroups: [],
  polygonNameMap: new Map(),
  geographyNameMap: new Map(),
  isUndoAvailable: false,
  isLoadingGeoHierarchy: false,
  isLoadingSelection: false,
  isLoadingRules: false,
  isLoadingCustomerPins: false,
  hierarchySelectionDetails: null,
  bounds: null,
  rules: [],
  customers: [],
  selectedAccounts: [],
  customHierarchies: [],
  customHierarchyFilter: defaultCustomHierarchyFilter,
  ignoredRuleIds: new Set(),
  lockedRuleIds: new Set()
});

const useMonitorWorkerManagedState = (): WorkerManagedState => {
  const [state, setState] = useState(getInitialWorkerManagedState);
  const handleWorkerMessage = useCallback((message: FromMapWorkerMessage) => {
    if (message.type === 'set-worker-managed-state') {
      setState((oldState) => ({ ...oldState, ...message.state }));
    }
  }, []);
  useOnMapWorkerMessage(handleWorkerMessage);
  return state;
};

const useRequestInitialWorkerManagedState = () => {
  const postMessage = useMapWorkerPostMessage();
  useEffect(() => {
    postMessage({ type: 'get-managed-state' });
  }, []);
};

export const useWorkerManagedState = (): WorkerManagedState => {
  useRequestInitialWorkerManagedState();
  return useMonitorWorkerManagedState();
};
