import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import {
  CellClickedEvent,
  ColDef,
  GridApi,
  GridReadyEvent,
  RowNode,
  SelectionChangedEvent,
  SuppressHeaderKeyboardEventParams
} from '@ag-grid-community/core';
import { InputGroup } from '@blueprintjs/core';
import { Search, Close, Locked, Unlocked, ViewOff, View } from '@carbon/icons-react';

import IconButton, { IconButtonProps } from 'components/Buttons/IconButton/IconButton';
import EllipsisText from 'components/EllipsisText/EllipsisText';
import Icon from 'components/Icon/Icon';

import AdvancedGrid from 'app/components/AdvancedGrid/AdvancedGrid';
import IconButtonCellRenderer from 'app/components/AdvancedGrid/CellRenderers/IconButtonCellRenderer/IconButtonCellRenderer';
import { MapIgnoreCellRenderer } from 'app/components/AdvancedGrid/CellRenderers/MapIgnoreCellRenderer/MapIgnoreCellRenderer';
import { MapLockCellRenderer } from 'app/components/AdvancedGrid/CellRenderers/MapLockCellRenderer/MapLockCellRenderer';
import TerritoryColorCellRenderer from 'app/components/AdvancedGrid/CellRenderers/TerritoryColorCellRenderer/TerritoryColorCellRenderer';
import { useLockAndIgnoreActions } from 'app/components/TerritoryMap/hooks/useLockAndIgnoreActions';
import { useTerritoryLockingTreatment } from 'app/components/TerritoryMap/hooks/useTerritoryLockingSplit';
import TerritoryGroupMapGrid from 'app/components/TerritoryMap/TerritoryMapGrid/TerritoryGroupMapGrid';

import { useDedicatedMapProvider } from 'app/contexts/dedicatedMapProvider';
import { useMapVariant } from 'app/contexts/mapVariantProvider';

import { TERRITORY_MAP_GRID_WIDTH } from 'app/global/variables';

import useKeyDown from 'app/hooks/useKeyDown';

import {
  GridFields,
  GridHeaders,
  IconButtonCellRendererType,
  RuleChangeCauses,
  RuleForMap,
  RuleChangeCause,
  NamedHierarchy,
  CollectionFilter
} from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import { EmptyMapGridMessage } from './EmptyMapGridMessage';
import MapGridHeader from './MapGridHeader';
import MapRuleSettingsDialog from './MapRuleSettingsDialog';
import style from './TerritoryMapGrid.module.pcss';

const b = block(style);

export const MAP_GRID_ROW_HEIGHT = 28;
export const MAP_GRID_HEADER_HEIGHT = 32;

export interface TerritoryMapGridProps {
  territoryRules: RuleForMap[];
  loading: boolean;
  selectedRuleIds: number[];
  isSelectionEnabled: boolean;
  lockedRuleIds: Set<number>;
  ignoredRuleIds: Set<number>;
  onSelectRules: (ruleIds: number[]) => void;
  onUpdateRules: (cause: RuleChangeCause) => void;
  customHierarchies: NamedHierarchy[];
  customHierarchyFilter: CollectionFilter<number>;
}

const TerritoryMapGrid: FC<TerritoryMapGridProps> = ({
  territoryRules,
  loading,
  selectedRuleIds,
  lockedRuleIds,
  ignoredRuleIds,
  onSelectRules,
  onUpdateRules,
  isSelectionEnabled,
  customHierarchies,
  customHierarchyFilter
}) => {
  const gridApi = useRef<GridApi>(null);
  const wrapperRef = useRef<HTMLDivElement | undefined>();
  const searchText = useRef('');

  const [showMapRuleSettingsDialog, setShowMapRuleSettingsDialog] = useState(false);

  const [shouldShowSearchBar, setShouldShowSearchBar] = useState(false);

  const { setOpenTerritoryColorPaletteId } = useMapVariant();
  const { territoryGroupLevel } = useDedicatedMapProvider();

  const lockingTreatment = useTerritoryLockingTreatment();
  const territoryCount = territoryRules.length;
  const areAllRulesIgnored = territoryCount === ignoredRuleIds.size;
  const areAllRulesLocked = territoryCount === lockedRuleIds.size;
  const { toggleLockRule, toggleIgnoreRule, toggleIgnoreAll, toggleLockAll } = useLockAndIgnoreActions();

  const colDefs = useMemo((): ColDef[] => {
    const colorIndicatorCellSelector = (params) => {
      if (params.value.isOverAssigned) {
        return {
          frameworkComponent: IconButtonCellRenderer,
          params: {
            iconCellRendererType: IconButtonCellRendererType.CONFLICT,
            tooltipPosition: 'right'
          }
        };
      } else if (params.value.isMappable) {
        return {
          frameworkComponent: TerritoryColorCellRenderer,
          params: {
            params,
            onUpdateRules: () => onUpdateRules(RuleChangeCauses.Recolor)
          }
        };
      } else {
        return {
          frameworkComponent: IconButtonCellRenderer,
          params: {
            iconCellRendererType: IconButtonCellRendererType.NON_MAPPABLE,
            tooltipPosition: 'right'
          }
        };
      }
    };

    const lockHeaderProps: IconButtonProps = {
      icon: areAllRulesLocked ? <Locked className={b('headerIcon')} /> : <Unlocked className={b('headerIcon')} />,
      tooltipText: formatMessage(areAllRulesLocked ? `UNLOCK_ALL_TERRITORIES` : `LOCK_ALL_TERRITORIES`),
      onClick: toggleLockAll,
      testId: 'map-lock-all-cell',
      type: 'button'
    };
    const ignoreHeaderProps: IconButtonProps = {
      icon: areAllRulesIgnored ? <ViewOff className={b('headerIcon')} /> : <View className={b('headerIcon')} />,
      tooltipText: formatMessage(areAllRulesIgnored ? `SHOW_ALL_TERRITORIES` : `HIDE_ALL_TERRITORIES`),
      onClick: toggleIgnoreAll,
      testId: 'map-ignore-all-cell',
      type: 'button'
    };
    return [
      {
        headerName: '',
        colId: 'Indicator',
        field: GridFields.TERRITORY_COLOR,
        width: iconColumnSize,
        cellRendererFramework: TerritoryColorCellRenderer,
        cellRendererSelector: colorIndicatorCellSelector,
        cellClass: b('centeredCell')
      },
      {
        headerName: GridHeaders.TERRITORY_ID,
        field: GridFields.TERRITORY_ID,
        flex: 5,
        hide: lockingTreatment.isMvpOn,
        cellClass: b('centeredCell')
      },
      {
        headerName: GridHeaders.TERRITORY_NAME,
        field: GridFields.TERRITORY_NAME,
        flex: 6,
        hide: lockingTreatment.isMvpOn,
        cellClass: b('centeredCell')
      },
      {
        headerName:
          territoryCount > 0
            ? formatMessage('TERRITORIES_AND_COUNT', { count: territoryCount })
            : formatMessage('TERRITORIES'),
        field: GridFields.TERRITORY_ID_AND_NAME,
        hide: !lockingTreatment.isMvpOn,
        flex: 1,
        cellRendererFramework: TerritoryIdAndNameRenderer
      },
      {
        colId: 'Locked',
        hide: !lockingTreatment.isMvpOn,
        width: iconColumnSize,
        field: GridFields.IS_LOCKED,
        cellRendererFramework: MapLockCellRenderer,
        headerComponentFramework: IconButton,
        headerComponentParams: lockHeaderProps,
        suppressHeaderKeyboardEvent: allowKeyboardClicks(toggleLockAll),
        headerClass: b('iconHeader'),
        cellClass: b('centeredCell')
      },
      {
        colId: 'Ignored',
        hide: !lockingTreatment.isMvpOn,
        width: iconColumnSize,
        field: GridFields.IS_IGNORED,
        cellRendererFramework: MapIgnoreCellRenderer,
        headerComponentFramework: IconButton,
        headerComponentParams: ignoreHeaderProps,
        suppressHeaderKeyboardEvent: allowKeyboardClicks(toggleIgnoreAll),
        headerClass: b('iconHeader'),
        cellClass: b('centeredCell')
      }
    ];
  }, [onUpdateRules, areAllRulesLocked, areAllRulesIgnored, territoryCount]);

  const territoryRowData = useMemo(
    () =>
      JSON.stringify(
        territoryRules.map(
          (rule): TerritoryMapGridRow => ({
            [GridFields.RULE]: rule,
            [GridFields.TERRITORY_ID_AND_NAME]: formatMessage('TERRITORY_ID_AND_NAME', {
              territoryId: rule.territoryId,
              territoryName: rule.territoryName
            }),
            [GridFields.TERRITORY_COLOR]: {
              color: rule.color,
              rule,
              isMappable: rule.isMappable,
              isOverAssigned: rule.isOverAssigned
            },
            [GridFields.RULE_ID]: rule.ruleId,
            [GridFields.TERRITORY_ID]: rule.territoryId,
            [GridFields.TERRITORY_NAME]: rule.territoryName,
            [GridFields.IS_LOCKED]: lockedRuleIds.has(rule.ruleId),
            [GridFields.IS_IGNORED]: ignoredRuleIds.has(rule.ruleId)
          })
        )
      ),
    [territoryRules, lockedRuleIds, ignoredRuleIds]
  );

  const handleCellClicked = useCallback(
    (event: CellClickedEvent) => {
      const ruleId = event.data.ruleId;
      if (!ruleId) return;
      switch (event.colDef.colId) {
        case 'Locked':
          toggleLockRule(ruleId);
          break;
        case 'Ignored':
          toggleIgnoreRule(ruleId);
          break;
        default:
          if (!isSelectionEnabled) return;
          if (event.node.isSelected()) {
            event.api.deselectNode(event.node, false);
          } else {
            event.api.selectNode(event.node, true, false);
          }
      }
    },
    [toggleLockRule, toggleIgnoreRule, isSelectionEnabled]
  );

  const handleOnGridReady = (e: GridReadyEvent) => {
    gridApi.current = e.api;
    applyRuleSelectionToGrid();
  };

  const isTerritoriesLevel = territoryGroupLevel == null;

  const onSelectionChanged = useCallback(
    (e: SelectionChangedEvent) => {
      if (territoryGroupLevel != null) return;
      const selectedRows = e.api.getSelectedNodes();
      const selectedRuleIds: number[] = selectedRows.map((row) => row.data.ruleId);
      onSelectRules(selectedRuleIds);
    },
    [territoryGroupLevel]
  );

  const applyRuleSelectionToGrid = useCallback(() => {
    const selectedRuleIdSet = new Set(selectedRuleIds);
    gridApi.current?.forEachNode((node) => {
      if (node?.data?.ruleId) {
        const isNodeSelected = selectedRuleIdSet.has(node.data.ruleId);
        node.setSelected(isNodeSelected, false, true);
      }
    });
  }, [selectedRuleIds]);

  useEffect(() => {
    applyRuleSelectionToGrid();
  }, [selectedRuleIds]);

  const isExternalFilterPresent = useCallback(() => {
    return !!searchText.current;
  }, []);

  const doesFilterPass = useCallback((node: RowNode): boolean => {
    const { territoryName, territoryId, territoryGroupName, leafTerritoryName, leafTerritoryId, territoryIdAndName } =
      node.data;
    const searchInput = searchText.current?.toLowerCase();
    return (
      !searchInput ||
      territoryIdAndName?.toLowerCase().includes(searchInput) ||
      territoryName?.toLowerCase().includes(searchInput) ||
      territoryId?.toLowerCase().includes(searchInput) ||
      !!territoryGroupName?.toLowerCase().includes(searchInput) ||
      !!leafTerritoryName?.toLowerCase().includes(searchInput) ||
      !!leafTerritoryId?.toLowerCase().includes(searchInput)
    );
  }, []);

  useKeyDown('Escape', () => setOpenTerritoryColorPaletteId(null));

  const toggleMapRuleSettingsDialog = () => setShowMapRuleSettingsDialog((prev) => !prev);

  const handleSearchChange = (event) => {
    searchText.current = event.target?.value;
    gridApi.current.onFilterChanged();
  };
  const handleShowSearchBar = () => setShouldShowSearchBar(true);
  const handleHideSearchBar = () => {
    searchText.current = '';
    gridApi.current.onFilterChanged();
    setShouldShowSearchBar(false);
  };

  const gridHeight = wrapperRef.current?.clientHeight - 120 || 100;
  const gridWidth = TERRITORY_MAP_GRID_WIDTH;

  return (
    <div className={b('gridContainer')} data-testid="territory-map-grid-container">
      <div className={b('gridWrapper')} data-testid="territory-map-grid-wrapper" ref={wrapperRef}>
        {territoryRules.length === 0 && !loading ? (
          <EmptyMapGridMessage />
        ) : (
          <>
            {shouldShowSearchBar && (
              <div className={b('inputWrapper')}>
                <InputGroup
                  onChange={handleSearchChange}
                  placeholder={formatMessage('SEARCH')}
                  leftIcon={<Icon icon={<Search />} />}
                  rightElement={
                    <IconButton
                      icon={<Close />}
                      onClick={handleHideSearchBar}
                      type="button"
                      testId="close-search-button"
                    />
                  }
                />
              </div>
            )}
            {isTerritoriesLevel ? (
              <>
                {!shouldShowSearchBar && (
                  <MapGridHeader
                    onClick={toggleMapRuleSettingsDialog}
                    onSearchIconClick={handleShowSearchBar}
                    customHierarchyFilter={customHierarchyFilter}
                    customHierarchies={customHierarchies}
                  />
                )}
                <AdvancedGrid
                  columnDefs={colDefs}
                  data={territoryRowData}
                  gridProps={{
                    suppressCellSelection: true,
                    isExternalFilterPresent,
                    doesExternalFilterPass: doesFilterPass,
                    onGridReady: handleOnGridReady,
                    rowHeight: MAP_GRID_ROW_HEIGHT,
                    headerHeight: MAP_GRID_HEADER_HEIGHT,
                    onCellClicked: handleCellClicked
                  }}
                  data-testid="territory-map-grid"
                  showGridLoading={loading}
                  onSelectionChanged={onSelectionChanged}
                  rowSelection="multiple"
                  suppressRowClickSelection
                  onRowDataChanged={applyRuleSelectionToGrid}
                  animateRows
                  rowMultiSelectWithClick
                  gridHeight={gridHeight}
                  gridWidth={gridWidth}
                />
              </>
            ) : (
              <TerritoryGroupMapGrid
                isExternalFilterPresent={isExternalFilterPresent}
                doesExternalFilterPass={doesFilterPass}
                loading={loading}
                territoryRules={territoryRules}
                handleOnGridReady={handleOnGridReady}
                shouldShowMapGridHeader={!shouldShowSearchBar}
                onSearchIconClick={handleShowSearchBar}
                mapVisualizationSettingsOnClick={toggleMapRuleSettingsDialog}
                gridHeight={gridHeight}
                gridWidth={gridWidth}
                customHierarchyFilter={customHierarchyFilter}
                customHierarchies={customHierarchies}
              />
            )}
            {showMapRuleSettingsDialog && <MapRuleSettingsDialog onClose={toggleMapRuleSettingsDialog} />}
          </>
        )}
      </div>
    </div>
  );
};

const TerritoryIdAndNameRenderer: FC<{ value: string; data: TerritoryMapGridRow }> = ({
  value,
  data: { isIgnored }
}) => {
  return <EllipsisText text={value} className={b('territoryIdAndName', { isIgnored })} />;
};

export type TerritoryMapGridRow = {
  [GridFields.RULE]: RuleForMap;
  [GridFields.TERRITORY_ID_AND_NAME]: string;
  [GridFields.TERRITORY_COLOR]: {
    color: string;
    rule: RuleForMap;
    isMappable: boolean;
    isOverAssigned: boolean;
  };
  [GridFields.RULE_ID]: number;
  [GridFields.TERRITORY_ID]: string;
  [GridFields.TERRITORY_NAME]: string;
  [GridFields.IS_LOCKED]: boolean;
  [GridFields.IS_IGNORED]: boolean;
};

const iconColumnSize = 28;

const allowKeyboardClicks =
  (onClick: () => void) =>
  ({ event }: SuppressHeaderKeyboardEventParams) => {
    if (event.key === 'Enter' || event.key === ' ') {
      onClick();
      return true;
    }
    return false;
  };

export default TerritoryMapGrid;
