import React from 'react';

import { AnchorButton, Spinner } from '@blueprintjs/core';
import { Warning } from '@carbon/icons-react';

import MessageTooltip from 'components/MessageTooltip/MessageTooltip';

import block from 'utils/bem-css-modules';

import style from './InsightsCard.module.pcss';

const b = block(style);

export interface InsightsCardStatProps {
  label: string;
  text: string;
  testId: string;
  onStatClick?: () => void;
  tooltipText?: string;
  shouldShowWarning?: boolean;
  isLoading?: boolean;
  showStatValue?: boolean;
  rightIcon?: JSX.Element;
  disabled: boolean;
}

const InsightsCardStat: React.FC<InsightsCardStatProps> = ({
  label,
  text,
  testId,
  onStatClick,
  disabled = false,
  tooltipText = '',
  shouldShowWarning = false,
  isLoading = false,
  showStatValue = true,
  rightIcon = null
}: InsightsCardStatProps) => (
  <Stat label={label} testId={`${testId}-stat`} isLoading={isLoading} tooltip={tooltipText}>
    {shouldShowWarning && <Warning className={b('statIcon')} data-testid="warning-icon" />}
    <AnchorButton
      text={showStatValue ? text : '0'}
      type="button"
      rightIcon={rightIcon}
      data-testid={`${testId}-insights-button`}
      minimal
      large={false}
      onClick={onStatClick}
      disabled={disabled}
      className={b('', { noIconStat: !rightIcon })}
    />
  </Stat>
);

const Stat: React.FC<{ label: string; testId: string; isLoading?: boolean; tooltip?: string }> = ({
  isLoading,
  label,
  tooltip,
  testId,
  children
}) => (
  <dl className={b('stat')} data-testid={testId}>
    <dt className={b('statLabel')}>{label}</dt>
    <MessageTooltip
      placement={'left'}
      content={tooltip}
      disabled={!tooltip}
      target={
        <dd data-testid={`${testId}-value`} className={b('statValue')}>
          {isLoading ? <Spinner className={b('spinner')} intent="primary" size={12} /> : children}
        </dd>
      }
    />
  </dl>
);

export default InsightsCardStat;
