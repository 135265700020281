import { useMemo } from 'react';

import { useMapWorkerPostMessage } from 'app/contexts/mapWorkerContext';

export const useLockAndIgnoreActions = () => {
  const postMessage = useMapWorkerPostMessage();
  return useMemo(
    () => ({
      toggleLockRules: (ruleIds: number[]) => postMessage({ type: 'toggle-rules-locked', ruleIds }),
      toggleLockRule: (ruleId: number) => postMessage({ type: 'toggle-rules-locked', ruleIds: [ruleId] }),
      toggleLockAll: () => postMessage({ type: 'toggle-all-rules-locked' }),

      toggleIgnoreRules: (ruleIds: number[]) => postMessage({ type: 'toggle-rules-ignored', ruleIds }),
      toggleIgnoreRule: (ruleId: number) => postMessage({ type: 'toggle-rules-ignored', ruleIds: [ruleId] }),
      toggleIgnoreAll: () => postMessage({ type: 'toggle-all-rules-ignored' }),

      isolateRules: () => postMessage({ type: 'isolate-rules' })
    }),
    [postMessage]
  );
};
