import { QueryTuple, useLazyQuery } from '@apollo/client';

import { GetConversionRates, GetConversionRatesVariables } from 'app/graphql/generated/graphqlApolloTypes';
import { handleError } from 'app/graphql/handleError';
import { GET_CONVERSION_RATES } from 'app/graphql/queries/getConversionRates';

import useShowToast from 'app/hooks/useShowToast';

import { formatMessage } from 'utils/messages/utils';

export const useGetConversionRates = (
  planningCycleId: number
): QueryTuple<GetConversionRates, GetConversionRatesVariables> => {
  const showToast = useShowToast();
  return useLazyQuery<GetConversionRates, GetConversionRatesVariables>(GET_CONVERSION_RATES, {
    variables: {
      planningCycleId
    },
    fetchPolicy: 'network-only',
    onError({ graphQLErrors, networkError }) {
      showToast(formatMessage('GET_CONVERSION_RATES_COUNT_ERROR'), 'warning');
      handleError(graphQLErrors, networkError);
    }
  });
};
