import { RumEvent, RumEventDomainContext, RumFetchResourceEventDomainContext } from '@datadog/browser-rum';

import { config } from 'utils/config';

export const transformAnalyticsEvent = (rumEvent: RumEvent, context: RumEventDomainContext): boolean => {
  if (rumEvent.type === 'action' && rumEvent.action.type === 'click' && 'events' in context) {
    const domEvent = context.events?.find(
      (event) => event instanceof PointerEvent || event instanceof KeyboardEvent || event instanceof MouseEvent
    );
    if (domEvent?.target instanceof Node) {
      rumEvent.context.ancestorIds = collectAncestorIds(domEvent.target);
    }
  }
  if (rumEvent.type === 'resource' && 'requestInit' in context && 'requestInput' in context) {
    rumEvent.context.gqlExtras = extractGqlExtras(context);
  }

  return true;
};

export const collectAncestorIds = (startingNode: Node) => {
  const ancestorIds = new Array<string>();
  let node = startingNode;
  while (node) {
    const dataset = (node as HTMLElement).dataset;
    const id = dataset && (dataset.ddActionName ?? dataset.testid ?? dataset.test);
    if (id) ancestorIds.push(id);
    node = node.parentElement;
  }
  return ancestorIds;
};

export function extractGqlExtras(context: RumFetchResourceEventDomainContext) {
  try {
    if (context.requestInput !== config.APPSYNC_GRAPHQL_ENDPOINT) return null;
    if (typeof context.requestInit?.body !== 'string') return null;
    const inputBody = JSON.parse(context.requestInit.body);
    return {
      operationName: inputBody?.operationName,
      variables: summarizeRequestVariables(inputBody?.variables)
    };
  } catch (error) {
    console.warn(`Error while trying to grab GQL extras`, error);
    return null;
  }
}

const SummaryLimits = {
  objectProperties: 20,
  stringLength: 100,
  arrayLength: 5
};

export function summarizeRequestVariables(value: unknown) {
  if (!value) return value;
  if (Array.isArray(value)) return summarizeArray(value);

  switch (typeof value) {
    case 'string':
      return summarizeString(value);
    case 'object':
      return summarizeObject(value);
    default:
      return value;
  }
}
function summarizeString(string: string) {
  if (string.length <= SummaryLimits.stringLength) return string;
  return {
    type: `[SUMMARIZED STRING]`,
    originalLength: string.length,
    value: string.substring(0, SummaryLimits.stringLength)
  };
}

function summarizeArray(array: unknown[]) {
  const summary = new Array<unknown>();
  for (let index = 0; index < Math.min(array.length, SummaryLimits.arrayLength); index++) {
    summary.push(summarizeRequestVariables(array[index]));
  }
  if (array.length <= SummaryLimits.arrayLength) return summary;
  return {
    type: `[SUMMARIZED ARRAY]`,
    originalLength: array.length,
    value: summary
  };
}

function summarizeObject(object: object) {
  const summary = {};
  const entries = Object.entries(object);
  for (let index = 0; index < Math.min(entries.length, SummaryLimits.objectProperties); index++) {
    const [entryKey, entryValue] = entries[index];
    summary[entryKey] = summarizeRequestVariables(entryValue);
  }
  if (entries.length <= SummaryLimits.objectProperties) return summary;
  return {
    type: `[SUMMARIZED OBJECT]`,
    originalLength: entries.length,
    value: summary
  };
}

export function isLikelyExternalUser(emailAddress: string): boolean {
  if (!emailAddress) return true; // Better to over-include
  const chunks = emailAddress.split('@');
  const domain = chunks[chunks.length - 1].toLowerCase();
  if (!domain) return true;
  if (domain.startsWith('varicent')) return false;
  if (domain.startsWith('inbox.testmail.app')) return false;
  if (domain.startsWith('yopmail')) return false;
  return true;
}
