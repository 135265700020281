import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useMutation } from '@apollo/client';
import { Tag, Text } from '@varicent/components';
import { Field, Form, Formik, FormikProps } from 'formik';
import debounce from 'lodash.debounce';

import Dialog from 'components/Dialog/Dialog';
import SearchableSelectMenu from 'components/SearchableSelectMenu/SearchableSelectMenu';

import FormTextInputGroup from 'app/components/FormFields/FormTextInputGroup/FormTextInputGroup';
import TextMessageInputField from 'app/components/FormFields/TextMessageInputField/TextMessageInputField';

import { debounceDelay } from 'app/constants/DebounceConstants';

import { useDedicatedMapProvider } from 'app/contexts/dedicatedMapProvider';
import { useMapContextRedistributor } from 'app/contexts/MapContextRedistributor/mapContextRedistributorProvider';

import { SplitFeatures } from 'app/global/features';

import { UpsertTerritoryRule, UpsertTerritoryRuleVariables } from 'app/graphql/generated/graphqlApolloTypes';
import { handleError } from 'app/graphql/handleError';
import { UPSERT_TERRITORY_RULE } from 'app/graphql/mutations/upsertTerritoryRule';
import { useGetIsTerritoryIdAvailableLazy } from 'app/graphql/queries/getIsTerritoryIdAvailable';

import useShowToast from 'app/hooks/useShowToast';
import useTreatment from 'app/hooks/useTreatment';

import { MchQuantity, RuleForMap } from 'app/models';

import block from 'utils/bem-css-modules';
import { LeastUsedColorPicker } from 'utils/LeastUsedColorPicker';
import { formatMessage } from 'utils/messages/utils';

import { useMapQuestion } from './hooks/useMapQuestion';
import style from './UpsertTerritoryRuleDialog.module.pcss';

const b = block(style);

interface UpsertTerritoryRuleDialogFormValues {
  territoryGroup: { key: string; value: number };
  territoryId: string;
  territoryName: string;
}

interface UpsertTerritoryRuleDialogProps {
  onClose: () => void;
  onUpsertTerritory?: () => void;
  resetSelectedPolygons?: () => void;
  mappableTerritoryRules?: RuleForMap[];
}

const UpsertTerritoryRuleDialog: React.FC<UpsertTerritoryRuleDialogProps> = ({
  onClose,
  resetSelectedPolygons = null,
  onUpsertTerritory,
  mappableTerritoryRules
}: UpsertTerritoryRuleDialogProps) => {
  const [canCreateTerritoryWithCustomHierOnMap] = useTreatment(SplitFeatures.MAP_CREATE_TER_WITH_CUSTOM_HIER);
  const { selectedBattleCard } = useMapContextRedistributor();
  const { territoryGroupTree, chosenCustomHierarchy } = useDedicatedMapProvider();
  const showToast = useShowToast();

  const formRef = useRef<FormikProps<UpsertTerritoryRuleDialogFormValues>>();

  const [showUniqueTerritoryIdMessage, setShowUniqueTerritoryIdMessage] = useState<boolean>(false);

  const [upsertTerritoryRule] = useMutation<UpsertTerritoryRule, UpsertTerritoryRuleVariables>(UPSERT_TERRITORY_RULE, {
    onCompleted() {
      showToast(formatMessage('CREATE_NEW_TERRITORY_SUCCESS'), 'success');
      onClose();
      resetSelectedPolygons?.();
      if (onUpsertTerritory) {
        onUpsertTerritory();
      }
    },
    onError({ graphQLErrors, networkError }) {
      showToast(formatMessage('CREATE_NEW_TERRITORY_ERROR'), 'danger');
      handleError(graphQLErrors, networkError);
    }
  });

  const {
    answer: createTerritoryDetails,
    ask: getCreateTerritoryDetails,
    isLoading: isLoadingCreateTerritoryDetails
  } = useMapQuestion('get-details-for-create-rule');

  useEffect(() => {
    getCreateTerritoryDetails({ canCreateTerritoryWithCustomHierOnMap });
  }, []);

  const territoryGroupMenuItems = useMemo(
    () =>
      [...territoryGroupTree.leafs].map((tgId) => ({
        key: territoryGroupTree.groupById.get(tgId).name,
        value: tgId
      })),
    [territoryGroupTree]
  );

  const isCustomHierarchyTagsVisible = createTerritoryDetails?.selectedCustomHierarchies.length > 0;

  const submitForm = async (values: {
    territoryGroup: { value: number };
    territoryId: string;
    territoryName: string;
  }) => {
    await upsertTerritoryRule({
      variables: {
        definition: createTerritoryDetails?.definition,
        territoryGroupId: values.territoryGroup.value,
        territoryId: values.territoryId.trim(),
        territoryName: values.territoryName.trim(),
        ruleId: undefined, // the backend will only generate a ruleId if an undefined ruleId is provided
        color: pickTerritoryColor()
      }
    });
  };

  const pickTerritoryColor = () => {
    if (!mappableTerritoryRules) return null;
    const colorPicker = new LeastUsedColorPicker(mappableTerritoryRules);
    return colorPicker.useLeastUsedColor();
  };

  const initialFormValues: UpsertTerritoryRuleDialogFormValues = useMemo(
    () => ({
      territoryGroup: territoryGroupMenuItems[0] ?? { key: '', value: null },
      territoryId: '',
      territoryName: ''
    }),
    [territoryGroupMenuItems]
  );

  const getSearchableMenuPlaceholderText = () => territoryGroupMenuItems[0]?.key || formatMessage('SELECT');

  const [
    checkIsTerritoryIdAvailable,
    { data: isTerritoryIdAvailableResult, loading: isLoadingGetIsTerritoryIdAvailable }
  ] = useGetIsTerritoryIdAvailableLazy({
    fetchPolicy: 'network-only'
  });

  const handleTerritoryIdValidation = () => {
    if (!formRef?.current) return;

    checkIsTerritoryIdAvailable({
      variables: {
        territoryGroupId: formRef.current.values.territoryGroup.value,
        territoryId: formRef.current.values.territoryId
      }
    });
  };

  const isTerritoryIdAvailable = useMemo(
    () => isTerritoryIdAvailableResult?.getIsTerritoryIdAvailable.isAvailable,
    [isTerritoryIdAvailableResult]
  );

  const setTerritoryIdError = (errorMessage: string) => {
    const { setFieldError } = formRef.current;
    setFieldError('territoryId', errorMessage);
  };

  useEffect(() => {
    if (!formRef?.current) return;
    if (!isLoadingGetIsTerritoryIdAvailable && isTerritoryIdAvailable === false) {
      const { values, setFieldTouched } = formRef.current;
      setFieldTouched('territoryId', true, false);
      setTerritoryIdError(
        formatMessage('UNAVAILABLE_TERRITORY_ID', {
          territoryId: values.territoryId
        })
      );
    }
    setShowUniqueTerritoryIdMessage(isTerritoryIdAvailable);
  }, [isLoadingGetIsTerritoryIdAvailable, isTerritoryIdAvailable]);

  const debounceHandler = useCallback(debounce(handleTerritoryIdValidation, debounceDelay), []);

  const handleOnChange = () => {
    debounceHandler();
    setShowUniqueTerritoryIdMessage(false);
    setTerritoryIdError('');
  };

  const getCustomHierarchyTagsHeaderText = (): string => {
    return chosenCustomHierarchy?.quantity === MchQuantity.SINGULAR
      ? formatMessage('CHOSEN_CUSTOM_HIERARCHY', {
          hierarchy: chosenCustomHierarchy?.details.rootName
        })
      : formatMessage('HIERARCHIES');
  };

  return (
    <Formik<UpsertTerritoryRuleDialogFormValues>
      initialValues={initialFormValues}
      onSubmit={submitForm}
      enableReinitialize
      innerRef={formRef}
    >
      {({ handleSubmit, values, isSubmitting }) => {
        return (
          <Dialog
            isLoading={isLoadingCreateTerritoryDetails}
            isOpen
            title={formatMessage('CREATE_NEW_TERRITORY_DIALOG_TITLE')}
            cancelButtonText={formatMessage('CANCEL')}
            confirmButtonText={formatMessage('CREATE_TERRITORY')}
            confirmButtonLoading={isSubmitting}
            onSubmit={() => handleSubmit()}
            onClose={() => onClose()}
            showOverflow={true}
            disableConfirm={!values.territoryId || !values.territoryName || !values.territoryGroup?.key}
            size="small"
          >
            <Form>
              {
                <div data-testid="upsert-territory-rule-form">
                  <div className={b('formBody')}>
                    <div className={b('inputRow')}>
                      <div className={b('battleCardInput')}>
                        <span className={b('formBodyLabel')} data-testid="battlecard-header-label">
                          {formatMessage('BATTLE_CARD')}
                        </span>
                        <div data-testid="battlecard-name-container">
                          <span>{selectedBattleCard?.battlecardName}</span>
                        </div>
                      </div>
                      <br />
                      <div className={b('territoryGroupInput')} data-testid="territory-group-field-container">
                        <span className={b('formBodyLabel')} data-testid="territory-group-header-label">
                          {formatMessage('TERRITORY_GROUP_REQUIRED_MARK')}
                        </span>
                        <Field
                          component={SearchableSelectMenu}
                          name="territoryGroup"
                          placeHolderText={getSearchableMenuPlaceholderText()}
                          initialLoadingComplete={true}
                          shouldDisableSearch
                          showIconInField={false}
                          showErrors
                          items={territoryGroupMenuItems}
                          theme="default"
                        />
                      </div>
                      <div className={b('territoryIdInput')} data-testid="territory-id-field-container">
                        <TextMessageInputField
                          isRequired
                          name="territoryId"
                          textLabel={formatMessage('TERRITORY_ID')}
                          textPlaceholder={formatMessage('ENTER_TERRITORY_ID_FOR_RULE')}
                          isLoading={isLoadingGetIsTerritoryIdAvailable}
                          shouldShowSuccessIcon={isTerritoryIdAvailable && !!values.territoryId}
                          shouldShowErrorIcon={isTerritoryIdAvailable === false}
                          shouldShowMessage={showUniqueTerritoryIdMessage}
                          shouldValidateOnTouch={false}
                          isMessageAvailable={isTerritoryIdAvailable && !!values.territoryId}
                          message={formatMessage('AVAILABLE_TERRITORY_ID', {
                            territoryId: values.territoryId,
                            territoryGroup: values.territoryGroup.key
                          })}
                          onChange={handleOnChange}
                        />
                      </div>
                      <div className={b('territoryNameInput')} data-testid="territory-name-field-container">
                        <span className={b('formBodyLabel')} data-testid="territory-name-label">
                          {formatMessage('TERRITORY_NAME_REQUIRED_MARK')}
                        </span>
                        <Field
                          name="territoryName"
                          component={FormTextInputGroup}
                          placeHolder={formatMessage('ENTER_TERRITORY_NAME_FOR_RULE')}
                        />
                        {isCustomHierarchyTagsVisible && (
                          <div>
                            <Text className={b('customHierarchyLabel')}>{getCustomHierarchyTagsHeaderText()}</Text>
                            <div className={b('customHierarchyTags')}>
                              {createTerritoryDetails?.selectedCustomHierarchies?.map((hierarchy) => (
                                <Tag className={b('customHierarchyTag')} key={hierarchy.hierarchyId} intent="primary">
                                  {hierarchy.name}
                                </Tag>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              }
            </Form>
          </Dialog>
        );
      }}
    </Formik>
  );
};

export default UpsertTerritoryRuleDialog;
