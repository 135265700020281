import React, { MutableRefObject, useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import { WarningAltFilled, DataTable, Diagram } from '@carbon/icons-react';
import { Tag } from '@varicent/components';
import { Field, useFormikContext } from 'formik';

import CurrencySelect from 'components/CurrencySelect/CurrencySelect';
import Icon from 'components/Icon/Icon';
import { MultiSelectBubbleColor } from 'components/models';
import MultiSelectMenu from 'components/MultiSelectMenu/MultiSelectMenu';
import ReorderableList from 'components/ReorderableList/ReorderableList';
import SelectMenu from 'components/SelectMenu/SelectMenu';

import BattleCardDesignerReorderableListItem from 'app/components/BattleCardDiagram/Designer/BattleCardDesignerReorderableListItem/BattleCardDesignerReorderableListItem';
import FormTextInputGroup from 'app/components/FormFields/FormTextInputGroup/FormTextInputGroup';
import SellerSelect from 'app/components/SellerSelect/SellerSelect';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useCurrency } from 'app/contexts/currencyProvider';
import { useLocalization } from 'app/contexts/localizationProvider';
import { useScope } from 'app/contexts/scopeProvider';

import { SplitFeatures } from 'app/global/features';
import {
  HIERARCHY_TARGET_SETTING_MAX_100_MEMBER_COUNT,
  HIERARCHY_TARGET_SETTING_MAX_20_MEMBER_COUNT
} from 'app/global/variables';

import {
  BCMapLevelEnum,
  FileTypeEnum,
  GetFileList,
  GetFileList_getPlanningCycleSpec_tables_tableList,
  GetFileListVariables
} from 'app/graphql/generated/graphqlApolloTypes';
import { handleError } from 'app/graphql/handleError';
import { GET_FILE_LIST } from 'app/graphql/queries/getFileList';

import useTreatment from 'app/hooks/useTreatment';

import {
  BattleCardDesignerRootHierarchy,
  BattlecardType,
  FileStatus,
  HierarchyType,
  HierarchyWithTypeAndFirstLevelMemberCount
} from 'app/models';

import block from 'utils/bem-css-modules';
import { getSortedCurrencyItems } from 'utils/helpers/currencyHelpers';
import { formatMessage } from 'utils/messages/utils';
import {
  cardTypeSelectionDropDownDataPrimaryTeam,
  cardTypeSelectionDropDownDataRollup
} from 'utils/uiCopy/battleCardTypeSelectionDropDownData';

import style from './BattleCardDesignerInitialSetupPage.module.pcss';

const b = block(style);

interface BattleCardDesignerInitialSetupPageProps {
  parentBattleCardType: BattlecardType;
  editingBattleCardId: string;
  rootHierarchies: Record<number, BattleCardDesignerRootHierarchy>;
  blankReorderableListItem: Record<string, unknown>;
  mapLevel: BCMapLevelEnum;
  deploymentModelId?: number;
  parentRef?: MutableRefObject<HTMLDivElement>;
}

interface FormValues {
  reorderableListItems: [{ owner: { key: string; value: unknown }; hierarchies: unknown }];
}

const QuotaComponentsField = ({ quotaComponentCount, items, disabledQuotaComponents }) => {
  return (
    <>
      {quotaComponentCount > 1 ? (
        <div className={b('cardItemContainer')}>
          <div className={b('multiSelectContainer')} data-testid="battlecard-quota-component-container">
            <div className={b('cardItemLabel')} data-testid="battlecard-quota-component-label">
              {formatMessage('QUOTA_COMPONENTS_ONE_OR_MANY_WITH_COLON')}
            </div>
            <Field
              name="quotaComponents"
              items={items}
              theme="default"
              component={MultiSelectMenu}
              bubbleIcon={<Diagram />}
              bubbleColor={MultiSelectBubbleColor.PINK}
              disabledItems={disabledQuotaComponents}
              errorMessage={formatMessage('NO_QUOTA_COMPONENTS')}
              loadingComplete
              data-testid="battlecard-quota-component-select"
              small
            />
          </div>
        </div>
      ) : null}
    </>
  );
};

const QuotaBreakdownField = ({
  quotaBreakdownCount,
  items,
  hasExistingQuotaBreakdown
}: {
  quotaBreakdownCount: number;
  items: HierarchyWithTypeAndFirstLevelMemberCount[];
  hasExistingQuotaBreakdown: boolean;
}) => {
  const { values, setFieldValue } = useFormikContext();

  if (quotaBreakdownCount < 1) return null;

  return (
    <div className={b('cardItemContainer')}>
      <div className={b('multiSelectContainer')} data-testid="battlecard-quota-breakdown-container">
        <div className={b('cardItemLabel')} data-testid="battlecard-quota-breakdown-label">
          {formatMessage('QUOTA_BREAKDOWN_BY')}
        </div>

        <Field
          name="quotaBreakdowns"
          items={items}
          theme="default"
          component={MultiSelectMenu}
          bubbleIcon={<Diagram />}
          bubbleColor={MultiSelectBubbleColor.PINK}
          errorMessage={formatMessage('NO_QUOTA_COMPONENTS')}
          placeHolderText={formatMessage('QUOTA_BREAKDOWN_BY_PLACEHOLDER_TEXT')}
          loadingComplete
          dropdownTooltipText={formatMessage('QUOTA_BREAKDOWN_BY_TOOLTIP')}
          onChange={(selectedItems) => {
            if (
              values['hierarchyBasedTarget']?.value &&
              !selectedItems?.find((hierarchy) => hierarchy.value === values['hierarchyBasedTarget']?.value)
            ) {
              setFieldValue('hierarchyBasedTarget', null);
            }
          }}
          data-testid="battlecard-quota-breakdown-select"
        />
        {hasExistingQuotaBreakdown && (
          <div
            className={b('quotaBreakdownWarningMessageContainer')}
            data-testid="battlecard-quota-breakdown-warning-message"
          >
            <Tag intent="warning" large icon={<Icon icon={<WarningAltFilled size={20} />} />}>
              <span className={b('quotaBreakdownWarningMessage')}>{formatMessage('QUOTA_BREAKDOWN_WARNING')}</span>
            </Tag>
          </div>
        )}
      </div>
    </div>
  );
};

const HierarchyBasedTargetSettingField = () => {
  const { values } = useFormikContext();
  const [isHierarchyLimitFlag] = useTreatment(SplitFeatures.HBTS_WITH_100_HIERARCHY_LIMIT);
  const hierarchyTargetLimitCount = isHierarchyLimitFlag
    ? HIERARCHY_TARGET_SETTING_MAX_100_MEMBER_COUNT
    : HIERARCHY_TARGET_SETTING_MAX_20_MEMBER_COUNT;
  // target based setting options are the one selected in quota breakdown and not custom account
  // selection is enabled if the hierarchy meet this condition: 1 <= # of 1st level members <= HIERARCHY_TARGET_SETTING_MAX_MEMBER_COUNT
  const allOptions = values['quotaBreakdowns']?.filter(
    (hierarchy) => hierarchy.type !== HierarchyType.CustomerAccountHierarchy
  );
  const disabledOptions = allOptions.filter(
    (hierarchy) => hierarchy.firstLevelMemberCount > hierarchyTargetLimitCount || hierarchy.firstLevelMemberCount < 1
  );

  return (
    !!allOptions?.length && (
      <div className={b('cardItemContainer')} data-testid="hierarchy-based-target-container">
        <div className={b('cardItem')}>
          <div className={b('cardItemLabel')} data-testid="hierarchy-based-target-label">
            {formatMessage('HIERARCHY_BASED_TARGET_SETTING')}
          </div>
          <Field
            name="hierarchyBasedTarget"
            items={allOptions}
            theme="default"
            component={SelectMenu}
            placeHolderText={formatMessage('SELECT_A_HIERARCHY')}
            dropdownTooltipText={formatMessage('HIERARCHY_BASED_TARGET_SETTING_TOOLTIP', {
              maxMemberCount: hierarchyTargetLimitCount
            })}
            disabledItems={disabledOptions}
            data-testid="hierarchy-based-target-select"
          />
        </div>
      </div>
    )
  );
};

const SellerField = () => {
  const { values } = useFormikContext();

  return (
    <div className={b('cardItemContainer')} data-testid="battlecard-owner-container">
      <div className={b('cardItem')}>
        <div className={b('cardItemLabel')} data-testid="battlecard-owner-label">
          {formatMessage('OWNER_WITH_COLON')}
        </div>
        <Field
          name="battleCardOwner"
          theme="default"
          component={SellerSelect}
          isSellerDisabled={(seller) => {
            const existingSellers = values['reorderableListItems']?.filter((item) => {
              return item.owner?.value === seller;
            });

            return !!existingSellers.length;
          }}
          data-testid="battlecard-owner-select"
        />
      </div>
    </div>
  );
};

const BattleCardDesignerInitialSetupPage: React.FC<BattleCardDesignerInitialSetupPageProps> = ({
  parentBattleCardType,
  editingBattleCardId,
  rootHierarchies,
  blankReorderableListItem,
  parentRef
}: BattleCardDesignerInitialSetupPageProps) => {
  const { values } = useFormikContext<FormValues>();
  const { selectedPlanningCycle } = useScope();
  const { currencies } = useCurrency();
  const { currenciesInUse } = useLocalization();

  const { quotaComponentList, battleCardLookupMap } = useBattleCard();

  const [fileData, setFileData] = useState<GetFileList_getPlanningCycleSpec_tables_tableList[] | null>(null);
  const [totalFileCount, setTotalFileCount] = useState<number | null>(null);

  const [isQuotaBreakdownEnabled] = useTreatment(SplitFeatures.BREAKDOWN_OF_QUOTA_BY_HIERARCHIES);
  const [isHierarchyBasedTargetEnabled] = useTreatment(SplitFeatures.HIERARCHY_BASED_TOP_DOWN_TARGET_SETTING);

  const currencyItems = getSortedCurrencyItems(currencies, currenciesInUse);

  // If in edit mode and card being edited has children, we don't want to change the battlecard type
  const hasChildren =
    editingBattleCardId && battleCardLookupMap && battleCardLookupMap[editingBattleCardId]?.children?.length > 0;

  // If current card type is primary team, and it has any hierarchies included, we want to disable the card type selection dropdown
  const hasTerritoryGroupTypes = values['reorderableListItems'].filter((item) => !!item.hierarchies)?.length > 0;
  const isPrimaryCardWithTerritoryGroups =
    values['cardType']?.value === BattlecardType.PrimaryTeam && hasTerritoryGroupTypes;

  const isRollUpBC = values?.['cardType']?.value === BattlecardType.Rollup;

  const isPrimaryCardWithDirectOverlay = values['cardType']?.value === BattlecardType.DirectOverlay;
  const hasExistingQuotaBreakdown =
    editingBattleCardId &&
    battleCardLookupMap &&
    battleCardLookupMap[editingBattleCardId]?.quotaDistributionHierarchies?.length > 0;

  // Set card type selection drop down list content based on parent card type
  let cardTypeSelectionDropDownData;
  if (parentBattleCardType === BattlecardType.Rollup) {
    cardTypeSelectionDropDownData = cardTypeSelectionDropDownDataRollup;
  } else if (parentBattleCardType === BattlecardType.PrimaryTeam) {
    cardTypeSelectionDropDownData = cardTypeSelectionDropDownDataPrimaryTeam;
  }

  let quotaComponentDropdownData = [];

  const getQuotaComponentDropdownData = () => {
    if (quotaComponentList?.length > 0) {
      quotaComponentDropdownData = quotaComponentList?.map((quotaComponent) => {
        return {
          key: quotaComponent.quotaComponentName,
          value: quotaComponent.quotaComponentId
        };
      });
    }
  };

  getQuotaComponentDropdownData();

  const disabledQuotaComponentDropdownData = [];

  // generate disabled quota components for a battle card according to it's children's qc
  // only look for children battle card with non-overlay type
  const getDisabledQuotaComponentDropdownData = () => {
    const childrenQCSet = new Set();
    const children = battleCardLookupMap[editingBattleCardId]?.children;
    if (children?.length > 0) {
      for (const child of children) {
        const childBattleCardInfo = battleCardLookupMap[child];
        const isOverlayBattleCard =
          childBattleCardInfo.battlecardType === BattlecardType.DirectOverlay ||
          childBattleCardInfo.battlecardType === BattlecardType.IndirectOverlay;

        if (!isOverlayBattleCard) {
          for (const qc of childBattleCardInfo.quotaComponents) {
            childrenQCSet.add(qc.quotaComponentName);
          }
        }
      }

      for (const qc of quotaComponentDropdownData) {
        if (childrenQCSet.has(qc.key)) {
          disabledQuotaComponentDropdownData.push(qc.key);
        }
      }
    }
  };

  getDisabledQuotaComponentDropdownData();

  const quotaBeakdownDropdownData = !rootHierarchies
    ? []
    : Object.keys(rootHierarchies)
        .filter(
          (rootHierarchyId) =>
            rootHierarchies[rootHierarchyId]['hierarchyTreeData'].hierarchyType !==
            HierarchyType.GeographicTerritoryHierarchy
        )
        .map((rootHierarchyId) => ({
          key: rootHierarchies[rootHierarchyId]['hierarchyTreeData'].label,
          value: rootHierarchyId,
          type: rootHierarchies[rootHierarchyId]['hierarchyTreeData'].hierarchyType,
          firstLevelMemberCount: rootHierarchies[rootHierarchyId]['hierarchyTreeData'].firstLevelMemberCount
        }));

  const [loadFileList, { data: fileList }] = useLazyQuery<GetFileList, GetFileListVariables>(GET_FILE_LIST, {
    variables: {
      planningCycleId: selectedPlanningCycle?.id,
      tableInput: {
        uploadFilter: {
          fileTypes: [FileTypeEnum.Activity],
          statuses: [FileStatus.COMPLETED]
        }
      }
    },
    fetchPolicy: 'network-only',
    onError({ graphQLErrors, networkError }) {
      handleError(graphQLErrors, networkError);
    }
  });

  // TODO TQP-3575 We can remove this block after adding infinite loading to the multi select menu.
  useEffect(() => {
    const total = fileList?.getPlanningCycleSpec?.tables?.totalNumOfTables;

    if (total === 0) {
      setTotalFileCount(0);
      setFileData([]);
      return;
    }

    if (fileData || !fileList) {
      return;
    }

    if (totalFileCount === null && total > 1) {
      setTotalFileCount(total);

      // Since backend only returns the first 30 items if no startRow and endRow are provided, we have to get the totalCount first and
      // call loadFileList again to fetch all the files
      loadFileList({
        variables: {
          planningCycleId: selectedPlanningCycle?.id,
          tableInput: {
            startRow: 1,
            endRow: total,
            uploadFilter: {
              fileTypes: [FileTypeEnum.Activity],
              statuses: [FileStatus.COMPLETED]
            }
          }
        }
      });
    } else {
      setFileData(fileList?.getPlanningCycleSpec?.tables?.tableList);
    }
  }, [fileList]);

  return (
    <div data-testid="battle-card-designer-initial-setup-page" className={b('cardsContainer')}>
      {!parentBattleCardType ? (
        <>
          <SellerField />
          <div className={b('parentBattleCardSpacer')} />
          <QuotaComponentsField
            quotaComponentCount={quotaComponentList?.length}
            items={quotaComponentDropdownData}
            disabledQuotaComponents={disabledQuotaComponentDropdownData}
          />
        </>
      ) : (
        <>
          <div className={b('cardItemContainer')}>
            <div className={b('cardItem')} data-testid="battlecard-name-container">
              <div className={b('cardItemLabel')} data-testid="battlecard-name-label">
                {formatMessage('NAME_WITH_COLON')}
              </div>
              <Field
                name="cardName"
                theme="default"
                placeHolder={formatMessage('UNTITLED')}
                component={FormTextInputGroup}
              />
            </div>
            <div className={b('cardItem')} data-testid="battlecard-currency-container">
              <div className={b('cardItemLabel')} data-testid="battlecard-currency-label">
                {formatMessage('LOCAL_CURRENCY_WITH_COLON')}
              </div>
              <Field
                name="currency"
                theme="default"
                items={currencyItems}
                currenciesInUse={currenciesInUse}
                component={CurrencySelect}
                showErrors={false}
                disabled={!parentBattleCardType} // disable currency selection on root battle card (user should set on planning cycle page)
                data-testid="battlecard-currency-select"
              />
            </div>
          </div>
          <div className={b('cardItemContainer')} data-testid="battlecard-type-container">
            <div className={b('cardItem')}>
              <div className={b('cardItemLabel')} data-testid="battlecard-type-label">
                {formatMessage('CARD_TYPE_TEAM_FUNCTION')}
              </div>
              <Field
                name="cardType"
                items={cardTypeSelectionDropDownData ?? []}
                theme="default"
                component={SelectMenu}
                parentRef={parentRef}
                usePortal
                allowFlip={false}
                showErrors={false}
                disabled={
                  !cardTypeSelectionDropDownData ||
                  hasChildren ||
                  isPrimaryCardWithTerritoryGroups ||
                  isPrimaryCardWithDirectOverlay
                }
                data-testid="battlecard-type-select"
              />
            </div>
          </div>
          <SellerField />
          <QuotaComponentsField
            quotaComponentCount={quotaComponentList?.length}
            items={quotaComponentDropdownData}
            disabledQuotaComponents={disabledQuotaComponentDropdownData}
          />

          {isQuotaBreakdownEnabled && !isRollUpBC && (
            <QuotaBreakdownField
              quotaBreakdownCount={quotaBeakdownDropdownData.length}
              items={quotaBeakdownDropdownData}
              hasExistingQuotaBreakdown={hasExistingQuotaBreakdown}
            />
          )}

          {isHierarchyBasedTargetEnabled && <HierarchyBasedTargetSettingField />}

          {!isRollUpBC && (
            <>
              <div className={b('cardItemContainer')} data-testid="battlecard-activity-file-container">
                <div className={b('multiSelectContainer')}>
                  <div className={b('cardItemLabel')} data-testid="battlecard-activity-file-label">
                    {formatMessage('ACTIVITY_FILE_WITH_COLON')}
                  </div>
                  <Field
                    name="activityFiles"
                    items={
                      fileData?.map((file) => {
                        return { key: file.tableName, value: file.tableId };
                      }) ?? []
                    }
                    theme="default"
                    component={MultiSelectMenu}
                    bubbleIcon={<DataTable />}
                    bubbleColor={MultiSelectBubbleColor.BLUE}
                    onSelectOpen={loadFileList}
                    loadingComplete={fileData !== null}
                    errorMessage={formatMessage('NO_ACTIVITY_FILES')}
                    data-testid="battlecard-activity-file-select"
                  />
                </div>
              </div>
              <div className={b('cardItemContainer')} data-testid="territory-group-type-container">
                <div className={b()} data-testid="territory-group-type-section">
                  <div data-testid="territory-group-type-title" className={b('title')}>
                    {formatMessage('DEFINE_TERRITORY_GROUP_TYPES')}
                  </div>
                  <div data-testid="territory-group-type-description" className={b('description')}>
                    {formatMessage('DEFINE_TERRITORY_GROUP_TYPES_DESCRIPTION')}
                  </div>
                  <div data-testid="territory-group-type-include-message" className={b('message')}>
                    {formatMessage('TERRITORY_GROUP_TYPE_INCLUDE_MESSAGE')}
                  </div>
                </div>
                <div className={b('reorderListHeader')}>
                  <span className={b('reorderableListHeaderItem')} data-testid="territory-group-type-header">
                    {formatMessage('TERRITORY_GROUP_TYPE')}
                    <div className={b('star')}>*</div>
                  </span>
                  <span className={b('reorderableListHeaderItem')} data-testid="territory-group-refer-to-as-header">
                    {formatMessage('REFER_TO_AS')}
                  </span>
                  <span className={b('reorderableListHeaderItem')} data-testid="territory-group-owner-header">
                    {formatMessage('OWNER')}
                  </span>
                </div>
                <ReorderableList
                  blankReorderableListItem={blankReorderableListItem}
                  addItemText={formatMessage('ADD_TERRITORY_GROUP_TYPE')}
                >
                  <BattleCardDesignerReorderableListItem rootHierarchies={rootHierarchies} />
                </ReorderableList>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default BattleCardDesignerInitialSetupPage;
