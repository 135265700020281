import React, { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';

import { ICellRendererParams } from '@ag-grid-community/core';
import { useLazyQuery } from '@apollo/client';
import { TrashCan } from '@carbon/icons-react';
import { HTMLHeading } from '@varicent/components';
import dayjs from 'dayjs';
import { Field, Form, Formik, FormikProps } from 'formik';
import debounce from 'lodash.debounce';

import TextButton from 'components/Buttons/TextButton/TextButton';

import ConfirmDeleteHierarchyModal from 'app/components/CommandCenterHierarchyPanel/ConfirmDeleteHierarchyModal';
import FormDatePicker from 'app/components/FormFields/FormDatePicker/FormDatePicker';
import FormTextInputGroup from 'app/components/FormFields/FormTextInputGroup/FormTextInputGroup';
import TextMessageInputField from 'app/components/FormFields/TextMessageInputField/TextMessageInputField';
import Split from 'app/components/Split/Split';

import { debounceDelay } from 'app/constants/DebounceConstants';

import { useScope } from 'app/contexts/scopeProvider';

import { SplitFeatures } from 'app/global/features';

import {
  GetAllHierarchiesVariables,
  GetSubtreeCustomerAccountHierarchies_getSubtreeCustomerAccountHierarchies,
  GetSubtreeCustomHierarchies_getSubtreeCustomHierarchies,
  GetSubtreeGeographicTerritoryHierarchies_getSubtreeGeographicTerritoryHierarchies
} from 'app/graphql/generated/graphqlApolloTypes';
import { GET_ALL_HIERARCHIES } from 'app/graphql/queries/getAllHierarchies';
import { GET_HIERARCHY_BY_KEY } from 'app/graphql/queries/getHierarchyByKey';
import { GET_ROOT_HIERARCHIES } from 'app/graphql/queries/getRootHierarchies';

import useTreatment from 'app/hooks/useTreatment';

import { CommandCenterHierarchyPanelContentFormValues, HierarchyAttributeType, HierarchyType } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './CommandCenterHierarchyPanelContent.module.pcss';
import CommandCenterHierarchyPanelContentAttributeFields from './CommandCenterHierarchyPanelContentAttributeFields/CommandCenterHierarchyPanelContentAttributeFields';
import { useUpsertHierarchy } from './hooks/useUpsertHierarchy';
import validationSchema from './validationSchema';

const b = block(style);

interface CommandCenterHierarchyPanelContentProps {
  selectedNode: ICellRendererParams;
  setSelectedNode: Dispatch<SetStateAction<ICellRendererParams>>;
  hierarchyType: HierarchyType;
  rootHierarchy; // Not able to set the type due to a type conflict of hierarchyType between frontend and backend
  setInitialBlock: Dispatch<
    SetStateAction<
      | GetSubtreeCustomHierarchies_getSubtreeCustomHierarchies
      | GetSubtreeCustomerAccountHierarchies_getSubtreeCustomerAccountHierarchies
      | GetSubtreeGeographicTerritoryHierarchies_getSubtreeGeographicTerritoryHierarchies
    >
  >;
  searchString?: string;
  setSearchString?: Dispatch<SetStateAction<string>>;
  getAllHierarchiesVariables?: GetAllHierarchiesVariables;
  isReadOnly?: boolean;
}

const CommandCenterHierarchyPanelContent: React.FC<CommandCenterHierarchyPanelContentProps> = ({
  selectedNode,
  hierarchyType,
  setSelectedNode,
  rootHierarchy,
  setInitialBlock,
  searchString = '',
  getAllHierarchiesVariables,
  setSearchString,
  isReadOnly = false
}: CommandCenterHierarchyPanelContentProps) => {
  const { data: nodeData } = selectedNode;
  const isCreateMode = !nodeData?.hierarchyId;
  const { selectedPlanningCycle } = useScope();
  const [showDeleteHierarchyDialog, setShowDeleteHierarchyDialog] = useState<boolean>(false);
  const [isKeyValidated, setIsKeyValidated] = useState<boolean>(!!nodeData?.key);
  const [shouldShowSuccessIcon, setShouldShowSuccessIcon] = useState<boolean>(false);
  const [shouldShowMessage, setShouldShowMessage] = useState<boolean>(false);

  const [isHierarchyEffectiveDatingOn] = useTreatment(SplitFeatures.HIERARCHY_EFFECTIVE_DATING);

  const formRef = useRef<FormikProps<CommandCenterHierarchyPanelContentFormValues>>();

  // To reset checkmark when switching tree nodes
  useEffect(() => {
    setShouldShowSuccessIcon(false);
  }, [selectedNode]);

  const [upsertHierarchy, { loading: upsertingHierarchy }] = useUpsertHierarchy({
    hierarchyType,
    isCreateMode,
    rootHierarchyName: rootHierarchy?.rootName,
    nodeData,
    selectedNode,
    setSelectedNode,
    setInitialBlock,
    searchString,
    setSearchString
  });

  const FormRow = (label1, name1, label2?, name2?) => {
    return (
      <div className={b('formRow')}>
        <div className={b('formFieldFirst')}>
          <Field
            label={
              <div className={b('formFieldLabel')} data-testid={`${label1}-label`}>
                {label1}
              </div>
            }
            name={name1}
            theme="default"
            component={FormTextInputGroup}
          />
        </div>
        {label2 && name2 && (
          <div className={b('formFieldSecond')}>
            <Field
              label={
                <div className={b('formFieldLabel')} data-testid={`${label2}-label`}>
                  {label2}
                </div>
              }
              name={name2}
              theme="default"
              component={FormTextInputGroup}
            />
          </div>
        )}
      </div>
    );
  };

  const customAttributes = rootHierarchy.attributes?.filter((attr) => attr.editable === true);
  const customProperties =
    isCreateMode || !nodeData?.customProperties ? [] : JSON.parse(nodeData?.customProperties)?.properties;

  const customAttributeValue = customAttributes.map((attr) => {
    const attribute = customProperties?.find((property) => property.name === attr.name);
    return {
      name: attr.name,
      val: attribute ? attribute.val : null,
      type: attr.type
    };
  });

  const formatDate = (date: Date) => {
    if (date === null) return null;
    return dayjs(date).format('YYYY-MM-DD');
  };

  const handleSubmit = (values) => {
    values.customProperties?.forEach((property) => {
      if (property.type === HierarchyAttributeType.NUMERIC) {
        property.val = !property.val ? null : Number(property.val);
      } else if (property.type === HierarchyAttributeType.DATE) {
        property.val = formatDate(property.val);
      }
    });

    const variables = {
      hierarchyId: nodeData?.hierarchyId,
      planningCycleId: selectedPlanningCycle?.id,
      name: values.hierarchyName,
      key: values.hierarchyKey,
      parentKey: nodeData?.parentKey,
      version: 1,
      customProperties: JSON.stringify({ properties: values.customProperties || [] })
    };

    if (hierarchyType === HierarchyType.CustomHierarchy) {
      upsertHierarchy({
        variables: {
          ...variables,
          rootId: rootHierarchy?.rootHierarchyId,
          effectiveDate: formatDate(values.effectiveDate),
          endDate: formatDate(values.endDate)
        },
        refetchQueries
      });
    } else if (hierarchyType === HierarchyType.CustomerAccountHierarchy) {
      upsertHierarchy({
        variables: {
          ...variables,
          address1: values.address1,
          address2: values.address2,
          city: values.city,
          country: values.country,
          customerAccountNumber: values.hierarchyKey,
          industry: values.industry,
          zipPostal: values.zipPostal,
          stateProvince: values.stateProvince,
          effectiveDate: formatDate(values.effectiveDate),
          endDate: formatDate(values.endDate)
        },
        refetchQueries
      });
    } else {
      upsertHierarchy({
        variables: { ...variables, geographicTerritoryId: values.hierarchyKey },
        refetchQueries
      });
    }
  };

  const [hierarchyExistsByKey, { data: hierarchyExists, loading: hierarchyExistsLoading }] = useLazyQuery(
    GET_HIERARCHY_BY_KEY,
    { fetchPolicy: 'network-only' }
  ); // Set network-only to avoid returning not exist for a key that was just created

  // When the key value changes or hierarchyExists updates, set success icon/message or error icon/message accordingly
  useEffect(() => {
    setIsKeyValidated(false);
    setShouldShowSuccessIcon(false);
    setShouldShowMessage(false);
    if (!formRef?.current) {
      return;
    }
    const { setFieldTouched, values, setFieldError } = formRef?.current;

    if (!hierarchyExistsLoading && hierarchyExists) {
      const keyExists = !!hierarchyExists?.getHierarchyByKey;

      if (keyExists) {
        setFieldTouched('hierarchyKey', true, false);
        setFieldError('hierarchyKey', formatMessage('KEY_EXISTS_ERROR', { value: values.hierarchyKey }));
      }

      setIsKeyValidated(!keyExists);
      setShouldShowSuccessIcon(!keyExists);
      setShouldShowMessage(!keyExists);
    }
  }, [hierarchyExists, hierarchyExistsLoading, formRef]);

  const handleHierarchyKeyValidation = () => {
    if (!formRef?.current) {
      return;
    }

    const { values, isSubmitting } = formRef?.current;

    const hierarchyKey = values?.hierarchyKey;

    // in edit mode and hierarchy key has not been changed -> key is valid
    if (hierarchyKey?.trim()?.toLowerCase() === nodeData?.hierarchyKey?.trim().toLowerCase()) {
      setIsKeyValidated(true);
      return;
    }

    if (!hierarchyKey || isSubmitting) {
      return;
    }

    const getHierarchyByKeyVariables = {
      hierarchyType,
      hierarchyKey,
      planningCycleId: selectedPlanningCycle?.id,
      version: 1
    };

    hierarchyExistsByKey({
      variables: {
        ...getHierarchyByKeyVariables,
        ...(hierarchyType === HierarchyType.CustomHierarchy && {
          rootId: rootHierarchy?.rootHierarchyId
        })
      }
    });
  };

  const debounceHandler = useCallback(debounce(handleHierarchyKeyValidation, debounceDelay), []);

  const refetchQueries = [
    {
      query: GET_ROOT_HIERARCHIES,
      variables: { planningCycleId: selectedPlanningCycle?.id }
    }
  ];

  if (searchString !== '' && getAllHierarchiesVariables) {
    refetchQueries.push({
      query: GET_ALL_HIERARCHIES,
      variables: getAllHierarchiesVariables
    });
  }

  const handleCancelAddChild = () => {
    nodeData?.parent?.node?.setSelected(false);
    setSelectedNode(null);
  };

  const renderSecondaryAction = (isCreateAction) => {
    let button;
    if (isCreateAction) {
      button = (
        <TextButton
          type="button"
          text={formatMessage('CANCEL')}
          testId={'cancel-button'}
          onClick={handleCancelAddChild}
        />
      );
    } else {
      button = (
        <TextButton
          testId={'delete-button'}
          type="button"
          icon={<TrashCan />}
          text={formatMessage('DELETE')}
          minimal
          intent="danger"
          onClick={() => setShowDeleteHierarchyDialog(true)}
        />
      );
    }
    return <div className={b('button')}>{button}</div>;
  };

  const panelTitle = () => {
    if (isReadOnly) {
      return formatMessage('VIEW');
    }
    if (isCreateMode) {
      return formatMessage('CREATE_CHILD_UNDER', { value: nodeData?.parent?.value || formatMessage('ROOT') });
    }
    return formatMessage('EDIT_WITH_VALUE', { value: nodeData?.name });
  };

  const customerAccountValues = {
    address1: nodeData.address1,
    address2: nodeData.address2,
    city: nodeData.city,
    country: nodeData.country,
    industry: nodeData.industry,
    zipPostal: nodeData.zipPostal,
    stateProvince: nodeData.stateProvince
  };

  const initialValues: CommandCenterHierarchyPanelContentFormValues = {
    hierarchyName: nodeData.name,
    hierarchyKey: nodeData.key,
    effectiveDate: nodeData.effectiveDate,
    endDate: nodeData.endDate,
    ...(hierarchyType === HierarchyType.CustomerAccountHierarchy && customerAccountValues),
    customProperties: customAttributeValue
  };

  return (
    <div className={b()} data-testid="command-center-hierarchy-panel-content">
      {nodeData && (
        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({ values, errors, touched }) => (
            <div data-testid="hierarchy-panel-form">
              <Form>
                <div className={b('header')}>
                  <HTMLHeading tagLevel="h4" text={panelTitle()} data-testid="hierarchy-name-header" />
                </div>
                <div className={b('container')}>
                  <div className={b('containerHeader')} data-testid="form-header">
                    <HTMLHeading tagLevel="h5" text={formatMessage('DETAILS')} />
                  </div>
                  <div className={b('form')}>
                    <Field
                      label={
                        <div className={b('formFieldLabel')} data-testid="Name-label">
                          {formatMessage('NAME')}
                          <div className={b('star')}>*</div>
                        </div>
                      }
                      name="hierarchyName"
                      theme="default"
                      type="text"
                      placeHolder={formatMessage('UNTITLED')}
                      component={FormTextInputGroup}
                      disabled={isReadOnly}
                    />
                    <TextMessageInputField
                      name="hierarchyKey"
                      textLabel={
                        <div className={b('formFieldLabel')} data-testid="Key-label">
                          {formatMessage('KEY')}
                          <div className={b('star')}>*</div>
                        </div>
                      }
                      textPlaceholder={formatMessage('ADD')}
                      isLoading={hierarchyExistsLoading}
                      shouldShowSuccessIcon={shouldShowSuccessIcon}
                      shouldShowErrorIcon={!!hierarchyExists?.getHierarchyByKey}
                      isMessageAvailable={hierarchyExists && !!values?.hierarchyKey}
                      shouldShowMessage={shouldShowMessage}
                      message={
                        <div className={b('message')} data-testid="message">
                          <span className={b('messageBold')}>"{values.hierarchyKey}"</span>
                          {formatMessage('IS_AVAILABLE')}
                        </div>
                      }
                      onChange={() => {
                        setIsKeyValidated(false);
                        setShouldShowSuccessIcon(false);
                        setShouldShowMessage(false);
                        debounceHandler();
                      }}
                      onTransitionEnd={() => setShouldShowMessage(false)}
                      disabled={isReadOnly}
                    />
                    {isHierarchyEffectiveDatingOn && (
                      <div className={b('effectiveDate')}>
                        <Field
                          label={
                            <div className={b('formFieldLabel')} data-testid="effective-date-label">
                              {formatMessage('EFFECTIVE_DATE')}
                            </div>
                          }
                          name="effectiveDate"
                          theme="default"
                          component={FormDatePicker}
                          dateFormat={'yyyy-MM-dd'}
                          maxDate={values.endDate}
                        />

                        <div className={b('endDate')}>
                          <Field
                            label={
                              <div className={b('formFieldLabel')} data-testid="end-date-label">
                                {formatMessage('END_DATE')}
                              </div>
                            }
                            name="endDate"
                            theme="default"
                            component={FormDatePicker}
                            dateFormat={'yyyy-MM-dd'}
                            minDate={values.effectiveDate}
                          />
                        </div>
                      </div>
                    )}

                    <Split
                      name={SplitFeatures.CUSTOM_HIERARCHY_ATTRIBUTES_V2}
                      splitTreatment={{
                        on: (
                          <CommandCenterHierarchyPanelContentAttributeFields
                            hierarchyType={hierarchyType}
                            data-testid="command-center-hierarchy-panel-content-attribute-fields"
                          />
                        )
                      }}
                      fallback={
                        <>
                          {hierarchyType === HierarchyType.CustomerAccountHierarchy && (
                            <div>
                              {FormRow(formatMessage('COUNTRY'), 'country', formatMessage('CITY'), 'city')}
                              {FormRow(
                                formatMessage('STATE_OR_PROVINCE'),
                                'stateProvince',
                                formatMessage('ZIP_OR_POSTAL'),
                                'zipPostal'
                              )}
                              {FormRow(formatMessage('ADDRESS1'), 'address1', formatMessage('ADDRESS2'), 'address2')}
                              {FormRow(formatMessage('INDUSTRY'), 'industry')}
                            </div>
                          )}
                        </>
                      }
                    />
                  </div>
                </div>
                {!isReadOnly && (
                  <div className={b('footer')} data-testid={'hierarchy-panel-content-footer'}>
                    {renderSecondaryAction(isCreateMode)}
                    <div className={b('button')}>
                      <TextButton
                        testId={isCreateMode ? 'add-button' : 'save-button'}
                        type="submit"
                        text={isCreateMode ? formatMessage('ADD') : formatMessage('SAVE_CHANGES')}
                        intent="primary"
                        disabled={
                          !values.hierarchyName ||
                          !values.hierarchyKey ||
                          (isCreateMode && !isKeyValidated) ||
                          (!isCreateMode && touched.hierarchyKey && !isKeyValidated) ||
                          !!errors.customProperties
                        }
                        loading={upsertingHierarchy}
                      />
                    </div>
                  </div>
                )}
              </Form>
              <ConfirmDeleteHierarchyModal
                selectedNode={selectedNode}
                rootHierarchy={rootHierarchy}
                showDeleteHierarchyDialog={showDeleteHierarchyDialog}
                setShowDeleteHierarchyDialog={setShowDeleteHierarchyDialog}
                setSelectedNode={setSelectedNode}
                setInitialBlock={setInitialBlock}
                searchString={searchString}
                setSearchString={setSearchString}
                getAllHierarchiesVariables={getAllHierarchiesVariables}
              />
            </div>
          )}
        </Formik>
      )}
    </div>
  );
};

export default CommandCenterHierarchyPanelContent;
