import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';

import { GridApi } from '@ag-grid-community/core';
import { useLazyQuery } from '@apollo/client';
import { HTMLHeading } from '@varicent/components';

import AdvancedGrid from 'app/components/AdvancedGrid/AdvancedGrid';
import { getPaginationCheck } from 'app/components/AdvancedGrid/GridHelper';
import buildActivityFilesPanelColumnDef from 'app/components/AdvancedGrid/GridHelpers/ActivityFilesPanel/ActivityFilesPanelColumnDef';

import { CELL_HEIGHT } from 'app/constants/DataTrayConstants';

import { useData } from 'app/contexts/dataProvider';
import { useScope } from 'app/contexts/scopeProvider';

import { BLOCK_SIZE } from 'app/global/variables';

import { FileTypeEnum, GetFileList, GetFileListVariables } from 'app/graphql/generated/graphqlApolloTypes';
import { handleError } from 'app/graphql/handleError';
import { GET_FILE_LIST } from 'app/graphql/queries/getFileList';

import { DataPanelViews, FileStatus } from 'app/models/index';

import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './UnpublishedTable.module.pcss';

const b = block(style);

interface UnpublishedTableProps {
  shouldRefetch: boolean;
  setSelectedDataView: Dispatch<SetStateAction<DataPanelViews>>;
  setShouldRefetch: Dispatch<SetStateAction<boolean>>;
}

const UnpublishedTable: React.FC<UnpublishedTableProps> = ({
  shouldRefetch,
  setSelectedDataView,
  setShouldRefetch
}: UnpublishedTableProps) => {
  const { selectedPlanningCycle } = useScope();
  const { setSelectedTable, fileUploadInProgress } = useData();

  const [gridApi, setGridApi] = useState<GridApi>(null);
  const containerRef = useRef(null);
  const tablesLoadingContainerRef = useRef(null);
  const [blockSize, setBlockSize] = useState<number>(BLOCK_SIZE);

  // set rowRef using tablesGridContainerRef
  // used for displaying loading states for specific cells in ag-grid
  const rowRef = {
    current: {
      ...containerRef?.current,
      offsetHeight: CELL_HEIGHT,
      offsetWidth: containerRef?.current?.offsetWidth
    }
  };

  useEffect(() => {
    if (containerRef?.current?.offsetHeight) {
      setBlockSize(Math.round((containerRef.current.offsetHeight / CELL_HEIGHT) * 2));
    }
  }, [containerRef]);

  const [getUnpublishedFiles, { data, loading: loadingFileList, fetchMore }] = useLazyQuery<
    GetFileList,
    GetFileListVariables
  >(GET_FILE_LIST, {
    variables: {
      planningCycleId: selectedPlanningCycle?.id,
      tableInput: {
        startRow: 1,
        endRow: blockSize,
        uploadFilter: {
          fileTypes: [FileTypeEnum.Activity],
          statuses: [FileStatus.PENDING, FileStatus.IN_PROGRESS, FileStatus.FAILED]
        }
      }
    },
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      if (data) {
        setShouldRefetch(false);
      }
    },
    onError({ graphQLErrors, networkError }) {
      handleError(graphQLErrors, networkError);
    }
  });

  const activityFiles = data?.getPlanningCycleSpec?.tables?.tableList || [];
  const totalCount = data?.getPlanningCycleSpec?.tables?.totalNumOfTables || 0;

  useEffect(() => {
    getUnpublishedFiles();
  }, []);

  useEffect(() => {
    if (shouldRefetch) {
      getUnpublishedFiles();
    }
  }, [shouldRefetch]);

  useEffect(() => {
    if (fileUploadInProgress.length === 0) {
      setShouldRefetch((prevState) => (prevState === false ? true : false));
    }
  }, [fileUploadInProgress]);

  useEffect(() => {
    if (totalCount && gridApi) {
      gridApi.setRowCount(totalCount); // set the vertical scroll for ag-grid
    }
  }, [totalCount, gridApi]);

  const onTableSelectionChanged = (params) => {
    const tableGridApi = params?.api as GridApi;
    const selectedRow = tableGridApi.getSelectedRows();

    // If the file is in progress, it means it is being uploaded to Symon, so we don't want user to click into the file details until it's completed
    if (selectedRow?.[0].status === FileStatus.IN_PROGRESS_GET_FILE) {
      return;
    }
    setSelectedDataView(DataPanelViews.TABLE_DETAIL);
    setSelectedTable(selectedRow?.[0]);
  };

  const onGridReady = (params) => {
    setGridApi(params?.api);
  };

  const fileListPanelGridProps = {
    defaultColDef: {
      resizable: true
    },
    rowModelType: 'infinite',
    cacheBlockSize: blockSize,
    cacheOverflowSize: 2,
    infiniteInitialRowCount: totalCount,
    maxBlocksInCache: 10,
    getRowNodeId: (row) => row.tableId,
    datasource: {
      rowCount: totalCount,
      getRows: async (params) => {
        if (params?.endRow === blockSize) {
          // no need to query for the first block as we already fetched it in the initial load
          params?.successCallback(activityFiles, totalCount);
        } else {
          // ag-grid starts row count from 0 and the backend pagination starts from 1
          if (getPaginationCheck(params.startRow, totalCount)) {
            const fetchMoreFiles = await fetchMore<GetFileList, GetFileListVariables>({
              variables: {
                planningCycleId: selectedPlanningCycle?.id,
                tableInput: {
                  startRow: params?.startRow + 1,
                  endRow: params?.endRow,
                  uploadFilter: {
                    fileTypes: [FileTypeEnum.Activity],
                    statuses: [FileStatus.PENDING, FileStatus.IN_PROGRESS, FileStatus.FAILED]
                  }
                }
              }
            });
            params?.successCallback(
              fetchMoreFiles?.data?.getPlanningCycleSpec?.tables?.tableList,
              fetchMoreFiles?.data?.getPlanningCycleSpec?.tables?.totalNumOfTables
            );
          }
        }
      }
    }
  };

  return (
    <div className={b()}>
      <div className={b('headingContainer')}>
        <HTMLHeading tagLevel={'h5'} bold text={formatMessage('UNPUBLISHED')} />
      </div>
      <div className={b('tablesGrid')} ref={tablesLoadingContainerRef}>
        <div className={`ag-theme-alpine ${b('grid')}`} ref={containerRef}>
          {loadingFileList || activityFiles?.length > 0 ? (
            <AdvancedGrid
              gridProps={fileListPanelGridProps}
              columnDefs={buildActivityFilesPanelColumnDef({ totalCount, rowRef })}
              rowSelection="single"
              noDataMessage={formatMessage('NO_ACTIVITY_FILES')}
              onGridReady={onGridReady}
              data-testid="tables-grid"
              showGridLoading={loadingFileList}
              onSelectionChanged={onTableSelectionChanged}
              gridWidth={tablesLoadingContainerRef?.current?.offsetWidth}
              gridHeight={tablesLoadingContainerRef?.current?.offsetHeight}
            />
          ) : (
            <div className={b('noData')} data-testid="tables-grid-empty">
              {formatMessage('EMPTY_GRID')}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UnpublishedTable;
