import { useMutation } from '@apollo/client';

import { useData } from 'app/contexts/dataProvider';

import {
  StartDataPreviewFromSymonPipe,
  StartDataPreviewFromSymonPipeVariables
} from 'app/graphql/generated/graphqlApolloTypes';
import { handleError } from 'app/graphql/handleError';
import { START_DATA_PREVIEW_FROM_SYMON_PIPE } from 'app/graphql/mutations/startDataPreviewFromSymonPipe';

// eslint-disable-next-line no-restricted-imports
import showToast from 'utils/helpers/showToast';
import { formatMessage } from 'utils/messages/utils';

interface UseGetSymonPipeDataPreviewReturn {
  pollForConfigurationPreviewTokenId;
}

export const useStartSymonPipeDataPreview = (
  exportId: string,
  pipeId: string,
  planningCycleId: number
): UseGetSymonPipeDataPreviewReturn => {
  const { setPollingTokenId } = useData();

  const [pollForConfigurationPreviewTokenId] = useMutation<
    StartDataPreviewFromSymonPipe,
    StartDataPreviewFromSymonPipeVariables
  >(START_DATA_PREVIEW_FROM_SYMON_PIPE, {
    variables: {
      exportId,
      pipeId,
      planningCycleId
    },
    notifyOnNetworkStatusChange: true,
    onError({ graphQLErrors, networkError }) {
      handleError(graphQLErrors, networkError);
      // eslint-disable-next-line deprecation/deprecation
      showToast(formatMessage('SYMON_PIPE_CONFIGURATIONS_ERROR'), 'danger');
    },
    onCompleted(data) {
      const { startDataPreviewFromSymonPipe } = data;
      if (!!startDataPreviewFromSymonPipe.pollingTokenId) {
        setPollingTokenId(startDataPreviewFromSymonPipe.pollingTokenId);
      }
    }
  });

  return { pollForConfigurationPreviewTokenId };
};
