import React, { useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { Divider, InputGroup } from '@blueprintjs/core';
import { ArrowLeft, Edit, Renew, Save } from '@carbon/icons-react';
import { HTMLHeading } from '@varicent/components';

import IconButton from 'components/Buttons/IconButton/IconButton';
import TextButton from 'components/Buttons/TextButton/TextButton';

import SheetController from 'app/components/DataPanel/SheetsPanel/SheetDetail/SheetController';

import { useData } from 'app/contexts/dataProvider';
import { useScope } from 'app/contexts/scopeProvider';

import {
  GetDataSheets,
  GetDataSheetsVariables,
  UpsertSheet,
  UpsertSheetVariables
} from 'app/graphql/generated/graphqlApolloTypes';
import { handleError } from 'app/graphql/handleError';
import { UPSERT_SHEET } from 'app/graphql/mutations/upsertSheet';
import { GET_DATA_SHEETS } from 'app/graphql/queries/getDataSheets';

import useShowToast from 'app/hooks/useShowToast';

import { DataPanelViews } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './QuotaSheetHeader.module.pcss';

const b = block(style);

const validateName = (name: string): boolean => {
  return name !== '';
};

interface QuotaSheetHeaderProps {
  permissionRowData: React.ReactNode[];
  bcRowData: React.ReactNode[];
  qcRowData: React.ReactNode[];
  getAllBattleCards: () => void;
}

const QuotaSheetHeader: React.FC<QuotaSheetHeaderProps> = ({
  permissionRowData,
  bcRowData,
  qcRowData,
  getAllBattleCards
}) => {
  const [editSheetName, setEditSheetName] = useState<boolean>(false);
  const { selectedSheet, setSelectedSheet, setSelectedDataView } = useData();
  const { selectedDeploymentModelId } = useScope();
  const showToast = useShowToast();

  const { data: sheetsData } = useQuery<GetDataSheets, GetDataSheetsVariables>(GET_DATA_SHEETS, {
    variables: { deploymentModelId: selectedDeploymentModelId }
  });

  const [renameSheet, { loading: upsertSheetLoading }] = useMutation<UpsertSheet, UpsertSheetVariables>(UPSERT_SHEET, {
    onCompleted() {
      showToast(formatMessage('SHEET_RENAME_SUCCESS'), 'success');
      setEditSheetName(false);
      setSelectedSheet(
        sheetsData.getDeploymentModelSpec.dataSheets.find((sheet) => sheet.sheetId === selectedSheet.sheetId)
      );
    },
    onError({ graphQLErrors, networkError }) {
      handleError(graphQLErrors, networkError);
      showToast(graphQLErrors[0].message, 'danger');
    },
    refetchQueries: [{ query: GET_DATA_SHEETS, variables: { deploymentModelId: selectedDeploymentModelId } }],
    awaitRefetchQueries: true
  });

  let sheetNameInput = selectedSheet?.sheetName;

  const handleBackClicked = () => {
    setSelectedDataView(DataPanelViews.DATA_OVERVIEW);
    setSelectedSheet(null);
  };

  const handleSheetNameChange = () => {
    if (validateName(sheetNameInput)) {
      renameSheet({
        variables: {
          input: {
            dataSheets: [{ sheetId: selectedSheet.sheetId, sheetName: sheetNameInput }]
          }
        }
      });
    } else {
      showToast(formatMessage('QUOTA_BLANK_NAME_ERROR'), 'danger');
    }
  };

  return (
    <div className={b('header')}>
      <div className={b('leftActionItems')}>
        <IconButton
          className={b('backIcon')}
          type="button"
          icon={<ArrowLeft />}
          onClick={handleBackClicked}
          testId="back-icon"
        />
        {editSheetName ? (
          <InputGroup
            defaultValue={selectedSheet?.sheetName}
            onChange={(e) => {
              sheetNameInput = e.target.value;
            }}
          />
        ) : (
          <HTMLHeading tagLevel={'h5'} text={selectedSheet?.sheetName} data-testid="sheets-title" />
        )}
      </div>
      <div className={b('rightActionItems')}>
        <div className={b('hiddenItem')}>
          <span className={b('rightActionText')}>
            {formatMessage('LAST_MODIFIED', { value: formatMessage('SYSTEM_TIMESTAMP') })}
          </span>
          <Renew />
        </div>
        {editSheetName ? (
          <div>
            <span className={b('sheetNameButtons')}>
              <TextButton
                text={formatMessage('CANCEL')}
                type={'button'}
                testId={'quota-sheet-header-cancel-button'}
                minimal
                large={false}
                disabled={upsertSheetLoading}
                onClick={() => setEditSheetName(false)}
              />
            </span>
            <span className={b('sheetNameButtons')}>
              <TextButton
                text={formatMessage('SAVE')}
                type={'button'}
                testId={'quota-sheet-header-save-button'}
                intent="primary"
                icon={<Save size={24} />}
                large={false}
                loading={upsertSheetLoading}
                onClick={handleSheetNameChange}
              />
            </span>
          </div>
        ) : (
          <>
            <IconButton
              type={'button'}
              icon={<Edit />}
              onClick={() => setEditSheetName(true)}
              tooltipText={formatMessage('EDIT_SHEET_NAME')}
              testId={'quota-sheet-header-edit-button'}
            />
            <Divider />
            <SheetController
              permissionRowData={permissionRowData}
              bcRowData={bcRowData}
              qcRowData={qcRowData}
              getAllBattleCards={getAllBattleCards}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default QuotaSheetHeader;
