import React, { useMemo } from 'react';

import { Filter, ChevronDown } from '@carbon/icons-react';
import { VariationPlacement } from '@popperjs/core';
import { Button, Intent } from '@varicent/components';

import { BasicSelect } from 'components/BasicSelect/BasicSelects';
import { BasicSelectItem } from 'components/BasicSelect/SelectPropsType';
import SwitchButton from 'components/Buttons/SwitchButton/SwitchButton';
import TextButton from 'components/Buttons/TextButton/TextButton';
import DropdownMenuDivider from 'components/DropdownMenu/DropdownMenuDivider';
import Popover from 'components/Popover/Popover';
import ToastMessage from 'components/ToastMessage/ToastMessage';

import { CustomHierarchyFilterMenu } from 'app/components/TerritoryMap/CustomHierarchyFilterMenu';

import { useDedicatedMapProvider } from 'app/contexts/dedicatedMapProvider';

import useShowToast from 'app/hooks/useShowToast';

import { CollectionFilter, NamedHierarchy, PrimaryTerritoryField } from 'app/models';

import block from 'utils/bem-css-modules';
import { createScopedSessionStorage } from 'utils/helpers/sessionStorageHelpers';
import { getTerritoryGroupLevelName } from 'utils/helpers/territoryMapUtils';
import { formatMessage } from 'utils/messages/utils';

import style from './MapGridFilter.module.pcss';

const b = block(style);

const suppressColorByToastStorage = createScopedSessionStorage<boolean>('MAP_HIDE_COLOR_BY_TERRITORY_GROUP_TOAST');

interface MapGridFilterProps {
  customHierarchies: NamedHierarchy[];
  customHierarchyFilter: CollectionFilter<number>;
  shouldShowCustomHierarchyFilter: boolean;
  primaryTerritoryField?: PrimaryTerritoryField;
  onPrimaryTerritoryFieldChange?: (field: PrimaryTerritoryField) => void;
}
const MapGridFilter: React.FC<MapGridFilterProps> = ({
  customHierarchies,
  customHierarchyFilter,
  shouldShowCustomHierarchyFilter,
  primaryTerritoryField,
  onPrimaryTerritoryFieldChange
}) => {
  const showToast = useShowToast();
  const {
    groupLevelList,
    territoryGroupLevel,
    setTerritoryGroupLevel,
    setShouldColorByTerritoryGroup,
    isColoringByTerritoryGroup
  } = useDedicatedMapProvider();

  const groupLevelMenuItems: BasicSelectItem<number>[] = useMemo(
    () => [
      ...groupLevelList
        .filter((group) => group.levelIndex !== 0)
        .map(({ levelIndex }) => ({
          text: getTerritoryGroupLevelName(levelIndex, true),
          value: levelIndex
        })),
      { text: getTerritoryGroupLevelName(null, true), value: null }
    ],
    [groupLevelList]
  );

  const saveShouldHideColorByTerritoryGroupToast = () => {
    suppressColorByToastStorage.write(true);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShouldColorByTerritoryGroup(event.currentTarget.checked);

    if (event.currentTarget.checked && !suppressColorByToastStorage.read()) {
      showToast(
        <ToastMessage
          title={formatMessage('COLOR_BY_TERRITORY_GROUP_TOAST_TITLE')}
          message={
            <div>
              {formatMessage('COLOR_BY_TERRITORY_GROUP_TOAST_MESSAGE')}
              <Button
                onClick={saveShouldHideColorByTerritoryGroupToast}
                className={b('colorByTerritoryToastButton')}
                minimal
                text={formatMessage('DONT_SHOW_THIS_AGAIN')}
                data-testid="dont-show-me-this-again-button"
              />
            </div>
          }
        />,
        Intent.PRIMARY
      );
    }
  };

  const sharedSelectProps = {
    showIconOnButton: false,
    showIconOnItem: false,
    placement: 'bottom-start' as VariationPlacement
  };
  return (
    <Popover
      content={
        <div className={b('filterWrapper')} data-testid="map-grid-filter">
          <div>
            <div className={b('menuTitle')}>{formatMessage('GROUP_BY_WITHOUT_COLON')}</div>
            <BasicSelect<number | null>
              items={groupLevelMenuItems}
              label={formatMessage('GROUP_BY_WITHOUT_COLON')}
              value={territoryGroupLevel}
              onSelectedValueChange={setTerritoryGroupLevel}
              testId={'tg-selection-level-menu'}
              {...sharedSelectProps}
            />
          </div>
          {territoryGroupLevel != null && (
            <SwitchButton
              checked={isColoringByTerritoryGroup}
              onChange={handleChange}
              labelElement={
                <>
                  <span data-testid="color-by-territory-group-label">{formatMessage('COLOR_BY_TERRITORY_GROUP')}</span>
                  <br />
                  <span data-testid="color-by-territory-group-label-view-only">
                    {formatMessage('COLOR_BY_TERRITORY_GROUP_VIEW_ONLY')}
                  </span>
                </>
              }
              intent="primary"
              testId="color-by-territory-group-switch"
            />
          )}
          <div>
            <div className={b('menuTitle')}>{formatMessage('SORT_BY')}</div>
            <BasicSelect
              items={PRIMARY_TERRITORY_FIELD_ITEMS}
              label={formatMessage('SORT_BY')}
              value={primaryTerritoryField}
              onSelectedValueChange={onPrimaryTerritoryFieldChange}
              testId="sort-by-menu"
              {...sharedSelectProps}
            />
          </div>
          {shouldShowCustomHierarchyFilter && (
            <>
              <DropdownMenuDivider />
              <CustomHierarchyFilterMenu filter={customHierarchyFilter} hierarchies={customHierarchies} />
            </>
          )}
        </div>
      }
      placement="bottom-start"
      minimal
    >
      <TextButton
        text={formatMessage('FILTERS')}
        type="button"
        minimal
        large={false}
        icon={<Filter />}
        rightIcon={<ChevronDown />}
        testId={'filter-button'}
      />
    </Popover>
  );
};

const PRIMARY_TERRITORY_FIELD_ITEMS: Array<BasicSelectItem<PrimaryTerritoryField>> = [
  { text: formatMessage('TERRITORY_ID'), value: 'territoryId' },
  { text: formatMessage('TERRITORY_NAME'), value: 'territoryName' }
];

export default MapGridFilter;
