import { useEffect, useMemo, useRef } from 'react';

import { DocumentNode, useLazyQuery, useMutation, useQuery } from '@apollo/client';

import { useCascadeHeaders } from 'app/contexts/HeaderCascade';

import {
  ApolloErrorContainer,
  ApolloErrorWithInfo,
  ApolloOverrides as AO,
  OverrideErrorWithContainer
} from 'app/graphql/apolloTypeOverrides';
import { handleErrorContainer } from 'app/graphql/handleError';

// By default apollo re-executes queries when the context changes
// To prevent this we store the cascade headers in a ref
const useCascadeHeadersRef = () => {
  const cascadeHeaders = useCascadeHeaders();
  const ref = useRef(cascadeHeaders);
  useEffect(() => {
    ref.current = cascadeHeaders;
  }, [cascadeHeaders]);
  return ref;
};

export const createUseQuery =
  <TData = never, TVariables = never>(documentNode: DocumentNode) =>
  (options?: AO.QueryHookOptions<TData, TVariables>): AO.QueryResult<TData, TVariables> => {
    const cascadeHeadersRef = useCascadeHeadersRef();

    const result = useQuery<TData, TVariables>(documentNode, {
      ...options,
      context: {
        cascadeHeadersRef,
        ...options?.context
      },
      onError(rawError) {
        const errorContainer = asErrorContainer(rawError);
        handleErrorContainer(errorContainer);
        options?.onError?.(errorContainer);
      }
    });
    return useErrorOverride(result);
  };

export const createUseLazyQuery = <TData = never, TVariables = never>(documentNode: DocumentNode) => {
  return (options?: AO.LazyQueryHookOptions<TData, TVariables>): AO.LazyQueryResultTuple<TData, TVariables> => {
    const cascadeHeadersRef = useCascadeHeadersRef();
    const [query, result] = useLazyQuery<TData, TVariables>(documentNode, {
      ...options,
      context: {
        cascadeHeadersRef,
        ...options?.context
      },
      onError(rawError) {
        const errorContainer = asErrorContainer(rawError);
        handleErrorContainer(errorContainer);
        options?.onError?.(errorContainer);
      }
    });
    const extendedResult = useErrorOverride(result);
    return [query, extendedResult];
  };
};

export const createUseMutation = <TData = never, TVariables = never>(documentNode: DocumentNode) => {
  return (options?: AO.MutationHookOptions<TData, TVariables>): AO.MutationTuple<TData, TVariables> => {
    const cascadeHeadersRef = useCascadeHeadersRef();
    const [mutate, result] = useMutation<TData, TVariables>(documentNode, {
      ...options,
      context: {
        cascadeHeadersRef,
        ...options?.context
      },
      onError(rawError, clientOptions) {
        const errorContainer = asErrorContainer(rawError);
        handleErrorContainer(errorContainer);
        options?.onError?.(errorContainer, clientOptions);
      }
    });

    const extendedResult = useErrorOverride(result);

    return [mutate, extendedResult];
  };
};

const asErrorContainer = (apolloError: ApolloErrorWithInfo): ApolloErrorContainer => ({
  apolloError,
  graphQLErrors: apolloError.graphQLErrors,
  networkError: apolloError.networkError,
  knownError: apolloError.graphQLErrors?.find((error) => error.errorInfo?.errorCode)?.errorInfo ?? null
});

const useErrorOverride = <T extends { error?: ApolloErrorWithInfo }>(data: T): OverrideErrorWithContainer<T> =>
  useMemo(() => ({ ...data, error: data.error ? asErrorContainer(data.error) : null }), [data]);
