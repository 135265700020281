import React, { createContext, FC, useCallback, useEffect, useMemo } from 'react';

import { wrapWithAnalytics } from 'app/workers/MapWorker/MapAnalyticsReporter';
import { FromMapWorkerMessage, ToMapWorkerMessage } from 'app/workers/MapWorker/MapWorkerProtocol';
import { MapWorkerProxy } from 'app/workers/MapWorker/MapWorkerProxy';

import { SplitFeatures } from 'app/global/features';

import { useContextSafe } from 'app/hooks/useContextSafe';
import useTreatment from 'app/hooks/useTreatment';

const mapWorkerContext = createContext<MapWorkerProxy | undefined>(wrapWithAnalytics(new MapWorkerProxy(), 'primary'));
mapWorkerContext.displayName = 'mapWorkerContext';

export const useMapWorkerPostMessage = (): ((message: ToMapWorkerMessage) => void) => {
  const worker = useContextSafe(mapWorkerContext);

  const [isAccountFirstMapOn] = useTreatment(SplitFeatures.ACCOUNT_FIRST_MAP);
  const version = isAccountFirstMapOn ? 2 : 1;

  return useCallback((message: ToMapWorkerMessage) => worker.postMessage({ ...message, version }), [version]);
};

export const useOnMapWorkerMessage = (onMessage: (message: FromMapWorkerMessage) => void): void => {
  const worker = useContextSafe(mapWorkerContext);

  useEffect(() => worker.addOnMessage(onMessage), [onMessage]);
};

let secondaryMapWorkerProxy: MapWorkerProxy | null = null;
export const SecondaryMapWorkerProvider: FC = ({ children }) => {
  const value = useMemo(() => {
    if (secondaryMapWorkerProxy === null) {
      secondaryMapWorkerProxy = wrapWithAnalytics(new MapWorkerProxy(), 'secondary');
    }
    return secondaryMapWorkerProxy;
  }, []);
  return <mapWorkerContext.Provider value={value}>{children}</mapWorkerContext.Provider>;
};
