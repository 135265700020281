import React, { useEffect } from 'react';

import { useLazyQuery } from '@apollo/client';

import AdvancedGrid from 'app/components/AdvancedGrid/AdvancedGrid';
import buildActivityDrillInColumnDef from 'app/components/AdvancedGrid/GridHelpers/ActivityDrillIn/ActivityDrillInColumnDef';

import { TABLE_ROW_SIZE } from 'app/constants/DataPanelConstants';
import { CELL_HEIGHT } from 'app/constants/DataTrayConstants';

import { useData } from 'app/contexts/dataProvider';
import { useLocalization } from 'app/contexts/localizationProvider';
import { useScope } from 'app/contexts/scopeProvider';

import { GetActivitiesForFile, GetActivitiesForFileVariables } from 'app/graphql/generated/graphqlApolloTypes';
import { handleError } from 'app/graphql/handleError';
import { GET_ACTIVITIES_FOR_FILE } from 'app/graphql/queries/getActivitiesForFile';

import useShowToast from 'app/hooks/useShowToast';

import block from 'utils/bem-css-modules';
import { buildRowDataFromFile } from 'utils/helpers/buildRowDataFromFile';
import { formatMessage } from 'utils/messages/utils';

import style from './TablePublishedDrillIn.module.pcss';

const b = block(style);

const TablePublishedDrillIn: React.FC = () => {
  const containerRef = React.useRef(null);

  const { defaultReportingCurrency } = useLocalization();
  const { selectedPlanningCycle } = useScope();
  const { selectedTable } = useData();
  const showToast = useShowToast();

  const rowRef = {
    current: {
      ...containerRef?.current,
      offsetHeight: CELL_HEIGHT,
      offsetWidth: containerRef?.current?.offsetWidth
    }
  };

  const [getActivities, { data: activities, loading: activitiesLoading }] = useLazyQuery<
    GetActivitiesForFile,
    GetActivitiesForFileVariables
  >(GET_ACTIVITIES_FOR_FILE, {
    variables: {
      planningCycleId: selectedPlanningCycle.id,
      fileId: selectedTable.tableId,
      startRow: 0,
      endRow: TABLE_ROW_SIZE
    },
    fetchPolicy: 'network-only',
    onError({ graphQLErrors, networkError }) {
      showToast(formatMessage('GET_ACTIVITIES_FOR_FILE_ERROR'), 'danger');
      handleError(graphQLErrors, networkError);
    }
  });

  const activityData = buildRowDataFromFile(activities?.getActivitiesForFile?.territoryActivitiesList);

  useEffect(() => {
    if (selectedTable && selectedPlanningCycle) {
      getActivities();
    }
  }, [selectedTable, selectedTable]);

  const gridProps = {
    headerHeight: CELL_HEIGHT,
    rowHeight: CELL_HEIGHT,
    rowClass: style.DataTray_gridRow,
    suppressScrollOnNewData: true,
    suppressAnimationFrame: true,
    suppressColumnMoveAnimation: true,
    maintainColumnOrder: true,
    rowSelection: 'single'
  };

  return (
    <div className={b('tableGridContainer')} data-testid="table-published-drill-in" ref={containerRef}>
      {activitiesLoading || activityData?.length > 0 ? (
        <AdvancedGrid
          gridProps={gridProps}
          columnDefs={buildActivityDrillInColumnDef(
            activities?.getActivitiesForFile?.territoryActivitiesList || [],
            defaultReportingCurrency,
            false,
            0,
            rowRef
          )}
          rowData={activityData}
          data-testid="published-file-content-grid"
          showGridLoading={activitiesLoading}
          gridWidth={containerRef?.current?.offsetWidth}
          gridHeight={containerRef?.current?.offsetHeight}
        />
      ) : (
        <div className={b('noData')} data-testid="published-file-content-grid-empty">
          {formatMessage('EMPTY_GRID')}
        </div>
      )}
    </div>
  );
};

export default TablePublishedDrillIn;
