import enUS from './en-US';

/* allow anonymous default export */
/* eslint-disable import/no-anonymous-default-export */

/* disallow template strings in messages file */
/* eslint quotes: ["error", "single", { "avoidEscape": true }] */

/* messages from this file should not be imported directly */

const esES: Partial<Record<keyof typeof enUS, string>> = {
  VARICENT_SP: 'Sales Planning de Varicent',
  SALES_PLANNING: 'Sales Planning (Planificación de ventas)',
  PLANNING_CYCLES: 'Ciclos de planificación',
  TEXT_FILTERS: 'Filtros de texto',
  NEW_CYCLE: 'Nuevo ciclo',
  DATA: 'Datos',
  CREATED_WITH_DATE: 'Creado: {date}',
  DELETE: 'Eliminar',
  NEW: 'Nuevo',
  RUN: 'Ejecutar',
  NEW_PLANNING_CYCLE: 'Nuevo ciclo de planificación',
  PLANNING_CYCLE: 'Ciclo de planificación',
  DONT_SAVE: 'No guardar',
  SAVE: 'Guardar',
  CANCEL: 'Cancelar',
  VISUALIZE: 'Visualizar',
  VISUALIZATION_SETTINGS_DIALOG_EXPLANATION: 'Seleccione los tipos de reglas territoriales que quiere ver en el mapa.',
  VISUALIZATION_SETTINGS_DIALOG_WARNING: 'Algunas acciones no están disponibles cuando se visualizan reglas complejas.',
  BACK: 'Atrás',
  COMPLETE: 'Completado',
  UNTITLED: 'Sin título',
  START_DATE_REQUIRED_MARK: 'Fecha de inicio*',
  START_DATE_MONTH_REQUIRED_MARK: 'Fecha de inicio (mes)*',
  DATE_FORMAT_PLACEHOLDER: 'MM/DD/YYYY',
  INTERVAL_CYCLE_REQUIRED_MARK: 'Ciclo de intervalos*',
  PERIODICITY: 'Periodicidad',
  PLAN_DURATION: 'Duración del plan',
  CREATE: 'Crear',
  CREATE_USER_PROFILE: 'Crear perfil de usuario',
  SOMETHING_WENT_WRONG: 'Algo ha ido mal',
  BULK_INVITE_ERROR_MESSAGE: 'No hemos podido enviar ninguna invitación. Inténtelo de nuevo',
  INVITE_EXCEEDS_BATCH_LIMIT: 'No se puede invitar a más de 100 usuarios al mismo tiempo.',
  USER_PROFILE_ADDED: 'Se ha añadido el perfil de usuario',
  USER_PROFILE_CREATION_SUCCESS: 'Hemos añadido a {name} a sus usuarios.',
  ACCESS_GRANTED_TITLE: 'Acceso de {systemRoleName} concedido',
  INVITATION_SENT: 'Se ha enviado una invitación.',
  INVITATIONS_SENT: 'Se han enviado las invitaciones a {count} usuarios.',
  ENTER_EMPLOYEE_ID: 'Introduzca un ID de empleado.',
  ENTER_FIRST_NAME: 'Introduzca un nombre.',
  ENTER_LAST_NAME: 'Introduzca un apellido o apellidos.',
  ENTER_EMAIL: 'Introduzca una dirección de correo electrónico.',
  ROLE_REQUIRED: 'Función*',
  CONTRIBUTOR_INVITE_CALLOUT: 'A este usuario se le concederá acceso de colaborador. Se le enviará una invitación.',
  ADMIN_INVITE_CALLOUT: 'A este usuario se le concederá acceso de administrador. Se le enviará una invitación.',
  AN_ERROR_OCCURRED: 'Se ha producido un error.',
  PLANNING_CYCLE_ERROR_EMPTY: 'Introduzca un título para su ciclo de planificación.',
  PLANNING_CYCLE_ERROR: 'No se han podido recuperar sus ciclos de planificación del servidor.',
  PLANNING_CYCLE_COPY_PROGRESS_DESCRIPTION:
    'Estamos copiando su ciclo de planificación titulado {name}. No realice ningún cambio en el ciclo de planificación hasta que finalice este proceso',
  PLANNING_CYCLE_PROGRESS_HEADING: 'Copia del ciclo de planificación iniciada',
  PLANNING_CYCLE_COPIED: 'Ciclo de planificación copiado',
  PLANNING_CYCLE_COPIED_DESCRIPTION: 'Hemos copiado su ciclo de planificación titulado {pcName}.',
  PLANNING_CYCLE_COPIED_FAILED: 'Se ha producido un error al copiar el ciclo de planificación',
  PLANNING_CYCLE_START_DATE_PROGRESS_HEADING: 'Se está guardando el ciclo de planificación',
  PLANNING_CYCLE_START_DATE_PROGRESS_DESCRIPTION:
    'Estamos guardando los cambios que ha realizado en su ciclo de planificación titulado {name}. No realice ningún cambio en el ciclo de planificación hasta que finalice este proceso',
  CREATE_NEW_CYCLE_ERROR: 'No se ha podido crear un nuevo ciclo de planificación.',
  CREATE_NEW_CYCLE_SUCCESS: 'Se ha creado su nuevo ciclo de planificación.',
  NUM_OF_MONTHS: 'Número de meses',
  BATTLECARD_CANVAS_ERROR: 'No se han podido recuperar sus argumentarios de ventas del servidor.',
  BATTLECARD_ERROR: 'No se ha podido recuperar su argumentario de ventas del servidor.',
  CREATE_NEW_BATTLECARD_ERROR: 'No se ha podido crear un nuevo argumentario de ventas.',
  CREATE_NEW_BATTLECARD_SUCCESS: 'Se ha creado su nuevo argumentario de ventas.',
  UPDATE_BATTLECARD_SUCCESS: 'Se ha actualizado su argumentario de ventas.',
  UPDATE_BATTLECARD_ERROR: 'No se ha podido actualizar su argumentario de ventas.',
  UPDATE_BATTLECARD_OWNER_ERROR: 'No se ha podido actualizar el responsable de su argumentario de ventas.',
  UPDATE_ACTIVITY_FILES_ERROR: 'No se han podido actualizar los archivos de actividad de su argumentario de ventas.',
  UPDATE_QUOTA_COMPONENT_ERROR: 'No se ha podido actualizar el componente de cuota del argumentario de ventas.',
  REMOVE_QUOTA_COMPONENT_ERROR:
    'Debe seleccionar los componentes de cuota asignados al elemento principal de este argumentario de ventas.',
  DISALLOW_REMOVE_QUOTA_COMPONENT:
    'Debe eliminar primero los componentes de cuota a los que se hace referencia en los argumentarios de ventas principales o secundarios consolidados antes de poder eliminarlos del argumentario considerado el elemento principal.',
  UPDATE_HIERARCHY_TOP_ERROR: 'No se ha podido actualizar la jerarquía superior.',
  UPDATE_NEW_BUSINESS_TARGET_SUCCESS: 'Se ha actualizado el nuevo objetivo comercial.',
  UPDATE_NEW_BUSINESS_TARGET_ERROR:
    'No se ha podido actualizar el nuevo objetivo comercial en su argumentario de ventas.',
  BATTLECARD_MEASURES_ERROR: 'No se han podido recuperar las medidas del argumentario de ventas.',
  UNABLE_TO_RETRIEVE_HIERARCHIES: 'No se han podido recuperar las jerarquías del servidor.',
  PIN_TYPE_ACCOUNT: 'Marcadores de cuentas',
  PIN_TYPE_CUSTOM: 'Otros marcadores',
  CLUSTERS: 'Clústeres',
  HEAT_AND_PINS: 'Mapas de calor y marcadores',
  HIDE_ACCOUNTS: 'Ocultar cuentas',
  DEPLOYMENT_MODEL_ERROR: 'No se ha podido recuperar su modelo de implementación del servidor.',
  PINS_ACTIVATED_TOAST_TITLE: 'Marcadores activados',
  PINS_ACTIVATED_TOAST_BODY:
    'Se han activado los marcadores del clúster. Ahora puede interactuar con sus marcadores en este modo de selección.',
  CUSTOMER_PINS_ERROR: 'No se ha podido recuperar los marcadores de clientes.',
  MAP_PINS_ERROR: 'No se han podido recuperar los marcadores de mapas.',
  ACCOUNT: 'Cuenta',
  ACCOUNT_WITH_COUNT: '{totalCount} cuenta',
  ACCOUNTS: 'Cuentas',
  ACCOUNTS_WITH_COUNT: '{totalCount} cuentas',
  ACCOUNT_MOVE_DESCRIPTION: 'El {startDate} moveremos su cuenta {accountName} a {destinationText}.',
  ACCOUNT_MOVE_DESCRIPTION_2: 'El {startDate} moveremos su cuenta {accountName} de {sourceTerritoryId} a {targetText}.',
  ACCOUNT_MOVE_DIALOG_TITLE: 'Programar movimiento para {accountName}',
  ACCOUNT_MOVE_DIALOG_CONFIRM: 'Programar movimiento',
  ACCOUNT_MOVE_DIALOG_BODY:
    'Revise el territorio que ha seleccionado y realice los cambios necesarios. A continuación, añada la fecha de inicio en la que deba entrar en vigor este movimiento.',
  SCHEDULE_ACCOUNT_MOVE: 'Programar movimiento de cuenta',
  ACCOUNT_MOVE_SCHEDULED: 'Movimiento de cuenta programado',
  ACCOUNT_EFFECTIVE_DATE_UPDATED: 'Fechas de entrada en vigor del movimiento de cuenta actualizadas',
  UPDATED_EFFECTED_DATES:
    'Hemos actualizado las fechas de entrada en vigor de los movimientos de cuenta que ha programado para {accountName}.',
  ORIGINAL_TERRITORY: 'Territorio original',
  PREVIOUS_TERRITORY: 'Territorio anterior',
  GAP_DETECTED: 'Discrepancia detectada',
  SCHEDULED_MOVE: 'Movimiento programado',
  SCHEDULED_MOVES_WITH_COUNT: '{totalCount} movimientos programados',
  ONE_SCHEDULED_MOVE: '1 movimiento programado',
  ACCOUNT_NAME: 'Nombre de la cuenta',
  UNASSIGNED_TERRITORY: 'Territorio sin asignar',
  DELETE_REDIRECT: '¿Eliminar el movimiento de cuenta programado?',
  DELETE_REDIRECT_DIALOGUE_BODY:
    'Si elimina este movimiento de cuenta programado para {accountName}, se creará una discrepancia y su cuenta quedará sin asignar. Si decide eliminarlo, seleccione una opción para solventar la discrepancia:',
  DELETE_REDIRECT_DIALOGUE_SOLO_DELETE_BODY: 'Si decide eliminarlo, la cuenta permanecerá en su territorio original.',
  DELETE_REDIRECT_START_RADIO: 'Ajuste la fecha de inicio del siguiente movimiento de cuenta programado:',
  DELETE_REDIRECT_END_RADIO: 'Ajuste la fecha de finalización del anterior movimiento de cuenta:',
  DELETE_REDIRECT_ORIGINAL_RADIO: 'Devolver la cuenta al territorio original:',
  DELETE_REDIRECT_TOAST_TITLE: 'Se ha eliminado el movimiento de cuenta programado',
  DELETE_REDIRECT_TOAST_BODY_ORIGINAL:
    'Hemos eliminado el movimiento de cuenta programado para {accountName}. El {startDate} devolveremos esta cuenta al territorio original.',
  DELETE_REDIRECT_TOAST_BODY_END: 'Mantendremos esta cuenta en el territorio {prevTerritoryName} hasta el {endDate}.',
  DELETE_REDIRECT_TOAST_BODY_START: 'El {startDate} moveremos esta cuenta al territorio {nextTerritoryName}.',
  DELETE_REDIRECT_ERROR: 'No se ha podido eliminar el movimiento de cuenta.',
  CREATE_REDIRECT_ERROR: 'No se ha podido mover la cuenta.',
  UPDATE_REDIRECT_ERROR: 'No se ha podido actualizar el movimiento de cuenta.',
  EFFECTIVE_DATE: 'Fecha de entrada en vigor',
  ADD_TERRITORY: 'Añadir un territorio',
  ADD_THIS_TERRITORY: 'Añadir este territorio',
  EMPTY_PANEL_TEXT: 'Añadir un territorio de la lista de la izquierda',
  ALLOCATE_TOP_DOWN: 'Asignar (en sentido descendente)',
  QUOTA_TARGET_TOP_DOWN: 'Objetivo de la cuota (en sentido descendente)',
  TERRITORY: 'Territorio',
  TERRITORY_WITH_COUNT: '{totalCount} territorio',
  TERRITORIES: 'Territorios',
  TERRITORIES_WITH_COUNT: '{totalCount} territorios',
  CURRENT_TERRITORY: 'Territorio actual',
  TERRITORY_TOGGLE_TOOLTIP: 'Esto le permite seleccionar sus territorios existentes.',
  ACCOUNT_TOGGLE_TOOLTIP: 'Esto le permite seleccionar sus cuentas existentes.',
  PLAN: 'Plan',
  MANAGE: 'Administrar',
  EXISTING: 'Existente',
  TOP_DOWN_TARGET: 'Objetivo descendente',
  QUOTA_ADJUSTMENTS: 'Ajustes de la cuota',
  QUOTA: 'Cuota',
  ADD_NUMBER: 'Añadir número',
  BASED_ON: 'En función de',
  ALLOCATE_QUOTAS_BASED_ON: 'Asignar cuotas en función de',
  PRESERVE_QUOTA_ADJUSTMENTS: 'Mantener los ajustes de la cuota',
  PRESERVE_SET_SEASONALITY: 'Mantener la estacionalidad configurada',
  TERRITORY_DEFINE_REFINE: 'Definición de territorios',
  CREATE_NEW_HIERARCHY_ERROR: 'No se ha podido crear una nueva jerarquía.',
  CREATE_NEW_HIERARCHY_SUCCESS: 'Se ha creado la nueva jerarquía.',
  EDIT_TERRITORY_GROUP: 'Editar grupo de territorios',
  DELETE_TERRITORY_GROUP: 'Eliminar grupo de territorios',
  ADD_TERRITORY_GROUP: 'Añadir grupo de territorios',
  TERRITORY_GROUPS_ERROR: 'No se han podido recuperar los grupos de territorios del servidor.',
  TERRITORY_GROUP_ERROR: 'No se ha podido recuperar la información del grupo de territorios del servidor.',
  COUNT_WITH_NUMBER: 'Recuento {number}',
  COUNT_WITH_FRACTION: 'Recuento {numerator}/{denominator}',
  AVERAGE: 'Media',
  AVERAGE_WITH_NUMBER: 'Media {number}',
  STATUS: 'Estado',
  VIEW: 'Ver',
  VIEW_WITH_ELLIPSIS: 'Ver…',
  HIERARCHY_ERROR: 'No se han podido recuperar los datos de la jerarquía.',
  HIERARCHY_MOVE_ERROR: 'No se ha podido mover la jerarquía.',
  HIERARCHY_MOVE_SUCCESS: 'Se ha movido la jerarquía correctamente.',
  TOP_DOWN_ALLOCATION_ERROR: 'No se ha podido realizar la asignación.',
  CREATE_TERRITORY: 'Crear territorio',
  REASSIGN_GEOGRAPHIC_REGION: 'Reasignar región geográfica',
  CREATE_NEW_TERRITORY_GROUP_SUCCESS: 'Se ha creado su nuevo grupo de territorios.',
  CREATE_NEW_TERRITORY_GROUP_ERROR: 'No se ha podido crear un nuevo grupo de territorios.',
  NEW_BUSINESS_TARGET: 'Nuevo objetivo comercial',
  BUSINESS_TARGET: 'Objetivo comercial',
  PLANNED_TERRITORY_QUOTA: 'Cuota por territorio planificada',
  UPDATED_QUOTA: 'Cuota actualizada',
  UNDERALLOCATED_BY: 'El valor asignado no llega al objetivo por {amount}',
  OVERALLOCATED_BY: 'El valor asignado supera el objetivo por {amount}',
  SELECT_BATTLE_CARD_TO_VIEW_TERRITORIES:
    'Seleccione un argumentario de ventas principal, un tipo de grupo de territorios o un grupo de territorios para ver los territorios',
  EMPTY_GRID: 'No hay datos disponibles en este momento',
  NEW_BUSINESS_TARGET_MEASURE_PLACEHOLDER: 'Establecer objetivo',
  BATTLECARD_MEASURE_PLACEHOLDER: 'Establecer valor',
  COINSORT_PROCESS_SUCCESS: 'La acción de clasificación se ha ejecutado correctamente.',
  ACTIVITIES_SORTED_INTO: 'Se han clasificado {activityCount} actividades en {territoryCount} territorios.',
  ACTIVITIES_UNASSIGNED: 'Hay {activityCount} actividades sin asignar.',
  COINSORT_PROCESS_FAILURE: 'No se ha podido ejecutar la clasificación por monedas.',
  COINSORT_FETCH_ERROR: 'No se ha podido obtener el progreso de la clasificación por monedas.',
  COINSORT_CANCEL_ERROR: 'No se ha podido cancelar el proceso de clasificación por monedas.',
  COINSORT_CHECK_ERROR: 'No se ha podido determinar si se puede ejecutar la clasificación por monedas.',
  COINSORT_RUN_ERROR_TITLE: 'Se ha producido un error durante la clasificación por monedas',
  COINSORT_CANCEL_ERROR_DESC:
    'La clasificación por monedas no se está ejecutando, de manera que no hay nada que cancelar.',
  COINSORT_GENERIC_ERROR_DESC:
    'No hemos podido ejecutar la clasificación por monedas porque se han producido algunos problemas. Actualice la página e inténtelo de nuevo.',
  COINSORT_RUN_ERROR_DESC:
    'En este momento no podemos ejecutar la clasificación por monedas porque ya hay una petición de esta acción ejecutándose. Espere a que finalice la petición actual e inténtenlo de nuevo.',
  COINSORT_MISSING_TERRITORY_GROUP_ERROR_DESC:
    'Al no haber definido todavía ningún grupo de territorios, no podemos ordenar sus actividades de ventas. Cree algunos grupos de territorios e inténtelo de nuevo.',
  COINSORT_ACCESS_DENIED_ERROR_DESC:
    'Lo sentimos, no tiene permiso para ejecutar la clasificación por monedas. Póngase en contacto con su administrador de Sales Planning de Varicent para obtener ayuda.',
  COINSORT_MISSING_ACTIVITY_ERROR_DESC:
    'Al no haber añadido ningún dato sobre actividades de ventas en este argumentario de ventas, no podemos ordenar nada. Añada datos de actividades e inténtelo de nuevo.',
  COINSORT_MISSING_TERRITORY_RULE_ERROR_DESC:
    'Al no tener todavía reglas territoriales, no podemos ordenar sus actividades de ventas. Cree algunas reglas territoriales e inténtelo de nuevo.',
  DELETE_NEW_TERRITORY_GROUP_SUCCESS: 'Se ha eliminado el grupo de territorios.',
  DELETE_NEW_TERRITORY_GROUP_ERROR: 'No se ha podido eliminar el grupo de territorios.',
  CONFIRM_DELETE: '¿Seguro que quiere eliminarlo?',
  DELETE_GROUP_WARNING: 'La acción de eliminación suprimirá este grupo de territorios.',
  DELETE_GROUP_WITH_TERRITORIES_WARNING_BODY:
    'Hay territorios en este grupo de territorios. Mueva los territorios a otro grupo de territorios antes de eliminarlos.',
  DELETE_GROUP_WITH_TERRITORIES_WARNING_HEADER: 'Se ha producido un error al eliminar el grupo de territorios',
  LOGOUT: 'Cerrar sesión',
  TERRITORY_REQUIRED_MARK: 'Territorio*',
  TERRITORY_ID: 'ID de territorio',
  TERRITORY_ID_WITH_ID: 'ID de territorio {territoryID}',
  TERRITORY_NAME: 'Nombre del territorio',
  TERRITORY_NAME_REQUIRED_MARK: 'Nombre del territorio*',
  TERRITORY_NAME_WITH_COLON: 'Nombre del territorio:',
  TERRITORY_NAME_WITH_VALUE: 'Nombre del territorio {value}',
  TERRITORY_GROUP_REQUIRED: 'El grupo de territorios es obligatorio',
  BALANCED: 'Equilibrados',
  BATTLE_CARD: 'Argumentario de ventas',
  TERRITORY_GROUP_REQUIRED_MARK: 'Grupo de territorios*',
  ENTER_TERRITORY_ID_FOR_RULE: 'Introduzca el ID de territorio',
  ENTER_TERRITORY_NAME_FOR_RULE: 'Introduzca el nombre del territorio',
  CARD_TYPE_TEAM_FUNCTION: 'Tipo de argumentario de ventas/función del equipo:',
  DEFINE_TERRITORY_GROUP_TYPES: 'Definir tipos de grupos de territorios',
  DEFINE_TERRITORY_GROUP_TYPES_DESCRIPTION:
    'Seleccione uno o más tipos de grupos de territorios, según las jerarquías que haya cargado, con el fin de definir cómo quiere organizar sus territorios.',
  HIERARCHIES: 'Jerarquías',
  REFER_TO_AS: 'Denominar',
  TERRITORY_GROUP_TYPE: 'Tipo de grupo de territorios',
  PRIMARY_TEAM: 'Equipo principal',
  PRIMARY_TEAM_WITH_CONSOLIDATE: 'Equipo principal (consolidar)',
  DIRECT_OVERLAY: 'Superpuestos (sin consolidación)',
  ROLL_UP: 'Consolidados (consolidar)',
  COMMAND_CENTER: 'Centro de control',
  ADMIN: 'Administración',
  AVAILABLE_WITH_COUNT: '{count} disponible(s)',
  ACCOUNT_OVERRIDES: 'reglas de anulación de las cuentas',
  DEFAULT: 'Predeterminado',
  DEFAULT_WITH_BRACKETS: '(Predeterminado)',
  GEOGRAPHY_RULES_ONLY: 'Solo reglas geográficas',
  COMPLEX_RULES: 'Reglas complejas',
  CUSTOM_HIERARCHY_RULES: 'reglas jerárquicas personalizadas',
  DYNAMIC_HIERARCHY_PLACEHOLDER: '[hierarchy]',
  VISUALIZATION_SETTINGS: 'Visualización de reglas',
  AND: 'Y',
  OR: 'O',
  GEOGRAPHY: 'Región geográfica',
  NUMBER_OF_ACCOUNTS: 'Número de cuentas',
  TOTAL_NUMBER_OF_ACCOUNTS: 'Número total de cuentas',
  NUMBER_OF_OVERRIDE_ACCOUNTS: 'Número de cuentas incluidas en las reglas de anulación',
  INCLUDE_OVERRIDE_ACCOUNTS: 'Incluir cuentas incluidas en las reglas de anulación',
  ADD_HIERARCHY: 'Añadir jerarquía',
  USER_MANAGEMENT: 'Administración de usuarios',
  USERS: 'Usuarios',
  COPIED_TO_CLIPBOARD: 'Se ha copiado en el portapapeles',
  COPY_TO_CLIPBOARD: 'Copiar en el portapapeles',
  GENERATE_API_KEY: 'Generar clave de API',
  GENERATE_API_KEY_ERROR_MESSAGE: 'No se ha podido generar la clave de API',
  GENERATE_KEY: 'Generar clave',
  GENERATE_API_KEY_DESCRIPTION_1:
    'Utilice esta clave de API para conectar su cuenta de canalización con su cuenta de Sales Planning.',
  GENERATE_API_KEY_DESCRIPTION_2:
    'Sales Planning no guarda esta clave de API. Una vez generada, cópiela y guárdela en un lugar seguro. Al generar una nueva clave se invalidará la anterior.',
  API_KEY_GENERATED: 'Clave de API generada',
  API_KEY_COPIED: 'La clave de API se ha copiado en el portapapeles.',
  API_KEY_GENERATED_DESC_1:
    'Sales Planning no guarda esta clave de API. Copie y guarde esta clave de API en un lugar seguro.',
  API_KEY_GENERATED_DESC_2:
    'Una vez que haga clic en Listo, no se podrá recuperar esta clave. Al generar una nueva clave se invalidará la anterior.',
  EMAIL_PLACEHOLDER: 'johnsmith@company.com',
  PASSWORD: 'Contraseña',
  PASSWORD_CHANGE_SUCCESS: 'La contraseña se ha modificado correctamente',
  PASSWORD_CHANGE_ERROR: 'No se ha podido actualizar la contraseña. Vuelva a intentarlo más tarde',
  INVALID_INVITATION_ERROR: 'El enlace de invitación no es válido.',
  ADD_USER_TO_TENANT_SUCCESS: 'Se le ha añadido correctamente a su organización',
  ADD_USER_TO_TENANT_ERROR:
    'No ha sido posible añadirle a su organización. Póngase en contacto con su administrador para obtener una nueva invitación.',
  GLOBAL_MEASURES: 'Medidas generales',
  PLAN_TARGETS: 'Objetivos del plan',
  EXPANDED_COMPLETION_RATE: 'Porcentaje de consecución:',
  QUOTA_APPROVAL_WORKFLOW: 'Flujo de trabajo de aprobación de las cuotas',
  QUOTA_WORKFLOW: 'Flujo de trabajo de las cuotas',
  INITIATE_WORKFLOW_TEXT: 'Iniciar flujo de trabajo',
  INITIATE_WORKFLOW_ERROR: '¡Lo sentimos! Se ha producido un error al iniciar el flujo de trabajo.',
  CANCEL_WORKFLOW_TEXT: 'Cancelar flujo de trabajo',
  CANCEL_WORKFLOW_ERROR: '¡Lo sentimos! Se ha producido un error al cancelar el flujo de trabajo.',
  TERRITORY_GROUP: 'Grupo de territorios',
  TERRIORY_GROUP_LEVEL: 'Nivel del grupo de territorios',
  AUTH_CHECK_FAILURE: 'Se ha producido un error de autenticación. Inicie sesión de nuevo',
  MEASURE_VALUE_TYPE_ERROR: 'El valor debe ser un número.',
  QUOTA_COMPONENTS_ONE_OR_MANY_WITH_COLON: 'Componente(s) de cuota(s):',
  QUOTA_BREAKDOWN_BY: 'Desglose de la cuota por',
  QUOTA_BREAKDOWN_VALIDATION_ERROR: 'Seleccione 2 jerarquías, como máximo.',
  QUOTA_BREAKDOWN_BY_TOOLTIP:
    'Esto le permite ver un desglose de sus cuotas por territorio en función de las jerarquías seleccionadas y ajustarlas en consecuencia.',
  QUOTA_BREAKDOWN_BY_PLACEHOLDER_TEXT: 'Seleccione un máximo de 2 jerarquías',
  HIERARCHY_BASED_TARGET_SETTING: 'Establecimiento de objetivos en función de la jerarquía',
  HIERARCHY_BASED_TARGET_SETTING_TOOLTIP:
    'Seleccione una jerarquía para establecer objetivos específicos en relación con la cuota. Solo puede seleccionar una jerarquía que esté formada por un máximo de {maxMemberCount} miembros',
  ADD_NEW_QC: 'Añadir nuevo componente de cuota',
  ACTIVITY_FILE: 'Archivo(s) de actividad',
  ACTIVITY_FILE_WITH_COLON: 'Archivo(s) de actividad:',
  SELECT: 'Seleccionar',
  REMOVE: 'Eliminar',
  REVOKE: 'Revocar',
  INVITE: 'Invitar',
  COMMENT: 'Comentario',
  COMMENT_ERROR: 'No se ha podido actualizar el comentario.',
  DATE: 'Fecha',
  EMAIL: 'Correo electrónico',
  NUMERIC: 'Numérico',
  QUOTA_ALLOCATION: 'Asignación de la cuota:',
  TERRITORY_GROUPS: 'Grupos de territorios',
  UPDATE: 'Actualizar',
  ADD: 'Añadir',
  ACTION: 'Acción',
  ACTIONS: 'Acciones',
  ACTION_MENU_TOOLTIP:
    'Muestra una lista de acciones disponibles en función de la región geográfica o el territorio que se seleccione.',
  ADD_TO_SELECTION: 'Añadir a la selección',
  REMOVE_FROM_SELECTION: 'Eliminar de la selección',
  UPDATE_TERRITORY_GROUP_SUCCESS: 'Se ha actualizado el grupo de territorios.',
  UPDATE_TERRITORY_GROUP_ERROR: 'No se ha podido actualizar el grupo de territorios.',
  NO_HIERARCHIES: 'No hay jerarquías',
  USER_MANAGEMENT_DROPDOWN_TOOLTIP: 'Seleccione usuarios con el mismo tipo de función para enviarles las invitaciones.',
  USER_MANAGEMENT_LOAD_USERS: 'Cargar usuarios desde archivo',
  USER_MANAGEMENT_ADMIN_INVITE: 'Invitar en calidad de administrador',
  USER_MANAGEMENT_ADMINS_INVITE: 'Invitar en calidad de administradores',
  USER_MANAGEMENT_RESEND_ADMIN_INVITE: 'Reenviar invitación para ser administrador',
  USER_MANAGEMENT_CONTRIBUTOR_INVITE: 'Invitar en calidad de colaborador',
  USER_SELECTION_FRACTION: '{selectionCount}/{totalCount} seleccionados',
  USER_MANAGEMENT_CONTRIBUTORS_INVITE_BODY:
    '¿Seguro que quiere enviar las invitaciones a {selectionCount} usuarios? A estos usuarios se les concederá acceso de colaboradores.',
  USER_MANAGEMENT_ADMINS_INVITE_BODY:
    '¿Seguro que quiere enviar las invitaciones a {selectionCount} usuarios? A estos usuarios se les otorgará acceso de administradores.',
  USER_MANAGEMENT_CONTRIBUTORS_INVITE: 'Invitar en calidad de colaboradores',
  USER_MANAGEMENT_RESEND_CONTRIBUTOR_INVITE: 'Reenviar invitación para ser colaborador',
  USER_MANAGEMENT_MEMBER_REVOKED: 'Se ha revocado el acceso de colaborador a este miembro.',
  USER_MANAGEMENT_ADMIN_REVOKED: 'La función de este miembro se ha actualizado a colaborador.',
  USER_MANAGEMENT_MEMBER_REVOKED_ERROR: 'Ha habido un problema al revocar la función.',
  USER_MANAGEMENT_MEMBER_DELETED: 'Se ha eliminado a este miembro.',
  USER_MANAGEMENT_MEMBER_DELETED_ERROR: 'Ha habido un problema al eliminar a este miembro.',
  USER_MANAGEMENT_ADMIN_ACCESS:
    'Se ha concedido acceso de administrador. Se ha enviado un correo electrónico a este miembro.',
  USER_MANAGEMENT_CONTRIBUTOR_ACCESS:
    'Se ha concedido acceso de colaborador. Se ha enviado un correo electrónico a este miembro.',
  USER_MANAGEMENT_ACCESS_ERROR: 'Ha habido un problema al enviar la invitación.',
  USER_MANAGEMENT_TABLE_EMPLOYEE_ID: 'ID de empleado',
  USER_MANAGEMENT_TABLE_JOB_TITLE: 'Cargo',
  USER_MANAGEMENT_TABLE_ROLE_NAME: 'Nombre de la función',
  USER_MANAGEMENT_REVOKE_ADMIN: 'Revocar acceso de administrador',
  USER_MANAGEMENT_REVOKE_CONTRIBUTOR: 'Revocar acceso de colaborador',
  USER_MANAGEMENT_DIALOG_REVOKE_HEADLINE: '¿Revocar el acceso de colaborador?',
  USER_MANAGEMENT_DIALOG_REVOKE_BODY:
    'El perfil del usuario y sus credenciales de inicio de sesión permanecerán en el sistema. Sin embargo, no tendrá acceso a esta organización.',
  USER_MANAGEMENT_DIALOG_REVOKE_ADMIN_HEADLINE: '¿Revocar el acceso de administrador?',
  USER_MANAGEMENT_DIALOG_REVOKE_ADMIN_BODY:
    'El perfil del usuario permanecerá en el sistema. Su función se actualizará a colaborador y perderá el acceso de administrador.',
  USER_MANAGEMENT_DIALOG_REMOVE_HEADLINE: 'Eliminar perfil de participante',
  USER_MANAGEMENT_DIALOG_REMOVE_BODY:
    'Al completar esta acción se eliminará inmediatamente este perfil de usuario del sistema. Si el perfil estaba asignado a un territorio, grupo o cuota, es posible que se produzca un estado de error.',
  USER_MANAGEMENT_DIALOG_INVITE_HEADLINE: 'Invitar a este miembro en calidad de colaborador',
  USER_MANAGEMENT_DIALOG_INVITE_BODY:
    'Esta acción enviará un correo electrónico con una invitación para que el miembro se convierta en colaborador. ¿Quiere completar esta acción?',
  USER_MANAGEMENT_DIALOG_ADMIN_INVITE_HEADLINE: 'Invitar a este miembro en calidad de administrador',
  USER_MANAGEMENT_DIALOG_ADMIN_INVITE_BODY:
    'Esta acción enviará un correo electrónico con una invitación para que el miembro se convierta en administrador. ¿Quiere completar esta acción?',
  ROLE_PERMISSIONS_TABLE_VIEW_HEADER_TOOLTIP:
    'Si habilita los permisos de visualización, esta función solo podrá acceder al ciclo de planificación para consultarlo.',
  ROLE_PERMISSIONS_TABLE_EDIT_HEADER_TOOLTIP:
    'Si habilita los permisos de edición, esta función podrá acceder a este ciclo de planificación para modificarlo. En la pestaña Cuota dentro de Plan, asegúrese de que el flujo de trabajo de las cuotas esté habilitado.',
  ROLE_PERMISSIONS_TABLE_VIEW_CHECKBOX_TOOLTIP: 'El acceso de visualización solo puede concederse en el nivel más alto',
  ROLE_PERMISSIONS_TABLE_EDIT_CHECKBOX_TOOLTIP:
    'Si selecciona los permisos de edición también se activarán los permisos de visualización.',
  TERRITORY_SHEET_GRID_EDIT_UNALLOWED_TOOLTIP: 'No tiene permiso para editar esto.',
  EXPAND_ALL: 'Expandir todo',
  COLLAPSE_ALL: 'Contraer todo',
  ROLE_PERMISSIONS_TABLE_QUOTA_SHEET_INFO:
    'Los permisos de la hoja de cuotas se pueden modificar en la pestaña de datos del Centro de control',
  ROLES: 'Funciones',
  ROLE_PERMISSIONS_UPSERT_ERROR: 'No hemos podido guardar sus permisos. Inténtelo de nuevo',
  ROLE_PERMISSIONS_UPSERT_SUCCESS: 'Hemos actualizado correctamente los permisos para la función {role}',
  ROLE_PERMISSIONS_GET_ERROR: 'No hemos podido cargar sus permisos.',
  CHANGE_REQUESTS: 'Solicitudes de cambios',
  REVIEW_PENDING_CHANGE_REQUESTS: 'Revisar solicitudes de cambios pendientes',
  SUBMIT_CHANGE_REQUEST: 'Enviar solicitud de cambio',
  VIEW_CHANGE_REQUEST_LOG: 'Ver registro de solicitudes de cambios',
  REQUEST_ID: 'ID de solicitud',
  REQUESTED_BY: 'Solicitado por',
  DATE_REQUESTED: 'Fecha de la solicitud',
  DATE_REVIEWED: 'Fecha de la revisión',
  APPROVE_CHANGE_REQUEST_DIALOG_BODY:
    '¿Todo listo para aprobar esta solicitud de cambio? Los cambios aprobados se mostrarán en la bandeja de datos.',
  REJECT_CHANGE_REQUEST_DIALOG_BODY:
    '¿Seguro que quiere rechazar esta solicitud de cambio? Los cambios rechazados se descartarán.',
  ADD_A_COMMENT: 'Añadir un comentario',
  APPROVE_CHANGE_REQUEST: 'Aprobar solicitud de cambio',
  REJECT_CHANGE_REQUEST: 'Rechazar solicitud de cambio',
  CONTRIBUTOR_ROLES: 'Funciones de los colaboradores',
  ADMINISTRATOR_ACCESS_DESCRIPTION: 'Esta función de usuario puede acceder a todas las funcionalidades',
  SEND_INVITATION: 'Enviar invitación',
  RESEND_INVITATION: 'Reenviar invitación',
  ADD_DESCRIPTION_PLACEHOLDER: 'Añadir descripción...',
  EDIT_PLANNING_CYCLE: 'Editar ciclo de planificación',
  EDIT_PLANNING_CYCLE_SUCCESS_TITLE: 'Se han guardado los cambios en el ciclo de planificación',
  EDIT_PLANNING_CYCLE_FAILURE_TITLE: 'Se ha producido un error al actualizar el ciclo de planificación',
  EDIT_NEW_CYCLE_SUCCESS: 'Se ha actualizado su ciclo de planificación.',
  EDIT_NEW_CYCLE_ERROR: 'No se ha podido actualizar el ciclo de planificación.',
  EDIT: 'Editar',
  EDIT_WITH_VALUE: 'Editar {value}',
  LOADING: 'Cargando...',
  STARTING: 'Iniciando...',
  STOPPING: 'Deteniendo...',
  DELETE_BATTLECARD_SUCCESS: 'Se ha eliminado su argumentario de ventas.',
  DELETE_BATTLECARD_GENERIC_ERROR: 'No se ha podido eliminar su argumentario de ventas.',
  DELETE_BATTLECARD_TERRITORY_RULE_ERROR:
    'En este momento no podemos eliminar este argumentario de ventas. Elimine todos los territorios del argumentario de ventas e inténtelo de nuevo.',
  NO_RESULTS: 'Sin resultados',
  TERRITORY_OWNER: 'Responsable del territorio:',
  OWNERSHIP_VALUE_RANGE_ERROR: 'Debe ser un valor entre 1 y 100.',
  PERCENTAGE_MEASURE_VALUE_RANGE_ERROR: 'Debe ser un valor entre 0 y 100.',
  MEASURES: 'Medidas',
  BUILT_IN_MEASURES: 'Medidas incorporadas',
  CUSTOM_MEASURES: 'Medidas personalizadas',
  DESCRIPTION: 'Descripción',
  NAME: 'Nombre',
  NAME_WITH_COLON: 'Nombre:',
  TYPE: 'Tipo',
  TYPE_REQUIRED: '*Tipo:',
  ATTRIBUTE_NAME: 'Nombre del atributo',
  ADD_CUSTOM_ATTRIBUTE: 'Añadir atributo personalizado',
  ATTRIBUTE_NAME_MESSAGE: 'Introduzca un nombre para el atributo',
  FORMAT: 'Formato',
  FORMAT_REQUIRED: '*Formato:',
  FORMULA: 'Fórmula',
  DATE_RANGE: 'Intervalo de fechas',
  ADD_MEASURE: 'Añadir medida',
  TERRITORY_OWNER_ERROR: 'Seleccione un responsable del territorio.',
  ASSIGNMENT_BUILDER_ADD_SUCCESS: 'Se ha añadido la tarea asignada.',
  ASSIGNMENT_BUILDER_ADD_FAILURE: 'No se ha podido añadir la tarea asignada.',
  ASSIGNMENT_BUILDER_UPDATE_SUCCESS: 'Se ha actualizado la tarea asignada.',
  ASSIGNMENT_BUILDER_UPDATE_FAILURE: 'No se ha podido actualizar la tarea asignada.',
  TERRITORY_GROUP_TYPE_INCLUDE_MESSAGE: 'Debe haber al menos un tipo de grupo de territorios.',
  UPDATE_USER_SUCCESS: 'El perfil de usuario se ha actualizado correctamente.',
  UPDATE_USER_ERROR: 'Se ha producido un error al actualizar el perfil de usuario.',
  ADD_TERRITORIES_TOOLTIP:
    'Añada territorios para poder realizar la asignación en sentido descendente a este grupo de territorios.',
  ADD_A_CARD: 'Añadir un argumentario de ventas',
  FIVE_HUNDRED: '¡Vaya! Tenemos un problema con el servidor. Vuelva a intentarlo más tarde.',
  FOUR_O_ONE: '¡Lo sentimos! No tiene permiso para ver esta página.',
  FOUR_O_FOUR: '¡Vaya! La página que está buscando se ha eliminado o el enlace no funciona.',
  INTERNAL_ERROR: '¡Vaya! Tenemos un problema con la aplicación. Vuelva a intentarlo más tarde.',
  UPSERT_TERRITORY_GROUP_OWNER_ERROR: 'No se ha podido actualizar el responsable del grupo de territorios.',
  OWNER: 'Responsable',
  OWNER_WITH_COLON: 'Responsable:',
  CREATE_ORGANIZATION_TITLE: 'Cree su organización',
  NAME_YOUR_ORGANIZATION: 'Indique el nombre de su organización',
  FIRST_NAME: 'Nombre',
  LAST_NAME: 'Apellidos',
  FIRST_NAME_PLACEHOLDER: 'John',
  LAST_NAME_PLACEHOLDER: 'Smith',
  ORGANIZATION_NAME_PLACEHOLDER: 'Más de 8 caracteres',
  CREATE_ORGANIZATION: 'Crear organización',
  TERMS_OF_SERVICE: 'Condiciones del servicio',
  PRIVACY_POLICY: 'Política de privacidad',
  INVALID_EMAIL: 'Correo electrónico no válido',
  ORGANIZATION_NAME_LENGTH_ERROR: 'El nombre de la organización debe tener al menos 2 caracteres.',
  ENTER_YOUR_ORGANIZATION_NAME: 'Introduzca el nombre de su organización',
  BY_CLICKING: 'Al hacer clic en {actionText}, acepta nuestras',
  AND_OUR: 'y nuestra',
  QUOTA_COMPONENT: 'Componente de cuota',
  QUOTA_COMPONENT_WITH_COLON: 'Componente de cuota:',
  QUOTA_COMPONENT_WITH_VALUE: 'Componente de cuota {value}',
  QUOTA_COMPONENTS: 'Componentes de cuota',
  QUOTA_COMPONENTS_WITH_VALUE: 'Componentes de cuota {value}',
  QUOTA_COMPONENTS_UPDATE_SUCCESS: 'Se ha actualizado el componente de cuota.',
  QUOTA_COMPONENTS_DELETED_SUCCESS: 'Se ha eliminado el componente de cuota.',
  QUOTA_COMPONENTS_UPDATE_ERROR: 'No se ha podido actualizar el componente de cuota.',
  NO_QUOTA_COMPONENTS: 'No hay componentes de cuota',
  DELETE_QUOTA_COMPONENT: 'Eliminar componente de cuota',
  CONFIRM_DELETE_QUOTA_COMPONENT: '¿Seguro que quiere eliminar el componente de cuota {name}?',
  NO_ACTIVITY_FILES: 'No hay archivos de actividad',
  NO_DATA: 'No hay datos',
  NO_FILE_SELECTED: 'No se ha seleccionado ningún archivo',
  INSIGHTS: 'Información',
  SELECTION_INSIGHTS: 'Información sobre los elementos seleccionados',
  CLEAR_SELECTION: 'Borrar selección',
  CLEAR_ALL: 'Borrar todo',
  CLEAR: 'Borrar',
  CHOOSE_FILE_FOR_UPLOAD:
    'Seleccione el archivo CSV que desee cargar (el asterisco [*] indica que se trata de un encabezado obligatorio)',
  CHOOSE_A_FILE: 'Seleccione un archivo',
  FILE_UPLOAD_VALIDATION_ERROR:
    'La extensión del archivo seleccionado (“{file}”) no es .csv. Seleccione un archivo diferente o añada la extensión .csv al nombre de su archivo.',
  BEGIN_UPLOAD_ERROR: 'No se ha podido iniciar la carga del archivo seleccionado.',
  ADD_USERS: 'Añadir usuarios',
  PROCESSING_MESSAGE_PENDING: 'Se está procesando el archivo.',
  PROCESSING_MESSAGE_COMPLETED: 'El archivo se ha procesado correctamente.',
  PROCESSING_MESSAGE_ERROR: 'El archivo no se ha procesado correctamente.',
  PROCESSING_STATUS_PENDING: 'Pendiente',
  PROCESSING_STATUS_ERROR: 'Error',
  RETRIEVE_PROCESSING_STATUS_ERROR: 'No se ha podido recuperar el estado de procesamiento del archivo.',
  GET_FILE_HEADERS_ERROR: 'No se han podido recuperar los encabezados del archivo.',
  NOTIFY_SERVER_ERROR: 'No se ha podido comunicar el estado de carga al servidor.',
  CHECK_REQUIRED_FILE_HEADERS: 'Compruebe que en su archivo figuren los encabezados de las columnas obligatorios.',
  BEGIN_FILE_UPLOAD: 'Iniciar carga del archivo',
  FILE_PROCESSING_SUCCESS: 'El archivo se ha procesado correctamente',
  FILE_PROCESSING_ERROR: 'Se ha producido un error al procesar el archivo',
  FILE_NAME_VALIDATION_ERROR:
    'No podemos importar este archivo porque el nombre del archivo contiene caracteres no compatibles',
  FILE_COLUMN_VALIDATION_ERROR:
    'No podemos importar este archivo porque los encabezados de las columnas del archivo contienen caracteres no compatibles',
  FILE_NAME: 'Nombre del archivo',
  SUMMARY: 'Resumen',
  IMPORT_DATA: 'Importar datos',
  SEARCH: 'Buscar',
  SUBMIT: 'Enviar',
  READONLY: 'Solo lectura',
  REJECT: 'Rechazar',
  APPROVE: 'Aprobar',
  ADD_TERRITORY_ROW: 'Añadir fila de territorios',
  LOAD_TERRITORIES: 'Cargar territorios',
  ADD_TERRITORIES: 'Añadir territorios',
  ADD_CUSTOM_MEASURE_SUCCESS: 'Se ha añadido la medida.',
  ADD_CUSTOM_MEASURE_FAILURE: 'No se ha podido añadir la medida.',
  UPDATE_CUSTOM_MEASURE_SUCCESS: 'Se ha actualizado la medida.',
  UPDATE_CUSTOM_MEASURE_FAILURE: 'No se ha podido actualizar la medida.',
  UPDATE_MEASURE: 'Actualizar medida',
  DELETE_CUSTOM_MEASURE_SUCCESS: 'Se ha eliminado la medida.',
  DELETE_CUSTOM_MEASURE_FAILURE: 'No se ha podido eliminar la medida.',
  MEASURE_NAME_REQUIRED: 'Escriba un nombre para la columna.',
  MEASURE_TYPE_REQUIRED: 'Seleccione una fuente.',
  MEASURE_FORMAT_REQUIRED: 'Seleccione un formato.',
  MEASURE_SOURCE_REQUIRED: 'Seleccione un tipo de tabla de datos.',
  FIELD_ID_REQUIRED: 'Seleccione una tabla de datos.',
  FIELD_NAME_REQUIRED: 'Seleccione una columna.',
  CREATE_NEW_ORGANIZATION_SUCCESS: 'Se ha creado su nueva organización.',
  CREATE_NEW_ORGANIZATION_ERROR: 'No se ha podido crear una nueva organización.',
  TERRITORY_DEFINITIONS: 'Definiciones de territorios',
  STALE_DATA_MESSAGE:
    'Está viendo datos obsoletos. Ejecute la acción de ordenar para que se muestren los territorios más recientes.',
  ASSIGNED_TOOLTIP: 'Incluir regiones geográficas asignadas',
  ASSIGNED_TOOLTIP_ACCOUNTS: 'Incluir cuentas asignadas',
  ALL_TOOLTIP: 'Incluir todas las regiones geográficas',
  ALL_TOOLTIP_ACCOUNTS: 'Incluir todas las cuentas',
  UNASSIGNED_TOOLTIP: 'Incluir regiones geográficas sin asignar',
  UNASSIGNED_TOOLTIP_ACCOUNTS: 'Incluir cuentas sin asignar',
  ASSIGNED: 'Asignado',
  UNASSIGNED: 'Sin asignar',
  UNASSIGNED_LOWERCASE: 'sin asignar',
  WILL_START: 'Comenzará en {territoryName}',
  WILL_END: 'Finalizará en {territoryName}',
  UNABLE_TO_PROCESS_CONTRIBUTOR: 'No se ha podido procesar su solicitud.',
  EDIT_TERRITORY: 'Editar territorio',
  QUOTA_COMPONENT_VALIDATION_ERROR: 'Seleccione al menos un componente de cuota.',
  EDIT_HIERARCHY: 'Editar jerarquía',
  EDIT_HIERARCHY_ERROR: 'No se ha podido editar su jerarquía.',
  EDIT_HIERARCHY_SUCCESS: 'Se ha editado la jerarquía',
  ENTER_HIERARCHY_NAME: 'Introduzca un nombre para su jerarquía',
  HIERARCHY_NAME: 'Nombre de la jerarquía',
  UNSAVED_CHANGES: '¿Seguro que quiere salir? Hay algunos cambios sin guardar.',
  BATTLE_CARD_TITLE_VALIDATION_ERROR: 'Introduzca un nombre para su argumentario de ventas.',
  LEVEL_WITH_VALUE: 'Nivel {value}',
  MULTIPLE_ACCOUNTS_TITLE: 'Varias cuentas',
  MULTIPLE_ACCOUNTS_BODY:
    'Esta región geográfica contiene varias cuentas. Con la herramienta de lazo, seleccione este clúster y consulte el desglose de cuentas en su ficha de información.',
  UNASSIGN: 'Anular asignación',
  REASSIGN: 'Reasignar',
  GET_ACTIVITIES_FOR_FILE_ERROR: 'No se han podido recuperar las actividades del archivo.',
  GET_ACTIVITIES_FOR_TERRITORY_ERROR: 'No se han podido recuperar las actividades del territorio.',
  GET_ACTIVITIES_FOR_TERRITORY_GROUP_ERROR: 'No se han podido recuperar las actividades del grupo de territorios.',
  GET_UNASSIGNED_ACTIVITIES_ERROR: 'No se han podido recuperar las actividades sin asignar.',
  WORKFLOW_APPROVE_SUCCESS: 'El flujo de trabajo {actionWorkflowName} ha sido aprobado.',
  WORKFLOW_APPROVE_FAILURE: 'No se ha podido aprobar el flujo de trabajo {actionWorkflowName}',
  WORKFLOW_REJECT_SUCCESS: 'El flujo de trabajo {actionWorkflowName} ha sido rechazado.',
  WORKFLOW_REJECT_FAILURE: 'No se ha podido rechazar el flujo de trabajo {actionWorkflowName}',
  APPROVE_TERRITORY_GROUP_DIALOG_MESSAGE:
    '{approver} es la persona responsable de aprobar este grupo de territorios y puede que esté en proceso de modificar las cuotas. ¿Seguro que quiere aprobarlo?',
  REJECT_TERRITORY_GROUP_DIALOG_MESSAGE:
    '{approver} es la persona responsable de rechazar este grupo de territorios y puede que esté en proceso de modificar las cuotas. ¿Seguro que quiere rechazarlo?',
  APPROVE_BATTLE_CARD_DIALOG_MESSAGE:
    '{approver} es la persona responsable de aprobar este argumentario de ventas y puede que esté en proceso de modificar las cuotas. ¿Seguro que quiere aprobarlo?',
  REJECT_BATTLE_CARD_DIALOG_MESSAGE:
    '{approver} es la persona responsable de rechazar este argumentario de ventas y puede que esté en proceso de modificar las cuotas. ¿Seguro que quiere rechazarlo?',
  APPROVE_DIALOG_TITLE: 'Lo está aprobando en nombre de otro usuario',
  REJECT_DIALOG_TITLE: 'Lo está rechazando en nombre de otro usuario',
  AVAILABLE_TERRITORY_ID: '“{territoryId}” está disponible en el grupo de territorios “{territoryGroup}”.',
  UNAVAILABLE_TERRITORY_ID: '“{territoryId}” no está disponible porque ya existe.',
  AVAILABLE_WITH_PLANNING_CYCLE_ID: 'está disponible con el ID de ciclo de planificación',
  UNAVAILABLE_WITH_PLANNING_CYCLE_ID:
    '“{name}” no está disponible porque el ID de ciclo de planificación “{slugName}” ya existe.',
  YOUR_ORGANIZATION_URL_WILL_BE: 'La URL de su organización será',
  PLANNING_CYCLE_NAME: 'Nombre del ciclo de planificación',
  NEXT: 'Siguiente',
  SIGN_IN: 'Iniciar sesión',
  ARCHIVE: 'Archivar',
  ARCHIVE_WITH_TITLE: 'Archivar {title}',
  PLANNING_CYCLE_ARCHIVE_MESSAGE_1: 'Está a punto de archivar este ciclo de planificación:',
  PLANNING_CYCLE_ARCHIVE_MESSAGE_2: 'Se guardará en el archivo durante 30 días.',
  PLANNING_CYCLE_ARCHIVE_SUCCESS: '{title} se ha archivado.',
  PLANNING_CYCLE_ARCHIVE_FAILURE: 'No se ha podido archivar {title}',
  VIEW_ALL_ACTIVITIES: 'Ver todas las actividades',
  ADD_A_CHILD: 'Añadir elemento secundario',
  KEY: 'Clave',
  ADDRESS1: 'Línea de dirección 1',
  ADDRESS2: 'Línea de dirección 2',
  CITY: 'Ciudad',
  COUNTRY: 'País',
  INDUSTRY: 'Sector',
  ZIP_OR_POSTAL: 'Código postal',
  STATE_OR_PROVINCE: 'Estado o provincia',
  GEOGRAPHIC_REGION: 'Región geográfica',
  GEO_REGION: 'Región geog.',
  SELECTED_GEOGRAPHIC_REGIONS: 'Regiones geográficas seleccionadas',
  SELECTED_TERRITORIES: 'Territorios seleccionados',
  GEOGRAPHIC_REGION_TOGGLE_TOOLTIP:
    'Esto le permite seleccionar límites individuales en función del nivel geográfico que haya escogido. Por ejemplo, países, estados o códigos postales.',
  SAVE_CHANGES: 'Guardar cambios',
  CONTACT_ADMIN_EMAIL: 'Póngase en contacto con su administrador para cambiar su dirección de correo electrónico.',
  REQUIRED_FIELD: 'Campo obligatorio',
  UPDATE_HIERARCHY_ERROR: 'No se ha podido actualizar {rootName} “{name}”.',
  ADDED_HIERARCHY: 'Se ha añadido {rootName} “{name}”.',
  DETAILS: 'Detalles',
  DELETE_SUCCESS_WITH_NAMES_QUOTED: '{nameOne} “{nameTwo}” se ha eliminado correctamente.',
  HIERARCHY_DELETE_ERROR_WITH_NAMES: 'No se ha podido eliminar {rootName} “{name}”.',
  HIERARCHY_EMPTY: 'Jerarquía vacía',
  CONFIRM_DELETE_HIERARCHY: '¿Seguro que quiere eliminar {name}?',
  OPTIONS: 'Opciones',
  DELETE_TERRITORY_GROUP_CONFIRM: '¿Seguro que quiere eliminar este territorio?',
  DELETE_TERRITORY_SUCCESS: 'Se ha eliminado el territorio {territoryId}: {territoryName}.',
  DELETE_TERRITORY_ERROR: 'No se ha podido eliminar el territorio {territoryId}: {territoryName}.',
  DELETE_TERRITORY_IN_USE_BY_OVERLAY_ERROR_MESSAGE:
    'No hemos podido eliminar este territorio porque se está utilizando en un argumentario de ventas superpuesto. Elimine las referencias a este territorio del argumentario de ventas superpuesto e inténtelo de nuevo.',
  DELETE_TERRITORY_IN_USE_BY_OVERLAY_ERROR_MESSAGE_GENERIC:
    'No hemos podido eliminar los territorios seleccionados porque hay algunas reglas que se están utilizando en un argumentario de ventas superpuesto. Elimine las referencias a los territorios seleccionados del argumentario de ventas superpuesto e inténtelo de nuevo.',
  DELETE_TERRITORY_TEXT: 'Eliminar territorio',
  DELETE_TERRITORY_TEXT_WITH_ID_AND_NAME: 'Eliminar territorio {territoryId}: {territoryName}',
  DELETE_MULTIPLE_TERRITORIES_TEXT: 'Eliminar territorios',
  TERRITORY_DELETE_DIALOG_BODY: '¿Quiere eliminar {count} territorio seleccionado?',
  TERRITORIES_DELETE_DIALOG_BODY: '¿Quiere eliminar los {count} territorios seleccionados?',
  CURRENCIES: 'Monedas',
  CURRENCIES_WITH_COUNT: '{count} monedas',
  CURRENCY: 'Moneda',
  CURRENCY_WITH_COUNT: '{count} moneda',
  REPORTING_CURRENCY: 'Moneda para los informes',
  REPORTING_CURRENCY_WITH_VALUE: 'Moneda para los informes ({value})',
  LOCAL_CURRENCY: 'Moneda local',
  LOCAL_CURRENCY_WITH_COLON: 'Moneda local:',
  REPORTING_AND_LOCAL_CURRENCIES: 'Monedas para los informes y monedas locales',
  AVAILABLE_CURRENCIES: 'Monedas disponibles',
  REPORTING_CURRENCY_DEFAULT: 'Moneda (predeterminada) para los informes',
  REPORTING_CURRENCY_DESIGNATED: 'Moneda (designada) para los informes',
  CONSTANT_CURRENCY_EXCHANGE_RATE: 'Tipo de cambio de moneda constante',
  ADD_CURRENCY: 'Añadir moneda',
  VALUES_SHOWN_IN_REPORTING_CURRENCY: 'Los valores se muestran en la moneda para los informes ({value})',
  VALUES_SHOWN_IN_LOCAL_CURRENCY: 'Los valores se muestran en la moneda local ({value})',
  REPORTING_CURRENCY_IS: 'La moneda para los informes es {value}',
  LOCAL_CURRENCY_IS: 'La moneda local es {value}',
  EMPTY_HIERARCHY_MESSAGE: 'Seleccione un elemento de la jerarquía para ver los detalles.',
  ALL: 'Todo',
  SELECT_CURRENCY: 'Seleccionar una moneda',
  SELECT_CURRENCY_ERROR_MESSAGE: 'Seleccione una moneda.',
  SELECT_CONVERSION_ERROR_MESSAGE: 'Introduzca un tipo de cambio.',
  CURRENCY_IN_USE: 'Moneda en uso',
  DELETE_CURRENCY: 'Eliminar moneda',
  ADD_TERRITORY_GROUP_TYPE: 'Añadir tipo de grupo de territorios',
  KEY_EXISTS_ERROR: '{value} no está disponible porque ya existe.',
  IS_AVAILABLE: 'está disponible.',
  NO_GRID_TERRITORIES: 'No hay territorios que mostrar.',
  NO_GRID_TERRITORIES_ADDED: 'Aún no se han añadido territorios.',
  NO_TERRITORY: 'No hay territorios',
  EQUALS_SIGN: '=',
  INVERT_EXCHANGE_RATES: 'Invertir tipos de cambio',
  CURRENCY_PAGE_SUCCESS: 'Monedas actualizadas correctamente',
  CURRENCY_PAGE_ERROR: 'No se han podido actualizar las monedas.',
  DELETE_REPORTING_CURRENCY: 'Eliminar moneda para los informes',
  CONFIRM_DELETE_REPORTING_CURRENCY:
    '¿Seguro que quiere eliminar esta moneda para los informes? También se eliminarán todas las monedas locales asociadas a esta moneda para informes.',
  DELETE_LOCAL_CURRENCY: 'Eliminar moneda local',
  CONFIRM_DELETE_LOCAL_CURRENCY: '¿Seguro que quiere eliminar esta moneda local?',
  GET_CONVERSION_RATES_COUNT_ERROR: 'No se ha podido recuperar la cantidad de monedas en uso.',
  UPLOAD_COLUMN_HEADERS_MESSAGE_PREFIX:
    'Utilice los siguientes encabezados de columna en el archivo que cargue: {value}',
  UPDATE_MEASURE_VALUE_ERROR: 'No se ha podido actualizar el valor de la medida.',
  FILE_UPLOAD_HEADER_ERROR_SINGLE: 'Falta el siguiente encabezado de columna: {value}',
  FILE_UPLOAD_HEADER_ERROR_PLURAL: 'Faltan los siguientes encabezados de columnas: {value}',
  FILE_UPLOAD_HEADER_ERROR_UNKNOWN: 'Se ha producido un error al validar este archivo.',
  SET_CONVERSION_RATE: 'Establecer tipo de cambio',
  DELETE_HIERARCHY_WITH_NAME: '¿Eliminar jerarquía {name}?',
  DELETE_HIERARCHY_MEMBER_WITH_VALUE: '¿Eliminar {value} miembro de la jerarquía?',
  DELETE_HIERARCHY_MEMBERS_WITH_VALUE: '¿Eliminar {value} miembros de la jerarquía?',
  DELETE_HIERARCHY_SUCCESS: 'La jerarquía {name} se ha eliminado correctamente.',
  DELETE_HIERARCHY_ERROR: 'No se ha podido eliminar la jerarquía {name}.',
  MIXED_CLUSTER_TOOLTIP: 'Este clúster contiene cuentas procedentes de varios territorios',
  MIXED_CLUSTER_WITH_UNASSIGNED_TOOLTIP:
    'Este clúster contiene cuentas procedentes de varios territorios o de cuentas sin asignar',
  OVERASSIGNED_CLUSTER_TOOLTIP: 'Este clúster contiene cuentas que pertenecen implícitamente a dos o más territorios',
  UNASSIGNED_CLUSTER_TOOLTIP: 'Este clúster contiene cuentas procedentes de cuentas sin asignar',
  ASSIGNED_CLUSTER_TOOLTIP: 'Este clúster contiene cuentas procedentes de {territoryId} ({territoryName})',
  TERRITORIES_AND_COUNT: 'Territorios ({count})',
  TERRITORY_ID_AND_NAME: '{territoryId} ({territoryName})',
  PRIOR_YEAR_ACTUAL: 'Cifras de ventas del ejercicio anterior: ',
  PRIOR_YEAR_SALES: 'Ventas del ejercicio anterior',
  TOTAL: 'Total',
  TOTAL_WITH_LABEL: '{label} en total',
  TOTAL_WITH_VALUE: 'Total {value}',
  TOTAL_WITH_FRACTION: 'Total {numerator}/{denominator}',
  CANCEL_WORKFLOW_CONFIRMATION: '¿Seguro que quiere cancelar el flujo de trabajo?',
  YES: 'Sí',
  NO: 'No',
  LATER: 'Más tarde',
  REPARENTING: 'Reasignando relación jerárquica…',
  NOT_APPLICABLE_ABBREVIATION: 'NC',
  PIPE_CONFIGURATION_DELETE_PROMPT:
    'Esta acción eliminará la configuración de su canalización “{pipeName}, {exportName}” de forma permanente. Esta acción no se puede deshacer. ¿Confirmar?',
  CONTRIBUTOR_QUOTA_COMPONENT_SUBMIT_WARNING: 'Antes de proceder con el envío, debe revisar {value}',
  CONTRIBUTOR_HIERARCHY_SUBMIT_WARNING:
    'Antes de proceder con el envío, debe aprobar todos los territorios secundarios.',
  SHOW_CHANGES: 'Mostrar cambios',
  SHOW_IMPACTED_ROWS_ONLY: 'Mostrar solo filas afectadas',
  REVIEW_CHANGES: 'Revisar cambios',
  SHEETS: 'Hojas',
  TABLES: 'Tablas',
  UNPUBLISHED: 'Sin publicar',
  PUBLISHED: 'Publicado',
  PUBLISHING_IN_PROGRESS: 'Publicación en curso',
  IMPORT: 'Importar',
  DATE_CREATED: 'Fecha de creación',
  DATE_MODIFIED: 'Fecha de modificación',
  SELLERS: 'Vendedores',
  SELLER: 'Vendedor',
  SELECT_SELLER: 'Seleccionar vendedor',
  SELECT_TERRITORY_GROUP: 'Seleccionar grupo de territorios',
  MOVE: 'Mover',
  MOVE_ACCOUNTS: 'Mover cuenta(s)',
  MOVE_ACCOUNTS_DIALOG_BODY: 'Seleccione un territorio al que mover las cuentas seleccionadas:',
  OVERRIDE_ACCOUNTS_TITLE: '¿Anular las cuentas?',
  OVERRIDE_REASSIGN_BODY_1: 'Si mueve las cuentas seleccionadas, se añadirán reglas de anulación a su territorio.',
  OVERRIDE_REASSIGN_BODY_2:
    'Si no quiere añadir reglas de anulación, cancele este movimiento y actualice la regla base de su territorio en el editor de reglas.',
  DONT_SHOW_THIS_AGAIN: 'No volver a mostrar',
  HYPHEN: '-',
  EXPAND: 'Expandir',
  COLLAPSE: 'Contraer',
  ZOOM_IN: 'Acercar',
  ZOOM_OUT: 'Alejar',
  CLOSE: 'Cerrar',
  LAST_MODIFIED: 'Última modificación: {value}',
  LAST_OPENED: 'Abierto por última vez',
  BATTLE_CARD_REFERENCED: 'Argumentario de ventas referenciado',
  SELECT_BATTLE_CARD_REFERENCE: 'Seleccione los argumentarios de ventas y los niveles que hacen referencia a esta hoja',
  BATTLE_CARD_NAME: 'Nombre del argumentario de ventas',
  SHEETS_REFERENCED: 'Hojas referenciadas',
  REFERENCE_THIS_SHEET: 'Haga referencia a esta hoja',
  PUBLISH: 'Publicar',
  PUBLISHED_TOOLTIP: 'Escenario publicado: {name}',
  UNPUBLISHED_TOOLTIP: 'Escenario sin publicar: {name}',
  SCENARIO_PUBLISHED: 'Se ha publicado el escenario',
  SCENARIO_PUBLISHED_MESSAGE: 'Hemos publicado el escenario {name}.',
  SCENARIO_PUBLISHED_FAILURE: 'Se ha producido un error al publicar el escenario',
  SCENARIO_DELETE_PROMPT:
    'Esta acción eliminará el escenario {name} de forma permanente. Esta acción no se puede deshacer. ¿Seguro que quiere eliminarlo?',
  SCENARIO_PUBLISHED_ERROR: '{name} está actualmente publicado',
  SCENARIO_PUBLISHED_ERROR_DESCRIPTION:
    'El escenario {name} no se puede eliminar porque actualmente está publicado. Publique otro escenario e inténtelo de nuevo.',
  SCENARIO_DELETED: 'Se ha eliminado el escenario',
  SCENARIO_DELETED_FAILURE: 'Se ha producido un error al eliminar el escenario',
  SCENARIO_DELETED_FAILURE_MESSAGE:
    'No hemos podido eliminar el escenario {name}. Actualice la página e inténtelo de nuevo.',
  PERMISSIONS: 'Permisos',
  APPLY_PER_USER_ROLE: 'Aplicar por función de usuario',
  USER: 'Usuario',
  QUOTA_SHEET_COLUMNS: 'Columnas de la hoja de cuotas',
  TERRITORY_SHEET_COLUMNS: 'Columnas de la hoja de territorios',
  SELLER_SHEET_COLUMNS: 'Columnas de la hoja de vendedores',
  COLUMNS_DESCRIPTION: 'Gestionar campos incorporados y añadir campos personalizados',
  CREATE_FIELD: 'Crear campo',
  EDIT_FIELD: 'Editar campo',
  SHOW_TOTALS: 'Mostrar totales',
  REPARENT_TO_ROOT: 'Reasignar relación jerárquica a argumentario raíz',
  ADD_HIERARCHY_UNDER_ROOT: 'Añadir jerarquía bajo el argumentario raíz',
  PIN_SET: 'Conjunto de marcadores',
  PIN_TOOLTIP: 'Ver y añadir marcadores',
  ADD_PIN_SET: 'Añadir conjunto de marcadores',
  PIN_SET_NAME: 'Nombre del conjunto de marcadores',
  PIN_SET_ICON: 'Icono del conjunto de marcadores',
  PIN_SET_COLOR: 'Color del conjunto de marcadores',
  COLOR_OPTION: 'Opción de color {value}',
  PIN_NAME_PLACEHOLDER: 'Introduzca el nombre del marcador',
  PIN_SET_DATA_PLACEHOLDER: 'Seleccione una fuente de datos para su conjunto de marcadores',
  CREATE_PIN_SET_SUCCESS: 'Hemos creado su conjunto de marcadores.',
  CREATE_PIN_SET_ERROR: 'Lo sentimos, no hemos podido crear su conjunto de marcadores.',
  LOCATION_GROUP_UNIQUENESS_ERROR: 'Ya existe una tabla de datos con este nombre',
  MISSING_REQUIRED_LOCATION_GROUP_NAME: 'Introduzca un nombre para la tabla de datos',
  MISSING_REQUIRED_PIN_SET_NAME: 'Introduzca un nombre para el conjunto de marcadores',
  PIN_SET_NAME_TOO_LONG: 'Ha superado el límite máximo de caracteres ({maxCharacters})',
  MISSING_REQUIRED_LOCATION_GROUP: 'Seleccione una fuente de datos',
  MISSING_REQUIRED_ICON: 'Seleccione un icono',
  MISSING_REQUIRED_COLOR: 'Seleccione un color',
  DATA_SOURCE_DUPLICATE_USAGE:
    'En el argumentario de ventas ya hay un conjunto de marcadores que utiliza esta fuente de datos. Seleccione una fuente de datos distinta.',
  PIN_SET_NAME_UNIQUENESS_ERROR: 'Ya existe un conjunto de datos con este nombre.',
  LOCATION_FILE_TYPE: 'Marcadores del mapa',
  EXISTING_PIN_SET_TEXT: 'Usar datos de un conjunto de marcadores existente',
  NEW_PIN_SET_TEXT: 'Importar datos de un conjunto de marcadores nuevo',
  PREVIEW: 'Vista previa',
  ADD_USER_ROLE: '+ Añadir',
  QUOTA_COMPONENTS_REFERENCED: 'Componentes de cuota referenciados',
  QUOTA_COMPONENTS_REFERENCED_CHECKBOX_SELECTION_WARNING: 'Haga referencia a un componente de cuota, como mínimo',
  COLUMN_NAME: 'Nombre de columna',
  SOURCE: 'Fuente',
  DATA_TABLE_TYPE_WITH_COLON: 'Tipo de tabla de datos:',
  DATA_TABLE_NAME: 'Nombre de la tabla de datos*',
  DATA_TABLE_PLACEHOLDER: 'Por ejemplo, Ayuntamientos de EE. UU. 2022',
  DATA_TABLE: 'Tabla de datos',
  COLUMN: 'Columna',
  GROUP_BY: 'Agrupar por:',
  APPLIED_FILTERS: 'Filtros aplicados:',
  RESET: 'Restablecer',
  DELETE_FIELD_FAILED: 'No se ha podido eliminar el campo',
  CREATE_FIELD_SUCCESS: 'El campo se ha creado correctamente',
  UPDATE_FIELD_SUCCESS: 'El campo se ha actualizado correctamente',
  DELETE_FIELD_SUCCESS: 'El campo se ha eliminado correctamente',
  UPDATE_QUOTA_SHEET_SUCCESS: 'La hoja de cuotas se ha actualizado correctamente',
  UPDATE_QUOTA_SHEET_FAILED: 'No se ha podido actualizar la hoja de cuotas',
  COLUMN_REORDER_FAILED: 'No se han podido reordenar los campos de las columnas',
  COLUMN_REORDER_SUCCESS: 'Los campos de las columnas se han reordenado correctamente',
  DISABLED_CHECKBOX_TOOLTIP: 'Esta propiedad no está habilitada para este tipo de campo.',
  DISABLED_PLANNING_CYCLE_FIELD_TOOLTIP:
    'Este campo no se puede editar después de haber creado el ciclo de planificación.',
  VISIBLE: 'Visible',
  EDITABLE: 'Editable',
  CANCEL_LASSO: 'Cancelar elementos seleccionados con el lazo',
  LASSO_TOOLTIP: 'Herramienta de lazo',
  LASSO_DROPDOWN_TOOLTIP:
    'Filtre los elementos seleccionados con el lazo para incluir regiones geográficas asignadas o sin asignar.',
  LASSO_DROPDOWN_TOOLTIP_ACCOUNTS:
    'Filtre los elementos seleccionados con el lazo para incluir cuentas asignadas o sin asignar.',
  SELECT_TOOLTIP: 'Herramienta de selección',
  UNDO: 'Deshacer',
  MAP_UNDO_BODY:
    'Está a punto de deshacer el último cambio que ha realizado. Una vez completada la acción, no podrá rehacerlo. ¿Seguro que desea continuar?',
  MAP_UNDO_TOAST_ERROR_TITLE: 'No se ha podido deshacer',
  MAP_UNDO_TOAST_ERROR_BODY: 'Algo ha ido mal. No hemos podido deshacer el cambio que ha realizado en la regla.',
  MAP_UNDO_TOAST_SUCCESS: 'La acción de deshacer se ha completado correctamente.',
  MODERATE_DEVIATION: 'moderada',
  SIGNIFICANT_DEVIATION: 'importante',
  MINOR_DEVIATION: 'menor',
  ABOVE_AVERAGE:
    'Puntuación de discrepancia: {gapScore}. Este valor está por encima de la media, lo que lo sitúa en el percentil {percentile} e indica una desviación {deviationType}.',
  BELOW_AVERAGE:
    'Puntuación de discrepancia: {gapScore}. Este valor está por debajo de la media, lo que lo sitúa en el percentil {percentile} e indica una desviación {deviationType}.',
  ON_AVERAGE:
    'Puntuación de discrepancia: {gapScore}. Este territorio se sitúa en la media de todos los territorios que utilizan el mismo indicador, por lo que no muestra ninguna desviación importante.',
  GAP: 'Discrepancia',
  CREATE_NEW_TERRITORY_ERROR: 'No se ha podido crear el territorio.',
  DUPLICATE_OVERRIDE_INCLUSION_KEY: 'La clave de inclusión de la jerarquía ya existe en otra regla de anulación',
  CREATE_NEW_TERRITORY_SUCCESS: 'Se ha creado el nuevo territorio.',
  CREATE_NEW_TERRITORY_DIALOG_TITLE: 'Crear nuevo territorio',
  EFFECTIVE_DATING: 'Fecha de entrada en vigor',
  EXCEL: 'Excel',
  USER_PROFILE: 'Perfil',
  PROFILE_PAGE_PERSONAL_INFORMATION: 'Información personal',
  PROFILE_PICTURE: 'Imagen de perfil',
  PROFILE_PICTURE_SUBTITLE: 'Añada una foto para personalizar su cuenta',
  USE_LASSO_TOOL: 'Utilice la herramienta de lazo ',
  DRAW_NEW_TERRITORY: ' para dibujar un nuevo territorio',
  EMPTY_MAP_GRID_TITLE: 'No se han podido cargar las vistas previas',
  EMPTY_MAP_TGT_GRID_MESSAGE:
    'Es posible que no tenga territorios dentro de su tipo de grupo de territorios geográficos. Póngase en contacto con su administrador.',
  EMPTY_MAP_TG_GRID_MESSAGE:
    'Es posible que no tenga territorios dentro de su grupo de territorios. Póngase en contacto con su administrador.',
  PASSWORD_LAST_CHANGED: 'Última modificación: {date}',
  PASSWORD_STRENGTH: 'Seguridad de la contraseña: ',
  PASSWORD_STRENGTH_INSTRUCTION:
    'Utilice al menos 8 caracteres. No utilice la misma contraseña que utiliza para acceder a otro sitio web, o algo que pueda resultar demasiado obvio, como el nombre de su mascota.',
  PASSWORD_INSTRUCTION: 'Elija una contraseña segura y no utilice la misma en otras cuentas.',
  CHANGE_PASSWORD: 'Cambiar contraseña',
  CONFIRM_EXISTING_PASSWORD_LABEL: 'Vuelva a introducir la contraseña',
  NEW_PASSWORD_LABEL: 'Contraseña nueva',
  CONFIRM_NEW_PASSWORD_LABEL: 'Confirmar contraseña nueva',
  SET_BALANCING_METRIC: 'Este es un indicador que mide el equilibrio',
  MAKE_THIS_DEFAULT: 'Establecer como valor predeterminado',
  BALANCING_MODAL_DELETE_HEADER: '¿Eliminar este territorio?',
  REBALANCING_COINSORT_HEADER: '¿Ejecutar clasificación por monedas?',
  REBALANCING_COINSORT_BODY:
    '¿Quiere ejecutar la clasificación por monedas ahora? Los territorios se ordenarán con las nuevas reglas actualizadas.',
  BALANCING_MODAL_DELETE_BODY: 'Si elimina este territorio también se cancelarán los cambios que no haya guardado.',
  BALANCING_MODAL_BATTLECARD_BODY:
    'Si cierra este argumentario de ventas también se cancelarán los cambios que no haya guardado.',
  REBALANCING_SUCCESS: 'Los territorios se han reequilibrado correctamente.',
  UPLOADING: 'Cargando...',
  UPLOAD: 'Cargar',
  DONE: 'Hecho',
  DATA_FILE_DRAG_DROP_TITLE: 'Arrastrar archivo o hacer clic para cargar',
  DATA_FILE_DRAG_DROP_TEXT: 'Cargue un archivo de texto desde su dispositivo local. El formato aceptado es: .csv',
  FILE_PUBLISH_ERROR: 'No se ha podido publicar el archivo',
  FILE_HAS_BEEN_PUBLISH_SUCCESS: '{name} se ha publicado correctamente',
  FILE_HAS_BEEN_PUBLISH_ERROR: 'Se ha producido un error al publicar {name}',
  SYSTEM_TIMESTAMP: 'Marca de tiempo del sistema',
  DATA_FILE_UPLOAD_SEQUENCE_ERROR: '¡Lo sentimos! Se ha producido un error al cargar el archivo: {message}',
  REMOVE_REBALANCING_TERRITORY: 'Eliminar este territorio',
  REMOVE_TERRITORY: 'Eliminar territorio',
  ADDITIONAL_REBALANCING_TERRITORY_ERROR: '¡Lo sentimos! Solo se pueden seleccionar dos territorios al mismo tiempo.',
  TERRITORY_RULE: 'Regla territorial',
  UPDATE_TERRITORY_SUCCESS: 'El territorio se ha actualizado correctamente.',
  UPDATE_TERRITORY_ERROR: 'No se ha podido actualizar el territorio.',
  MENU: 'Menú',
  HELP: 'Ayuda',
  WARNING: 'Advertencia',
  OPEN: 'Abrir',
  BUILT_IN_MEASURES_CANNOT_EDIT: 'Las medidas incorporadas no se pueden editar.',
  BEFORE_BALANCING_METRIC_DEFAULT:
    'Se utilizará este indicador de forma predeterminada. Por ejemplo, cuando reequilibre territorios y tenga varios indicadores de equilibrio, las funciones que solo utilicen un único indicador al mismo tiempo utilizarán este de forma predeterminada.',
  DEFAULT_BALANCING_METRIC_WARNING_MESSAGE:
    'Seleccione otro indicador de equilibrio si desea modificar el que está establecido por defecto.',
  AUTOMATCH: 'Coincidencia automática',
  GET_S3_FILE_ERROR: 'No se ha podido recuperar el contenido del archivo',
  SELECT_A_HIERARCHY: 'Seleccionar una jerarquía',
  SELECT_TERRITORY: 'Seleccionar territorio',
  EDIT_HIERARCHY_NONE: 'Seleccionar una jerarquía para editar',
  IMPORT_FROM_CSV: 'Importar desde archivo .csv',
  IMPORT_FROM_SYMON: 'Importar desde canalización',
  MAP_DATA_ERROR: 'Se ha producido un error al cargar los datos del mapa',
  CANNOT_REBALANCE_CUSTOMER:
    'No puede mover un cliente que pertenezca a varios territorios o que tenga actividades sin asignar en cualquier componente de cuota.',
  CANNOT_REBALANCE_GEOGRAPHY:
    'No puede mover una región geográfica que pertenezca a varios territorios o que tenga actividades sin asignar en cualquier componente de cuota.',
  CANNOT_REBALANCE_MISSING_GEO_HIERARCHY:
    'No se ha podido mover la región geográfica porque falta una jerarquía. Compruebe que las jerarquías cargadas coincidan con las que aparecen en los archivos de actividad.',
  TERRITORY_GRID_ERROR:
    'No se pueden cargar los territorios en la cuadrícula. Haga clic en Actualizar para volver a cargar los territorios.',
  ENTER_TERRITORY_GROUP_NAME: 'Introduzca un nombre para su grupo de territorios',
  GLOBAL_ERROR_BOUNDARY_TROUBLE: 'Estamos teniendo problemas para procesar todos los datos. ',
  GLOBAL_ERROR_BOUNDARY_REFRESH: 'Actualice la página, le redirigiremos a la página de inicio.',
  SYMON_AI_SOURCE: 'Fuente de la canalización',
  NO_RESULTS_FOUND: 'No se han encontrado resultados',
  GET_SYMON_PIPENAMES_ERROR: 'No se han podido recuperar los nombres de las canalizaciones.',
  GET_SYMON_EXPORT_NODES_ERROR: 'No se han podido recuperar los nodos de exportación.',
  MOVE_TERRITORY: 'Mover territorio',
  MOVE_TERRITORY_DESCRIPTION: 'Seleccione el grupo de territorios al que mover el territorio seleccionado:',
  MOVE_TERRITORY_ERROR: 'No se han podido mover los territorios.',
  MOVE_TERRITORY_SUCCESS_NAMED:
    'El territorio {territoryId} se ha movido correctamente al grupo de territorios {territoryGroup}',
  MOVE_TERRITORY_SUCCESS_MULTIPLE:
    'Varios territorios se han movido correctamente al grupo de territorios {territoryGroup}',
  MOVE_TERRITORY_DISABLED:
    'No puede mover los territorios seleccionados porque pertenecen a diferentes tipos de grupos de territorios.',
  CARD_TYPE: 'Tipo de argumentario de ventas: ',
  BATTLECARD_TYPE_OVERLAY: 'Superpuesto',
  BATTLECARD_TYPE_OVERLAY_ICON: 'Icono de argumentario de ventas superpuesto',
  BATTLECARD_TYPE_PRIMARY: 'Principal',
  BATTLECARD_TYPE_PRIMARY_ICON: 'Icono de argumentario de ventas principal',
  BATTLECARD_TYPE_ROLL_UP: 'Consolidado',
  CREATE_CHILD_UNDER: 'Crear elemento secundario en {value}',
  ROOT: 'argumentario de ventas raíz',
  INTEGRATIONS: 'Integraciones',
  SYMON: 'Symon',
  VIEW_SOURCES: 'Ver fuentes',
  MANAGE_TABLES: 'Administrar tablas',
  BUILD_AND_VISUALIZE: 'Crear y visualizar',
  DASHBOARDS: 'Paneles de control',
  CONFIRM: 'Confirmar',
  SYMON_CREDENTIALS_SAVE_SUCCESS: 'Las credenciales de la canalización se han guardado correctamente',
  SYMON_CREDENTIALS_DELETE_SUCCESS: 'Las credenciales de la canalización se han eliminado correctamente',
  SYMON_CREDENTIALS_SAVE_FAILED: 'Se ha producido un error al guardar las credenciales de la canalización',
  SYMON_CREDENTIALS_BEING_VALIDATED: 'Se están validando sus credenciales de API.',
  SYMON_CREDENTIALS_VALID: 'Hemos validado su clave de API y la conexión a la canalización ya está lista.',
  SYMON_CREDENTIALS_INVALID: 'No se han podido verificar sus credenciales, asegúrese de que sean correctas.',
  VALIDATE_API_KEY: 'Validar clave de API',
  DELETE_API_KEY: 'Eliminar clave de API',
  SYMON_DOMAIN: 'Dominio de la canalización',
  SAVED_SYMON_DOMAIN_INVALID_TITLE: 'El dominio de la canalización que tiene guardado no es válido.',
  SAVED_SYMON_DOMAIN_INVALID_DESCRIPTION: 'Actualice su clave a una del dominio: ',
  SYSTEM_API_KEY: 'Clave de API del sistema',
  CONFIGURE_FOR_PLANNING: 'Configurar para la planificación',
  GET_SYMON_ACCOUNT_ERROR: 'No se ha podido recuperar la cuenta de la canalización.',
  SYMON_DOMAIN_REQUIRED: 'El dominio de la canalización es obligatorio.',
  SYSTEM_API_KEY_REQUIRED: 'La clave de API del sistema es obligatoria',
  SYMON_API_URL_INVALID: 'La URL de la canalización no es válida',
  SYMON_EXPORT_NODE_REQUIRED: 'El nodo de exportación de la canalización es obligatorio.',
  SYMON_PIPE_NAME_REQUIRED: 'La fuente de la canalización es obligatoria',
  DESTINATION_TABLE_REQUIRED: 'El nombre de la tabla de destino es obligatorio',
  NEW_TERRITORY: 'Nuevo territorio',
  FORWARD_SLASH: '/',
  INPUT_TEXT: 'Texto de entrada',
  TABLE_CONTROLLER_PUBLISHED_STATUS_FAILED_DEFAULT_ERROR:
    'No se ha podido publicar su archivo. Compruebe que el archivo que ha importado incluya campos que coincidan con las columnas correspondientes.',
  ACTION_REQUIRED_REVIEW_ERROR: 'Acción necesaria: revisar error',
  ACTION_REQUIRED_MATCH_FIELDS: 'Acción necesaria: emparejar campos',
  MATCH_FIELDS_TITLE: 'Emparejar campos',
  MATCH_FIELDS_TEXT_RUN_THIS_CONFIGURATION:
    'Seleccione las columnas obligatorias para que coincidan con sus campos de datos. Todas las columnas obligatorias deben coincidir con sus campos de datos para poder ejecutar esta configuración.',
  MATCH_FIELDS_TEXT_PUBLISH_THIS_FILE:
    'Seleccione las columnas obligatorias para que coincidan con sus campos de datos. Todas las columnas obligatorias deben coincidir con sus campos de datos para poder publicar este archivo.',
  MEMBER_SELECTED_WITH_COUNT: '{count} miembro seleccionado',
  MEMBERS_SELECTED_WITH_COUNT: '{count} miembros seleccionados',
  DELETE_MEMBER_WITH_VALUE: 'Eliminar {count} miembro',
  DELETE_MEMBERS_WITH_VALUE: 'Eliminar {count} miembros',
  DESELECT_ALL: 'Deseleccionar todo',
  DELETE_HIERARCHIES_ERROR: 'No se han podido eliminar las jerarquías.',
  CONFIGURATIONS: 'Configuraciones',
  SYMON_AI_EXPORT_NODE: 'Nodo de exportación',
  CREATE_CONFIGURATION: 'Crear configuración',
  RUN_CONFIGURATION_AFTER_CREATING: 'Ejecutar esta configuración después de crearla',
  NO_SYMON_PIPES: 'No hay canalizaciones',
  UPDATED_HIERARCHY: 'Se ha actualizado {rootName} “{name}”.',
  CHECK_FOR_CHANGES: 'Comprobar si ha habido cambios',
  FILE_TYPE: 'Tipo de archivo',
  FILE_REQUIREMENT_TEXT: 'Los archivos {fileType} deben tener las siguientes columnas para poder importarse:',
  DESTINATION: 'Destino',
  DESTINATION_TABLE: 'Tabla de destino',
  NO_SYMON_EXPORT_NODE_ERROR_MESSAGE:
    'No hay nodos de exportación disponibles para esta fuente. Seleccione una fuente de canalización diferente para crear la configuración.',
  NO_SYMON_AI_SOURCE_ERROR_MESSAGE:
    'Para poder seleccionar una fuente de canalización, primero debe configurar una canalización en la plataforma.',
  TEXT: 'Texto',
  ACTIVITY: 'Actividad',
  DATA_MAPPING_PANEL_TEXT:
    'Todavía no figuran sus datos en el sistema. Esta tabla es solo una vista previa. Para importar los datos y crear una nueva tabla, haga clic en Ejecutar.',
  DATA_MAPPING_FIELDS_ERROR: 'Compruebe que ha emparejado todos los campos con las columnas correspondientes.',
  CONFIGURATION_CREATION_SUCCESS: 'La configuración se ha creado correctamente',
  CONFIGURATION_CREATION_FAIL: 'Se ha producido un error al crear la configuración',
  UNDER_BY: 'Se sitúa por debajo del valor por {value}',
  OVER_BY: 'Se sitúa por encima del valor por {value}',
  RUN_THIS_CONFIGURATION: 'Ejecutar esta configuración',
  SYMON_PIPE_CONFIGURATIONS_ERROR: 'No se han podido recuperar las configuraciones de su canalización.',
  GETTING_PREVIEW_DATA: 'Extrayendo datos de vista previa de la configuración de la canalización...',
  UPDATE_SYMON_PIPE_CONFIGURATION_SUCCESS: 'La configuración se ha actualizado correctamente.',
  UPDATE_SYMON_PIPE_CONFIGURATION_ERROR: 'No se ha podido actualizar la configuración de su canalización.',
  DELETE_SYMON_PIPE_CONFIGURATION_SUCCESS: 'La configuración se ha eliminado correctamente.',
  DELETE_SYMON_PIPE_CONFIGURATION_ERROR: 'Se ha producido un error al eliminar la configuración.',
  CONFIGURATION: 'Configuración',
  MEMBERS_REPARENTED_SUCCESS: 'La relación jerárquica entre los {count} miembros se ha reasignado correctamente',
  SYMON_FILE_IMPORT_IN_PROGRESS: 'Estamos importando su archivo de datos.',
  SYMON_FILE_IMPORT_ERROR: 'No se ha podido importar el archivo de datos',
  NOT_STARTED: 'No iniciado',
  RUNNING: 'En ejecución',
  COMPLETED: 'Completado',
  FAILED: 'Se ha producido un error',
  FILE_SUCCESSFULLY_PUBLISHED: 'Su archivo se ha publicado correctamente.',
  MAKE_A_COPY: 'Hacer una copia',
  PLANNING_CYCLE_CLONE_FAILURE: 'No se ha podido clonar el ciclo de planificación',
  PLANNING_CYCLE_CLONE_REQUEST_SUCCESS:
    'La solicitud para clonar el ciclo de planificación se ha realizado correctamente',
  SELECT_AN_EXISTING_CONFIGURATION: 'Seleccionar una configuración existente',
  IMPORT_CONFIG_MESSAGE:
    'Importaremos su archivo de datos en la tabla de destino. Ahora podrá consultarlo en la página Datos.',
  CREATE_NEW_CONFIGURATION: 'Crear nueva configuración',
  RUN_EXISTING_CONFIGURATION: 'Ejecutar configuración existente',
  FILE_PREVIEW: 'Vista previa del archivo',
  REVIEW_IMPORT: 'Revisar importación',
  COMMENTS: 'Comentarios',
  ALL_GROUPS: 'Todos los grupos',
  ALL_TERRITORIES: 'Todos los territorios',
  NO_COMMENTS_TO_SHOW: 'No hay comentarios que mostrar',
  ADD_COMMENTS_MESSAGE: 'Añada comentarios para compartir su opinión sobre la planificación de territorios',
  ADD_COMMENTS: 'Añadir comentarios',
  NEW_COMMENTS_PLACEHOLDER: 'Utilice la fórmula @nombre para etiquetar un ID de territorio o grupo',
  REPLY: 'Responder',
  MAP_SELECTION_STATS_ERROR: 'No se han podido obtener las estadísticas para los elementos seleccionados',
  ENTER_TABLE_NAME: 'Introduzca el nombre de la tabla',
  CHOOSE_THE_SOURCE_AND_EXPORT_NODE:
    'Seleccione la fuente y el nodo de exportación del que desea extraer el archivo de datos.',
  SYMON_AI_SOURCE_AND_EXPORT_NODE: 'Fuente de la canalización y nodo de exportación',
  SYMON_REVIEW_DIALOG_CONFIGURATION_TEXT: 'Guardaremos esta configuración en la página Configuraciones.',
  SYMON_REVIEW_DIALOG_EXISTING_CONFIGURATION_TEXT:
    'Volveremos a importar su configuración existente. De esta manera, se creará una nueva versión de la tabla de datos {name}.',
  TERRITORY_RULES: 'Reglas territoriales',
  MAP: 'Ver mapa',
  GRID: 'Ver cuadrícula',
  URL_ERROR_MESSAGE_GENERIC: 'Introduzca una URL válida',
  INCLUSIONS: 'Elementos incluidos',
  EXCLUSIONS: 'Elementos excluidos',
  VIEW_TERRITORIES: 'Ver territorios',
  VIEW_QUOTAS: 'Ver cuotas',
  NO_ACTIVITIES_TOOLTIP: 'Todavía no ha añadido ningún dato de actividad en este argumentario de ventas.',
  COINSORT_COMPLETE_TOOLTIP: 'Ejecute la acción de clasificación por monedas para obtener estadísticas más precisas',
  COINSORT_RUNNING_TOOLTIP: 'Estamos ordenando sus actividades por territorios. Inténtelo de nuevo más tarde.',
  HIDDEN: 'Oculto',
  NON_MAPPABLE_TOOLTIP: 'Este territorio contiene reglas complejas y no se puede representar en el mapa',
  CREATE_TERRITORY_TOOLTIP_GEO: 'Seleccione límites sin asignar para crear un nuevo territorio.',
  CREATE_TERRITORY_TOOLTIP_ACCOUNT: 'Seleccione cuentas sin asignar para crear un nuevo territorio.',
  IMPORT_QUOTAS: 'Importar cuotas',
  REQUIRED: 'Obligatorio *',
  OPTIONAL: 'Opcional',
  ADD_RULE: 'Añadir regla',
  ADD_OVERRIDE: 'Añadir regla de anulación',
  OVERRIDE_RULE_TOOLTIP_ENABLED:
    'Las reglas de anulación se moverán a este territorio y se eliminarán de sus reglas territoriales actuales.',
  BASE_RULE: 'Regla base',
  OVERRIDE: 'Anular',
  HIERARCHY_MEMBER_DELETE_SUCCESS: 'El miembro de la jerarquía se ha eliminado correctamente.',
  YOUR_FILE_IS_MISSING: 'Falta(n) al menos {count} columna(s) obligatoria(s) en su archivo.',
  DOWNLOAD_QUOTA_FILE_TEXT: 'Descargar plantilla del archivo de cuotas',
  DOWNLOAD_SELLER_ASSIGNMENT_FILE_TEXT: 'Descargar plantilla del archivo de tareas asignadas a vendedores',
  DOWNLOAD_ACTIVITY_FILE_TEXT: 'Descargar plantilla del archivo de actividad',
  DOWNLOAD_LOCATION_TEMPLATE_CSV: 'Descargar plantilla del archivo CSV',
  CONFLICTING_RULES: 'Hay {count} regla(s) contradictoria(s)',
  CONFLICTING_RULE: 'Regla contradictoria',
  LOCKED: 'Bloqueado',
  CONFLICTING_RULES_GRID_TOOLTIP: 'Este territorio contiene reglas que contradicen a otro territorio',
  REMOVE_CONFLICT_BUTTON: 'Eliminar contradicciones',
  REMOVE_CONFLICT_ACTION_TOOLTIP:
    'Elimine las contradicciones suprimiendo la región geográfica del territorio seleccionado',
  REMOVE_CONFLICT_DIALOG_TITLE: '¿Eliminar contradicciones en las reglas territoriales?',
  REMOVE_CONFLICT_DIALOG_TEXT:
    'Está a punto de eliminar las siguientes contradicciones en las reglas territoriales de {territory}:',
  NO_TERRITORY_RULES:
    'Como los siguientes territorios no contienen reglas territoriales, no hay nada que mostrar en el mapa: {territories}',
  HIDE_GRID: 'Ocultar cuadrícula',
  SHOW_GRID: 'Mostrar cuadrícula',
  CHANGE_COLOR: 'Cambiar color',
  UPLOAD_HIERARCHY: 'Cargar jerarquía',
  UPLOAD_HIERARCHY_NONE: 'Seleccionar una jerarquía para cargar en',
  UPLOAD_TO: 'Cargar en {name}',
  SET_SEASONALITY: 'Establecer estacionalidad',
  APPLY: 'Aplicar',
  FORMULA_EDITOR_TOOLTIP_MESSAGE:
    'Seleccione operadores y fuentes de datos para crear una fórmula o escríbala en este cuadro de texto.',
  CALCULATED_FIELD_EXAMPLE: 'Ejemplo: (Ingresos-Gastos)*0,4',
  OPERATORS: 'Operadores',
  CANNOT_VIEW_TERRITORY_GROUP: 'Solo puede ver los grupos de territorios de los cuales es la persona responsable',
  START_WORKFLOW: 'Iniciar flujo de trabajo',
  STOP_WORKFLOW: 'Detener flujo de trabajo',
  START_TERRITORY_WORKFLOW_HEADER: '¿Iniciar flujo de trabajo en el territorio?',
  START_TERRITORY_WORKFLOW_BODY:
    '¿Quiere iniciar este flujo de trabajo en el territorio? Esta acción notificará a todos los colaboradores que el flujo de trabajo ha comenzado. Los colaboradores podrán ver y dejar comentarios sobre los territorios de los cuales sean responsables.',
  STOP_TERRITORY_WORKFLOW_HEADER: '¿Detener el flujo de trabajo en el territorio?',
  STOP_TERRITORY_WORKFLOW_BODY:
    '¿Quiere detener este flujo de trabajo en el territorio? Esta acción notificará a todos los colaboradores que el flujo de trabajo se ha detenido. Los colaboradores ya no podrán acceder al ciclo de planificación.',
  RETRIEVE_TERRITORY_WORKFLOW_ERROR:
    'Estamos teniendo problemas para cargar sus opciones del flujo de trabajo. Para iniciar o detener su flujo de trabajo, actualice la página.',
  START_TERRITORY_WORKFLOW_ERROR:
    'En este momento estamos teniendo problemas para iniciar este flujo de trabajo. Actualice la página e inténtelo de nuevo.',
  STOP_TERRITORY_WORKFLOW_ERROR:
    'En este momento estamos teniendo problemas para detener este flujo de trabajo. Actualice la página e inténtelo de nuevo.',
  ENTER_COMMENT: 'Introduzca un comentario',
  COMMENT_PANEL_LOADING_ERROR:
    'Lo sentimos, en este momento estamos teniendo problemas para cargar estos comentarios. Pruebe a actualizar la página.',
  POST_COMMENT_ERROR: 'Lo sentimos, estamos teniendo problemas para publicar este comentario. Inténtelo de nuevo.',
  SELLER_ASSIGNMENT: 'Tarea asignada a vendedor',
  ADD_MORE: 'Añadir más',
  GET_QUOTA_WORKFLOW_STATUS_ERROR:
    'No se ha podido recuperar el estado del flujo de trabajo de las cuotas del servidor.',
  SEASONALITY_INSTRUCTION:
    'Introduzca factores de ponderación de la estacionalidad personalizados o seleccione una estacionalidad existente para comenzar. El valor total asignado debe ser igual al 100 %.',
  SET_PC_DURATION_PERIODICITY_WARNING: 'Advertencia sobre la duración y la periodicidad del plan',
  SET_PC_DURATION_PERIODICITY_WARNING_DETAIL_UPDATED:
    'Revise la duración de su plan y el ciclo de intervalos de periodicidad. Una vez que haya creado su ciclo de planificación, ya no podrá modificarlos.',
  IMPORT_SELLER_ASSIGNMENTS: 'Importar tareas asignadas a vendedores',
  MULTIPLE: 'Múltiples',
  FORMULA_EDITOR_VALUE_EXPECTED_ERROR_BEFORE: 'Añada una fuente de datos o un valor antes de {operator}',
  FORMULA_EDITOR_VALUE_EXPECTED_ERROR_BEFORE_FULL:
    'Añada una fuente de datos o un valor antes del operador {operator} ({text}).',
  FORMULA_EDITOR_VALUE_EXPECTED_ERROR_AFTER_FULL:
    'Añada una fuente de datos o un valor después del operador {operator} ({text}).',
  FORMULA_EDITOR_VALUE_EXPECTED_ERROR_AFTER: 'Añada una fuente de datos o un valor después de {operator}',
  MISSING_CLOSED_PARENTHESIS_ERROR:
    'Falta un paréntesis de cierre en esta fórmula. Asegúrese de que todos los paréntesis de apertura tengan un paréntesis de cierre.',
  INVALID_DATA_SOURCE_ERROR:
    'Esta fórmula contiene una fuente de datos que no se reconoce. Compruebe que las fuentes de datos estén bien escritas.',
  UNEXPECTED_OPERAND_NO_OPERATOR_ERROR:
    'Operando inesperado ‘{{value}’}. Las fuentes de datos y los valores deben estar separados por un operador.',
  OWNERSHIP: 'Responsabilidad',
  ADD_SELLER: 'Añadir vendedor',
  EXPORT_TO_CSV: 'Exportar a archivo .csv',
  EXPORTING_DATA: 'Exportando datos...',
  EXPORTING_DATA_FAILED: 'Se ha producido un error al exportar los datos',
  TERRITORY_WORKFLOW_NOT_STARTED:
    'Su administrador no ha iniciado un flujo de trabajo de planificación de territorios.',
  TERRITORY_TAB_NO_PERMISSIONS: 'No tiene permisos de visualización para consultar esta pestaña.',
  QUOTA_WORKFLOW_NOT_STARTED: 'Su administrador no ha iniciado un flujo de trabajo de planificación de cuotas.',
  DELETE_SELLER_ASSIGNMENT: '¿Eliminar tarea asignada?',
  DELETE_SELLER_ASSIGNMENT_CONFIRMATION_MESSAGE:
    '¿Quiere eliminar esta tarea asignada al vendedor? La acción de eliminación no se puede deshacer',
  EDIT_ASSIGNMENT: 'Editar tarea asignada',
  FORMULA_REQUIRED: 'Introduzca una fórmula válida.',
  DELETE_SELLER_ASSIGNMENT_SUCCESS: 'La tarea asignada al vendedor se ha eliminado correctamente.',
  DELETE_SELLER_ASSIGNMENT_ERROR: 'No se ha podido eliminar la tarea asignada al vendedor.',
  DELETE_SELLER_FROM_MULTIPLE_TERRITORIES_ERROR: 'No todos los vendedores se han eliminado correctamente.',
  REMOVE_SELLER_TITLE: 'Vendedor eliminado',
  REMOVE_SELLERS_TITLE: 'Vendedores eliminados',
  REMOVE_SELLER_ERROR_TITLE: 'Se ha producido un error al eliminar a los vendedores',
  PERCENTAGE: 'Porcentaje',
  KEEP: 'Conservar',
  ADD_GEO_HIERARCHY_TITLE: 'Añadir datos de jerarquías geográficas',
  ADD_GEO_HIERARCHY_DESC:
    'Seleccione de una lista de países admitidos para añadir datos de jerarquías geográficas a su ciclo de planificación.',
  GEO_GRID_DESC:
    'A continuación, seleccione el nivel geográfico que desea utilizar para planificar sus territorios, por ejemplo, país, estado o código postal.',
  NO_IN_USE_GEO_DESC: 'Debe añadir datos de jerarquías geográficas para poder crear territorios en el mapa.',
  ADD_GEO_HIERARCHY_DROP_DOWN_LABEL: 'Seleccionar países',
  EXTERNAL_DOCS_LINK: 'Haga clic aquí para obtener más información sobre los niveles geográficos',
  RAMP: 'Periodo de progresión',
  NO_RAMP: 'Sin periodo de progresión',
  RENEW: 'Renovar',
  GEO_HIERARCHY_UPDATED_SUCCESS: 'Se han actualizado las jerarquías geográficas',
  GEO_HIERARCHY_UPDATED_ERROR: 'Se ha producido un error al actualizar las jerarquías geográficas',
  GEO_HIERARCHY_GET_ERROR: 'Se ha producido un error al obtener las jerarquías geográficas',
  PLAN_TERRITORIES: 'Territorios del plan',
  PLAN_QUOTAS: 'Cuotas del plan',
  LOOKUP: 'Búsqueda',
  SCHEDULE_NAME: 'Nombre del programa',
  ENTER_NAME: 'Introducir nombre',
  MONTH: 'Mes {value}',
  SEASONALITY_SUM_MESSAGE: 'Su programa estacional por meses debe sumar en total el 100 %.',
  RAMP_VALUE_MESSAGE:
    'Hay un mes en su programa del periodo de progresión que supera el 100 %. Ajuste su programa del periodo de progresión para que cada mes esté entre el 0 % y el 100 %.',
  PERCENTAGE_VALUE_MESSAGE: 'Solo se aceptan valores numéricos mayores o iguales a 0.',
  NO_END_DATE: 'Sin fecha de finalización',
  NO_START_DATE: 'Sin fecha de inicio',
  SCHEDULE_NAME_EXIST_ERROR: 'El nombre del programa ya existe.',
  SCHEDULE_NAME_EMPTY_ERROR: 'Introduzca un nombre para el programa.',
  YOUR_MONTH_SEASONALITY_PLEASE_ADJUST:
    'El porcentaje total de su programa estacional por meses es del {value} %. Ajuste su programa para que la suma de todos los meses sea igual al 100 %.',
  ENTER_AMOUNT: 'Introducir importe',
  RAMP_SCHEDULES: 'Programas del periodo de progresión',
  SEASONALITY_SCHEDULES: 'Programas estacionales',
  VIEW_RAMP_DATA: 'Ver datos del periodo de progresión',
  VIEW_SEASONALITY_DATA: 'Ver datos de la estacionalidad',
  GET_LOOKUP_TABLE_SCHDULES_ERROR: 'No se han podido recuperar los programas',
  UPSERT_LOOKUP_ROW_ERROR: 'No se ha podido añadir el nuevo programa',
  SYMON_ERROR_MESSAGE_1: 'No hemos podido cargar esta página.',
  SYMON_ERROR_MESSAGE_2: 'Actualice la página para intentarlo de nuevo.',
  REFRESH: 'Actualizar',
  SYMON_IMPORT_THIRD_PARTY_HEADER_OVERRIDE: 'Conectores de datos ({num})',
  RAMP_TABLE_MESSAGE: 'Su programa mensual del periodo de progresión debe estar comprendido entre 0 y 100.',
  SELLERS_ADDED: 'Se han añadido los vendedores',
  FAILED_TO_ADD_SELLER: 'Se ha producido un error al añadir a los vendedores',
  FAILED_TO_ADD_SELLER_MESSAGE:
    'No hemos podido añadir a los vendedores a su territorio. Actualice la página e inténtelo de nuevo.',
  SYMON_UPLOAD_START_MESSAGE: 'Estamos cargando el archivo.',
  SYMON_IMPORT_START_MESSAGE:
    'Hemos iniciado la importación del archivo. Puede acceder a él desde la pestaña Ver fuentes.',
  SYMON_UPLOAD_ERROR: 'No hemos podido cargar el archivo.',
  SYMON_CONNECTION_UPDATED_TITLE: 'Se ha actualizado la conexión',
  SYMON_CONNECTION_UPDATED_BODY: 'Se ha validado la conexión {connectionType}',
  SYMON_CONNECTION_FAILURE_TITLE: 'Credenciales no válidas',
  SYMON_CONNECTION_FAILURE_BODY:
    'No se ha podido validar la conexión {connectionType}. Revise sus credenciales e inténtelo de nuevo.',
  IMPORT_ERRORS: 'Errores de importación',
  VIEW_DETAILS: 'Ver detalles',
  COPY_SUMMARY: 'Copiar resumen',
  ROLE_PERMISSIONS: 'Permisos de función',
  ERROR_SUMMARY_COPIED_TO_CLIPBOARD: 'Se ha copiado el resumen de errores en el portapapeles',
  PLEASE_SELECT_A_START_DATE: 'Seleccione una fecha de inicio.',
  PLEASE_SELECT_AN_END_DATE: 'Seleccione una fecha de finalización.',
  SEASONALITY_TOOLTIP_MESSAGE:
    'Aquí es donde se aplica el programa estacional. Utilice estos programas para repartir la cuota a lo largo del ciclo de planificación en función de las tendencias estacionales.',
  PLANNED_QUOTA_TOOLTIP_MESSAGE:
    'Se trata del valor final de la cuota planificada de su ciclo de planificación activo. Incluye su objetivo de cuota asignado (descendente o importado), además de cualquier ajuste de la cuota.',
  ASSIGNMENT_PERCENTAGE_TOOLTIP_MESSAGE:
    'Este es el porcentaje de la cuota por territorio asignada a los vendedores durante las fechas de vigencia.',
  RAMP_TOOLTIP_MESSAGE:
    'Aquí es donde se aplica el programa del periodo de progresión. Utilice estos programas para incorporar nuevos vendedores y permitirles progresar hasta alcanzar su productividad total.',
  HISTORICAL_MEASURE_DATE_RANGE_TOOLTIP_MESSAGE:
    'Seleccione una fecha de inicio y una fecha de finalización para que solo se incluyan los datos de actividad que se sitúen dentro de este intervalo de fechas. Si no selecciona una fecha, utilizaremos de forma predeterminada la fecha de inicio y finalización del ciclo de planificación.',
  MAP_DISABLED_TOOLTIP_MESSAGE:
    'Para usar el mapa, añada la jerarquía geográfica a su argumentario de ventas como un tipo de grupo de territorios',
  BULK_DELETE_DISABLED_TOOLTIP:
    'La función de eliminar se desactiva cuando se activa la opción “Seleccionar todo” y se aplican filtros.',
  NEW_FIELD: 'Nuevo campo',
  EXISTING_FIELD: 'Campo existente',
  ADD_EXISTING_FIELD: 'Añadir campo existente',
  SELECT_MEASURE_NAME_REQUIRED: 'Seleccione un nombre para la columna.',
  SELECT_COLUMN_NAME: 'Seleccionar un nombre para la columna',
  NO_EXISTING_MEASURES: 'No hay medidas existentes válidas',
  REMOVE_SELLER_ASSIGNMENT: 'Eliminar tarea asignada a vendedor',
  ACTIVATE: 'Activar',
  ACTIVATE_WITH_NAME: '¿Activar {name}?',
  ACTIVATION_STARTED: 'Activación iniciada',
  ACTIVATE_PLANNING_CYCLE: 'Activar ciclo de planificación',
  SELECT_A_TERRITORY: 'Seleccionar un territorio',
  ACTIVATE_PLANNING_CYCLE_CONFIRMATION:
    '¿Todo listo para activar su ciclo de planificación {name}? Esta acción creará una nueva versión de administración de su ciclo de planificación, basada en su versión final del plan. Una vez que active la versión del plan, seguirá estando disponible, pero solo debe utilizarse como referencia.',
  ACTIVATE_PLANNING_CYCLE_MANAGE_VERSION:
    'La versión de administración le permite seguir planificando de forma continua durante todo el ciclo de planificación. Utilícela para adaptarte a los cambios en el mercado o a la rotación de personal y conserve la versión del plan con fines de referencia.',
  ACTIVATE_PLANNING_CYCLE_BEFORE_MESSAGE:
    'Antes de activar este ciclo de planificación, asegúrese de que todas las partes interesadas exigidas lo hayan aprobado.',
  ACTIVATE_PLANNING_CYCLE_WARNING:
    'Solo puede activar su ciclo de planificación una vez y la acción de activación no se puede deshacer.',
  ACTIVATE_SCENARIO_CONFIRMATION:
    'Esta acción creará una nueva versión de administración del escenario que ha publicado. Una vez que active el escenario publicado, seguirá estando disponible, pero solo debe utilizarse como referencia.',
  ACTIVATE_SCENARIO_MANAGE_VERSION:
    'La versión de administración le permite seguir planificando de forma continua durante todo el ciclo de planificación. Utilícela para adaptarse a los cambios en el mercado o a la rotación de personal y conserve el escenario publicado con fines de referencia.',
  ACTIVATE_SCENARIO_BEFORE_MESSAGE:
    'Antes de activar este escenario, asegúrese de que todas las partes interesadas exigidas lo hayan aprobado.',
  ACTIVATE_SCENARIO_WARNING:
    'Solo puede activar el escenario publicado para su ciclo de planificación una vez y la acción de activación no se puede deshacer.',
  SCENARIO_ACTIVATION_STARTED_MESSAGE:
    'Estamos activando su escenario titulado {name}. Este proceso puede tardar unos minutos. No realice ningún cambio en el ciclo de planificación hasta que finalice este proceso.',
  ACTIVATION_STARTED_MESSAGE:
    'Estamos activando la versión de administración de su ciclo de planificación titulado {name}. Este proceso puede tardar unos minutos. No realice ningún cambio en el ciclo de planificación hasta que finalice este proceso.',
  CREATE_QUOTA_SHEET: 'Crear hoja de cuotas',
  QUOTA_SHEET_CREATED: 'La hoja de cuotas se ha creado correctamente',
  CREATE_QUOTA_SHEET_ERROR: 'No se ha podido crear una hoja de cuotas.',
  QUOTA_BLANK_NAME_ERROR: 'El nombre de la hoja de cuotas no puede dejarse en blanco.',
  EDIT_SHEET_NAME: 'Editar nombre de la hoja',
  SHEET_RENAME_SUCCESS: 'Se ha cambiado el nombre de la hoja correctamente',
  REVISED_TERRITORY_QUOTA: 'Cuota por territorio revisada',
  ASSIGNED_SELLER_QUOTA: 'Cuota asignada a los vendedores',
  CHANGE_HISTORY_EXPORT_STARTED: 'Exportación iniciada',
  CHANGE_HISTORY_EXPORT_ERROR: 'Error durante la exportación',
  CHANGE_HISTORY_EXPORT_SUCCESS: 'Se ha exportado el registro de auditoría',
  EXPORT_CHANGE_HISTORY: 'Exportar registro de auditoría',
  EXPORT_CHANGE_HISTORY_DISABLED: 'Estamos exportando su registro de auditoría. Espere a que complete este proceso.',
  CHANGE_HISTORY_TOAST: 'Estamos exportando el registro de auditoría de su ciclo de planificación {planningCycleName}.',
  CHANGE_HISTORY_EXPORT_SUCCESS_DESCRIPTION:
    'Hemos exportado el registro de auditoría de su ciclo de planificación {planningCycleName}.',
  ACTIVATION_FAILED: 'Se ha producido un error durante la activación',
  SCENARIO_ACTIVATION_FAILED_MESSAGE:
    'No hemos podido activar el escenario {name}. Actualice la página e inténtelo de nuevo.',
  ACTIVATION_FAILED_MESSAGE:
    'No hemos podido activar el ciclo de planificación {name}. Actualice la página e inténtelo de nuevo.',
  ACTIVATE_PLANNING_CYCLE_COMPLETED: 'Activación completada',
  SCENARIO_ACTIVATION_COMPLETED_MESSAGE:
    'Hemos activado el escenario {name}. Ahora administra usted su ciclo de planificación.',
  ACTIVATION_COMPLETED_MESSAGE:
    'Hemos activado el ciclo de planificación {name}. Ahora administra usted su ciclo de planificación.',
  EQUALS: 'Es igual a',
  NOT_EQUAL: 'No es igual a',
  LESS_THAN: 'Inferior a',
  LESS_THAN_OR_EQUALS: 'Inferior o igual a',
  GREATER_THAN: 'Superior a',
  GREATER_THAN_OR_EQUAL: 'Superior o igual a',
  CONTAINS: 'Contiene',
  NOT_CONTAINS: 'No contiene',
  STARTS_WITH: 'Comienza por',
  ENDS_WITH: 'Finaliza con',
  COPY_WITH_NAME: '¿Copiar {name}?',
  COPY_PC_MESSAGE:
    'Este ciclo de planificación está activo y existe una versión de administración. Si copia este ciclo de planificación, copiaremos los datos de la versión original de su plan. Esto significa que los cambios realizados en la versión de administración no se copiarán. Su nuevo ciclo de planificación solo incluirá una versión del plan, hasta que decida activarlo. ¿Desea continuar?',
  DELETE_HIERARCHY_CONFIRMATION_MESSAGE:
    '¿Quiere eliminar esta jerarquía? Esta acción eliminará la jerarquía y todos sus miembros. También invalidará cualquier regla territorial que haga referencia a la jerarquía. La acción de eliminación no se puede deshacer.',
  DELETE_HIERARCHY_MEMBER_CONFIRMATION_MESSAGE:
    '¿Quiere eliminar este miembro de la jerarquía? Esta acción eliminará el miembro de la jerarquía y todos los elementos secundarios que deriven de él. También invalidará cualquier regla territorial que haga referencia al miembro de la jerarquía. La acción de eliminación no se puede deshacer.',
  DELETE_HIERARCHY_MEMBERS_CONFIRMATION_MESSAGE:
    '¿Quiere eliminar estos miembros de la jerarquía? Esta acción eliminará los miembros de la jerarquía y todos los elementos secundarios que deriven de ellos. También invalidará cualquier regla territorial que haga referencia a estos miembros de la jerarquía. La acción de eliminación no se puede deshacer.',
  DELETE_HIERARCHY_CUSTOM_ATTRIBUTE: 'Esta acción eliminará los valores de los atributos de todos los miembros.',
  QUOTA_GRID_ERROR:
    'No hemos podido generar los datos de la cuadrícula. Ejecute primero la clasificación por monedas del argumentario de ventas e inténtelo de nuevo.',
  ADD_QUOTA_ADJUSTMENT: 'Añadir ajuste de la cuota',
  EXCLUDE_COLUMN: 'Excluir columna',
  DELETE_MULTIPLE_TERRITORIES_DIALOG_TITLE: '¿Eliminar territorios?',
  DELETE_BC_TERRITORIES_CONFIRM:
    '¿Quiere eliminar {count} territorios de este argumentario de ventas? La acción de eliminación no se puede deshacer.',
  DELETE_BC_TERRITORY_CONFIRM:
    '¿Quiere eliminar {count} territorio de este argumentario de ventas? La acción de eliminación no se puede deshacer.',
  DELETE_ALL_BATTLECARD_TERRITORIES_CONFIRMATION_MESSAGE:
    '¿Quiere eliminar todos los territorios de este argumentario de ventas? La acción de eliminación no se puede deshacer.',
  DELETE_BC_TERRITORIES_WITH_EXCEPTION:
    '¿Quiere eliminar todos los territorios de este argumentario de ventas excepto los {count} territorios que no están seleccionados? La acción de eliminación no se puede deshacer.',
  DELETE_BC_TERRITORY_WITH_EXCEPTION:
    '¿Quiere eliminar todos los territorios de este argumentario de ventas excepto {count} territorio que no está seleccionado? La acción de eliminación no se puede deshacer.',
  DELETE_TG_TERRITORIES_CONFIRM:
    '¿Quiere eliminar {count} territorios de este grupo de territorios? La acción de eliminación no se puede deshacer.',
  DELETE_TG_TERRITORY_CONFIRM:
    '¿Quiere eliminar {count} territorio de este grupo de territorios? La acción de eliminación no se puede deshacer.',
  DELETE_ALL_TERRITORY_GROUP_TERRITORIES_CONFIRMATION_MESSAGE:
    '¿Quiere eliminar todos los territorios de este grupo de territorios? La acción de eliminación no se puede deshacer.',
  DELETE_TG_TERRITORIES_WITH_EXCEPTION:
    '¿Quiere eliminar todos los territorios de este grupo de territorios excepto los {count} territorios que no están seleccionados? La acción de eliminación no se puede deshacer.',
  DELETE_TG_TERRITORY_WITH_EXCEPTION:
    '¿Quiere eliminar todos los territorios de este grupo de territorios excepto {count} territorio que no está seleccionado? La acción de eliminación no se puede deshacer.',
  HOW_IT_WORKS: 'Funcionamiento:',
  START_OPTIMIZATION: 'Iniciar optimización',
  CONTINUE_OPTIMIZATION: 'Continuar con la optimización',
  EXPORT_CURRENT_TERRITORY_RULES: 'Exportar reglas territoriales actuales',
  CONSTRAINTS: 'Restricciones',
  PARAMETERS_AND_TERRITORY_RULES: 'Parámetros y reglas territoriales',
  OPTIMIZE_RESULTS: 'Optimizar resultados',
  OPTIMIZE_TERRITORIES: 'Optimizar territorios',
  FAIL_OPTIMIZE_TERRITORIES: 'Se ha producido un error al optimizar los territorios',
  OPTIMIZATION_SETUP: 'Configuración de la optimización',
  OPTIMIZE_TERRITORIES_FOR_TG: 'Optimizar los territorios de {territoryGroup}',
  TERRITORY_OPTIMIZATION: 'Optimización de territorios',
  TERRITORY_OPTIMIZATION_TITLE: 'Optimización: la solución definitiva para mejorar su plan de ventas.',
  TERRITORY_OPTIMIZATION_DESCRIPTION_STEP1:
    'Tiene la opción de establecer parámetros y restricciones para ejecutar la herramienta de optimización.',
  TERRITORY_OPTIMIZATION_DESCRIPTION_STEP2:
    'Luego, podrá seleccionar entre tres escenarios de territorios distintos según el que mejor se ajuste a sus objetivos comerciales.',
  TERRITORY_OPTIMIZATION_DESCRIPTION_WARNING:
    'Después de publicar los resultados de la optimización, sus reglas territoriales actuales se modificarán de forma permanente.',
  TERRITORY_OPTIMIZATION_DESCRIPTION_EXPORT:
    'Tiene la opción de revertir los cambios exportando sus reglas territoriales actuales.',
  FAIL_BULK_DELETE_TERRITORY_TITLE: 'Se ha producido un error al eliminar los territorios',
  REMOVE_RULE_HAS_OVERLAY_DEPENDENCY:
    'Algunas reglas están en uso por un argumentario de ventas superpuesto. Elimínelas del argumentario superpuesto e inténtelo de nuevo.',
  REMOVE_RULE_HAS_ONGOING_TERRITORY_OPTIMIZATION:
    'En estos momentos se está optimizando el grupo de reglas. Inténtelo de nuevo una vez haya finalizado la optimización de territorios.',
  FAIL_BULK_DELETE_TERRITORIES: 'No hemos podido eliminar {count} territorios.',
  FAIL_BULK_DELETE_TERRITORY: 'No hemos podido eliminar {count} territorio.',
  FAIL_BULK_DELETE_TERRITORY_GENERIC: 'No hemos podido eliminar los territorios seleccionados.',
  START_BULK_DELETE_TERRITORY_TITLE: 'Se están eliminando los territorios',
  START_BULK_DELETE_TERRITORY_GENERIC: 'Estamos eliminando los territorios seleccionados.',
  TERRITORY_DEFINITION_RADIO_LABEL: '¿Quiere definir sus territorios por cuentas o regiones geográficas?',
  RUN_TIME_SELECT_LABEL: 'Seleccione un tiempo de ejecución en función del nivel de precisión que quiera.',
  METRIC_OPTIMIZATION_SELECT_LABEL: 'Seleccione un atributo o indicador para optimizar sus territorios.',
  NUMBER_OF_TERRITORIES: 'Número de territorios',
  GEOGRAPHIC_BASED_RULES: 'Reglas geográficas',
  CONSTRAINTS_PAGE_DESCRIPTION: 'Puede establecer restricciones en sus cuentas y en los territorios resultantes.',
  IF_ALL_COMBINATOR_DESCRIPTION_TEXT_ACCOUNTS: 'No mueva las cuentas si se cumplen todas las condiciones siguientes',
  IF_ALL_COMBINATOR_DESCRIPTION_TEXT_TERRITORIES:
    'Optimice los territorios para que se cumplan todas las condiciones siguientes',
  CURRENT_STATE: 'Estado actual',
  CURRENT_SCENARIO: 'Escenario actual',
  MOST_BALANCED: 'Más equilibrados',
  MOST_CONTIGUOUS: 'Más próximos',
  OPTIMIZE: 'Optimizar',
  DISRUPTION: 'Alteración',
  TERRITORY_MAP: 'Mapa de territorios',
  BATTLE_CARD_1: 'Argumentario de ventas 1:',
  BATTLE_CARD_2: 'Argumentario de ventas 2:',
  SCENARIO_PLANNING_BC_PICKER_LABEL: 'Argumentario de ventas:',
  WITH: 'Con',
  COMPARE: 'Comparar',
  COMPARE_SELECTION: '{scenarioName} / {quotaComponentName} / ',
  SCENARIO_NAME: 'Nombre del escenario',
  SEE_ALL_SCENARIOS: 'Ver todos los escenarios',
  SCENARIO_RENAMED: 'Se ha cambiado el nombre del escenario',
  SCENARIO_RENAMED_MESSAGE: 'Hemos cambiado el nombre de su escenario a {name}.',
  SCENARIO_NAME_EXISTS: 'El nombre de escenario “{name}” ya existe.',
  SCENARIO_RENAMED_FAILURE: 'Se ha producido un error al cambiar el nombre del escenario',
  RENAME_SCENARIO: 'Cambiar el nombre del escenario',
  CHARACTER_COUNT_READOUT: '{currentLength}/{maxLength} caracteres',
  CREATE_SCENARIO: 'Crear escenario',
  DELETING_SCENARIO: 'Eliminando escenario',
  SCENARIO_LIMIT_REACHED: 'Se ha alcanzado el límite de escenarios',
  SCENARIO_LIMIT_DIALOG_BODY:
    'Ha alcanzado el límite máximo de {limit} escenarios para el ciclo de planificación {name}. Elimine los escenarios existentes para liberar espacio.',
  SCENARIO_COPY_FAILED: 'Se ha producido un error al copiar el escenario',
  SCENARIO_CREATE_FAILED: 'Se ha producido un error al crear el escenario',
  SCENARIO_CREATED: 'Se ha creado el escenario',
  SCENARIO_CREATED_MESSAGE: 'Hemos creado el escenario {name}.',
  COPY_SCENARIO: 'Copiar escenario',
  COPY_SCENARIO_NAME: '{name} (copia)',
  SCENARIO_COPY_STARTED: 'Se ha iniciado la copia del escenario',
  SCENARIO_COPY_MESSAGE:
    'Estamos copiando el escenario {name}. No realice ningún cambio en el escenario hasta que finalice este proceso.',
  SCENARIO_COPIED: 'Escenario copiado',
  SCENARIO_COPIED_MESSAGE: 'Hemos copiado el escenario {name}.',
  SCENARIO_DELETED_MESSAGE: 'Hemos eliminado el escenario {name}.',
  DELETION_STARTED_MESSAGE: 'Estamos eliminando el escenario {name}.',
  BLANK_SCENARIO: 'Escenario en blanco',
  NO_RECENT_SCENARIOS: 'No se han abierto otros escenarios recientemente.',
  NAME_REQUIRED: 'Nombre*',
  OPEN_RECENT: 'Abrir recientes',
  MANAGE_SCENARIOS: 'Gestionar escenarios',
  ACTIVATE_SCENARIO: 'Activar escenario',
  SCENARIO_WITH_COLON: 'Escenario:',
  COMPARE_MODE: 'Modo de comparación',
  RENAME: 'Cambiar nombre',
  COMPARE_MODE_ACROSS_DM_DIALOG_BODY:
    'Consulte, edite y compare los mapas de dos argumentarios de ventas procedentes de cualquier escenario de su ciclo de planificación.',
  COMPARE_MODE_WITHIN_DM_DIALOG_BODY:
    'Consulte, edite y compare los mapas de dos argumentarios de ventas procedentes de su ciclo de planificación.',
  COMPARE_MODE_TOOLTIP_TITLE: 'Ha accedido al modo de comparación',
  COMPARE_MODE_TOOLTIP_BODY:
    'Los cambios que realice en el modo de comparación se aplicarán a su(s) argumentario(s) de ventas.',
  COMPARE_MODE_ERROR_MISSING_REQUIRED:
    'Seleccione un escenario, un componente de cuota y un argumentario de ventas para cada uno de los mapas que quiera comparar.',
  COMPARE_MODE_ERROR_IDENTICAL_BC:
    'No se pueden comparar mapas del mismo argumentario de ventas procedentes del mismo escenario.',
  ACCOUNT_MOVES: 'Movimientos de cuentas',
  OPTIMIZATION_METRICS: 'Indicadores de optimización',
  OPTIMIZING_TERRITORIES: 'Estamos optimizando sus territorios.',
  OPTIMIZING_TERRITORIES_DESCRIPTION:
    'Este proceso puede tardar varios minutos. Le avisaremos cuando finalice la optimización.',
  OPTIMIZATION_FAILED: 'Se ha producido un error durante la optimización',
  OPTIMIZATION_FAILED_DESCRIPTION:
    'No hemos podido optimizar sus territorios en función de los parámetros y las restricciones que ha seleccionado. Actualice los parámetros o las restricciones e inténtelo de nuevo.',
  OPTIMIZATION_SUCCESS: 'Se han optimizado sus territorios.',
  OPTIMIZED_TERRITORIES: 'Hemos optimizado sus territorios para su tipo de grupo de territorios {territoryGroup}.',
  BACK_TO_CONSTRAINTS: 'Volver a las restricciones',
  VIEW_RESULTS: 'Ver resultados',
  OPTIMIZATION_RESULTS: 'Resultados de la optimización para {territoryGroup}',
  MIN_NUMBER_OF_ACCOUNTS: 'Número mín. de cuentas',
  MAX_NUMBER_OF_ACCOUNTS: 'Número máx. de cuentas',
  DISRUPTION_ACCOUNTS_REASSIGNED: 'Alteración (cuentas reasignadas)',
  CURRENT_STATE_DESCRIPTION: 'Configuración original de los territorios y las cuentas',
  MOST_BALANCED_DESCRIPTION: 'Distribución equitativa del atributo seleccionado entre los territorios',
  MOST_CONTIGUOUS_DESCRIPTION: 'Territorios geográficamente próximos que comparten una frontera común',
  UNSELECT: 'Anular selección',
  OPTIMIZATION_RESULTS_PAGE_HEADER_DESCRIPTION:
    'Seleccione un escenario para publicarlo en sus reglas territoriales. Puede obtener una vista previa de un escenario para ver un desglose de los territorios y los indicadores de optimización.',
  PUBLISH_CHANGES_TO: 'Publicar cambios en {value}',
  PUBLISH_OPTIMIZATION_DESCRIPTION:
    'Está a punto de publicar cambios en sus reglas territoriales. Esta acción ordenará sus cuentas en función de la optimización ejecutada.',
  PUBLISH_OPTIMIZATION_DESCRIPTION_EMPHASIS:
    'Al publicarlos se modificarán de forma permanente las reglas territoriales actuales.',
  PUBLISH_OPTIMIZATION_DESCRIPTION_WARNING:
    'Puede revertir los cambios exportando sus reglas territoriales actuales. ¿Seguro que desea continuar?',
  EXIT_OPTIMIZATION: 'Salir de la optimización',
  PUBLISHED_OPTIMIZATION_TITLE: 'Ha optimizado su grupo de territorios {territoryGroup}.',
  VIEW_OPTIMIZATION_SUMMARY_TEXT:
    'Mientras actualizamos sus reglas territoriales, aquí tiene un resumen de sus nuevos territorios.',
  SCORE_BREAKDOWN: 'Desglose de la puntuación',
  OPTIMIZATION_PUBLISH_SUBTITLE:
    'Sus territorios ya están equilibrados en función del indicador que ha seleccionado: {selectedOptimizationMetric}.',
  OPTIMIZATION_PUBLISH_SUBTITLE_GENERAL:
    'Sus territorios ya están equilibrados en función del indicador que ha seleccionado.',
  TERRITORY_QUOTA: 'Cuota por territorio',
  SELLER_QUOTA: 'Cuota de los vendedores',
  ACCOUNT_QUOTA: 'Cuota de la cuenta',
  TERRITORY_BALANCING: 'Equilibrio de territorios',
  ACCOUNT_LIST: 'Lista de cuentas',
  ASSIGNED_SELLERS: 'Hemos asignado a {count} vendedores a {territory}',
  LOADING_SHEETS_DATA: 'Cargando datos de las hojas...',
  BOOK_OF_BUSINESS: 'Cartera de clientes',
  YEARLY: 'Anualmente',
  SEMI_ANNUAL: 'Semestral',
  QUARTERLY: 'Trimestralmente',
  MONTHLY: 'Mensualmente',
  SORTING_COINSORT: 'Ordenando territorios ({currentNumberOfRulesProcessed} de {currentNumberOfRules})...',
  INPUT: 'Entrada',
  HISTORICAL: 'Histórico',
  LOADED: 'Cargado',
  ALLOCATION: 'Asignación',
  CALCULATED: 'Calculado',
  TERRITORY_GROUP_TYPE_OWNER: 'Responsable del tipo de grupo de territorios',
  TERRITORY_GROUP_OWNER: 'Responsable del grupo de territorios',
  TERRITORY_GROUP_AND_GROUP_TYPE_OWNER: 'Responsable del grupo de territorios y del tipo de grupo de territorios',
  BATTLE_CARD_OWNER: 'Responsable del argumentario de ventas',
  ADMINISTRATOR: 'Administrador',
  CONTRIBUTOR: 'Colaborador',
  NONE: 'Ninguno',
  NAME_IS_A_REQUIRED_FIELD: 'El nombre es un campo obligatorio',
  IN_PROGRESS: 'En curso',
  APPROVED: 'Aprobado',
  SUBMITTED: 'Enviado',
  REJECTED: 'Rechazado',
  APPROVER: 'Aprobador',
  ACCOUNT_KEY: 'Clave de cuenta',
  MEMBERS_DELETE_SUCCESS_WITH_COUNT: 'Se han eliminado {count} miembros correctamente.',
  MEMBERS_WITH_COUNT: '{count} miembros',
  SEND: 'Enviar',
  DELETE_VALUE: 'Eliminar {value}',
  DELETE_SELLER_FROM_TERRITORY_SUCCESS_MESSAGE: 'Hemos eliminado a {sellerCount} vendedor de {terCount} territorio.',
  DELETE_SELLER_FROM_TERRITORIES_SUCCESS_MESSAGE: 'Hemos eliminado a {sellerCount} vendedor de {terCount} territorios.',
  DELETE_SELLERS_FROM_TERRITORY_SUCCESS_MESSAGE: 'Hemos eliminado a {sellerCount} vendedores de {terCount} territorio.',
  DELETE_SELLERS_FROM_TERRITORIES_SUCCESS_MESSAGE:
    'Hemos eliminado a {sellerCount} vendedores de {terCount} territorios.',
  ITEMS_PLUS_OTHERS: '{itemsList} (+{otherCount} otros)',
  REMOVE_CONFLICT: 'Hemos eliminado correctamente {count} contradicción de {ruleId}',
  REMOVE_CONFLICTS: 'Hemos eliminado correctamente {count} contradicciones de {ruleId}',
  UPDATE_PC_SUCCESS: 'Hemos guardado los cambios en su ciclo de planificación titulado {planningCycleName}.',
  UPDATE_PC_FAILURE:
    'No hemos podido guardar los cambios realizados en su ciclo de planificación titulado {planningCycleName}. Inténtelo de nuevo.',
  TERRITORIES_DELETED_TOAST_TITLE: 'Se han eliminado los territorios',
  TERRITORIES_DELETED_TOAST_MESSAGE: 'Hemos eliminado {count} territorios.',
  TERRITORIES_DELETED_TOAST_MESSAGE_GENERIC: 'Hemos eliminado todos los territorios seleccionados.',
  ACCOUNT_FILTER: 'Filtro de cuenta',
  FILTERS: 'Filtros',
  SHOW_SELECTED_TERRITORY_ACCOUNTS: 'Mostrar las cuentas del territorio seleccionado',
  SHOW_ALL_ACCOUNTS: 'Mostrar todas las cuentas',
  BASE_OVERRIDE_ACCOUNTS: 'Cuentas incluidas en la regla base y en las reglas de anulación',
  OVERRIDE_ACCOUNTS_ONLY: 'Solo cuentas incluidas en las reglas de anulación',
  FILTER_ACCOUNTS_TERRITORY_HEADER: 'Filtrar cuentas por territorio',
  FILTER_ACCOUNTS_TYPE_HEADER: 'Filtrar cuentas por tipo',
  EMBEDDED_MAP_FALLBACK_HEADER: '¡Vaya! Algo ha ido mal.',
  EMBEDDED_MAP_FALLBACK_BODY_ERROR: 'Vuelva a intentarlo más tarde.',
  EMBEDDED_MAP_FALLBACK_BODY_NO_ACCESS: 'Parece que no tiene acceso. Póngase en contacto con su administrador.',
  CONSTRAINT_FIELD_ERROR: 'Rellene el valor de la restricción',
  PLEASE_SELECT_A_METRIC: 'Seleccione un indicador',
  ACCOUNT_ADDITIONS: 'Elementos añadidos a las cuentas',
  ACCOUNT_REMOVALS: 'Elementos eliminados de las cuentas',
  EXISTING_ACCOUNTS: 'Cuentas existentes',
  ZIP_CODE: 'Código postal',
  LETTER_GRADE: 'Calificación con letras',
  SIZE: 'Tamaño',
  ACCOUNT_ID: 'ID de cuenta',
  TARGET_SETTING: 'Establecimiento de objetivos',
  TARGET_SETTING_DESCRIPTION:
    'Seleccione una jerarquía personalizada para establecer objetivos de cuota para miembros individuales de la jerarquía',
  PC_START_DATE_POST_COINSORT_FAILURE: 'No se ha podido actualizar la fecha de inicio del ciclo de planificación.',
  PC_START_DATE_POST_COINSORT_FAILURE_MESSAGE:
    'No se ha podido actualizar la fecha de inicio de {planningCycleName}. Revise sus territorios, ejecute la acción de clasificación por monedas y después vuelva a intentarlo.',
  FAILED_TO_LASSO_PINS:
    'Se ha producido un error al seleccionar los marcadores con la herramienta de lazo. Inténtelo de nuevo.',
  EXIT_OPTIMIZATION_CONFIRMATION: 'Los cambios no se guardarán. ¿Seguro que desea salir de la optimización?',
  EXIT: 'Salir',
  UNABLE_TO_RETRIEVE_OPTIMIZATION_STATUS: 'No se ha podido obtener el estado de la optimización',
  CANNOT_START_OPTIMIZATION_USER_MESSAGE:
    'No se puede iniciar la optimización porque otro usuario ya ha iniciado una que está en curso.',
  RESTART_OPTIMIZATION: 'Reiniciar optimización',
  RESTART_OPTIMIZATION_DESCRIPTION:
    'El resultado de la optimización actual se descartará. ¿Seguro que desea reiniciar la optimización?',
  MAINTENANCE_MODE_TEXT_1: '¡Lo sentimos! Estamos trabajando en algo.',
  MAINTENANCE_MODE_TEXT_2:
    'Para asegurarnos de que seguimos ofreciéndole una experiencia de usuario de primer nivel, en estos momentos estamos realizando algunas tareas de mantenimiento planificadas en nuestro sistema. En este momento no es posible acceder al producto Sales Planning. Vuelva a intentarlo más tarde.',
  UNLOCK_TERRITORY: 'Desbloquear territorio',
  LOCK_TERRITORY: 'Bloquear territorio',
  SHOW_TERRITORY: 'Mostrar territorio',
  LOCK_ALL_TERRITORIES: 'Bloquear todos los territorios',
  UNLOCK_ALL_TERRITORIES: 'Desbloquear todos los territorios',
  HIDE_TERRITORY: 'Ocultar territorio',
  SHOW_ALL_TERRITORIES: 'Mostrar todos los territorios',
  HIDE_ALL_TERRITORIES: 'Ocultar todos los territorios',
  QUOTA_BREAKDOWN_WARNING:
    'Si actualiza las reglas territoriales, los archivos de actividad o las jerarquías seleccionadas para el desglose de la cuota, tendrá que volver a ejecutar la clasificación por monedas y la asignación descendente para que se reflejen los datos más recientes.',
  TENANT_ACCESS_ERROR: 'No tiene acceso a este inquilino',
  TENANTS_LIST_HEADER: 'Inquilinos en los que estoy actualmente',
  EMPTY_TENANT_LIST_MESSAGE: 'Póngase en contacto con su administrador de inquilinos para obtener una invitación.',
  UNASSIGN_GEO_TITLE: 'Anular asignación del territorio',
  UNASSIGN_GEO_BODY:
    '¿Seguro que desea anular la asignación de las {hierarchyCount} regiones geográficas de {ruleNamesTruncated}?',
  UNASSIGN_GEO_SUCCESS_TOAST: 'Se ha anulado la asignación de las regiones geográficas correctamente.',
  UNASSIGN_GEO_ERROR_TOAST: 'No hemos podido anular la asignación de las regiones geográficas.',
  UNASSIGN_ACCOUNT_TITLE: 'Anular la asignación de las cuentas incluidas en las reglas de anulación',
  UNASSIGN_ACCOUNT_BODY:
    '¿Seguro que quiere anular la asignación de las {hierarchyCount} cuentas de {ruleNamesTruncated}?',
  UNASSIGN_OVERRIDE_SUCCESS_TOAST:
    'Se ha anulado correctamente la asignación de las cuentas incluidas en las reglas de anulación.',
  UNASSIGN_OVERRIDE_ERROR_TOAST:
    'No hemos podido anular la asignación de las cuentas incluidas en las reglas de anulación.',
  UNASSIGN_OVERRIDE_TOOLTIP:
    'Esta acción le permitirá anular la asignación de las cuentas incluidas en las reglas de anulación que ha seleccionado.',
  REASSIGN_TITLE: 'Reasignar al territorio existente',
  MAP_ACTION_MENU_REASSIGN_GEO: 'Reasignar a las regiones geográficas',
  REASSIGN_GEO_BODY: 'Seleccione un territorio en el que quiera reasignar la(s) {count} región(es) geográfica(s)',
  REASSIGN_GEO_SUCCESS_TOAST: 'Se han reasignado las regiones geográficas correctamente.',
  REASSIGN_GEO_ERROR_TOAST: 'No hemos podido reasignar las regiones geográficas.',
  MAP_ACTION_MENU_REASSIGN_ACCOUNT: 'Reasignar cuenta(s)',
  REASSIGN_ACCOUNT_BODY: 'Seleccione un territorio en el que quiera reasignar la(s) {count} cuenta(s)',
  REASSIGN_ACCOUNT_SUCCESS_TOAST: 'Las cuentas se han reasignado correctamente.',
  REASSIGN_ACCOUNT_ERROR_TOAST: 'No hemos podido reasignar las cuentas.',
  COMPLEX_RULES_STATUS: 'Reglas complejas {status}',
  ON: 'ACTIVADO',
  OFF: 'DESACTIVADO',
  FUNCTIONALITY: 'Funcionalidad',
  MAPS: 'Mapas',
  HIERARCHY: 'Jerarquía',
  CUSTOM: 'Personalizado',
  DATA_SOURCE: 'Fuente de datos',
  FORMAT_CODE: 'Código de formato',
  INSERT_CALCULATION: 'Insertar cálculo',
  WELCOME_BACK: '¡Hola de nuevo!',
  CHOOSE_A_TENANT: 'Seleccione un inquilino con el que utilizar Sales Planning.',
  SELECT_TENANT: 'Seleccionar inquilino',
  IMPERSONATE_USER: 'Suplantar usuario',
  USER_EMAIL_TO_IMPERSONATE: 'Correo electrónico del usuario que se va a suplantar',
  IMPERSONATE: 'Suplantar',
  EXIT_IMPERSONATION: 'Salir de la suplantación',
  FAILED_TO_GENERATE_IMPERSONATION_TOKEN:
    'Se ha producir un error al generar el token de suplantación. Inténtelo de nuevo.',
  CUSTOM_HIERARCHY_FILTER_TITLE: 'Filtro de jerarquía personalizado',
  CUSTOM_HIERARCHY_FILTER_DYNAMIC_TITLE: 'Filtro de {hierarchyName}',
  CUSTOM_HIERARCHY_FILTER_KIND_LABEL: 'Tipo de filtro',
  CUSTOM_HIERARCHY_FILTER_IDS_LABEL: 'Seleccionar jerarquías',
  CUSTOM_HIERARCHY_FILTER_IDS_EMPTY_PLACEHOLDER: 'No hay jerarquías en uso',
  COLLECTION_FILTER_CONTAINS_ANY: 'Contiene cualquier',
  COLLECTION_FILTER_NOT_CONTAINS_ANY: 'No contiene',
  COLLECTION_FILTER_EQUALS_TO: 'Es igual a',
  COLLECTION_FILTER_NOT_EQUALS_TO: 'No es igual a',
  NO_EDIT_PERMISSION: 'No tiene permiso para editar esto',
  TOO_MANY_SELLERS:
    'Solo puede añadir {maxSellers} vendedores a un territorio. Elimine un vendedor antes de añadir uno nuevo.',
  REMOVE_GEO_HIER_HAS_DEPENDENCY:
    'No se pueden eliminar las regiones geográficas porque se están utilizando en los territorios: {territoryIds}',
  START_FILE_PROCESS_API_KEY_NOT_FOUND:
    'No se ha encontrado la clave de API para el usuario {emailAddress}. El usuario deberá generar una clave de API desde el menú de perfil',
  QUOTA_FILE_UPLOAD_NOTES: 'Notas sobre el uso de la plantilla generada:',
  QUOTA_FILE_UPLOAD_WARNING_1: 'Antes de descargarla, primero debe ejecutar la clasificación por monedas.',
  QUOTA_FILE_UPLOAD_WARNING_2:
    'Esta plantilla se aplica únicamente al componente de cuota seleccionado. Debe importar cuotas para cada uno de los componentes de cuota del argumentario de ventas.',
  QUOTA_FILE_UPLOAD_WARNING_3:
    'Si se modifica la plantilla, se producirán errores de integridad de los datos. Actualice solo la columna Valor con los objetivos de la cuota.',
  ACCOUNT_TOOLTIP_MULTIPLE_TERRITORIES_MESSAGE: 'Esta cuenta pertenece a {count} territorios',
  REMOVE_DEFAULT_BALANCING_METRIC:
    'No se puede eliminar esta medida, ya que requiere una medida de equilibrio predeterminada.',
  REMOVE_SHEET_MEASURE_FORMULA_HAS_DEPENDENCY: 'No se puede eliminar esta medida porque se utiliza en {measureName}.',
  OTHER_MEASURES: 'otras medidas',
  IMPORT_ACTIVITY_FILE_CALLOUT_MESSAGE:
    'Damos por sentado que todos los valores de ventas utilizan la misma moneda que se utiliza en los informes de su ciclo de planificación.',
  MEASURE_NAME_VALIDATION_MESSAGE: 'Los nombres de campo no pueden contener los siguientes caracteres: .,\'"{}',
  ACCOUNT_RULE_GRID_ERROR: 'No hemos podido cargar la lista de cuentas.',
  ALLOCATE_QUOTAS: 'Asignar cuotas',
  ALLOCATING_TOP_DOWN: 'Asignando en sentido descendente',
  ALLOCATING_TOP_DOWN_LOADING_MESSAGE: 'Espere mientras asignamos sus objetivos para {jobName}',
  SINGLE_ACCOUNT_TERRITORY: 'Territorio de cuenta única',
  SINGLE_ACCOUNT_TERRITORY_DISABLE_SAVE_TOOLTIP_TEXT:
    'No se puede asignar más de una cuenta a un territorio de cuenta única',
  COPILOT: 'Asistente virtual',
  ASSISTANT_HEADER_TITLE: 'Asistente virtual de Sales Planning',
  ASSISTANT_YOU: 'Usted',
  ASSISTANT_HEADER_CLEAR: 'Borrar chat',
  ASSISTANT_HEADER_HIDE: 'Ocultar',
  ASSISTANT_INPUT_PLACEHOLDER: '¿En qué puedo ayudarle?',
  ASSISTANT_EMPTY_TITLE: '¿En qué puedo ayudarle hoy?',
  ASSISTANT_EMPTY_SUBTITLE: 'Pregúnteme lo que quiera sobre Sales Planning',
  ASSISTANT_PROMPT_GENERAL: 'Preguntas generales',
  ASSISTANT_PROMPT_GENERAL_EXAMPLE: 'P. ej., ¿dónde está alineada una cuenta, un vendedor o un territorio?',
  ASSISTANT_PROMPT_GENERAL_INPUT: '¿Dónde está alineada la cuenta [Account]?',
  ASSISTANT_PROMPT_INSIGHTS: 'Información y análisis',
  ASSISTANT_PROMPT_INSIGHTS_EXAMPLE: 'P. ej., comparar indicadores clave entre dos mapas',
  ASSISTANT_PROMPT_INSIGHTS_INPUT:
    'Comparar indicadores clave entre los mapas de [Battle card name] y [Battle card name]',
  ASSISTANT_PROMPT_ACTIONS_EXAMPLE: 'p. ej., mover una cuenta a otro territorio',
  ASSISTANT_PROMPT_ACTIONS_INPUT: 'Mover [Account] a [Territory]',
  ASSISTANT_PROMPT_HISTORICAL: 'Búsqueda histórica',
  ASSISTANT_PROMPT_HISTORICAL_EXAMPLE: 'P. ej., ¿quién ha sido el último usuario que ha realizado cambios?',
  FILE_UPLOAD_INVALID_FIRST_ROW_ERROR:
    'Compruebe si la primera fila de datos del archivo cargado comienza por “#”. Si es así, puede evitar este error moviendo la primera columna a una posición diferente. Si no es el caso, póngase en contacto con el servicio de asistencia.',
  ASSISTANT_PROMPT_HISTORICAL_INPUT: '¿Quién ha sido el último usuario que ha realizado cambios en [Territory]?',
  STATUS_MENU_COINSORT_IN_PROGRESS: 'Ejecutando la clasificación por monedas de {battleCardName}',
  STATUS_MENU_COINSORT_COMPLETED: 'Se ha ejecutado la clasificación por monedas de {battleCardName}',
  STATUS_MENU_COINSORT_FAILED: 'Se ha producido un error al ejecutar la clasificación por monedas de {battleCardName}',
  COINSORT_COMPLETED_TOAST_MESSAGE:
    'Hemos ejecutado la clasificación por monedas de su argumentario de ventas {battleCardName}.',
  COINSORT_FAILED_TOAST_MESSAGE:
    'No hemos podido ejecutar la clasificación por monedas para el componente de cuota {quotaComponentName} de su argumentario de ventas {battleCardName}.',
  ALL_DONE: '¡Todo listo!',
  ALL_TASKS_COMPLETED: 'No tiene ninguna tarea en curso.',
  TASKS_IN_PROGRESS: '{numOfTasks} tareas en curso',
  COIN_SORTING_COMPLETED: 'Se ha completado la clasificación por monedas',
  COIN_SORTING_FAILED: 'Se ha producido un error durante la clasificación por monedas',
  VIEW_STATUS: 'Ver estado',
  COIN_SORTING_IN_PROGRESS: 'Clasificación por monedas en curso',
  RUN_COIN_SORT_DISABLED_MESSAGE:
    'No podemos ejecutar la clasificación por monedas para el componente de cuota {quotaComponentName} de su argumentario de ventas {battleCardName} porque ya hay una clasificación en curso.',
  RUN_COIN_SORT: 'Ejecutar clasificación por monedas',
  STARTED_BY: 'Iniciado por: {userName}',
  DISABLE_ADD_QUOTA_ADJUSTMENT_TOOLTIP:
    'Añada la cuota planificada antes de añadir el ajuste de la cuota por territorio',
  STARTED: 'Iniciado:',
  JUST_NOW: 'ahora mismo',
  A_MINUTE_AGO: 'Hace un minuto',
  ONE_HOUR_AGO: 'Hace 1 hora',
  HOURS_AGO: 'Hace {numHours} horas',
  MINUTES_AGO: 'Hace {numMinutes} minutos',
  ADJUSTMENT_EFFECTIVE_DATE_WARNING_TOOLTIP:
    'Las fechas de entrada en vigor de los ajustes de la cuota no se encuentran dentro de las fechas de entrada en vigor del territorio. Actualice las fechas de entrada en vigor de los ajustes de la cuota para que estén en consonancia con las del territorio.',
  ASCENDING: 'Ascendente',
  DESCENDING: 'Descendente',
  START_DATE_REQUIRED_MARK_QUOTA_MOVE: 'Fecha de entrada en vigor*',
  ACCOUNT_MOVE_WITH_QUOTA_DIALOG_BODY_1:
    'Añada un territorio de destino, una fecha de inicio y una asignación de la cuota para ',
  ACCOUNT_MOVE_WITH_QUOTA_DIALOG_BODY_2: 'Esto se aplicará a todos los componentes de cuota',
  TERRITORY_REQUIRED_MARK_V2: 'Territorio de destino*',
  QUOTA_REALLOCATION_MARK: 'Método de reasignación de la cuota*',
  MOVE_PRORATED_QUOTA: 'Mover cuota prorrateada',
  DONT_MOVE_QUOTA: 'No mover la cuota',
  SEASONALITY_DISABLE_TOOLTIP_TEXT:
    'La estacionalidad no se puede modificar porque las cuotas se han establecido a nivel de jerarquía en lugar de a nivel de territorio.',
  TERRITORY_EFFECTIVE_DATE_DISABLE_TOOLTIP_TEXT:
    'La fecha de entrada en vigor de este territorio no se puede modificar, ya que se han redireccionado cuentas a este territorio.',
  MOVE_ACCOUNT_DIALOG_DISABLE_TOOLTIP_TEXT_FOR_NO_ACCOUNT_VALUE:
    'El método de reasignación de la cuota no está disponible si el valor de los componentes de cuota es cero o no hay ningún valor en la cuota de la cuenta.',
  MOVE_ACCOUNT_DIALOG_DISABLE_TOOLTIP_TEXT_FOR_NO_OVERLAP_REDIRECT_DATE:
    'El método de reasignación de la cuota no está disponible si el periodo de redireccionamiento no se solapa con las fechas de entrada en vigor de las reglas territoriales en el territorio de destino deseado.',
  TERRITORY_EFFECTIVE_DATE_FOR_TERRITORY_QUOTA_DRILL_IN:
    'Fecha de entrada en vigor del territorio: {effectiveDate}-{endDate}',
  ADJUSTMENT_TYPE: 'Tipo de ajuste',
  ACCOUNT_MOVED_FROM: 'La cuenta se ha movido de',
  ACCOUNT_MOVED_TO: 'La cuenta se ha movido a',
  ALIGNMENT_EFFECTIVE_DATE: 'Fecha de entrada en vigor de la alineación'
};

export default esES;
