import { Dispatch, SetStateAction } from 'react';

import { QueryTuple, useLazyQuery } from '@apollo/client';

import { CheckSymonConnectivity, CheckSymonConnectivityVariables } from 'app/graphql/generated/graphqlApolloTypes';
import { handleError } from 'app/graphql/handleError';
import { CHECK_SYMON_CONNECTIVITY } from 'app/graphql/queries/checkSymonConnection';

// eslint-disable-next-line no-restricted-imports
import showToast from 'utils/helpers/showToast';
import { formatMessage } from 'utils/messages/utils';

/**
 * This query is used to check if the Symon credentials that will be saved to our system is valid
 */
export const useCheckSymonConnectivity = (
  setIsSymonKeyValid: Dispatch<SetStateAction<boolean>>,
  setIsValidating: Dispatch<SetStateAction<boolean>>
): QueryTuple<CheckSymonConnectivity, CheckSymonConnectivityVariables> =>
  useLazyQuery<CheckSymonConnectivity, CheckSymonConnectivityVariables>(CHECK_SYMON_CONNECTIVITY, {
    fetchPolicy: 'network-only',
    onCompleted(data) {
      if (data.checkSymonConnectivity) {
        setIsSymonKeyValid(true);
      }
      setIsValidating(false);
    },
    onError({ graphQLErrors, networkError }) {
      // eslint-disable-next-line deprecation/deprecation
      showToast(formatMessage('GET_SYMON_ACCOUNT_ERROR'), 'danger');
      handleError(graphQLErrors, networkError);
    }
  });
