import gql from 'graphql-tag';

export const DELETE_FILE = gql`
  mutation DeleteFile($fileId: String!) {
    deleteFile(input: { fileId: $fileId }) {
      battlecardId
      bucket
      fileId
      fileName
      fileType
      jobId
      key
      message
      metaDataCreatedAt
      metaDataUpdateAt
      planningCycleId
      published
      quotaComponentId
      status
      tenantId
    }
  }
`;
