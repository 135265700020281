import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { GridApi, RowNode } from '@ag-grid-community/core';
import { useLazyQuery } from '@apollo/client';
import { Spinner } from '@blueprintjs/core';
import debounce from 'lodash.debounce';

import AdvancedGrid from 'app/components/AdvancedGrid/AdvancedGrid';
import { CommandCenterHierarchyGridContext } from 'app/components/CommandCenterHierarchyPanel/CommandCenterHierarchyPanel';

import { debounceDelay } from 'app/constants/DebounceConstants';

import { BLOCK_SIZE } from 'app/global/variables';

import { GET_ALL_HIERARCHIES } from 'app/graphql/queries/getAllHierarchies';

import { HierarchyItem, HierarchyType, RulePartType } from 'app/models';

import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine-dark.css';
import block from 'utils/bem-css-modules';
import { config } from 'utils/config';
import { formatMessage } from 'utils/messages/utils';

import style from './HierarchySearch.module.pcss';

const b = block(style);

interface HierarchySearchProps {
  className?: string;
  searchString: string;
  hierarchyType: HierarchyType;
  rootHierarchyId?: number;
  planningCycleId: number;
  onSelect: (params) => void;
  enableCheckboxes?: boolean;
  styles?: React.ReactNode;
  blockSize?: number;
  setFilteredGridApi?: (value) => void;
  getRowClass?: (value) => string | string[];
  onRowClicked?: (value) => void;
  innerRendererSelector?: (value) => React.ReactNode;
  initialSelection?: HierarchyItem[];
  isReadOnly?: boolean;
  rulePartType?: RulePartType;
  isDefinitionFilters?: boolean;
}

const HierarchySearch: React.FC<HierarchySearchProps> = ({
  className,
  searchString,
  hierarchyType,
  rootHierarchyId,
  planningCycleId,
  onSelect,
  enableCheckboxes = false,
  styles,
  blockSize = BLOCK_SIZE,
  setFilteredGridApi,
  getRowClass,
  onRowClicked,
  innerRendererSelector,
  initialSelection = [],
  isReadOnly = false,
  rulePartType = RulePartType.BASE,
  isDefinitionFilters = false
}: HierarchySearchProps) => {
  const rowsRef = useRef<number>();
  // offset = number of search hits
  const [offset, setOffset] = useState<number>(0);
  const [rows] = useState<RowNode[]>([]);
  const [gridApi, setGridApi] = useState<GridApi>(null);
  // totalRowCount = total number of rows returned (including extra parent nodes)
  // totalRowCount >= offset always
  const [totalRowCount, setTotalRowCount] = useState<number>(0);
  const [fetchMoreData, setFetchMoreData] = useState<boolean>(false);
  const onScrollCallsCount = useRef<number>(0);

  const handleHierarchySearch = () => {
    if (!debounceHandler) {
      return;
    }

    gridApi?.setRowData([]);
    if (gridApi && searchString !== '') {
      getAllHierarchies({
        variables: {
          planningCycleId,
          searchHierarchyInput: {
            hierarchyType,
            searchString,
            rootHierarchyId,
            getTree: true,
            startRow: 0,
            endRow: blockSize,
            includeDetails: true
          }
        }
      });
      onScrollCallsCount.current = 0;
      setOffset(0);
      setTotalRowCount(0);
    }
  };

  const debounceHandler = useCallback(() => debounce(handleHierarchySearch, debounceDelay), []);
  rowsRef.current = totalRowCount;

  // Based on this thread, there's an issue with apollo client testing where mocked data isn't captured correctly when testing with fetchPolicies. https://github.com/apollographql/apollo-client/issues/6861
  // Temp workaround to disable fetchPolicy for testing
  // This can be removed when we update apollo to 3.4.8
  const [getAllHierarchies, { data: hierarchiesData, loading: hierarchiesDataLoading, fetchMore }] = useLazyQuery(
    GET_ALL_HIERARCHIES,
    config.NODE_ENV === 'test'
      ? {}
      : {
          fetchPolicy: 'network-only', // Have to set to newtwork-only to make sure it always shows latest result after mutation
          nextFetchPolicy: 'cache-first' // Prevents calling the initial query again on re-render
        }
  );

  const fetch = async () => {
    const { data } = await fetchMore({
      variables: {
        planningCycleId,
        searchHierarchyInput: {
          hierarchyType,
          searchString,
          rootHierarchyId,
          getTree: true,
          startRow: 0 + offset,
          endRow: blockSize + offset,
          includeDetails: true
        }
      }
    });
    gridApi?.applyTransaction({ add: data?.getAllHierarchies[0]?.hierarchies });
    setTotalRowCount(totalRowCount + data?.getAllHierarchies[0]?.hierarchies.length);
    setOffset(offset + data?.getAllHierarchies[0]?.numOfMembers);
  };

  const columnDefs = [
    {
      hide: true,
      field: 'hierarchyId'
    }
  ];

  const isNodePreselected = (node) => {
    return !!initialSelection.filter((selectedNode) => selectedNode.hierarchyId === node.data.hierarchyId).length;
  };

  const editableCheckboxSelection = ({ node }) => {
    if (node.parent.isSelected() || isNodePreselected(node)) {
      node.setSelected(true);
    } else if (!node.parent.isSelected()) {
      node.setSelected(false);
    }
    // Disable selection on parent nodes in overrides if exceptionsEnabled FF is on
    return !(rulePartType === RulePartType.OVERRIDE && !!node.allChildrenCount);
  };

  const autoGroupColumnDef = {
    headerName: '',
    field: 'name',
    cellRenderer: 'agGroupCellRenderer',
    cellRendererParams: {
      suppressCount: !!innerRendererSelector,
      ...(innerRendererSelector && {
        innerRendererSelector: (params) => {
          return innerRendererSelector(params);
        }
      }),
      ...(!innerRendererSelector && {
        innerRenderer(data) {
          const hierarchyName = data?.data?.name;
          const hierarchyKey = data?.data?.key;
          return isDefinitionFilters ? hierarchyName : `${hierarchyName} (${hierarchyKey})`;
        }
      })
    },
    checkboxSelection: isReadOnly || !enableCheckboxes ? false : editableCheckboxSelection
  };
  const isRowSelectable = useMemo(() => {
    if (isReadOnly) return () => false;
    return (params) => !!params.data;
  }, [isReadOnly]);

  const gridContext: CommandCenterHierarchyGridContext = { isReadOnly, hierarchyType };

  const baseGridProps = {
    suppressRowClickSelection: enableCheckboxes,
    rowSelection: null,
    defaultColDef: {
      flex: 1
    },
    groupDefaultExpanded: -1,
    getDataPath(data) {
      return data.mappedPath;
    },
    isRowSelectable,
    getRowStyle: styles?.['getRowStyle'],
    rowHeight: styles?.['rowHeight'],
    rowMultiSelectWithClick: false,
    suppressCellSelection: true,
    context: gridContext,
    getRowClass
  };

  const editableGridProps = {
    rowSelection: enableCheckboxes ? 'multiple' : 'single',
    onRowClicked
  };

  const gridProps = isReadOnly ? baseGridProps : { ...baseGridProps, ...editableGridProps };

  const onGridReady = (params) => {
    setGridApi(params?.api);
    setFilteredGridApi?.(params?.api);
  };

  const checkBuffer = async (event) => {
    if (totalRowCount < event.api?.getLastDisplayedRow() + blockSize && onScrollCallsCount.current > 0) {
      setFetchMoreData(true);
    }
    onScrollCallsCount.current = onScrollCallsCount.current + 1;
  };

  useEffect(() => {
    if (fetchMoreData && fetchMore) {
      fetch();
      setFetchMoreData(false);
    }
  }, [fetchMoreData]);

  useEffect(() => {
    handleHierarchySearch();
  }, [searchString, gridApi]);

  useEffect(() => {
    if (hierarchiesData && gridApi) {
      const newData = hierarchiesData?.getAllHierarchies[0]?.hierarchies;
      if (hierarchiesData?.getAllHierarchies[0]?.numOfMembers > 0) {
        gridApi?.applyTransaction({ add: newData });
        setTotalRowCount(totalRowCount + newData.length);
        setOffset(offset + blockSize);
      }
    }
  }, [hierarchiesData, gridApi]);

  const renderLoadingComponent = () => {
    if (isDefinitionFilters) {
      return <div className="bp3-skeleton" data-testid="searchable-select-menu-loading" />;
    }
    return <Spinner intent="primary" />;
  };

  return (
    <div className={className || b()} data-testid="hierarchy-search-component">
      {hierarchiesDataLoading ? (
        renderLoadingComponent()
      ) : (
        <div style={{ width: '100%', height: '100%', display: 'flex' }}>
          <AdvancedGrid
            data-testid="hierarchy-search-grid"
            columnDefs={columnDefs}
            treeData
            noDataMessage={formatMessage('NO_RESULTS')}
            rowData={rows}
            autoGroupColumnDef={autoGroupColumnDef}
            animateRows
            onRowSelected={onSelect}
            onGridReady={onGridReady}
            gridProps={gridProps}
            onBodyScroll={checkBuffer}
          />
        </div>
      )}
    </div>
  );
};

export default HierarchySearch;
