import React from 'react';

import { Field, useFormikContext } from 'formik';

import FormDatePicker from 'app/components/FormFields/FormDatePicker/FormDatePicker';
import FormTextInputGroup from 'app/components/FormFields/FormTextInputGroup/FormTextInputGroup';

import { CommandCenterHierarchyPanelContentFormValues, HierarchyAttributeType, HierarchyType } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './CommandCenterHierarchyPanelContentAttributeFields.module.pcss';

const b = block(style);

interface CommandCenterHierarchyPanelContentAttributeFieldsProps {
  hierarchyType: HierarchyType;
}

const customerAccountField = (values) => [
  { name: formatMessage('COUNTRY'), val: values.country, type: HierarchyAttributeType.TEXT, fieldName: 'country' },
  { name: formatMessage('CITY'), val: values.city, type: HierarchyAttributeType.TEXT, fieldName: 'city' },
  {
    name: formatMessage('STATE_OR_PROVINCE'),
    val: values.stateProvince,
    type: HierarchyAttributeType.TEXT,
    fieldName: 'stateProvince'
  },
  {
    name: formatMessage('ZIP_OR_POSTAL'),
    val: values.zipPostal,
    type: HierarchyAttributeType.TEXT,
    fieldName: 'zipPostal'
  },
  { name: formatMessage('ADDRESS1'), val: values.address1, type: HierarchyAttributeType.TEXT, fieldName: 'address1' },
  { name: formatMessage('ADDRESS2'), val: values.address2, type: HierarchyAttributeType.TEXT, fieldName: 'address2' },
  { name: formatMessage('INDUSTRY'), val: values.industry, type: HierarchyAttributeType.TEXT, fieldName: 'industry' }
];

const CommandCenterHierarchyPanelContentAttributeFields: React.FC<
  CommandCenterHierarchyPanelContentAttributeFieldsProps
> = ({ hierarchyType }: CommandCenterHierarchyPanelContentAttributeFieldsProps) => {
  const { values, errors, touched } = useFormikContext<CommandCenterHierarchyPanelContentFormValues>();

  const attributeFields = [
    ...(hierarchyType === HierarchyType.CustomerAccountHierarchy ? customerAccountField(values) : []),
    ...values.customProperties
  ];

  return (
    <div className={b()} data-testid="command-center-hierarchy-panel-content-attribute-fields">
      {attributeFields.map((attribute, index) => {
        const customAttributeIndex = index - (hierarchyType === HierarchyType.CustomerAccountHierarchy ? 7 : 0);
        const hasError =
          touched['customProperties']?.[customAttributeIndex] && errors['customProperties']?.[customAttributeIndex];
        return (
          <div key={attribute['name']}>
            <Field
              label={
                <div className={b('formFieldLabel')} data-testid={`${attribute['name']}-label`}>
                  {attribute['name']}
                </div>
              }
              name={attribute['fieldName'] ?? `customProperties[${customAttributeIndex}].val`}
              theme="default"
              component={attribute['type'] === HierarchyAttributeType.DATE ? FormDatePicker : FormTextInputGroup}
              customError={hasError ? errors['customProperties'][customAttributeIndex]['val'] : null}
              dateFormat={'yyyy-MM-dd'}
            />
          </div>
        );
      })}
    </div>
  );
};

export default CommandCenterHierarchyPanelContentAttributeFields;
