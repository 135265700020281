import React from 'react';

import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import { ChatRoleEnum } from 'app/graphql/generated/graphqlApolloTypes';

import { AssistantMessageDetails } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './AssistantMessage.module.pcss';

const b = block(style);

type AssistantMessageProps = {
  message: AssistantMessageDetails;
};

const AssistantMessage: React.FC<AssistantMessageProps> = ({ message }) => {
  const isHuman = message.content.role === ChatRoleEnum.human;

  return (
    <div className={b()}>
      <div className={b('chatDetails', { ai: !isHuman })} data-testid="assistant-message-details">
        {isHuman ? formatMessage('ASSISTANT_YOU') : formatMessage('ASSISTANT_HEADER_TITLE')}
      </div>
      <div className={b('chatBubble', { ai: !isHuman })} data-testid="assistant-message-bubble">
        <div className={b('chatBubbleInner')}>
          <Markdown remarkPlugins={[remarkGfm]}>{message.content.message}</Markdown>
        </div>
      </div>
    </div>
  );
};

export default AssistantMessage;
