// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".op4AK {\n  margin: var(--m) 0;\n}\n\n  .OCPAz {\n    margin: var(--m);\n  }\n\n  .OCPAz .bp3-skeleton {\n      width: 250px;\n      height: 20px;\n      margin-bottom: var(--xs);\n    }\n\n  .xNCUq {\n    margin: var(--m);\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AccountInformationCallout": "op4AK",
	"AccountInformationCallout_skeletonWrapper": "OCPAz",
	"AccountInformationCallout_list": "xNCUq"
};
export default ___CSS_LOADER_EXPORT___;
