import React, { Dispatch, SetStateAction, SyntheticEvent, useState } from 'react';

import { Spinner } from '@blueprintjs/core';
import { DocumentImport, Close } from '@carbon/icons-react';
import { CalloutV2, Intent } from '@varicent/components';

import MultiStepDialogFooter from 'components/Dialog/MultiStepDialogFooter/MultiStepDialogFooter';

import { useMappingFields } from 'app/components/DataMappingDrillIn/hooks/useMappingFields';
import DataTypeTagList from 'app/components/DataTypeTagList/DataTypeTagList';
import DownloadCsvSample from 'app/components/DownloadCsvSample/DownloadCsvSample';

import { useData } from 'app/contexts/dataProvider';
import { useScope } from 'app/contexts/scopeProvider';

import DataFileUploadSequence from 'app/core/dataFileUpload/DataFileUploadSequence';

import { DOWNLOAD_ACTIVITY_TEMPLATE_FILE_NAME } from 'app/global/variables';

import { FileTypeEnum } from 'app/graphql/generated/graphqlApolloTypes';
import { usePublishFileToDB } from 'app/graphql/hooks/usePublishFileToDB';

import useShowToast from 'app/hooks/useShowToast';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './ImportActivityFileUpload.module.pcss';

const b = block(style);

interface ImportActivityFileUploadProps {
  dataMatchingRequired?: boolean;
  setDataMatchingRequired?: Dispatch<SetStateAction<boolean>>;
  onNext?: () => void;
  handleShouldRefetch?: Dispatch<SetStateAction<boolean>>;
  onClose?: (event: SyntheticEvent<HTMLElement>) => void;
}

const ImportActivityFileUpload: React.FC<ImportActivityFileUploadProps> = ({
  dataMatchingRequired,
  onNext,
  onClose,
  setDataMatchingRequired,
  handleShouldRefetch
}: ImportActivityFileUploadProps) => {
  const [disableNext, setDisableNext] = useState(false);
  const [showCallout, setShowCallout] = useState(true);
  const { selectedPlanningCycle } = useScope();
  const { mappingFields, mappingFieldsLoading } = useMappingFields(selectedPlanningCycle.id, FileTypeEnum.Activity);
  const showToast = useShowToast();

  // function to get required headers into an array of strings
  const requiredHeaders = mappingFields.reduce((arr, field) => {
    if (field.properties.colRequired) {
      arr = [...arr, field.value];
    }
    return arr;
  }, []);

  const {
    isAllColumnsMapped,
    selectedTable,
    setSelectedTable,
    setFileUploadInProgress,
    pollForProcessingStatus,
    mappingProperties,
    missingRequiredFields,
    resetFileUploadValues
  } = useData();

  const canSubmit = selectedTable && isAllColumnsMapped && missingRequiredFields.length === 0;

  const [publishFileToDB, { loading: publishingFileToDb }] = usePublishFileToDB({
    selectedTable,
    setSelectedTable,
    setFileUploadInProgress,
    pollForProcessingStatus
  });

  const onSubmit = async (e) => {
    if (canSubmit) {
      const publishedFile = await publishFileToDB({
        variables: {
          input: {
            fileId: selectedTable.tableId,
            fieldNames: mappingProperties,
            planningCycleId: selectedPlanningCycle.id,
            fileType: FileTypeEnum.Activity
          }
        }
      });
      if (publishedFile?.data?.publishFileToDB) {
        showToast(formatMessage('PUBLISHING_IN_PROGRESS'), 'success');
        resetFileUploadValues();
        onClose(e);
      }
    }
  };

  return (
    <div data-testid="import-activity-page">
      {mappingFieldsLoading ? (
        <div className={b('spinnerContainer')} data-testid="spinner">
          <Spinner intent="primary" size={40} />
        </div>
      ) : (
        <>
          <DataTypeTagList fields={mappingFields} fileUploadType={formatMessage('ACTIVITY')} />
          {showCallout && (
            <CalloutV2
              className={b('callout')}
              intent={Intent.PRIMARY}
              action={() => setShowCallout(false)}
              actionIcon={Close}
              actionText={formatMessage('CLOSE')}
              hideActionText={true}
              data-testid="import-activity-callout"
            >
              {formatMessage('IMPORT_ACTIVITY_FILE_CALLOUT_MESSAGE')}
            </CalloutV2>
          )}
          <div className={b('linkContainer')}>
            <DownloadCsvSample
              data={[requiredHeaders]}
              linkText={formatMessage('DOWNLOAD_ACTIVITY_FILE_TEXT')}
              filename={DOWNLOAD_ACTIVITY_TEMPLATE_FILE_NAME}
            />
          </div>
          <div className={b('fileUploadContainer')}>
            <DataFileUploadSequence
              mappingFields={mappingFields}
              fileUploadType={FileTypeEnum.Activity}
              headersToValidate={requiredHeaders}
              setDataMatchingRequired={setDataMatchingRequired}
              setDisableComplete={setDisableNext}
              handleShouldRefetch={handleShouldRefetch}
            />
          </div>
        </>
      )}
      <MultiStepDialogFooter
        onNext={onNext}
        disableNext={disableNext}
        confirmButtonText={formatMessage('IMPORT')}
        confirmButtonIcon={<DocumentImport />}
        isFinalStep={!dataMatchingRequired}
        showBack={false}
        onClose={onClose}
        confirmButtonLoading={publishingFileToDb}
        onSubmit={onSubmit}
        disableConfirm={publishingFileToDb || !canSubmit}
      />
    </div>
  );
};

export default ImportActivityFileUpload;
