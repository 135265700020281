import gql from 'graphql-tag';

import { createUseLazyQuery } from 'app/graphql/apolloFactoryHelpers';
import {
  GetAccountQuotaRedirectDistribution,
  GetAccountQuotaRedirectDistributionVariables
} from 'app/graphql/generated/graphqlApolloTypes';

export const GET_ACCOUNT_QUOTA_REDIRECT_DISTRIBUTION = gql`
  query GetAccountQuotaRedirectDistribution($input: GetTerritoryQuotaInput!) {
    getTerritoryQuota(input: $input) {
      accountRedirectQuotaDistribution {
        accountKey
        accountName
        adjustmentType
        alignmentEffectiveDate {
          periodEndDate
          periodStartDate
        }
        measures {
          editable
          measureId
          measureName
          measureValue
          measureValueBreakdown {
            measureValue
            periodEndDate
            periodStartDate
          }
        }
        quotaRedirect {
          moveDirection
          territory {
            ruleId
            territoryId
            territoryName
          }
        }
      }
      accountRedirectQuotaDistributionCount
    }
  }
`;

export const useGetAccountQuotaRedirectDistributionLazy = createUseLazyQuery<
  GetAccountQuotaRedirectDistribution,
  GetAccountQuotaRedirectDistributionVariables
>(GET_ACCOUNT_QUOTA_REDIRECT_DISTRIBUTION);
