import React from 'react';

import Dialog from 'components/Dialog/Dialog';

import { formatMessage } from 'utils/messages/utils';

interface StartWorkflowDialogProps {
  onSubmit: () => void;
  onClose: () => void;
}

const StartWorkflowDialog: React.FC<StartWorkflowDialogProps> = ({ onSubmit, onClose }: StartWorkflowDialogProps) => {
  return (
    <Dialog
      isOpen
      title={formatMessage('START_TERRITORY_WORKFLOW_HEADER')}
      confirmButtonText={formatMessage('START_WORKFLOW')}
      size="small"
      onSubmit={onSubmit}
      onClose={onClose}
      bodyMinHeight={0}
    >
      <div data-testid="start-workflow-dialog">{formatMessage('START_TERRITORY_WORKFLOW_BODY')}</div>
    </Dialog>
  );
};

export default StartWorkflowDialog;
