import { useEffect, useState } from 'react';

const SP_CONVERSATIONS_V1 = 'sp_conversations_v1';

const useAssistantConversation = (planningCycleId: number | null) => {
  const [conversationId, setConversationId] = useState<string | null>(null);
  const [conversations, setConversations] = useState<{ [key: number]: string }>({});

  useEffect(() => {
    const storedConversations = localStorage.getItem(SP_CONVERSATIONS_V1);
    if (storedConversations) {
      const parsedConversations = JSON.parse(storedConversations);
      setConversations(parsedConversations);
      if (parsedConversations[planningCycleId]) {
        setConversationId(parsedConversations[planningCycleId]);
      }
    }
  }, [planningCycleId]);

  const setConversation = (conversationId: string) => {
    const newConversations = {
      ...conversations,
      [planningCycleId]: conversationId
    };

    setConversationId(conversationId);
    localStorage.setItem(SP_CONVERSATIONS_V1, JSON.stringify(newConversations));
  };

  const removeConversation = () => {
    const { [planningCycleId]: _, ...newConversations } = conversations;
    setConversationId(null);
    localStorage.setItem(SP_CONVERSATIONS_V1, JSON.stringify(newConversations));
  };

  return {
    activeConversation: conversationId,
    setConversation,
    removeConversation
  };
};

export default useAssistantConversation;
