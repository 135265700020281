import { QueryTuple, useLazyQuery } from '@apollo/client';

import {
  GetTerritoryRulesForFields,
  GetTerritoryRulesForFieldsVariables
} from 'app/graphql/generated/graphqlApolloTypes';
import { handleError } from 'app/graphql/handleError';
import { GET_TERRITORY_RULES_FOR_FIELDS } from 'app/graphql/queries/getTerritoryRulesForFields';

// eslint-disable-next-line no-restricted-imports
import showToast from 'utils/helpers/showToast';
import { formatMessage } from 'utils/messages/utils';

export const useGetTerritoryRulesForFields = (
  variables: GetTerritoryRulesForFieldsVariables
): QueryTuple<GetTerritoryRulesForFields, GetTerritoryRulesForFieldsVariables> =>
  useLazyQuery<GetTerritoryRulesForFields, GetTerritoryRulesForFieldsVariables>(GET_TERRITORY_RULES_FOR_FIELDS, {
    variables,
    fetchPolicy: 'network-only',
    onError({ graphQLErrors, networkError }) {
      // eslint-disable-next-line deprecation/deprecation
      showToast(formatMessage('TERRITORY_GRID_ERROR'), 'danger');
      handleError(graphQLErrors, networkError);
    }
  });
