import React, { useMemo, useState } from 'react';

import { ChevronDown, SettingsAdjust } from '@carbon/icons-react';
// eslint-disable-next-line no-restricted-imports
import { Classes, Menu, MenuDivider, Radio } from '@varicent/components';

import IconButton from 'components/Buttons/IconButton/IconButton';
import TextButton from 'components/Buttons/TextButton/TextButton';
import Popover from 'components/Popover/Popover';

import { useDynamicMapHierarchyCopy } from 'app/components/TerritoryMap/hooks/useDynamicMapHierarchyCopy';
import { useIsMapCapable } from 'app/components/TerritoryMap/hooks/useMapCapability';
import { useTerritoryLockingTreatment } from 'app/components/TerritoryMap/hooks/useTerritoryLockingSplit';
import MapVisualizationSettingsDialog from 'app/components/TerritoryMap/MapVisualizationSettingsDialog/MapVisualizationSettingsDialog';
import MapRuleSettingsDialog from 'app/components/TerritoryMap/TerritoryMapGrid/MapRuleSettingsDialog';

import { useDedicatedMapProvider } from 'app/contexts/dedicatedMapProvider';
import { useMapContextRedistributor } from 'app/contexts/MapContextRedistributor/mapContextRedistributorProvider';

import { SplitFeatures } from 'app/global/features';

import useTreatment from 'app/hooks/useTreatment';

import block from 'utils/bem-css-modules';
import { getTerritoryGroupLevelName } from 'utils/helpers/territoryMapUtils';
import { MapCapability } from 'utils/maps/mapCapabilityRegistry';
import { formatMessage } from 'utils/messages/utils';

import style from './MapGridTitle.module.pcss';

const b = block(style);

interface MapGridTitleProps {
  totalCount: number;
}

const MapGridTitle: React.FC<MapGridTitleProps> = ({ totalCount }) => {
  const [isAccountFirstMapOn] = useTreatment(SplitFeatures.ACCOUNT_FIRST_MAP);
  const { groupLevelList, territoryGroupLevel, setTerritoryGroupLevel } = useDedicatedMapProvider();
  const { isMapVisualizationSettingsLoading } = useMapContextRedistributor();
  const canViewVisualizationSettings = useIsMapCapable(MapCapability.VIEW_VISUALIZATION_SETTINGS);
  const isEnabled = useIsMapCapable(MapCapability.TOOLBAR);
  const isTerritoriesLevel = territoryGroupLevel == null;

  const [isRuleSettingsDialogOpen, setIsRuleSettingsDialogOpen] = useState(false);

  const { isMvpOn, isEverythingOn, isOnlyMvpOn } = useTerritoryLockingTreatment();

  const { mapGridTitleText } = useDynamicMapHierarchyCopy();

  const groupLevelMenuItems = useMemo(
    () => [
      { name: getTerritoryGroupLevelName(null), value: null },
      ...groupLevelList
        .filter((group) => group.levelIndex !== 0)
        .map(({ levelIndex }) => ({
          name: getTerritoryGroupLevelName(levelIndex),
          value: levelIndex
        }))
    ],
    [groupLevelList]
  );

  const renderRuleSettingsDialog = () => {
    if (isAccountFirstMapOn) {
      return (
        <MapVisualizationSettingsDialog
          onClose={() => setIsRuleSettingsDialogOpen(false)}
          onComplete={() => setIsRuleSettingsDialogOpen(false)}
          isLastStep={true}
        />
      );
    } else {
      return <MapRuleSettingsDialog onClose={() => setIsRuleSettingsDialogOpen(false)} />;
    }
  };

  const gridSubtitle = () => {
    if (isEverythingOn) return null;

    return isTerritoriesLevel ? (
      <p data-testid="total-territory-count">{totalCountText}</p>
    ) : (
      <p data-testid="group-level-name">{getTerritoryGroupLevelName(territoryGroupLevel)}</p>
    );
  };
  const totalCountText =
    totalCount === 1
      ? formatMessage('TERRITORY_WITH_COUNT', { totalCount })
      : formatMessage('TERRITORIES_WITH_COUNT', { totalCount });
  const territoryGridTitleText = isTerritoriesLevel ? formatMessage('TERRITORIES') : formatMessage('TERRITORY_GROUPS');

  return (
    <>
      {isMapVisualizationSettingsLoading ? (
        <div className={b('loadingSkeleton')} data-testid="loading-skeleton">
          <div className={Classes.SKELETON} />
        </div>
      ) : (
        <div className={b({ isEverythingOn })}>
          {isEverythingOn && (
            <>
              <span className={b('title')} data-testid="map-grid-title">
                {mapGridTitleText}
              </span>
              {canViewVisualizationSettings && (
                <IconButton
                  type="button"
                  icon={<SettingsAdjust />}
                  testId="settings-button"
                  onClick={() => setIsRuleSettingsDialogOpen(true)}
                />
              )}
            </>
          )}
          {isOnlyMvpOn && (
            <div className={b('title')} data-testid="tg-level-title">
              {territoryGridTitleText}
            </div>
          )}
          {!isMvpOn && (
            <Popover
              placement="bottom-start"
              modifiers={{
                arrow: {
                  enabled: false
                }
              }}
              disabled={!isEnabled}
              content={
                <Menu>
                  {groupLevelMenuItems.map((level) => (
                    <div key={level.name}>
                      <div className={b('menuItem')}>
                        <Radio
                          checked={territoryGroupLevel === level.value}
                          onChange={() => setTerritoryGroupLevel(level.value)}
                          data-testid={`territory-group-level-radio-${level.value}`}
                          labelElement={<span>{level.name}</span>}
                        />
                      </div>
                      {!level.value && groupLevelMenuItems.length > 1 && (
                        <>
                          <MenuDivider />
                          <div className={b('tgLevelTitle')} data-testid="dropdown-tg-level-title">
                            {formatMessage('TERRIORY_GROUP_LEVEL')}
                          </div>
                        </>
                      )}
                    </div>
                  ))}
                </Menu>
              }
            >
              <TextButton
                minimal
                disabled={!isEnabled}
                className={b('menuTrigger')}
                text={territoryGridTitleText}
                type="button"
                testId="grid-menu-button"
                rightIcon={<ChevronDown />}
                large={false}
              />
            </Popover>
          )}
          {gridSubtitle()}
          {isRuleSettingsDialogOpen && renderRuleSettingsDialog()}
        </div>
      )}
    </>
  );
};

export default MapGridTitle;
