import React from 'react';

// eslint-disable-next-line no-restricted-imports
import { Button, Intent } from '@blueprintjs/core';
import { Placement } from '@blueprintjs/popover2';

import MessageTooltip from 'components/MessageTooltip/MessageTooltip';

import block from 'utils/bem-css-modules';

import style from './IconButton.module.pcss';

const b = block(style);

export interface IconButtonProps {
  disabled?: boolean;
  type: 'submit' | 'reset' | 'button';
  icon: JSX.Element;
  outlined?: boolean;
  minimal?: boolean;
  intent?: Intent;
  small?: boolean;
  title?: string;
  onClick?: (e) => void;
  tooltipText?: string;
  className?: string;
  active?: boolean;
  loading?: boolean;
  testId: string;
  toolTipPlacement?: Placement;
}

const IconButton: React.FC<IconButtonProps> = ({
  disabled,
  type,
  title,
  icon,
  outlined,
  minimal = true,
  intent,
  small = false,
  onClick,
  tooltipText,
  className,
  active,
  loading,
  testId,
  toolTipPlacement = 'top'
}: IconButtonProps) => {
  const CustomButton = (
    <Button
      minimal={minimal}
      disabled={disabled}
      type={type}
      icon={icon}
      outlined={outlined}
      intent={intent}
      small={small}
      onClick={(e) => onClick && onClick(e)}
      title={title}
      data-testid={testId}
      className={className}
      active={active}
      loading={loading}
      aria-label={title}
    />
  );

  return (
    <span className={b({ disabled })}>
      {tooltipText ? (
        <MessageTooltip target={CustomButton} content={tooltipText} placement={toolTipPlacement} />
      ) : (
        CustomButton
      )}
    </span>
  );
};

export default IconButton;
