import React, { useEffect, useState } from 'react';

import { GridOptions } from '@ag-grid-community/core';
import { useMutation } from '@apollo/client';
import { AnchorButton } from '@blueprintjs/core';
import { CloseOutline, PlayOutline, Renew } from '@carbon/icons-react';
import { HTMLHeading } from '@varicent/components';

import TextButton from 'components/Buttons/TextButton/TextButton';
import StatusBar from 'components/StatusBar/StatusBar';

import AdvancedGrid from 'app/components/AdvancedGrid/AdvancedGrid';
import buildQuotaApprovalColumnDef from 'app/components/AdvancedGrid/GridHelpers/Administrator/QuotaApprovalColumnDef';
import ConfirmActionModal from 'app/components/ConfirmActionModal/ConfirmActionModal';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useQuotaApprovalWorkflow } from 'app/contexts/quotaApprovalWorkflowProvider';
import { useScope } from 'app/contexts/scopeProvider';

import { useUser } from 'app/core/userManagement/userProvider';

import { handleError } from 'app/graphql/handleError';
import { APPROVE_WORKFLOW } from 'app/graphql/mutations/approveWorkflow';
import { CANCEL_WORKFLOW } from 'app/graphql/mutations/cancelWorkflow';
import { INITIATE_WORKFLOW } from 'app/graphql/mutations/initiateWorkflow';
import { REJECT_WORKFLOW } from 'app/graphql/mutations/rejectWorkflow';

import useShowToast from 'app/hooks/useShowToast';

import { QuotaApprovalStatus, WorkflowStatus, WorkflowType } from 'app/models/index';

import block from 'utils/bem-css-modules';
import { formatMessage, formatNumber } from 'utils/messages/utils';

import style from './ExpandedQuotaApprovalWorkflow.module.pcss';

const b = block(style);

enum ActionType {
  APPROVE,
  REJECT
}

const gridOptions: GridOptions = {
  animateRows: true,
  treeData: true,
  groupDefaultExpanded: -1,
  defaultColDef: { flex: 1 },
  autoGroupColumnDef: {
    headerName: formatMessage('BATTLE_CARD'),
    minWidth: 300,
    cellRendererParams: { suppressCount: true },
    colId: 'battlecard'
  },
  suppressMenuHide: true
};

const ExpandedQuotaApprovalWorkflow: React.FC = () => {
  const { battleCardLookupMap, selectedBattleCardId } = useBattleCard();
  const {
    quotaApprovals,
    getQuotaApprovals,
    quotaApprovalsLoading,
    quotaApprovalsError,
    quotaApprovalStatus,
    updateQuotaApprovalStatus
  } = useQuotaApprovalWorkflow();
  const { completionRate } = quotaApprovals ?? { completionRate: 0 };
  const {
    selectedPlanningCycle: { id: planningCycleId },
    selectedDeploymentModelId: deploymentModelId
  } = useScope();
  const { userProfile } = useUser();
  const showToast = useShowToast();

  const [buttonType, setButtonType] = useState(null);
  const [showApproveRejectDialog, setShowApproveRejectDialog] = useState<boolean>(false);
  const [showCancelWorkflowDialog, setShowCancelWorkflowDialog] = useState<boolean>(false);
  const [currentWorkflowType, setCurrentWorkflowType] = useState<WorkflowType>(null);
  const [currentWorkflowId, setCurrentWorkflowId] = useState<number>(null);
  const [actionType, setActionType] = useState<ActionType>(null);
  const [dialogBody, setDialogBody] = useState<string>('');
  const [actionWorkflowName, setActionWorkflowName] = useState<string>('');

  const containerRef = React.useRef(null);

  useEffect(() => {
    setButtonType(
      quotaApprovalStatus === QuotaApprovalStatus.INITIATE ? QuotaApprovalStatus.CANCEL : QuotaApprovalStatus.INITIATE
    );
  }, [quotaApprovalStatus]);

  if (quotaApprovals?.workflows?.length === 0) {
    quotaApprovals?.workflows.push({
      workflowHierarchy: [battleCardLookupMap[selectedBattleCardId]?.battlecardName],
      user: `${userProfile.lastName}, ${userProfile.firstName}`,
      email: userProfile.emailAddress,
      status: WorkflowStatus.NOT_STARTED,
      type: WorkflowType.BATTLECARD,
      workflowId: 0,
      approver: ''
    });
  }

  const [initiateWorkflow, { loading: initiateLoading }] = useMutation(INITIATE_WORKFLOW, {
    onCompleted() {
      updateQuotaApprovalStatus(QuotaApprovalStatus.INITIATE);
    },
    onError({ graphQLErrors, networkError }) {
      handleError(graphQLErrors, networkError);
      showToast(formatMessage('INITIATE_WORKFLOW_ERROR'), 'danger');
    }
  });

  const [cancelWorkflow, { loading: cancelLoading }] = useMutation(CANCEL_WORKFLOW, {
    onCompleted() {
      updateQuotaApprovalStatus(QuotaApprovalStatus.CANCEL);
      setShowCancelWorkflowDialog(false);
    },
    onError({ graphQLErrors, networkError }) {
      handleError(graphQLErrors, networkError);
      showToast(formatMessage('CANCEL_WORKFLOW_ERROR'), 'danger');
    }
  });

  const [approveWorkflow, { loading: approveLoading }] = useMutation(APPROVE_WORKFLOW, {
    onCompleted() {
      showToast(formatMessage('WORKFLOW_APPROVE_SUCCESS', { actionWorkflowName }), 'success');
      loadQuotaApprovals();
      setShowApproveRejectDialog(false);
    },
    onError({ graphQLErrors, networkError }) {
      handleError(graphQLErrors, networkError);
      showToast(formatMessage('WORKFLOW_APPROVE_FAILURE', { actionWorkflowName }), 'danger');
    }
  });

  const [rejectWorkflow, { loading: rejectLoading }] = useMutation(REJECT_WORKFLOW, {
    onCompleted() {
      showToast(formatMessage('WORKFLOW_REJECT_SUCCESS', { actionWorkflowName }), 'success');
      loadQuotaApprovals();
      setShowApproveRejectDialog(false);
    },
    onError({ graphQLErrors, networkError }) {
      handleError(graphQLErrors, networkError);
      showToast(formatMessage('WORKFLOW_REJECT_FAILURE', { actionWorkflowName }), 'danger');
    }
  });

  const handleActionClick = (item, currentAction: ActionType) => {
    const { type, workflowId, approver, email, workflowHierarchy } = item;
    const workflowName = workflowHierarchy[workflowHierarchy?.length - 1]; // last item would give us the name of the action workflow

    setActionType(currentAction);
    setCurrentWorkflowId(workflowId);
    setCurrentWorkflowType(type);
    setActionWorkflowName(workflowName);

    if (
      (email && userProfile?.emailAddress === email) ||
      quotaApprovals?.rootBCOwnerEmail === userProfile?.emailAddress
    ) {
      handleAction(type, currentAction, workflowId);
    } else {
      setShowApproveRejectDialog(true);
    }

    if (currentAction === ActionType.APPROVE) {
      const approveMessage =
        type === WorkflowType.BATTLECARD
          ? formatMessage('APPROVE_BATTLE_CARD_DIALOG_MESSAGE', { approver })
          : formatMessage('APPROVE_TERRITORY_GROUP_DIALOG_MESSAGE', { approver });
      setDialogBody(approveMessage);
    } else {
      const rejectMessage =
        type === WorkflowType.BATTLECARD
          ? formatMessage('REJECT_BATTLE_CARD_DIALOG_MESSAGE', { approver })
          : formatMessage('REJECT_TERRITORY_GROUP_DIALOG_MESSAGE', { approver });
      setDialogBody(rejectMessage);
    }
  };

  const workflowOnClick = async (e) => {
    if (e) {
      e.stopPropagation();
    }

    if (buttonType === QuotaApprovalStatus.INITIATE) {
      await initiateWorkflow({
        variables: {
          deploymentModelId,
          planningCycleId
        }
      });

      loadQuotaApprovals();
      setButtonType(QuotaApprovalStatus.CANCEL);
    } else {
      setShowCancelWorkflowDialog(true);
    }
  };

  const loadQuotaApprovals = () => {
    getQuotaApprovals(deploymentModelId);
  };

  const handleOnCancelWorkflow = async () => {
    await cancelWorkflow({
      variables: {
        deploymentModelId,
        planningCycleId
      }
    });

    loadQuotaApprovals();
    setButtonType(QuotaApprovalStatus.INITIATE);
  };

  const handleAction = (workflowType: WorkflowType, currentAction: ActionType, workflowId: number) => {
    if (currentAction === ActionType.APPROVE) {
      handleApproval(workflowType, workflowId);
    } else {
      handleRejection(workflowType, workflowId);
    }
  };

  const handleApproval = (workflowType: WorkflowType, workflowId: number) => {
    if (workflowType === WorkflowType.BATTLECARD) {
      approveWorkflow({ variables: { battlecardWorkflowId: workflowId } });
    } else {
      approveWorkflow({ variables: { territoryGroupWorkflowId: workflowId } });
    }
  };

  const handleRejection = (type: WorkflowType, workflowId: number) => {
    if (type === WorkflowType.BATTLECARD) {
      rejectWorkflow({ variables: { battlecardWorkflowId: workflowId } });
    } else {
      rejectWorkflow({ variables: { territoryGroupWorkflowId: workflowId } });
    }
  };

  useEffect(() => {
    loadQuotaApprovals();
  }, []);

  useEffect(() => {
    if (quotaApprovalsError) {
      const { graphQLErrors, networkError, message } = quotaApprovalsError;
      if (graphQLErrors || networkError) {
        handleError(graphQLErrors, networkError);
        showToast('Error in fetching data', 'danger');
      } else {
        showToast(message, 'danger');
      }
    }
  }, [quotaApprovalsError]);

  const GridAction = (params) => {
    const { data } = params;

    if (!data?.approver) {
      return null;
    }

    const isApproveDisabled = data.status !== WorkflowStatus.SUBMITTED;
    const isRejectDisabled = data.status === WorkflowStatus.IN_PROGRESS || data.status === WorkflowStatus.NOT_STARTED;

    return (
      <div className={b('action')}>
        <AnchorButton
          intent="primary"
          minimal
          data-testid="approve-btn"
          text={formatMessage('APPROVE')}
          disabled={isApproveDisabled}
          onClick={() => handleActionClick(data, ActionType.APPROVE)}
        />
        <span className={b('actionSeparator', { disabled: isApproveDisabled && isRejectDisabled })}>|</span>
        <AnchorButton
          intent="primary"
          minimal
          data-testid="reject-btn"
          text={formatMessage('REJECT')}
          disabled={isRejectDisabled}
          onClick={() => handleActionClick(data, ActionType.REJECT)}
        />
      </div>
    );
  };

  return (
    <div className={b()}>
      <div className={b('header')}>
        <div className={b('headerStatus')}>
          <HTMLHeading tagLevel="h5" text={formatMessage('EXPANDED_COMPLETION_RATE')} />
          <div className={b('headerStatus_statusBar')}>
            <StatusBar completionPercent={completionRate} data-testid="quota-approval-status" />
          </div>
          <span data-testid="quota-approval-percent">
            {formatNumber(completionRate, { style: 'percent', maximumFractionDigits: 1 })}
          </span>
          {quotaApprovals?.totalApprovalsRequired > 0 && (
            <span className={b('headerStatus_numOfDone')} data-testid="quota-approval-status-text">
              {quotaApprovals?.numberOfApproved} of {quotaApprovals?.totalApprovalsRequired}
            </span>
          )}
        </div>
        <div className={b('headerButtons')}>
          <Renew
            size={32}
            className={b('refresh', { loading: quotaApprovalsLoading })}
            onClick={() => loadQuotaApprovals()}
          />
          <TextButton
            className={b('workflowButton')}
            intent={buttonType === QuotaApprovalStatus.INITIATE ? 'primary' : 'danger'}
            loading={buttonType === QuotaApprovalStatus.INITIATE ? initiateLoading : cancelLoading}
            onClick={workflowOnClick}
            type={'button'}
            text={
              buttonType === QuotaApprovalStatus.INITIATE
                ? formatMessage('INITIATE_WORKFLOW_TEXT')
                : formatMessage('CANCEL_WORKFLOW_TEXT')
            }
            icon={buttonType === QuotaApprovalStatus.INITIATE ? <PlayOutline size={24} /> : <CloseOutline size={24} />}
            large={false}
            testId={'action-buttons'}
          />
        </div>
      </div>

      <div className={`ag-theme-alpine ${b('grid')}`} ref={containerRef}>
        {(quotaApprovalsLoading || quotaApprovals?.workflows.length > 0) && (
          <AdvancedGrid
            data={JSON.stringify(quotaApprovals?.workflows)}
            columnDefs={buildQuotaApprovalColumnDef(true, GridAction, b('grid', { emailBlue: true }))}
            gridProps={gridOptions}
            noDataMessage={formatMessage('EMPTY_GRID')}
            getDataPath={(data) => {
              return data?.workflowHierarchy;
            }}
            getRowNodeId={(data) => {
              return data?.workflowHierarchy;
            }}
            data-testid="quota-approval-grid"
            gridWidth={containerRef?.current?.offsetWidth}
            gridHeight={containerRef?.current?.offsetHeight}
            showGridLoading={quotaApprovalsLoading}
          />
        )}
      </div>
      <ConfirmActionModal
        isOpen={showApproveRejectDialog}
        isSubmitting={approveLoading || rejectLoading}
        actionButtonIntent={actionType === ActionType.APPROVE ? 'primary' : 'danger'}
        onCancel={() => setShowApproveRejectDialog(false)}
        confirmButtonText={actionType === ActionType.APPROVE ? formatMessage('APPROVE') : formatMessage('REJECT')}
        onConfirmAction={(e) => {
          e.stopPropagation();
          handleAction(currentWorkflowType, actionType, currentWorkflowId);
        }}
        title={
          actionType === ActionType.APPROVE
            ? formatMessage('APPROVE_DIALOG_TITLE')
            : formatMessage('REJECT_DIALOG_TITLE')
        }
        body={dialogBody}
      />
      <ConfirmActionModal
        isOpen={showCancelWorkflowDialog}
        isSubmitting={cancelLoading}
        actionButtonIntent={'danger'}
        onCancel={() => setShowCancelWorkflowDialog(false)}
        confirmButtonText={formatMessage('YES')}
        cancelButtonText={formatMessage('NO')}
        onConfirmAction={(e) => {
          e.stopPropagation();
          handleOnCancelWorkflow();
        }}
        title={formatMessage('CANCEL_WORKFLOW_TEXT')}
        body={formatMessage('CANCEL_WORKFLOW_CONFIRMATION')}
      />
    </div>
  );
};

export default ExpandedQuotaApprovalWorkflow;
