import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useLazyQuery, useMutation } from '@apollo/client';
import { HTMLHeading } from '@varicent/components';
import { Formik, Form, Field, FormikProps } from 'formik';
import debounce from 'lodash.debounce';
import { useHistory } from 'react-router-dom';

import TextButton from 'components/Buttons/TextButton/TextButton';

import FormTextInputGroup from 'app/components/FormFields/FormTextInputGroup/FormTextInputGroup';
import TextMessageInputField from 'app/components/FormFields/TextMessageInputField/TextMessageInputField';
import PolicyMessage from 'app/components/PolicyMessage/PolicyMessage';

import { debounceDelay } from 'app/constants/DebounceConstants';

import { useUser } from 'app/core/userManagement/userProvider';

import { INVITATION_ID } from 'app/global/variables';

import { TenantAddInput } from 'app/graphql/generated/graphqlApolloTypes';
import { handleError } from 'app/graphql/handleError';
import { ADD_TENANT } from 'app/graphql/mutations/addTenant';
import { GET_PLANNING_CYCLES } from 'app/graphql/queries/getPlanningCycles';
import { GET_TENANT_WIDE_INFO } from 'app/graphql/queries/getTenantWideInfo';
import { TENANT_EXISTS_BY_NAME } from 'app/graphql/queries/tenantExistsByName';

import useShowToast from 'app/hooks/useShowToast';

import block from 'utils/bem-css-modules';
import { getSearchParams } from 'utils/helpers/searchParams';
import { formatMessage } from 'utils/messages/utils';

import style from './OrganizationCreationForm.module.pcss';
import validationsSchema from './validationsSchema';

const b = block(style);

export interface NewOrganizationForm {
  firstName: string;
  lastName: string;
  email: string;
  organizationName: string;
}

interface TenantExistsByName {
  tenantExistsByName: {
    exists: boolean;
    slugName: string;
  };
}

const OrganizationCreationForm: React.FC = () => {
  const [isSlugValidated, setIsSlugValidated] = useState<boolean>(false);
  const formRef = useRef<FormikProps<NewOrganizationForm>>();
  const history = useHistory();
  const { userProfile, setUserProfile, setInvitation, setShouldFetchUserSession } = useUser();
  const showToast = useShowToast();

  const [addTenant, { loading }] = useMutation(ADD_TENANT, {
    onCompleted(data) {
      showToast(formatMessage('CREATE_NEW_ORGANIZATION_SUCCESS'), 'success');
      const { tenantId, tenantSlug } = data.addTenant;
      if (tenantId) {
        // update userProfile with the newly created tenant
        setUserProfile({ ...userProfile, tenant: { id: tenantId, slug: tenantSlug } });
        // clear the invitation as its no longer valid
        setInvitation({ invitationId: null, regType: null });
        localStorage.removeItem(INVITATION_ID);
        localStorage.removeItem('regType');
        localStorage.removeItem('orgId');

        const redirectPath = `/${tenantSlug}`;
        history.push(redirectPath);

        // fetch the user's session
        setShouldFetchUserSession(true);
      }
    },
    onError({ graphQLErrors, networkError }) {
      handleError(graphQLErrors, networkError);
      showToast(formatMessage('CREATE_NEW_ORGANIZATION_ERROR'), 'danger');
    },
    awaitRefetchQueries: true,
    refetchQueries: [GET_TENANT_WIDE_INFO, GET_PLANNING_CYCLES]
  });

  const [tenantExistsByName, { data: tenantExists, loading: tenantExistsLoading }] =
    useLazyQuery<TenantExistsByName>(TENANT_EXISTS_BY_NAME);

  const handleOrganizationNameValidation = () => {
    const { values, errors, isSubmitting } = formRef?.current;
    const tenantName = values?.organizationName;

    // in case there are form errors with organization name or form is submitting we don't want to run this validation (we have already validated on change and blur)
    if (errors.organizationName || !tenantName || isSubmitting) {
      return;
    }

    tenantExistsByName({
      variables: { tenantName }
    });
  };

  const debounceHandler = useCallback(debounce(handleOrganizationNameValidation, debounceDelay), []);

  const handleSubmit = async (values: NewOrganizationForm) => {
    const invitationId = getSearchParams(history.location.search, INVITATION_ID);
    const input: TenantAddInput = {
      invitationId: +invitationId,
      tenantName: values.organizationName
    };
    const orgId = localStorage.getItem('orgId');
    if (orgId && orgId !== '') {
      input.auth0OrgId = orgId;
    }

    await addTenant({
      variables: { input }
    });
  };

  useEffect(() => {
    const { setFieldError, setFieldTouched, values } = formRef?.current;

    if (!tenantExistsLoading && tenantExists?.tenantExistsByName) {
      const slugExists = tenantExists?.tenantExistsByName.exists;

      if (slugExists) {
        setFieldTouched('organizationName', true, false);

        setFieldError(
          'organizationName',
          `"${values.organizationName}" is not available because organization ID "${tenantExists.tenantExistsByName.slugName}" is already taken.`
        );
      }

      setIsSlugValidated(!slugExists);
    }
  }, [tenantExistsLoading, tenantExists, formRef]);

  return (
    <div className={b()} data-testid={'new-organization-form'}>
      <Formik
        innerRef={formRef}
        initialValues={{
          firstName: userProfile.firstName,
          lastName: userProfile.lastName,
          email: userProfile.emailAddress,
          organizationName: ''
        }}
        onSubmit={handleSubmit}
        validateOnMount
        validationSchema={validationsSchema}
      >
        {({ isValid, isSubmitting, values, errors, setFieldError }) => (
          <div>
            <div className={b('title')} data-testid={'new-organization-form-title'}>
              <HTMLHeading tagLevel="h2" styleLevel="h2" text={formatMessage('CREATE_ORGANIZATION_TITLE')} />
            </div>
            <Form>
              <div className={b('inlineInputs')}>
                <span className={b('inlineInput')}>
                  <Field
                    label={formatMessage('FIRST_NAME')}
                    name="firstName"
                    type="text"
                    disabled
                    placeHolder={formatMessage('FIRST_NAME_PLACEHOLDER')}
                    component={FormTextInputGroup}
                    data-testid={'new-organization-first-name'}
                  />
                </span>
                <span className={b('inlineInput')}>
                  <Field
                    label={formatMessage('LAST_NAME')}
                    name="lastName"
                    type="text"
                    disabled
                    placeHolder={formatMessage('LAST_NAME_PLACEHOLDER')}
                    component={FormTextInputGroup}
                    data-testid={'new-organization-last-name'}
                  />
                </span>
              </div>
              <Field
                label={formatMessage('EMAIL')}
                name="email"
                type="email"
                disabled
                placeHolder={formatMessage('EMAIL_PLACEHOLDER')}
                component={FormTextInputGroup}
                data-testid={'new-organization-email'}
              />
              <TextMessageInputField
                name="organizationName"
                textLabel={formatMessage('NAME_YOUR_ORGANIZATION')}
                textPlaceholder={formatMessage('ORGANIZATION_NAME_PLACEHOLDER')}
                isLoading={tenantExistsLoading}
                shouldShowSuccessIcon={isSlugValidated}
                shouldShowErrorIcon={tenantExists?.tenantExistsByName?.exists}
                isMessageAvailable={isSlugValidated && values.organizationName && !errors.organizationName}
                shouldValidateOnTouch={false}
                isRequired
                message={
                  <div className={b('url')} data-testid="organization-url">
                    {formatMessage('YOUR_ORGANIZATION_URL_WILL_BE')}
                    <span
                      className={b('messageBold')}
                    >{`${window.location.origin}/${tenantExists?.tenantExistsByName?.slugName}`}</span>
                  </div>
                }
                onChange={() => {
                  setIsSlugValidated(false);
                  setFieldError('organizationName', '');
                  debounceHandler();
                }}
              />
              <div className={b('button')}>
                <TextButton
                  text={formatMessage('CREATE_ORGANIZATION')}
                  type="submit"
                  intent="primary"
                  fullWidth
                  disabled={!isValid || isSubmitting || !isSlugValidated}
                  loading={loading}
                  testId={'submit-button'}
                />
              </div>
            </Form>
            <div className={b('policy')}>
              <PolicyMessage buttonText={formatMessage('CREATE_ORGANIZATION')} />
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
};
export default OrganizationCreationForm;
