import React, { Dispatch, SetStateAction, useEffect } from 'react';

import { useMutation } from '@apollo/client';
import { Field, Form, Formik } from 'formik';

import Dialog from 'components/Dialog/Dialog';
import SelectMenu from 'components/SelectMenu/SelectMenu';

import FormTextInputGroup from 'app/components/FormFields/FormTextInputGroup/FormTextInputGroup';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { usePlanTargets } from 'app/contexts/planTargetsProvider';
import { useScope } from 'app/contexts/scopeProvider';

import { handleError } from 'app/graphql/handleError';
import { ADD_CUSTOM_MEASURE } from 'app/graphql/mutations/addCustomMeasure';
import { UPDATE_CUSTOM_MEASURE } from 'app/graphql/mutations/updateCustomMeasure';

import useShowToast from 'app/hooks/useShowToast';

import { MeasureFieldType, MeasureFormatType } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './CustomMeasureDialog.module.pcss';
import validationSchema from './validationSchema';

const b = block(style);

interface CustomMeasureDialogProps {
  isOpen: boolean;
  setShowCustomMeasureDialog: Dispatch<SetStateAction<boolean>>;
  measureId?: number;
  setUpsertLoading: Dispatch<SetStateAction<boolean>>;
}

export const typeDropDownData = [
  { key: formatMessage('HISTORICAL'), value: MeasureFieldType.HISTORICAL },
  { key: formatMessage('LOADED'), value: MeasureFieldType.LOADED },
  { key: formatMessage('ALLOCATION'), value: MeasureFieldType.ALLOCATION },
  { key: formatMessage('INPUT'), value: MeasureFieldType.INPUT },
  { key: formatMessage('CALCULATED'), value: MeasureFieldType.CALCULATED }
];

const typeDropDown = {
  theme: 'default',
  items: typeDropDownData,
  defaultItem: typeDropDownData[0],
  menuName: 'measureFieldType'
};

export const formatDropDownData = [
  { key: formatMessage('CURRENCY'), value: MeasureFormatType.CURRENCY },
  { key: formatMessage('PERCENTAGE'), value: MeasureFormatType.PERCENTAGE },
  { key: formatMessage('NUMERIC'), value: MeasureFormatType.NUMERIC },
  { key: formatMessage('DATE'), value: MeasureFormatType.DATE },
  { key: formatMessage('TEXT'), value: MeasureFormatType.TEXT }
];

const formatDropDown = {
  theme: 'default',
  items: formatDropDownData,
  defaultItem: formatDropDownData[0],
  menuName: 'measureFormatType'
};

interface SelectFieldType {
  key: string;
  value: string;
}

interface CustomMeasureForm {
  measureName: string;
  measureFieldType: SelectFieldType;
  measureFormatType: SelectFieldType;
}

const CustomMeasureDialog: React.FC<CustomMeasureDialogProps> = ({
  isOpen,
  setShowCustomMeasureDialog,
  measureId,
  setUpsertLoading
}: CustomMeasureDialogProps) => {
  const { measuresData, getMeasures } = usePlanTargets();
  const { selectedTenant, selectedPlanningCycle } = useScope();
  const { setShouldRefetchBattleCardDataImmediately } = useBattleCard();
  const showToast = useShowToast();

  const [addCustomMeasure, { loading: addQueryLoading }] = useMutation(ADD_CUSTOM_MEASURE, {
    onCompleted() {
      showToast(formatMessage('ADD_CUSTOM_MEASURE_SUCCESS'), 'success');
      setShowCustomMeasureDialog(false);
      getMeasures(selectedPlanningCycle?.id);
    },
    onError({ graphQLErrors, networkError }) {
      handleError(graphQLErrors, networkError);
      showToast(formatMessage('ADD_CUSTOM_MEASURE_FAILURE'), 'danger');
    }
  });

  const [updateCustomMeasure, { loading: updateQueryLoading }] = useMutation(UPDATE_CUSTOM_MEASURE, {
    onCompleted() {
      showToast(formatMessage('UPDATE_CUSTOM_MEASURE_SUCCESS'), 'success');
      setShowCustomMeasureDialog(false);
      getMeasures(selectedPlanningCycle?.id);
    },
    onError({ graphQLErrors, networkError }) {
      handleError(graphQLErrors, networkError);
      showToast(formatMessage('UPDATE_CUSTOM_MEASURE_FAILURE'), 'danger');
    }
  });

  useEffect(() => {
    if (updateQueryLoading || addQueryLoading) {
      setUpsertLoading(true);
    } else {
      setUpsertLoading(false);
    }
  }, [updateQueryLoading, addQueryLoading]);

  let editingMeasure;

  if (measureId) {
    editingMeasure = measuresData?.find((measure) => measure.measureId === measureId);
  }

  const submitForm = async (values: CustomMeasureForm, { resetForm }) => {
    const { measureName, measureFieldType, measureFormatType } = values;
    let result;
    if (measureId) {
      result = await updateCustomMeasure({
        variables: {
          tenantId: selectedTenant?.id,
          measureId,
          measureName,
          measureFormatType: measureFormatType.value
        }
      });
    } else {
      result = await addCustomMeasure({
        variables: {
          tenantId: selectedTenant?.id,
          planningCycleId: selectedPlanningCycle?.id,
          measureName,
          measureFieldType: measureFieldType.value,
          measureFormatType: measureFormatType.value
        }
      });
    }

    if (result?.data) {
      setShouldRefetchBattleCardDataImmediately(true);
      resetForm();
    }
  };

  return (
    <Formik
      initialValues={{
        measureName: editingMeasure ? editingMeasure.measureName : '',
        measureFieldType: editingMeasure
          ? { key: editingMeasure.measureFieldType, value: editingMeasure.measureFieldType }
          : null,
        measureFormatType: editingMeasure
          ? { key: editingMeasure.measureFormatType, value: editingMeasure.measureFormatType }
          : null
      }}
      validationSchema={validationSchema}
      validateOnMount
      enableReinitialize
      onSubmit={submitForm}
    >
      {({ isValid, isSubmitting, handleSubmit }) => {
        return (
          <Dialog
            isOpen={isOpen}
            title={measureId ? formatMessage('UPDATE_MEASURE') : formatMessage('ADD_MEASURE')}
            onClose={() => setShowCustomMeasureDialog(false)}
            onSubmit={() => handleSubmit()}
            confirmButtonLoading={addQueryLoading || updateQueryLoading}
            confirmButtonText={measureId ? formatMessage('UPDATE') : formatMessage('ADD')}
            disableConfirm={!isValid || isSubmitting}
            cancelButtonText={formatMessage('CANCEL')}
            disableCancel={isSubmitting}
            size="small"
          >
            <div data-testid="custom-measure-dialog">
              <Form>
                <div className={b('container')}>
                  <div>
                    <Field
                      data-testid="measure-name"
                      name="measureName"
                      label={formatMessage('NAME_WITH_COLON')}
                      isRequired
                      component={FormTextInputGroup}
                    />
                  </div>
                  {!measureId && (
                    <div>
                      <label className={b('label')} htmlFor="measureFieldType">
                        <span className={b('labelText')}>{formatMessage('TYPE_REQUIRED')}</span>
                        <Field
                          name={typeDropDown.menuName}
                          items={typeDropDown.items}
                          theme={typeDropDown.theme}
                          component={SelectMenu}
                          placeHolderText={formatMessage('SELECT')}
                          shouldValidateOnTouch={false}
                        />
                      </label>
                    </div>
                  )}
                  <div>
                    <label className={b('label')} htmlFor="measureFormatType">
                      <span className={b('labelText')}>{formatMessage('FORMAT_REQUIRED')}</span>
                      <Field
                        name={formatDropDown.menuName}
                        items={formatDropDown.items}
                        theme={formatDropDown.theme}
                        component={SelectMenu}
                        placeHolderText={formatMessage('SELECT')}
                        shouldValidateOnTouch={false}
                      />
                    </label>
                  </div>
                </div>
              </Form>
            </div>
          </Dialog>
        );
      }}
    </Formik>
  );
};

export default CustomMeasureDialog;
