import { gql } from '@apollo/client';

import { createUseQuery } from 'app/graphql/apolloFactoryHelpers';
import {
  GetTerritoryWorkflowStatus,
  GetTerritoryWorkflowStatusVariables
} from 'app/graphql/generated/graphqlApolloTypes';

export const GET_TERRITORY_WORKFLOW_STATUS = gql`
  query GetTerritoryWorkflowStatus($deploymentModelId: Int!) {
    getDeploymentModelSpec(input: { deploymentModelId: $deploymentModelId }) {
      isTerritoryWorkflowStarted
    }
  }
`;

export const usetGetTerritoryWorkflowStatus = createUseQuery<
  GetTerritoryWorkflowStatus,
  GetTerritoryWorkflowStatusVariables
>(GET_TERRITORY_WORKFLOW_STATUS);
