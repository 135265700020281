import React from 'react';

import { AddAlt, ArrowLeft, ArrowRight, Home, WarningAltFilled } from '@carbon/icons-react';
import { Intent, Tag } from '@varicent/components';
import { Field, Form, Formik } from 'formik';

import IconButton from 'components/Buttons/IconButton/IconButton';
import MessageTooltip from 'components/MessageTooltip/MessageTooltip';

import { formatDateYearMonthDay } from 'app/components/AdvancedGrid/Sheets/Account/AccountQuotaUtils';
import TerritorySearchableSelect from 'app/components/AdvancedGrid/Sheets/Account/TerritorySearchableSelect';

import {
  AccountRedirectInput,
  AccountRedirectTarget,
  GetAccountRuleBindings_getAccountRuleBindings_bindings_redirects,
  GetAccountRuleBindings_getAccountRuleBindings_bindings_sourceRule
} from 'app/graphql/generated/graphqlApolloTypes';

import { AccountMoveVariables, AqgTerritoryKind, DeleteRedirectVariables, Toast } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './AccountTerritoryCellRenderer.module.pcss';

const b = block(style);

export interface AccountTerritoryCellRendererProps {
  territoryName: string;
  territoryId: string;
  kind: AqgTerritoryKind;
  accountName: string;
  accountId: number;
  customHierarchyName?: string;
  customHierarchyId?: number;
  sourceRuleId: number;
  sourceRule: GetAccountRuleBindings_getAccountRuleBindings_bindings_sourceRule;
  openAccountMoveDialog: (moveVariables: AccountMoveVariables) => void;
  openDeleteRedirectDialog: (deleteVariables: DeleteRedirectVariables) => void;
  redirects: GetAccountRuleBindings_getAccountRuleBindings_bindings_redirects[];
  isCurrentTerritory: boolean;
  redirectId: number;
  handleRedirectAccountUnit: (accountRedirectInput: AccountRedirectInput, toast: Toast) => void;
  // TODO TQP-11297 Split view and edit functionality in AccountTerritoryCellRenderer
  editable?: boolean;
  territoryGroupTypeId?: number;
}

const iconByKind: Record<AqgTerritoryKind, JSX.Element> = {
  [AqgTerritoryKind.ROOT_SOURCE]: <Home />,
  [AqgTerritoryKind.EXPANDED_SOURCE]: <Home />,
  [AqgTerritoryKind.MOVE_BACK]: <ArrowLeft />,
  [AqgTerritoryKind.MOVE_AWAY]: <ArrowRight />,
  [AqgTerritoryKind.UNASSIGNED]: <WarningAltFilled />
};

const AccountTerritoryCellRenderer: React.FC<AccountTerritoryCellRendererProps> = ({
  territoryName,
  territoryId,
  kind,
  openAccountMoveDialog,
  openDeleteRedirectDialog,
  accountName,
  accountId,
  customHierarchyName,
  customHierarchyId,
  sourceRuleId,
  sourceRule,
  redirects,
  isCurrentTerritory,
  redirectId,
  handleRedirectAccountUnit,
  editable,
  territoryGroupTypeId
}) => {
  const handleOpenMoveDialog = (selection) => {
    const { key, value, effectiveDate, endDate } = selection.selectedItem;
    openAccountMoveDialog({
      accountName,
      accountId,
      customHierarchyName,
      customHierarchyId,
      sourceRuleId,
      sourceRule,
      targetRuleId: value,
      targetTerritoryId: key,
      sourceTerritoryId: territoryId,
      redirects,
      territoryGroupTypeId,
      effectiveDate,
      endDate
    });
  };

  const handleChangeRedirect = async (selection): Promise<void> => {
    const { key, value } = selection.selectedItem;
    if (key === formatMessage('DELETE_REDIRECT')) {
      openDeleteRedirectDialog({
        accountName,
        accountId,
        customHierarchyId,
        sourceRuleId,
        redirects,
        redirectId
      });
      return;
    }

    const replacedRedirect = redirects.find((redirect) => redirect.redirectId === redirectId);
    // The new redirect will get the old redirect's start and end date
    const newRedirect: AccountRedirectTarget = {
      targetRuleId: value,
      startDate: replacedRedirect.startDate,
      endDate: replacedRedirect.endDate
    };

    // when the new target is not unassigned territory or the source territory
    // and the replaced redirect had a reallocation field, we need to keep it in the new redirect
    if (value && value !== sourceRuleId && replacedRedirect.fields.length > 0) {
      const { primaryFieldId, fieldId } = replacedRedirect.fields[0];
      newRedirect.reallocationField = { primaryFieldId, fieldId };
    }

    const destinationText = key !== formatMessage('UNASSIGNED_TERRITORY') ? key : formatMessage('UNASSIGNED_LOWERCASE');
    const message = formatMessage('ACCOUNT_MOVE_DESCRIPTION', {
      accountName,
      destinationText,
      startDate: formatDateYearMonthDay(replacedRedirect.startDate)
    });
    handleRedirectAccountUnit(
      {
        targets: [newRedirect],
        redirectIdsToRemove: [replacedRedirect.redirectId],
        accountId,
        customHierarchyId,
        sourceRuleId
      },
      { message, title: formatMessage('ACCOUNT_MOVE_SCHEDULED') }
    );
  };

  const isSourceKind = kind === AqgTerritoryKind.ROOT_SOURCE || kind === AqgTerritoryKind.EXPANDED_SOURCE;
  const isUnassignedKind = kind === AqgTerritoryKind.UNASSIGNED;
  const isChangeDropdownEnabled = !isCurrentTerritory && !isSourceKind;

  const getRedirectedTerritoryTag = (onClick): React.ReactNode => {
    const territoryIdString = !isUnassignedKind ? ` (${territoryId})` : '';
    const content = `${territoryName} ${territoryIdString}`;

    const getIntent = () => {
      if (isSourceKind) {
        return Intent.NONE;
      } else if (isUnassignedKind) {
        return Intent.WARNING;
      }
      return Intent.PRIMARY;
    };

    return (
      <MessageTooltip
        className={b('tooltip')}
        data-testid="territory-tooltip"
        content={content}
        target={
          <Tag
            round
            icon={iconByKind[kind]}
            onClick={isChangeDropdownEnabled ? onClick : null}
            interactive={isChangeDropdownEnabled}
            intent={getIntent()}
          >
            {content}
          </Tag>
        }
        placement={'top'}
      />
    );
  };

  const getCurrentTerritoryTag = (onClick): React.ReactNode => (
    <IconButton
      type="button"
      icon={<AddAlt />}
      testId="select-menu-icon-button"
      intent="primary"
      minimal
      onClick={onClick}
      tooltipText={editable ? formatMessage('SCHEDULE_ACCOUNT_MOVE') : formatMessage('NO_EDIT_PERMISSION')}
      disabled={!editable}
    />
  );

  const sharedProps = {
    territoryGroupId: territoryGroupTypeId,
    shouldIncludeUnassignItem: true
  };

  return (
    <div data-testid="account-territory-cell-renderer">
      <Formik
        initialValues={{
          replacedRedirectTerritory: {
            key: '',
            value: ''
          },
          newRedirectTerritory: {
            key: '',
            value: ''
          }
        }}
        onSubmit={null}
      >
        {() => {
          return (
            <Form>
              <div className={b('selectionTargetContainer')}>
                <Field
                  name="replacedRedirectTerritory"
                  component={TerritorySearchableSelect}
                  renderTarget={getRedirectedTerritoryTag}
                  disabledItems={[
                    {
                      key: `${territoryName} (${territoryId})`,
                      value: redirects
                        .find((redirect) => redirect.targetRule?.territoryId === territoryId)
                        ?.targetRule?.ruleId?.toString()
                    }
                  ]}
                  shouldIncludeDeleteItem
                  onSelectItem={handleChangeRedirect}
                  {...sharedProps}
                />
                {isCurrentTerritory && (
                  <div data-testid="searchable-select-menu">
                    <Field
                      name="newRedirectTerritory"
                      component={TerritorySearchableSelect}
                      onSelectItem={handleOpenMoveDialog}
                      renderTarget={getCurrentTerritoryTag}
                      shouldReturnDates
                      {...sharedProps}
                    />
                  </div>
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default AccountTerritoryCellRenderer;
