import React from 'react';

import { ColDef } from '@ag-grid-community/core';
import { Tag } from '@blueprintjs/core';
import { WarningFilled } from '@carbon/icons-react';
import dayjs from 'dayjs';

import ImportErrorsMenuCellRenderer from 'app/components/AdvancedGrid/CellRenderers/ImportErrorsMenuCellRenderer/ImportErrorsMenuCellRenderer';

import { DATE_FORMAT } from 'app/global/variables';

import { formatMessage } from 'utils/messages/utils';

const buildImportErrorsPanelColumnDef = (): Array<ColDef> => [
  {
    headerName: formatMessage('NAME'),
    field: 'tableName',
    minWidth: 500,
    flex: 1
  },
  {
    headerName: formatMessage('FILE_TYPE'),
    field: 'tableDataType',
    minWidth: 200,
    flex: 1
  },
  {
    headerName: formatMessage('DATE'),
    field: 'createdAt',
    minWidth: 250,
    flex: 1,
    cellRendererFramework: (params) => dayjs(params.value).format(DATE_FORMAT)
  },
  {
    headerName: formatMessage('USER'),
    field: 'user',
    minWidth: 200,
    flex: 1,
    cellRendererFramework: (params) => params.value?.emailAddress ?? ''
  },
  {
    headerName: formatMessage('STATUS'),
    field: 'messages',
    minWidth: 200,
    flex: 1,
    cellStyle: { color: 'rgb(var(--color-red-3))' },
    cellRendererFramework: (params) => {
      const parsedMessages = params?.value ? JSON.parse(params.value) : {};
      const messagesLength = Object.keys(parsedMessages).length;
      if (messagesLength > 1) {
        return (
          <Tag minimal intent="danger" icon={<WarningFilled />}>
            {messagesLength} errors
          </Tag>
        );
      }
      return parsedMessages['1']?.message ?? '';
    }
  },
  {
    maxWidth: 56,
    pinned: 'right',
    cellStyle: { border: 'none', cursor: 'pointer' },
    cellRendererFramework: ImportErrorsMenuCellRenderer
  }
];

export default buildImportErrorsPanelColumnDef;
