import React, { useEffect, useMemo, useState } from 'react';

import { useMutation } from '@apollo/client';
import { Card, InputGroup } from '@blueprintjs/core';
import { AddFilled, Search } from '@carbon/icons-react';
import { HTMLHeading } from '@varicent/components';
import { Link } from 'react-router-dom';

import PlanningCycleToaster from 'components/PlanningCycleToaster/PlanningCycleToaster';

import CyclesCard from 'app/components/Cards/CyclesCard/CyclesCard';
import CyclesLoadingCard from 'app/components/Cards/CyclesLoadingCard/CyclesLoadingCard';

import { RoutePaths } from 'app/containers/App/Router/routePaths';

import { useScope } from 'app/contexts/scopeProvider';

import { PcJobInProgress, useUser } from 'app/core/userManagement/userProvider';

import { SplitFeatures } from 'app/global/features';

import {
  DeploymentModelTypeEnum,
  PCJobType,
  RequestPlanningCycleClone,
  RequestPlanningCycleCloneVariables
} from 'app/graphql/generated/graphqlApolloTypes';
import { handleError } from 'app/graphql/handleError';
import { REQUEST_PLANNING_CYCLE_CLONE } from 'app/graphql/mutations/requestPlanningCycleClone';

import useShowToast from 'app/hooks/useShowToast';
import useTreatment from 'app/hooks/useTreatment';

import { UserPlanningCycle } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';
import CanUser from 'utils/permissions/CanUser';
import { UserAction } from 'utils/permissions/userActions';

import style from './CyclesGrid.module.pcss';

const b = block(style);

const mockPlanningCycle = (pcId: number) => ({
  tenantId: -1,
  planningCycleId: pcId * -1,
  planningCycleName: 'Copy',
  planningCycleComment: null,
  planningCycleSlug: 'Copy',
  planningCycleDuration: 1,
  planningCyclePeriodicity: 'yearly',
  planningCycleStartDate: new Date(),
  planningCycleArchived: false,
  currencyCode: null,
  cloneId: null
});

const CyclesGrid: React.FC = () => {
  // remove these 2 states after feature flag is removed
  const [isCopyPCStatusOn] = useTreatment(SplitFeatures.COPY_PC_PROGRESS);
  const [inProgressClonePC, setInProgressClonePC] = useState<number | null>(null);

  const { selectedTenant } = useScope();

  const showToast = useShowToast();

  const [searchString, setSearchString] = useState('');

  const [planningCycles, setPlanningCycles] = useState<UserPlanningCycle[]>([]);

  const { userPlanningCycles, pcJobsInProgress, setPcJobsInProgress } = useUser();

  const [requestClonePlanningCycle, { loading: cloneLoading }] = useMutation<
    RequestPlanningCycleClone,
    RequestPlanningCycleCloneVariables
  >(REQUEST_PLANNING_CYCLE_CLONE, {
    onCompleted() {
      // remove after feature flag is removed
      setInProgressClonePC(null);
      if (!isCopyPCStatusOn) showToast(formatMessage('PLANNING_CYCLE_CLONE_REQUEST_SUCCESS'), 'success');
    },
    onError({ graphQLErrors, networkError }) {
      handleError(graphQLErrors, networkError);
      showToast(formatMessage('PLANNING_CYCLE_CLONE_FAILURE'), 'danger');
    }
  });

  const addPcLoadingCard = (pcInProgress: PcJobInProgress[], planningCycles: UserPlanningCycle[]) => {
    // add client only loading planning cycle card to show
    // that there are planning cycles being copied
    const pcIdInProgress = pcInProgress.map((pc) => Number(pc.planningCycleId));
    const pcWithCopy = planningCycles.reduce((acc, planningCycle) => {
      acc.push(planningCycle);
      if (pcIdInProgress.includes(planningCycle.planningCycleId)) {
        acc.push(mockPlanningCycle(planningCycle.planningCycleId));
      }
      return acc;
    }, []);
    setPlanningCycles(pcWithCopy);
  };

  const handleClonePC = async (planningCycleId, planningCycleName) => {
    if (isCopyPCStatusOn) {
      const requestClone = await requestClonePlanningCycle({
        variables: {
          planningCycleId
        }
      });
      if (!!requestClone?.data?.requestPlanningCycleClone?.messageId) {
        setPcJobsInProgress([
          ...pcJobsInProgress,
          {
            planningCycleId,
            planningCycleName,
            jobType: PCJobType.PLANNING_CYCLE_CLONE
          }
        ]);
      }
    } else {
      // remove else statement after feature flag is removed
      setInProgressClonePC(planningCycleId);
      requestClonePlanningCycle({
        variables: {
          planningCycleId
        }
      });
    }
  };

  const nonArchivedPlanningCycles = useMemo(
    () => userPlanningCycles.filter((plan) => plan?.planningCycleId && !plan.planningCycleArchived),
    [userPlanningCycles]
  );

  useEffect(() => {
    if (nonArchivedPlanningCycles?.length && searchString) {
      setPlanningCycles(
        nonArchivedPlanningCycles?.filter((plan) => plan.planningCycleName?.toLowerCase().includes(searchString))
      );
    } else if (nonArchivedPlanningCycles?.length) {
      setPlanningCycles(nonArchivedPlanningCycles);
    }
  }, [nonArchivedPlanningCycles, searchString]);

  useEffect(() => {
    const planningCyclesWithCloneJobs = pcJobsInProgress?.filter((pc) => pc.jobType === PCJobType.PLANNING_CYCLE_CLONE);

    if (planningCyclesWithCloneJobs?.length > 0) {
      addPcLoadingCard(planningCyclesWithCloneJobs, nonArchivedPlanningCycles);
    }
  }, [nonArchivedPlanningCycles, pcJobsInProgress]);

  const isPlanningCycleActivated = (planningCycle: UserPlanningCycle): boolean => {
    const { deploymentModels } = planningCycle;
    return (
      !!deploymentModels?.length &&
      deploymentModels.some((model) => model.deploymentModelType === DeploymentModelTypeEnum.Manage)
    );
  };

  return (
    <>
      <div className={b('cycles')}>
        <div className={b('header')}>
          <div className={b('title')}>
            <HTMLHeading
              tagLevel="h1"
              styleLevel="h2"
              text={formatMessage('PLANNING_CYCLES')}
              data-testid={'cycles-grid-title'}
            />
          </div>
          <div className={b('search')}>
            <InputGroup
              placeholder={formatMessage('SEARCH')}
              rightElement={
                <div className={b('searchIcon')}>
                  <Search />
                </div>
              }
              onChange={(event) => setSearchString(event?.target?.value ? event.target.value.toLowerCase() : '')}
              data-testid="planning-cycle-search"
              style={{ width: 195, height: 40 }}
            />
          </div>
        </div>
        <div className={b('gridContainer')}>
          <div className={b('row')}>
            <CanUser
              perform={UserAction.PLANNING_CYCLES_MODIFY}
              yes={
                <Card className={b('card')}>
                  <Link
                    to={`/${selectedTenant?.slug}${RoutePaths.CREATE_PLAN}`}
                    data-testid="new-cycle-card"
                    className={b('link')}
                  >
                    <div className={b('buttonContainer')}>
                      <div className={b('addCyclesButton')}>
                        <AddFilled size={32} />
                      </div>
                      <div className={b('addCyclesText')}>{formatMessage('NEW_CYCLE')}</div>
                    </div>
                  </Link>
                </Card>
              }
            />
            {planningCycles?.map((plan) => {
              const copyProgressCondition = isCopyPCStatusOn
                ? plan.planningCycleId < 0
                : cloneLoading && inProgressClonePC === plan.planningCycleId;
              return copyProgressCondition ? (
                <CyclesLoadingCard key={plan.planningCycleId} />
              ) : (
                <CyclesCard
                  key={plan.planningCycleId}
                  title={plan.planningCycleName}
                  planningCycleId={plan.planningCycleId}
                  planningCycleSlug={plan.planningCycleSlug}
                  isPlanningCycleActivated={isPlanningCycleActivated(plan)}
                  clonePlanningCycle={handleClonePC}
                  data-testid={`cycle-card-${plan.planningCycleId}`}
                />
              );
            })}
          </div>
        </div>
      </div>
      {isCopyPCStatusOn && pcJobsInProgress?.length > 0 && <PlanningCycleToaster />}
      {pcJobsInProgress?.length > 0 && <PlanningCycleToaster />}
    </>
  );
};

export default CyclesGrid;
