import React from 'react';

import { useMapVariant } from 'app/contexts/mapVariantProvider';

import { SplitFeatures } from 'app/global/features';

import { useUpsertTerritoryRule } from 'app/graphql/hooks/useUpsertTerritoryRule';
import { useRecolorTerritory } from 'app/graphql/mutations/recolorTerritoryRule';

import useShowToast from 'app/hooks/useShowToast';
import useTreatment from 'app/hooks/useTreatment';

import { RuleForMap, TerritoryColorList, WrappedRuleDefinition } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './TerritoryColorCellRenderer.module.pcss';

const b = block(style);

interface ColorPaletteProps {
  onUpdateRules: () => void;
  onLoadingChange: (isLoading: boolean) => void;
  generatedRuleDefinition: WrappedRuleDefinition;
  rule: RuleForMap;
}

export const ColorPalette: React.FC<ColorPaletteProps> = ({
  onLoadingChange,
  onUpdateRules,
  generatedRuleDefinition,
  rule
}) => {
  const showToast = useShowToast();
  const [isComplexRuleEditOn] = useTreatment(SplitFeatures.MAP_EDIT_CUSTOM_HIER_RULES);
  const [recolorTerritory] = useRecolorTerritory({
    onCompleted: () => {
      showToast(formatMessage('UPDATE_TERRITORY_SUCCESS'), 'success');
    },
    onError: () => {
      showToast(formatMessage('UPDATE_TERRITORY_ERROR'), 'danger');
    }
  });
  const [upsertTerritoryRule] = useUpsertTerritoryRule(
    formatMessage('UPDATE_TERRITORY_SUCCESS'),
    formatMessage('UPDATE_TERRITORY_ERROR')
  );
  const { setOpenTerritoryColorPaletteId } = useMapVariant();

  const handleColorChange = async (color: string) => {
    onLoadingChange(true);
    try {
      if (isComplexRuleEditOn) {
        await recolorTerritory({ variables: { input: { ruleId: rule.ruleId, color } } });
      } else {
        const { territoryGroupId, territoryName, territoryId, ruleId, effectiveDate, endDate } = rule;
        await upsertTerritoryRule({
          variables: {
            ...generatedRuleDefinition,
            territoryGroupId,
            territoryName,
            territoryId,
            ruleId,
            color,
            effectiveDate,
            endDate
          }
        });
      }
      setOpenTerritoryColorPaletteId(null);
      onUpdateRules();
    } finally {
      onLoadingChange(false);
    }
  };

  return (
    <>
      {TerritoryColorList.map((color, index) => (
        <div key={color}>
          <label className={b('pickerLabel')}>
            <input
              type="radio"
              name="color"
              value={color}
              className={b('radioField')}
              aria-label={formatMessage('COLOR_OPTION', { value: index + 1 })}
              onChange={() => handleColorChange(color)}
              data-testid={`territory-color-option-${index}`}
            />
            <div className={b('coloredCircle')} style={{ backgroundColor: color }} />
          </label>
        </div>
      ))}
    </>
  );
};

export default ColorPalette;
