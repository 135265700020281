import React, { FC } from 'react';

import { ChevronDown } from '@carbon/icons-react';

import IconButton from 'components/Buttons/IconButton/IconButton';
import Popover from 'components/Popover/Popover';

import ActionBarV2, { ActionBarV2Props } from 'app/components/TerritoryMap/MapToolbars/ActionBarV2/ActionBarV2';

import { formatMessage } from 'utils/messages/utils';

const ActionBarContainerV2: FC<ActionBarV2Props> = (props: ActionBarV2Props) => {
  return props.isSmallVariant ? (
    <Popover content={<ActionBarV2 {...props} />} placement="bottom-start" minimal>
      <IconButton
        type="button"
        testId="action-menu-button"
        icon={<ChevronDown />}
        tooltipText={formatMessage('ACTIONS')}
        toolTipPlacement="bottom"
      />
    </Popover>
  ) : (
    <ActionBarV2 {...props} />
  );
};

export default ActionBarContainerV2;
