import React from 'react';

import { useMutation, useQuery } from '@apollo/client';

import ConfirmDeleteModal from 'components/Dialog/ConfirmDeleteModal/ConfirmDeleteModal';
import Dialog from 'components/Dialog/Dialog';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useTerritoryDefineAndRefine } from 'app/contexts/territoryDefineAndRefineProvider';

import {
  GetNumberOfTerritoriesInTerritoryGroup,
  GetNumberOfTerritoriesInTerritoryGroupVariables
} from 'app/graphql/generated/graphqlApolloTypes';
import { handleError } from 'app/graphql/handleError';
import { DELETE_TERRITORY_GROUP } from 'app/graphql/mutations/deleteTerritoryGroup';
import { QUERY_TERRITORY_GROUPS } from 'app/graphql/queries/getAllTerritoryGroups';
import { QUERY_NUM_TERRITORIES_IN_TERRITORY_GROUP } from 'app/graphql/queries/getNumberOfTerritoriesInTerritoryGroup';

import useShowToast from 'app/hooks/useShowToast';

import { formatMessage } from 'utils/messages/utils';

interface TerritoryGroupConfirmDeleteProps {
  battleCardId: string;
}

const TerritoryGroupConfirmDelete: React.FC<TerritoryGroupConfirmDeleteProps> = ({
  battleCardId
}: TerritoryGroupConfirmDeleteProps) => {
  const {
    tdrDialogState: { territoryGroupId },
    closeTdrDialog,
    getTDR
  } = useTerritoryDefineAndRefine();

  const { selectedQuotaComponentId } = useBattleCard();
  const showToast = useShowToast();

  const { data: numTerritoriesData, loading: numTerritoriesLoading } = useQuery<
    GetNumberOfTerritoriesInTerritoryGroup,
    GetNumberOfTerritoriesInTerritoryGroupVariables
  >(QUERY_NUM_TERRITORIES_IN_TERRITORY_GROUP, {
    fetchPolicy: 'network-only',
    skip: !territoryGroupId || !battleCardId,
    variables: {
      territoryGroupId: +territoryGroupId,
      battlecardId: +battleCardId
    },
    onError({ graphQLErrors, networkError }) {
      handleError(graphQLErrors, networkError);
      showToast(formatMessage('TERRITORY_GROUP_ERROR'), 'danger');
    }
  });

  const groupHasTerritories = !!(
    numTerritoriesData &&
    numTerritoriesData.getNumberOfTerritoriesInTerritoryGroup &&
    numTerritoriesData.getNumberOfTerritoriesInTerritoryGroup.numOfTerritories
  );

  const [deleteTerritoryGroup, { loading: isDeleting }] = useMutation(DELETE_TERRITORY_GROUP, {
    onCompleted() {
      getTDR(battleCardId, selectedQuotaComponentId);
      showToast(formatMessage('DELETE_NEW_TERRITORY_GROUP_SUCCESS'), 'success');
      closeTdrDialog();
    },
    onError({ graphQLErrors, networkError }) {
      handleError(graphQLErrors, networkError);
      showToast(formatMessage('DELETE_NEW_TERRITORY_GROUP_ERROR'), 'danger');
      closeTdrDialog();
    }
  });

  const handleConfirmDelete = () => {
    deleteTerritoryGroup({
      variables: {
        territoryGroupId: +territoryGroupId
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: QUERY_TERRITORY_GROUPS,
          variables: { battlecardId: battleCardId }
        }
      ]
    });
  };

  return (
    <div>
      {!numTerritoriesLoading &&
        (groupHasTerritories ? (
          <Dialog
            isOpen
            title={formatMessage('DELETE_GROUP_WITH_TERRITORIES_WARNING_HEADER')}
            size="small"
            bodyMinHeight={0}
            showCancel={false}
            onSubmit={closeTdrDialog}
            confirmButtonText={formatMessage('OK')}
            data-testid="territory-group-has-territories-warning"
          >
            {formatMessage('DELETE_GROUP_WITH_TERRITORIES_WARNING_BODY')}
          </Dialog>
        ) : (
          <ConfirmDeleteModal
            isOpen
            onConfirmDelete={handleConfirmDelete}
            onCancel={closeTdrDialog}
            bodyText={formatMessage('DELETE_GROUP_WARNING')}
            isSubmitting={isDeleting}
          />
        ))}
    </div>
  );
};

export default TerritoryGroupConfirmDelete;
