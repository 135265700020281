import { useCallback } from 'react';

import { useLazyQuery } from '@apollo/client';

import { ChangeHistoryEventCategory } from 'app/constants/ChangeHistoryEventCategoryConstants';

import {
  ExportChangeHistory,
  ExportChangeHistoryVariables,
  ExportChangeHistory_getChangeHistoryExport,
  GetJobs_getJobs_items
} from 'app/graphql/generated/graphqlApolloTypes';
import { handleError } from 'app/graphql/handleError';
import { EXPORT_CHANGE_HISTORY } from 'app/graphql/queries/exportChangeHistory';
import { useGetJobsLazy } from 'app/graphql/queries/getJobs';

import { JobStatus } from 'app/models/index';

// eslint-disable-next-line no-restricted-imports
import showToast from 'utils/helpers/showToast';
import { formatMessage } from 'utils/messages/utils';

const POLLING_INTERVAL_MILLISECONDS = 1000;
const DISPLAY_TOAST_AFTER_COMPLETED = 500;
interface UseExportChangeHistoryValue {
  startExport: (
    planningCycleId: number,
    deploymentModelId: number,
    eventCategories: ChangeHistoryEventCategory[]
  ) => Promise<ExportChangeHistory_getChangeHistoryExport>;
}

type SetExportChangeHistoryProgress = React.Dispatch<React.SetStateAction<number>>;

// Note : Sync version returns downloadUrl directly from initial getChangeHistoryExport query.
// TODELETE : When AUDIT_TRAIL_ASYNC is decommissioned
const handleCompleted = (exportedCompletedMessage: JSX.Element) => (res) => {
  if (res?.getChangeHistoryExport.downloadUrl) {
    // eslint-disable-next-line deprecation/deprecation
    showToast(exportedCompletedMessage, 'success', 'top-right');
    const downloadUrl = res?.getChangeHistoryExport.downloadUrl;
    downloadFile(downloadUrl);
  }
};

// Shared function for sync and async
export const downloadFile = (downloadUrl: string): void => {
  const date = new Date();
  const [day, month, year] = [date.getDate(), date.getMonth() + 1, date.getFullYear()];

  const link = document.createElement('a');
  link.href = downloadUrl;
  link.download = `SalesPlanningAudit_${day}_${month}_${year}.csv`;

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

// Shared function for sync and async
const handleErrors = ({ graphQLErrors, networkError }) => {
  handleError(graphQLErrors, networkError);
  // eslint-disable-next-line deprecation/deprecation
  showToast(formatMessage('CHANGE_HISTORY_EXPORT_ERROR'), 'danger');
};

// Async version polls job progress once initial getChangeHistoryExport query is completed.
export const usePollJobProgress = () => {
  const [getJobStatus, { startPolling, stopPolling }] = useGetJobsLazy({
    fetchPolicy: 'network-only',
    pollInterval: POLLING_INTERVAL_MILLISECONDS,
    // eslint-disable-next-line deprecation/deprecation
    onError: () => showToast(formatMessage('CHANGE_HISTORY_EXPORT_ERROR'), 'danger')
  });
  // Retrieves downloadUrl from the polledJob to download and manages toast displays.
  const handleExportJobCompleted = (
    polledJob: GetJobs_getJobs_items,
    setExportChangeHistoryProgress: SetExportChangeHistoryProgress,
    exportedCompletedMessage: string
  ) => {
    const downloadUrl = JSON.parse(polledJob?.metadata)?.downloadUrl;
    stopPolling();
    setExportChangeHistoryProgress(polledJob?.progress);
    setTimeout(() => {
      // Timeout for user to see display of 100% progress
      setExportChangeHistoryProgress(0); // Will hide the toast
      if (downloadUrl) {
        // eslint-disable-next-line deprecation/deprecation
        showToast(exportedCompletedMessage, 'success', 'top-right');
        downloadFile(downloadUrl);
      } else {
        // eslint-disable-next-line deprecation/deprecation
        showToast(formatMessage('CHANGE_HISTORY_EXPORT_ERROR'), 'danger');
      }
    }, DISPLAY_TOAST_AFTER_COMPLETED);
  };

  const pollJobProgress = useCallback(
    (job: GetJobs_getJobs_items, setExportChangeHistoryProgress, exportedCompletedMessage) => {
      getJobStatus({
        variables: { input: { ids: [job.id] } },
        onCompleted: (data) => {
          const polledJob = data.getJobs.items?.find((item) => item.id === job.id);
          const jobStatus = polledJob?.status;
          if (jobStatus === JobStatus.COMPLETED) {
            handleExportJobCompleted(polledJob, setExportChangeHistoryProgress, exportedCompletedMessage);

            return;
          }
          if (jobStatus === JobStatus.FAILED) {
            // eslint-disable-next-line deprecation/deprecation
            showToast(formatMessage('CHANGE_HISTORY_EXPORT_ERROR'), 'danger');
            setExportChangeHistoryProgress(0); // Will hide the toast
            stopPolling();
          }
          setExportChangeHistoryProgress(polledJob?.progress);
        },
        onError: handleErrors
      });

      startPolling(POLLING_INTERVAL_MILLISECONDS);
    },
    [getJobStatus, startPolling, stopPolling]
  );

  return pollJobProgress;
};

export const useExportChangeHistory = (
  exportedCompletedMessage: JSX.Element,
  setExportChangeHistoryProgress: SetExportChangeHistoryProgress,
  isAsyncAuditTrailExportOn = false
): UseExportChangeHistoryValue => {
  const pollJobProgress = usePollJobProgress();

  const onCompletedHandler = (data) => {
    if (isAsyncAuditTrailExportOn && data.getChangeHistoryExport) {
      // Async handling: pass the jobCatalogItem to start pollJobProgress
      pollJobProgress(data.getChangeHistoryExport, setExportChangeHistoryProgress, exportedCompletedMessage);
    } else {
      // Sync handling: invoke handleCompleted to directly download the file
      handleCompleted(exportedCompletedMessage)(data);
      setExportChangeHistoryProgress(0);
    }
  };

  const [exportChangeHistory] = useLazyQuery<ExportChangeHistory, ExportChangeHistoryVariables>(EXPORT_CHANGE_HISTORY, {
    fetchPolicy: 'network-only',
    onCompleted: onCompletedHandler,
    onError: handleErrors
  });

  const startExport = async (
    planningCycleId: number,
    deploymentModelId: number,
    eventCategories: ChangeHistoryEventCategory[]
  ): Promise<ExportChangeHistory_getChangeHistoryExport> => {
    try {
      const { data } = await exportChangeHistory({
        variables: {
          input: {
            planningCycleId,
            deploymentModelId,
            eventCategories
          }
        }
      });

      return data?.getChangeHistoryExport ?? null;
    } catch (err) {
      // eslint-disable-next-line deprecation/deprecation
      showToast(formatMessage('CHANGE_HISTORY_EXPORT_ERROR'), 'danger');
      throw err;
    }
  };

  return { startExport };
};
