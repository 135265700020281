import { SplitFeatures } from 'app/global/features';

import useTreatment from 'app/hooks/useTreatment';

export const useTerritoryLockingTreatment = () => {
  const [, { treatment }] = useTreatment(SplitFeatures.TERRITORY_LOCKING);
  const isEverythingOn = treatment === 'everything';
  const isOnlyMvpOn = treatment === 'mvp';
  const isMvpOn = isEverythingOn || isOnlyMvpOn;
  return { isEverythingOn, isMvpOn, isOnlyMvpOn };
};
