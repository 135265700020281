import React, { FC } from 'react';

import { Add, Subtract } from '@carbon/icons-react';
import { MapboxMap } from 'react-map-gl';

import IconButton from 'components/Buttons/IconButton/IconButton';

import { ZOOM_DISTANCE, ZOOM_TRANSITION_DURATION } from 'app/global/variables';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './ZoomBarV2.module.pcss';
const b = block(style);

interface ZoomBarV2Props {
  mapboxMap: Pick<MapboxMap, 'easeTo' | 'getZoom'>;
}

const ZoomBarV2: FC<ZoomBarV2Props> = ({ mapboxMap }) => {
  const zoomMap = (direction: 'in' | 'out') => {
    const delta = direction === 'in' ? ZOOM_DISTANCE : -ZOOM_DISTANCE;
    const currentZoom = mapboxMap.getZoom();
    mapboxMap.easeTo({ zoom: currentZoom + delta, duration: ZOOM_TRANSITION_DURATION });
  };

  return (
    <div className={b('zoomButtonsWrapper')}>
      <IconButton
        testId="zoom-out-button"
        title={formatMessage('ZOOM_OUT')}
        tooltipText={formatMessage('ZOOM_OUT')}
        type="button"
        icon={<Subtract />}
        onClick={() => zoomMap('out')}
        toolTipPlacement="top-end"
      />
      <IconButton
        testId="zoom-in-button"
        title={formatMessage('ZOOM_IN')}
        tooltipText={formatMessage('ZOOM_IN')}
        type="button"
        icon={<Add />}
        onClick={() => zoomMap('in')}
        toolTipPlacement="top-end"
      />
    </div>
  );
};

export default ZoomBarV2;
