import React, { ReactNode } from 'react';

// eslint-disable-next-line no-restricted-imports
import { Classes, Dialog } from '@blueprintjs/core';
import { Close } from '@carbon/icons-react';

import IconButton from 'components/Buttons/IconButton/IconButton';
import TextButton from 'components/Buttons/TextButton/TextButton';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './ConfirmDeleteModal.module.pcss';

const b = block(style);

export type ConfirmDeleteModalProps = {
  isOpen: boolean;
  isLoading?: boolean;
  titleText?: string;
  isSubmitting?: boolean;
  bodyText: ReactNode;
  onCancel: (e) => void;
  onConfirmDelete: (e) => void;
};

const ConfirmDeleteModal: React.FC<ConfirmDeleteModalProps> = ({
  bodyText,
  isOpen,
  titleText,
  onConfirmDelete,
  onCancel,
  isLoading = false,
  isSubmitting = false
}) => {
  return (
    <Dialog
      isOpen={isOpen}
      canEscapeKeyClose={false}
      canOutsideClickClose={false}
      portalClassName={b()}
      data-testid="confirm-delete-modal"
    >
      <div className={b('modalHeader')}>
        <span className={b('title')} data-testid="modal-title">
          {titleText ?? formatMessage('CONFIRM_DELETE')}
        </span>
        <IconButton
          type="button"
          icon={<Close size={24} />}
          onClick={(e) => onCancel(e)}
          testId={'close-button'}
          title={formatMessage('CLOSE')}
        />
      </div>
      <div className={b('modalBody')}>
        {isLoading ? (
          <div className={`${Classes.SKELETON} ${b('loadingPlaceholder')}`} />
        ) : (
          <div data-testid="modal-body">{bodyText}</div>
        )}
      </div>
      <div className={b('modalFooter')}>
        <div className={b('footerSpacer')} />
        <div className={b('buttons')}>
          <span className={b('button')}>
            <TextButton
              testId={'confirm-modal-cancel-button'}
              text={formatMessage('CANCEL')}
              type="button"
              onClick={(e) => onCancel(e)}
            />
          </span>
          <TextButton
            testId={'confirm-modal-delete-button'}
            text={formatMessage('DELETE')}
            type="submit"
            intent="danger"
            loading={isSubmitting}
            disabled={isSubmitting}
            onClick={(e) => onConfirmDelete(e)}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default ConfirmDeleteModal;
