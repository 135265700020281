import { useCallback } from 'react';

import { usePermissionsContextRedistributor } from 'app/contexts/PermissionsContextRedistributor/permissionsContextRedistributorProvider';

import { SplitFeatures } from 'app/global/features';

import { BCInfoLevelEnum } from 'app/graphql/generated/graphqlApolloTypes';

import { UserRoleType, Permission, ExpandedTerritoryGroupDefineAndRefinePillData } from 'app/models';

import {
  UserAction,
  getPermissionsByRole,
  isStatic,
  hasStaticPermission,
  permissionsInclude,
  StaticUserActions
} from 'utils/permissions/userActions';

import { useScenarioPermissions } from './useScenarioPermissions';
import useTreatment from './useTreatment';

const getRoleFromInfoLevel = (bcInfoLevel: BCInfoLevelEnum): UserRoleType => {
  switch (bcInfoLevel) {
    case BCInfoLevelEnum.all:
      return UserRoleType.BATTLE_CARD_OWNER;
    case BCInfoLevelEnum.territoryGroupOwner:
    case BCInfoLevelEnum.territoryGroupTypeOwner:
      return UserRoleType.TERRITORY_GROUP_OWNER;
    default:
      return UserRoleType.NONE;
  }
};

const hasPermission = (
  userAction: UserAction,
  isAdmin: boolean,
  memberId: number,
  selectedTerritoryGroupId: string,
  userRole: UserRoleType,
  permissions: Permission[],
  territoryGroups: ExpandedTerritoryGroupDefineAndRefinePillData[],
  isRolePermissionsOn: boolean
) => {
  // determine if requested user action is static
  if (isStatic(userAction)) {
    return checkStaticPermissions(isAdmin, userAction);
  } else {
    return checkDynamicPermissions(
      isAdmin,
      memberId,
      selectedTerritoryGroupId,
      userRole,
      userAction,
      permissions,
      territoryGroups,
      isRolePermissionsOn
    );
  }
};

const checkStaticPermissions = (isAdmin: boolean, userAction: StaticUserActions) => {
  return hasStaticPermission(userAction, isAdmin ? UserRoleType.ADMIN : UserRoleType.CONTRIBUTOR);
};

const checkDynamicPermissions = (
  isAdmin: boolean,
  memberId: number,
  selectedTerritoryGroupId: string,
  userRole: UserRoleType,
  userAction: UserAction,
  permissions: Permission[],
  territoryGroups: ExpandedTerritoryGroupDefineAndRefinePillData[],
  isRolePermissionsOn: boolean
) => {
  let rolePermissions;
  if (userRole) {
    rolePermissions = getPermissionsByRole(permissions, userRole);
  }

  const isTGContext = !!selectedTerritoryGroupId;

  const isCurrentTGOwner = !!territoryGroups.some((territoryGroup) => {
    return (
      territoryGroup?.territoryGroupId === selectedTerritoryGroupId && territoryGroup?.owner?.memberId === memberId
    );
  });

  // if user owns both the BC and the TG inside
  // eslint-disable-next-line no-restricted-syntax
  const isTGandParentBCOwner = isCurrentTGOwner && userRole === UserRoleType.BATTLE_CARD_OWNER;

  // admins have permission for everything
  // contributors have permission for everything when FF is off
  if (isAdmin || (!isAdmin && !isRolePermissionsOn)) {
    return true;
  }
  // if userAction is plan or manage, this isn't stored in BE so determine if they have child view permissions from any role
  else if (userAction === UserAction.PLAN_VIEW) {
    return (
      permissionsInclude(permissions, UserAction.MAPS_VIEW) ||
      permissionsInclude(permissions, UserAction.SELLER_ASSIGNMENT_VIEW) ||
      permissionsInclude(permissions, UserAction.TERRITORY_DEFINITION_AND_ASSIGNMENT_VIEW) ||
      permissionsInclude(permissions, UserAction.TERRITORY_REBALANCING_VIEW)
    );
  } else if (userAction === UserAction.MANAGE_VIEW) {
    return (
      permissionsInclude(permissions, UserAction.ACCOUNT_QUOTA_VIEW) ||
      permissionsInclude(permissions, UserAction.SELLER_QUOTA_VIEW) ||
      permissionsInclude(permissions, UserAction.TERRITORY_QUOTA_VIEW)
    );
  } else if (
    isTGContext &&
    isCurrentTGOwner &&
    // eslint-disable-next-line no-restricted-syntax
    (userRole === UserRoleType.TERRITORY_GROUP_OWNER ||
      // eslint-disable-next-line no-restricted-syntax
      userRole === UserRoleType.TERRITORY_GROUP_TYPE_OWNER ||
      isTGandParentBCOwner)
  ) {
    // territory group or territory group type owner
    const tgOrTGTPermissions = getPermissionsByRole(permissions, UserRoleType.TERRITORY_GROUP_OWNER);
    const hasPermission = permissionsInclude(tgOrTGTPermissions, userAction);

    if (!hasPermission && isTGandParentBCOwner) {
      // see if they are also the BC owner, if so then use BC permission if higher
      const bcPermissions = getPermissionsByRole(permissions, UserRoleType.BATTLE_CARD_OWNER);
      return permissionsInclude(bcPermissions, userAction);
    }
    return hasPermission;
    // eslint-disable-next-line no-restricted-syntax
  } else if (!(isTGContext && isCurrentTGOwner) && userRole === UserRoleType.BATTLE_CARD_OWNER) {
    // look at BC role for permission
    return permissionsInclude(rolePermissions, userAction);
  } else {
    return false;
  }
};

const useCheckCanUser = (): ((userAction: UserAction) => boolean) => {
  const {
    selectedTenantId,
    selectedBattleCardId,
    battleCardLookupMap,
    selectedTerritoryGroupId,
    territoryGroups,
    userRole,
    userMembershipSpecs
  } = usePermissionsContextRedistributor();

  const permissions = useScenarioPermissions();

  const [isRolePermissionsOn] = useTreatment(SplitFeatures.PERMISSIONS_ENGINE);

  const memberId = userMembershipSpecs?.filter((membershipSpec) => {
    return membershipSpec?.tenantId === selectedTenantId;
  })?.[0]?.memberId;

  // eslint-disable-next-line no-restricted-syntax
  const isAdmin = userRole === UserRoleType.ADMIN;

  let dynamicRole;
  if (selectedBattleCardId) {
    // if we are in the context of a BC, get permission for that BC's role
    const currentBC = battleCardLookupMap?.[selectedBattleCardId];
    dynamicRole = getRoleFromInfoLevel(currentBC?.battlecardInfoLevel ?? BCInfoLevelEnum.hidden);
  }

  return useCallback(
    (userAction: UserAction) =>
      hasPermission(
        userAction,
        isAdmin,
        memberId,
        selectedTerritoryGroupId,
        dynamicRole,
        permissions,
        territoryGroups,
        isRolePermissionsOn
      ),
    [hasPermission, permissions, userRole, selectedTerritoryGroupId, dynamicRole, isRolePermissionsOn]
  );
};

export default useCheckCanUser;
