import { QueryResult, useQuery } from '@apollo/client';

import { GetSymonAccount, GetSymonAccountVariables } from 'app/graphql/generated/graphqlApolloTypes';
import { handleError } from 'app/graphql/handleError';
import { GET_SYMON_ACCOUNT } from 'app/graphql/queries/getSymonAccount';

// eslint-disable-next-line no-restricted-imports
import showToast from 'utils/helpers/showToast';
import { formatMessage } from 'utils/messages/utils';

/**
 * Fetch symon account & credentials
 */
export const useGetSymonAccount = (tenantId: number): QueryResult<GetSymonAccount, GetSymonAccountVariables> =>
  useQuery<GetSymonAccount, GetSymonAccountVariables>(GET_SYMON_ACCOUNT, {
    fetchPolicy: 'network-only',
    variables: {
      tenantId
    },
    onError({ graphQLErrors, networkError }) {
      // eslint-disable-next-line deprecation/deprecation
      showToast(formatMessage('GET_SYMON_ACCOUNT_ERROR'), 'danger');
      handleError(graphQLErrors, networkError);
    }
  });
