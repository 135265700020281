import { useMutation, MutationTuple } from '@apollo/client';

import { createUseMutation } from 'app/graphql/apolloFactoryHelpers';
import { UpsertTerritoryRule, UpsertTerritoryRuleVariables } from 'app/graphql/generated/graphqlApolloTypes';
import { handleError } from 'app/graphql/handleError';
import { UPSERT_TERRITORY_RULE } from 'app/graphql/mutations/upsertTerritoryRule';

// eslint-disable-next-line no-restricted-imports
import showToast from 'utils/helpers/showToast';
import { formatMessage } from 'utils/messages/utils';

const BACKEND_ERROR_STRINGS = {
  DUPLICATE_OVERRIDE_INCLUSION_KEY: 'The Hierarchy Inclusion Key already exists in another Override Rule'
};

export const BACKEND_ERROR_TO_FRONTEND_ERROR_MAP: Record<string, string> = {
  [BACKEND_ERROR_STRINGS.DUPLICATE_OVERRIDE_INCLUSION_KEY]: formatMessage('DUPLICATE_OVERRIDE_INCLUSION_KEY')
};

const getValidatedErrMessage = (backendError: string, defaultMsg: string): string => {
  return BACKEND_ERROR_TO_FRONTEND_ERROR_MAP[backendError] || defaultMsg;
};

export const useUpsertTerritoryRule = (
  successToastMessage?: string,
  errorToastMessage?: string,
  onCompleted?: () => void
): MutationTuple<UpsertTerritoryRule, UpsertTerritoryRuleVariables> =>
  useMutation<UpsertTerritoryRule, UpsertTerritoryRuleVariables>(UPSERT_TERRITORY_RULE, {
    onCompleted() {
      // eslint-disable-next-line deprecation/deprecation
      showToast(successToastMessage, 'success');
      if (onCompleted) {
        onCompleted();
      }
    },
    onError({ graphQLErrors, networkError }) {
      const returnedErrorMessage = graphQLErrors[0]?.message;
      // eslint-disable-next-line deprecation/deprecation
      showToast(getValidatedErrMessage(returnedErrorMessage, errorToastMessage), 'danger');
      handleError(graphQLErrors, networkError);
    }
  });

export const useUpsertTerritory = createUseMutation<UpsertTerritoryRule, UpsertTerritoryRuleVariables>(
  UPSERT_TERRITORY_RULE
);
