import React from 'react';

import TerritoryQuotaDrillIn from 'app/components/AdvancedGrid/Sheets/Territory/TerritoryQuotaGrid/TerritoryQuotaDrillInGrid/TerritoryQuotaDrillInGrid';
import TerritoryQuotaGrid from 'app/components/AdvancedGrid/Sheets/Territory/TerritoryQuotaGrid/TerritoryQuotaGrid';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useGrid } from 'app/contexts/gridProvider';
import { usePlanTargets } from 'app/contexts/planTargetsProvider';
import { useTerritoryDefineAndRefine } from 'app/contexts/territoryDefineAndRefineProvider';

import { SplitFeatures } from 'app/global/features';

import useTreatment from 'app/hooks/useTreatment';

import { HierarchyType } from 'app/models';

const TerritoryQuotaGridContainer: React.FC = () => {
  const { selectedBattleCardId, quotaBreakdownHierarchies } = useBattleCard();
  const { selectedPillIdPlanTargets } = usePlanTargets();
  const { selectedPillIdTDR } = useTerritoryDefineAndRefine();
  const { showManageTerritoryQuotaDrillIn } = useGrid();

  const [isAccountMoveInTerritoryQuotaGridEnabled] = useTreatment(
    SplitFeatures.AMWQ_ACCOUNT_QUOTA_IN_TERRITORY_QUOTA_GRID
  );
  const isOnlyCustomerHierarchySelected =
    quotaBreakdownHierarchies?.length === 1 &&
    quotaBreakdownHierarchies[0]?.hierarchyType === HierarchyType.CustomerAccountHierarchy;

  const selectedPillId = selectedPillIdPlanTargets || selectedPillIdTDR;

  if (!selectedBattleCardId && !selectedPillId) {
    return null;
  } else if (
    showManageTerritoryQuotaDrillIn &&
    isAccountMoveInTerritoryQuotaGridEnabled &&
    isOnlyCustomerHierarchySelected
  ) {
    return <TerritoryQuotaDrillIn data-testid="territory-quota-drill-in-grid" />;
  }

  return <TerritoryQuotaGrid data-testid="territory-quota-grid" />;
};

export default TerritoryQuotaGridContainer;
