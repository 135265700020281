import { Headers } from 'app/models';

import { config } from 'utils/config';

import { APIError } from './APIError';
import { buildHeaders, BuildHeaderParams, ApiHeaders } from './util';

enum APIUrlType {
  APP = 'app'
}

export class API {
  private baseUrl: string;
  private appUrl: string;

  constructor() {
    this.baseUrl = config.APPSYNC_GRAPHQL_SUBDOMAIN;
    this.appUrl = `${this.baseUrl}/${APIUrlType.APP}`;
  }

  public static buildHeaders(params: BuildHeaderParams): ApiHeaders {
    return buildHeaders(params);
  }

  async get<Res>(endpoint: string, queryParams?: Record<string, string>, headers?: Headers): Promise<Res> {
    const url = new URL(`${this.appUrl}/${endpoint}`);
    if (queryParams) {
      Object.keys(queryParams).forEach((key) => url.searchParams.append(key, queryParams[key]));
    }
    const response = await fetch(url.toString(), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...headers
      }
    });
    if (!response.ok) {
      await this.handleError(response, endpoint);
    }
    return response.json();
  }

  async post<Req = never, Res = never>(endpoint: string, data: Req, headers?: HeadersInit): Promise<Res> {
    const response = await fetch(`${this.appUrl}/${endpoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...headers
      },
      body: JSON.stringify(data)
    });
    if (!response.ok) {
      await this.handleError(response, endpoint);
    }
    return response.json();
  }

  async streamPost<Req = never>(
    endpoint: string,
    data: Req,
    headers?: Headers,
    dataReceived?: (data: string) => void
  ): Promise<void> {
    const response = await fetch(`${this.appUrl}/${endpoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...headers
      },
      body: JSON.stringify(data)
    });
    if (!response.ok) {
      await this.handleError(response, endpoint);
    }

    const reader = response.body?.getReader();
    if (!reader) {
      throw new APIError('Failed to get readable stream reader', 500, 'Internal Server Error', endpoint);
    }

    return new Promise((resolve, reject) => {
      const read = async () => {
        try {
          const { done, value } = await reader.read();
          if (done) {
            resolve();
            return;
          }
          const textChunk = new TextDecoder().decode(value);
          dataReceived?.(textChunk);
          read();
        } catch (error) {
          this.handleStreamError(error, endpoint, reader, reject);
        }
      };
      read();
    });
  }

  private async handleError(response: Response, endpoint: string): Promise<void> {
    const text = await response.text();
    let errorMessage: string;

    try {
      const data = JSON.parse(text); // Attempt to parse JSON
      errorMessage = data.message || response.statusText; // Use the JSON-provided message or the status text
    } catch (error) {
      console.log('Error parsing response JSON:', error);
      errorMessage = response.statusText; // Fallback to statusText if JSON parsing fails
    }

    // Now, throw the APIError outside of the try-catch
    throw new APIError(errorMessage, response.status, response.statusText, endpoint);
  }

  private handleStreamError(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error: any,
    endpoint: string,
    reader: ReadableStreamDefaultReader,
    reject: (reason?: Error) => void
  ) {
    reader
      .cancel()
      .then(() =>
        reject(
          new APIError(`Stream processing failed with ${error.toString()}`, 500, 'Internal Server Error', endpoint)
        )
      );
  }
}
