import gql from 'graphql-tag';

import { createUseQuery } from 'app/graphql/apolloFactoryHelpers';
import { GetChatHistory, GetChatHistoryVariables } from 'app/graphql/generated/graphqlApolloTypes';

export const GET_CHAT_HISTORY = gql`
  query GetChatHistory($conversationId: String!) {
    getChatHistory(input: { conversationId: $conversationId }) {
      content {
        message
        role
      }
      conversationId
    }
  }
`;

export const useGetChatHistory = createUseQuery<GetChatHistory, GetChatHistoryVariables>(GET_CHAT_HISTORY);
