import gql from 'graphql-tag';

export const UPSERT_PLANNING_CYCLE = gql`
  mutation UpsertPlanningCycle(
    $tenantId: Int!
    $planningCycleId: Int
    $planningCycleName: String
    $planningCyclePeriodicity: String
    $planningCycleStartDate: AWSDate
    $planningCycleDuration: Int
    $planningCycleComment: String
    $planningCycleArchived: Boolean
    $currencyCode: String
  ) {
    upsertPlanningCycle(
      planningCycle: {
        tenantId: $tenantId
        planningCycleId: $planningCycleId
        planningCycleName: $planningCycleName
        planningCyclePeriodicity: $planningCyclePeriodicity
        planningCycleStartDate: $planningCycleStartDate
        planningCycleDuration: $planningCycleDuration
        planningCycleComment: $planningCycleComment
        planningCycleArchived: $planningCycleArchived
        currencyCode: $currencyCode
      }
    ) {
      currencyCode
      planningCycleId
      planningCycleSlug
    }
  }
`;
