import React from 'react';

import { ProcessingStatus } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './FileStatusCellRenderer.module.pcss';

interface FileStatusCellRendererProps {
  value: string;
}

const b = block(style);

const FileStatusCellRenderer: React.FC<FileStatusCellRendererProps> = ({ value }) => {
  if (value === ProcessingStatus.FAILED) {
    return (
      <div className={b('textError')} data-testid="cell-status">
        {formatMessage('ACTION_REQUIRED_REVIEW_ERROR')}
      </div>
    );
  } else if (value === ProcessingStatus.PENDING) {
    return (
      <div className={b('textAction')} data-testid="cell-status">
        {formatMessage('ACTION_REQUIRED_MATCH_FIELDS')}
      </div>
    );
  } else if (value === ProcessingStatus.COMPLETED) {
    return <div data-testid="cell-status">{formatMessage('PUBLISHED')}</div>;
  } else if (value === ProcessingStatus.IN_PROGRESS) {
    return <div data-testid="cell-status">{formatMessage('PUBLISHING_IN_PROGRESS')}</div>;
  } else {
    return <div data-testid="cell-status">{value}</div>;
  }
};

export default FileStatusCellRenderer;
