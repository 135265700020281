import React from 'react';

import { Intent } from '@blueprintjs/core';

import Dialog from 'components/Dialog/Dialog';

import { formatMessage } from 'utils/messages/utils';

interface StopWorkflowDialogProps {
  onSubmit: () => void;
  onClose: () => void;
}

const StopWorkflowDialog: React.FC<StopWorkflowDialogProps> = ({ onSubmit, onClose }: StopWorkflowDialogProps) => {
  return (
    <Dialog
      isOpen
      title={formatMessage('STOP_TERRITORY_WORKFLOW_HEADER')}
      confirmButtonText={formatMessage('STOP_WORKFLOW')}
      confirmButtonIntent={Intent.DANGER}
      size="small"
      onSubmit={onSubmit}
      onClose={onClose}
      bodyMinHeight={0}
    >
      <div data-testid="stop-workflow-dialog">{formatMessage('STOP_TERRITORY_WORKFLOW_BODY')}</div>
    </Dialog>
  );
};

export default StopWorkflowDialog;
