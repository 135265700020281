import React, { useState } from 'react';

import { LazyQueryExecFunction } from '@apollo/client';
import { Intent, Menu } from '@blueprintjs/core';
import { DocumentImport, AddAlt, Renew, CheckboxIndeterminateFilled, MailAll, ChevronDown } from '@carbon/icons-react';
import { Tag } from '@varicent/components';

import IconButton from 'components/Buttons/IconButton/IconButton';
import Dialog, { DialogProps } from 'components/Dialog/Dialog';
import { MenuItem } from 'components/menu/MenuItem';
import MessageTooltip from 'components/MessageTooltip/MessageTooltip';
import Popover from 'components/Popover/Popover';
import ToastMessage from 'components/ToastMessage/ToastMessage';

import { useFileUpload } from 'app/core/fileUpload/fileUploadProvider';

import { GetMemberList, GetMemberListVariables, SystemRoleName } from 'app/graphql/generated/graphqlApolloTypes';
import { useBulkInviteUsers } from 'app/graphql/mutations/bulkInviteUsers';

import useShowToast from 'app/hooks/useShowToast';

import { FileType, MemberData } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './UserManagementPanel.module.pcss';

const b = block(style);

const sharedDialogProps: Partial<DialogProps> = {
  confirmButtonIcon: <MailAll />,
  confirmButtonText: formatMessage('SEND'),
  size: 'small',
  bodyMinHeight: 130
};

export interface UserManagementPanelHeaderProps {
  selectionCount: number;
  onDeselect: () => void;
  totalCount: number;
  isRefreshButtonLoading: boolean;
  getMemberList: LazyQueryExecFunction<GetMemberList, GetMemberListVariables>;
  onAddUserButtonClick: () => void;
  isContributorInvitationEnabled: boolean;
  isAdminInvitationEnabled: boolean;
  bulkInviteList: MemberData[];
}

const UserManagementPanelHeader: React.FC<UserManagementPanelHeaderProps> = ({
  selectionCount,
  onDeselect,
  totalCount,
  isRefreshButtonLoading,
  getMemberList,
  onAddUserButtonClick,
  isContributorInvitationEnabled,
  isAdminInvitationEnabled,
  bulkInviteList
}: UserManagementPanelHeaderProps) => {
  const { showUploadSequenceByFileType, setShowUploadSequenceByFileType } = useFileUpload();
  const showToast = useShowToast();

  const [showContributorInviteDialog, setShowContributorInviteDialog] = useState(false);
  const [showAdminInviteDialog, setShowAdminInviteDialog] = useState(false);

  const handleShowContributorDialog = () => setShowContributorInviteDialog(true);
  const handleShowAdminDialog = () => setShowAdminInviteDialog(true);
  const handleHideContributorDialog = () => setShowContributorInviteDialog(false);
  const handleHideAdminDialog = () => setShowAdminInviteDialog(false);

  const isInvitationEnabled = isAdminInvitationEnabled || isContributorInvitationEnabled;

  const [bulkInviteUsers, { loading: isBulkInvitesLoading }] = useBulkInviteUsers({
    onError(error) {
      const errorMessage =
        error.knownError?.errorCode === 'INVITE_EXCEEDS_BATCH_LIMIT'
          ? formatMessage('INVITE_EXCEEDS_BATCH_LIMIT')
          : formatMessage('BULK_INVITE_ERROR_MESSAGE');
      showToast(<ToastMessage title={formatMessage('SOMETHING_WENT_WRONG')} message={errorMessage} />, Intent.DANGER);
    }
  });

  const handleInviteUsers = async (systemRoleName: SystemRoleName) => {
    const users = bulkInviteList.map((invitedUser) => ({
      emailAddress: invitedUser.emailAddress,
      firstName: invitedUser.firstName,
      lastName: invitedUser.lastName,
      tenantId: invitedUser.tenantId,
      systemRoleName
    }));

    const result = await bulkInviteUsers({
      variables: {
        users
      }
    });

    if (result.data) {
      switch (systemRoleName) {
        case SystemRoleName.Contributor:
          handleHideContributorDialog();
          break;
        case SystemRoleName.Administrator:
          handleHideAdminDialog();
      }

      onDeselect();
      getMemberList();

      const toastMessage =
        users.length === 1
          ? formatMessage('INVITATION_SENT')
          : formatMessage('INVITATIONS_SENT', { count: users.length });
      showToast(
        <ToastMessage title={formatMessage('ACCESS_GRANTED_TITLE', { systemRoleName })} message={toastMessage} />,
        'success'
      );
    }
  };

  return (
    <>
      <header className={b('tableHeader')}>
        {selectionCount ? (
          <div>
            <IconButton
              type="button"
              testId="deselect-button"
              icon={<CheckboxIndeterminateFilled />}
              onClick={onDeselect}
            />
            <Tag intent="primary">{formatMessage('USER_SELECTION_FRACTION', { selectionCount, totalCount })}</Tag>

            <Popover
              content={
                <Menu data-testid="invitation-type-menu">
                  <MenuItem
                    text={formatMessage('USER_MANAGEMENT_CONTRIBUTORS_INVITE')}
                    disabled={!isContributorInvitationEnabled}
                    onClick={handleShowContributorDialog}
                    data-testid="contributor-invite-menu-item"
                  />
                  <MenuItem
                    text={formatMessage('USER_MANAGEMENT_ADMINS_INVITE')}
                    disabled={!isAdminInvitationEnabled}
                    onClick={handleShowAdminDialog}
                    data-testid="admin-invite-menu-item"
                  />
                </Menu>
              }
              placement={'bottom'}
              disabled={!isInvitationEnabled}
            >
              <MessageTooltip
                content={formatMessage('USER_MANAGEMENT_DROPDOWN_TOOLTIP')}
                target={
                  <IconButton
                    type="button"
                    testId="invitation-mail-button"
                    className={b('mailIcon')}
                    disabled={!isInvitationEnabled}
                    icon={
                      <>
                        <MailAll />
                        <ChevronDown />
                      </>
                    }
                  />
                }
                placement={'top'}
              />
            </Popover>
          </div>
        ) : (
          <h5 className={b('tableTitle')} data-testid="table-title">
            {formatMessage('ASSIGNED')}
          </h5>
        )}

        <div className={b('tableNavigation')}>
          <IconButton
            type="button"
            icon={<Renew />}
            testId={'refresh-button'}
            onClick={getMemberList}
            loading={isRefreshButtonLoading}
            tooltipText={formatMessage('REFRESH')}
          />
          <IconButton
            type="button"
            icon={<DocumentImport />}
            testId={'upload-users-button'}
            onClick={() => {
              setShowUploadSequenceByFileType({ ...showUploadSequenceByFileType, [FileType.PARTICIPANT]: true });
            }}
            tooltipText={formatMessage('USER_MANAGEMENT_LOAD_USERS')}
          />
          <IconButton
            type="button"
            icon={<AddAlt />}
            testId={'add-user-button'}
            onClick={onAddUserButtonClick}
            tooltipText={formatMessage('CREATE_USER_PROFILE')}
            toolTipPlacement="left"
          />
        </div>
      </header>
      {showContributorInviteDialog && (
        <Dialog
          title={formatMessage('USER_MANAGEMENT_CONTRIBUTORS_INVITE')}
          isOpen={showContributorInviteDialog}
          onClose={handleHideContributorDialog}
          onSubmit={() => handleInviteUsers(SystemRoleName.Contributor)}
          confirmButtonLoading={isBulkInvitesLoading}
          {...sharedDialogProps}
        >
          <div className={b('inviteDialogBody')} data-testid="contributor-invite-dialog-body">
            {formatMessage('USER_MANAGEMENT_CONTRIBUTORS_INVITE_BODY', { selectionCount })}
          </div>
        </Dialog>
      )}
      {showAdminInviteDialog && (
        <Dialog
          title={formatMessage('USER_MANAGEMENT_ADMINS_INVITE')}
          isOpen={showAdminInviteDialog}
          onClose={handleHideAdminDialog}
          onSubmit={() => handleInviteUsers(SystemRoleName.Administrator)}
          confirmButtonLoading={isBulkInvitesLoading}
          {...sharedDialogProps}
        >
          <div className={b('inviteDialogBody')} data-testid="admin-invite-dialog-body">
            {formatMessage('USER_MANAGEMENT_ADMINS_INVITE_BODY', { selectionCount })}
          </div>
        </Dialog>
      )}
    </>
  );
};

export default UserManagementPanelHeader;
