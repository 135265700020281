import React from 'react';

import { ViewOffFilled, ViewFilled, Search, CollapseAll, ExpandAll } from '@carbon/icons-react';
import { DividerV2 } from '@varicent/components';

import IconButton from 'components/Buttons/IconButton/IconButton';
import TextButton from 'components/Buttons/TextButton/TextButton';

import { useTerritoryLockingTreatment } from 'app/components/TerritoryMap/hooks/useTerritoryLockingSplit';

import { useDedicatedMapProvider } from 'app/contexts/dedicatedMapProvider';

import { CollectionFilter, MchQuantity, NamedHierarchy, PrimaryTerritoryField } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import MapGridFilter from './MapGridFilter';
import style from './TerritoryMapGrid.module.pcss';

const b = block(style);

interface MapGridHeaderProps {
  onClick: () => void;
  onSearchIconClick: () => void;
  customHierarchies: NamedHierarchy[];
  customHierarchyFilter: CollectionFilter<number>;
  onCollapseRows?: () => void;
  onExpandRows?: () => void;
  showCollapseAndExpandButtons?: boolean;
  primaryTerritoryField?: PrimaryTerritoryField;
  onPrimaryTerritoryFieldChange?: (field: PrimaryTerritoryField) => void;
}

const MapGridHeader: React.FC<MapGridHeaderProps> = ({
  onClick,
  onSearchIconClick,
  customHierarchies,
  customHierarchyFilter,
  onCollapseRows,
  onExpandRows,
  primaryTerritoryField,
  onPrimaryTerritoryFieldChange,
  showCollapseAndExpandButtons = false
}) => {
  const { chosenCustomHierarchy } = useDedicatedMapProvider();
  const { isEverythingOn } = useTerritoryLockingTreatment();

  const isCustomHierarchiesEnabled = chosenCustomHierarchy.quantity !== MchQuantity.NONE;
  const status = isCustomHierarchiesEnabled ? formatMessage('ON') : formatMessage('OFF');

  const expandAndCollapseButtons = () => {
    if (!showCollapseAndExpandButtons) return null;

    return (
      <>
        <DividerV2 vertical className={b('divider')} />
        <IconButton
          type="button"
          icon={<CollapseAll />}
          testId="collapse-all-button"
          minimal
          onClick={onCollapseRows}
          tooltipText={formatMessage('COLLAPSE_ALL')}
        />
        <IconButton
          type="button"
          icon={<ExpandAll />}
          testId="expand-all-button"
          minimal
          onClick={onExpandRows}
          tooltipText={formatMessage('EXPAND_ALL')}
        />
      </>
    );
  };

  return (
    <div className={b('visualizationContainer')}>
      {isEverythingOn ? (
        <>
          <div className={b('iconsContainer')}>
            <IconButton
              type="button"
              icon={<Search />}
              testId="search-icon-button"
              minimal
              onClick={onSearchIconClick}
            />
            {expandAndCollapseButtons()}
          </div>
          <MapGridFilter
            customHierarchyFilter={customHierarchyFilter}
            customHierarchies={customHierarchies}
            shouldShowCustomHierarchyFilter={isCustomHierarchiesEnabled}
            primaryTerritoryField={primaryTerritoryField}
            onPrimaryTerritoryFieldChange={onPrimaryTerritoryFieldChange}
          />
        </>
      ) : (
        <>
          <TextButton
            text={formatMessage('COMPLEX_RULES_STATUS', {
              status
            })}
            onClick={onClick}
            minimal
            type="button"
            testId="visualization-settings-button"
            large={false}
            icon={isCustomHierarchiesEnabled ? <ViewFilled /> : <ViewOffFilled />}
          />
          <IconButton type="button" icon={<Search />} testId="search-icon-button" minimal onClick={onSearchIconClick} />
        </>
      )}
    </div>
  );
};

export default MapGridHeader;
