import React, { useEffect, useRef } from 'react';

import { GridApi, GridOptions } from '@ag-grid-community/core';

import AdvancedGrid from 'app/components/AdvancedGrid/AdvancedGrid';
import { getPaginationCheck } from 'app/components/AdvancedGrid/GridHelper';

import { useData } from 'app/contexts/dataProvider';
import { useScope } from 'app/contexts/scopeProvider';

import { IMPORT_ERRORS_BLOCK_SIZE } from 'app/global/variables';

import { GetFileImportErrors, GetFileImportErrorsVariables } from 'app/graphql/generated/graphqlApolloTypes';
import { useGetFileImportErrorsLazy } from 'app/graphql/queries/getFileImportErrors';

import { DataPanelViews, FileStatus } from 'app/models';

import block from 'utils/bem-css-modules';

import style from './ImportErrorsPanel.module.pcss';
import buildImportErrorsPanelColumnDef from './ImportErrorsPanelColumnDef';

const b = block(style);

const ImportErrorsPanel: React.FC = () => {
  const importErrorsGridRef = useRef(null);
  const { selectedPlanningCycle } = useScope();
  const { setSelectedDataView, setSelectedTable } = useData();

  const [getFileImportErrorsList, { data, loading: loadingFileImportErrorsList, fetchMore }] =
    useGetFileImportErrorsLazy({
      fetchPolicy: 'network-only'
    });

  const fileImportErrorList = data?.getPlanningCycleSpec?.tables?.tableList || [];
  const totalCount = data?.getPlanningCycleSpec?.tables?.totalNumOfTables || 0;

  useEffect(() => {
    getFileImportErrorsList({
      variables: {
        planningCycleId: selectedPlanningCycle?.id,
        tableInput: {
          uploadFilter: {
            statuses: [FileStatus.FAILED]
          },
          startRow: 1,
          endRow: IMPORT_ERRORS_BLOCK_SIZE
        }
      }
    });
  }, [selectedPlanningCycle]);

  const onSelectionChanged = (params) => {
    const gridApi = params?.api as GridApi;
    const selectedRow = gridApi.getSelectedRows();
    setSelectedDataView(DataPanelViews.ERROR_DETAIL);
    setSelectedTable(selectedRow[0]);
  };

  const importErrorPanelGridOptions: GridOptions = {
    pagination: true,
    paginationAutoPageSize: true,
    suppressMovableColumns: true,
    cacheBlockSize: IMPORT_ERRORS_BLOCK_SIZE,
    cacheOverflowSize: 2,
    maxConcurrentDatasourceRequests: 2,
    infiniteInitialRowCount: 1,
    maxBlocksInCache: 2,
    rowModelType: 'infinite',
    datasource: {
      rowCount: totalCount,
      getRows: async (params) => {
        if (!params) return null;
        if (params.endRow === IMPORT_ERRORS_BLOCK_SIZE) {
          params.successCallback(fileImportErrorList, totalCount);
        } else if (getPaginationCheck(params.startRow, totalCount)) {
          const fetchMoreFiles = await fetchMore<GetFileImportErrors, GetFileImportErrorsVariables>({
            variables: {
              planningCycleId: selectedPlanningCycle?.id,
              tableInput: {
                uploadFilter: {
                  statuses: [FileStatus.FAILED]
                },
                startRow: params.startRow + 1,
                endRow: params.endRow
              }
            }
          });

          params.successCallback(
            fetchMoreFiles?.data?.getPlanningCycleSpec?.tables?.tableList,
            fetchMoreFiles?.data?.getPlanningCycleSpec?.tables?.totalNumOfTables
          );
        }
      }
    }
  };

  return (
    <div className={b('')}>
      <div className={b('importErrorsGrid')} data-testid="import-errors-panel" ref={importErrorsGridRef}>
        <div className={`ag-theme-alpine ${b('grid')}`}>
          <AdvancedGrid
            data={JSON.stringify(fileImportErrorList)}
            columnDefs={buildImportErrorsPanelColumnDef()}
            onSelectionChanged={onSelectionChanged}
            rowSelection="single"
            data-testid="sheets-summary-grid"
            showGridLoading={loadingFileImportErrorsList}
            gridWidth={importErrorsGridRef?.current?.offsetWidth}
            gridHeight={importErrorsGridRef?.current?.offsetHeight}
            gridOptions={importErrorPanelGridOptions}
          />
        </div>
      </div>
    </div>
  );
};

export default ImportErrorsPanel;
