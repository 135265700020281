import React, { useState, useMemo, Dispatch, SetStateAction, useEffect, useContext } from 'react';

import { useMapTerritoryGroupTree } from 'app/components/TerritoryMap/hooks/useMapTerritoryGroupTree';
import { useSetupHierarchyPolygons } from 'app/components/TerritoryMap/hooks/useSetupHierarchyPolygons';
import { useTerritoryLockingTreatment } from 'app/components/TerritoryMap/hooks/useTerritoryLockingSplit';

import { useMapWorkerPostMessage } from 'app/contexts/mapWorkerContext';

import {
  BaseContext,
  CustomerVisualization,
  GeoBounds,
  MapCustomHierarchySetting,
  MchQuantity,
  TerritoryGroupForMap,
  TerritoryGroupLevelDescription
} from 'app/models';

import { TerritoryGroupSlicer } from 'utils/helpers/TerritoryGroupSlicer';
import { TerritoryGroupTree } from 'utils/TerritoryGroupTree';

import { useMapContextRedistributor } from './MapContextRedistributor/mapContextRedistributorProvider';
import { useMapVariant } from './mapVariantProvider';

export interface DedicatedMapContextValues extends BaseContext {
  selectedPinSetIds: number[];
  setSelectedPinSetIds: Dispatch<SetStateAction<number[]>>;
  customerVisuals: CustomerVisualization;
  setCustomerVisuals: Dispatch<SetStateAction<CustomerVisualization>>;

  isHighwaysLayerVisible: boolean;
  setIsHighwaysLayerVisible: Dispatch<SetStateAction<boolean>>;

  territoryGroupLevel: number | null;
  setTerritoryGroupLevel: Dispatch<SetStateAction<number | null>>;
  groupLevelList: ReadonlyArray<TerritoryGroupLevelDescription>;
  groupLookup: ReadonlyMap<number, TerritoryGroupForMap>;
  territoryGroupTree: TerritoryGroupTree<TerritoryGroupForMap>;
  loadingTerritoryGroups: boolean;
  chosenCustomHierarchy: MapCustomHierarchySetting;
  setChosenCustomHierarchy: Dispatch<SetStateAction<MapCustomHierarchySetting>>;

  isExactCustomHierarchyFilterActive: boolean;
  setIsExactCustomHierarchyFilterActive: Dispatch<SetStateAction<boolean>>;

  isColoringByTerritoryGroup: boolean;
  setShouldColorByTerritoryGroup: Dispatch<SetStateAction<boolean>>;

  hierarchyBounds: GeoBounds;

  resetValues: () => void;
}

const defaultChosenCustomHierarchy: MapCustomHierarchySetting = {
  quantity: MchQuantity.NONE
};

export const DedicatedMapContext = React.createContext<DedicatedMapContextValues | null>(null);
DedicatedMapContext.displayName = 'DedicatedMapContext';

export const DedicatedMapProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const { selectedBattleCardId, isEmbedded, selectedTerritoryGroupId, savedCustomHierarchyPreference } =
    useMapContextRedistributor();
  const { isDataTrayMapOpen, isPreviewMapOpen } = useMapVariant();
  const lockingTreatment = useTerritoryLockingTreatment();
  const [chosenCustomHierarchy, setChosenCustomHierarchy] = useState<MapCustomHierarchySetting>(
    savedCustomHierarchyPreference ?? defaultChosenCustomHierarchy
  );

  const [selectedPinSetIds, setSelectedPinSetIds] = useState<number[]>([]);
  const [customerVisuals, setCustomerVisuals] = useState<CustomerVisualization | null>(CustomerVisualization.HIDE);
  const [territoryGroupLevel, setTerritoryGroupLevel] = useState<number | null>(null);
  const [isHighwaysLayerVisible, setIsHighwaysLayerVisible] = useState(false);

  const [isExactCustomHierarchyFilterActive, setIsExactCustomHierarchyFilterActive] = useState(false);

  const [shouldColorByTerritoryGroup, setShouldColorByTerritoryGroup] = useState(false);
  const isColoringByTerritoryGroup = lockingTreatment.isEverythingOn
    ? shouldColorByTerritoryGroup
    : territoryGroupLevel != null;

  useEffect(() => {
    setChosenCustomHierarchy(defaultChosenCustomHierarchy);
  }, [selectedBattleCardId]);

  useEffect(() => {
    if (savedCustomHierarchyPreference) {
      setChosenCustomHierarchy(savedCustomHierarchyPreference);
    }
  }, [savedCustomHierarchyPreference]);

  useEffect(() => {
    setTerritoryGroupLevel(null);
  }, [selectedTerritoryGroupId]);

  useEffect(() => {
    if (territoryGroupLevel == null) {
      setShouldColorByTerritoryGroup(false);
    }
  }, [territoryGroupLevel]);

  useEffect(() => {
    if (isPreviewMapOpen) {
      setCustomerVisuals(CustomerVisualization.HIDE);
    }
  }, [isPreviewMapOpen]);

  const { territoryGroupTree, loading: loadingTerritoryGroups } = useMapTerritoryGroupTree(
    !isEmbedded && !isDataTrayMapOpen
  );

  const groupSlicer = useMemo(() => {
    return new TerritoryGroupSlicer(territoryGroupTree);
  }, [territoryGroupTree]);

  const groupLevelList = useMemo(
    () =>
      groupSlicer.getLevelsAsGroups().map((groupsOnLevel, levelIndex) => ({
        levelIndex,
        groupsOnLevel
      })),
    [groupSlicer]
  );

  const groupLookup = useMemo((): Map<number, TerritoryGroupForMap> => {
    if (territoryGroupLevel == null) return new Map();
    return groupSlicer.getLeafLevelLookup(territoryGroupLevel);
  }, [groupSlicer, territoryGroupLevel]);

  const postMessage = useMapWorkerPostMessage();
  useEffect(() => {
    // the TG lookup should affect the actor only when coloring by territory group
    const shouldClearActorGroupLookup = groupLookup.size > 0 && !isColoringByTerritoryGroup;
    postMessage({
      type: 'group-lookup-change',
      groupLookup: shouldClearActorGroupLookup ? new Map() : groupLookup
    });
  }, [groupLookup, isColoringByTerritoryGroup]);

  const hierarchyBounds = useSetupHierarchyPolygons();

  const resetValues = () => {
    setSelectedPinSetIds([]);
    setTerritoryGroupLevel(null);
    setCustomerVisuals(CustomerVisualization.HIDE);
  };

  const values = useMemo(() => {
    return {
      selectedPinSetIds,
      setSelectedPinSetIds,
      territoryGroupLevel,
      setTerritoryGroupLevel,
      groupLevelList,
      groupLookup,
      customerVisuals,
      territoryGroupTree,
      loadingTerritoryGroups,
      setCustomerVisuals,
      chosenCustomHierarchy,
      setChosenCustomHierarchy,
      hierarchyBounds,
      setIsExactCustomHierarchyFilterActive,
      isExactCustomHierarchyFilterActive,
      setShouldColorByTerritoryGroup,
      isColoringByTerritoryGroup,
      isHighwaysLayerVisible,
      setIsHighwaysLayerVisible,
      resetValues
    };
  }, [
    territoryGroupLevel,
    groupLookup,
    groupLevelList,
    selectedPinSetIds,
    customerVisuals,
    territoryGroupTree,
    loadingTerritoryGroups,
    hierarchyBounds,
    chosenCustomHierarchy,
    isExactCustomHierarchyFilterActive,
    isColoringByTerritoryGroup,
    isHighwaysLayerVisible
  ]);

  return <DedicatedMapContext.Provider value={values}>{children}</DedicatedMapContext.Provider>;
};

// Custom hook to read these values from
// eslint-disable-next-line no-restricted-syntax
export const useDedicatedMapProvider = (): DedicatedMapContextValues => useContext(DedicatedMapContext);
