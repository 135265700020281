import React from 'react';

import { Information } from '@carbon/icons-react';

import IconButton from 'components/Buttons/IconButton/IconButton';
import MessageTooltip from 'components/MessageTooltip/MessageTooltip';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './RolePermissionColumnHeaderCellRenderer.module.pcss';

const b = block(style);

const RolePermissionColumnHeaderCellRenderer: React.FC = () => {
  return (
    <div className={b('rolePermissionColumnHeader')}>
      <div>
        <span data-testid="view-permission-header">{formatMessage('VISIBLE')}</span>
        <MessageTooltip
          content={formatMessage('ROLE_PERMISSIONS_TABLE_VIEW_HEADER_TOOLTIP')}
          target={<IconButton type="button" icon={<Information />} testId="territoryHoverToolbar-edit" />}
          placement={'top'}
        />
      </div>
      <div>
        <span data-testid="edit-permission-header">{formatMessage('EDITABLE')}</span>
        <MessageTooltip
          content={formatMessage('ROLE_PERMISSIONS_TABLE_EDIT_HEADER_TOOLTIP')}
          target={<IconButton type="button" icon={<Information />} testId="territoryHoverToolbar-edit" />}
          placement={'top'}
        />
      </div>
    </div>
  );
};

export default RolePermissionColumnHeaderCellRenderer;
