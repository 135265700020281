// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".o5K5g {\n    max-height: 100%;\n    overflow: hidden;\n  }\n\n    .GHMWl {\n      background-color: white;\n      padding: var(--s);\n      border-radius: var(--xs);\n      box-shadow: var(--elevation-1);\n      width: 355px; /* Set to ensure multiselect doesn't expand horizontally */\n    }\n  .ezjZx {\n    display: flex;\n    grid-gap: var(--xs);\n    gap: var(--xs);\n    align-items: center;\n  }\n  ._2aX6P.vds-heading {\n    font-size: 16px;\n    margin-right: auto;\n  }\n  .jUxUn {\n    margin-top: var(--xs);\n    display: flex;\n    flex-direction: column;\n    border: 1px solid rgb(var(--color-light-gray-2));\n    border-radius: var(--xs);\n    max-height: 100%;\n    overflow-y: auto;\n  }\n  .\\+WIAQ {\n    margin: 0;\n    border-top: 1px solid rgb(var(--color-light-gray-2));\n  }\n  .lEJvl {\n    box-shadow: none;\n  }\n  .lEJvl .vds-icon {\n    /* Used to match the icon location of the kind-select */\n    margin-right: 0 !important;\n  }\n  .mSN4n {\n    margin-top: var(--m);\n    text-align: center;\n  }\n  .mscUq {\n    margin-top: var(--xs);\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CustomHierarchyFilterMenu_filter": "o5K5g",
	"CustomHierarchyFilterMenu_filter__legacyFilter": "GHMWl",
	"CustomHierarchyFilterMenu_header": "ezjZx",
	"CustomHierarchyFilterMenu_heading": "_2aX6P",
	"CustomHierarchyFilterMenu_form": "jUxUn",
	"CustomHierarchyFilterMenu_fieldSeparator": "+WIAQ",
	"CustomHierarchyFilterMenu_hierarchyMultiSelect": "lEJvl",
	"CustomHierarchyFilterMenu_clearFilter": "mSN4n",
	"CustomHierarchyFilterMenu_filterCallout": "mscUq"
};
export default ___CSS_LOADER_EXPORT___;
