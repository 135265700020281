import { MutableRefObject } from 'react';

import { ColDef } from '@ag-grid-community/core';

import { getActivityFilesPanelCellRenderer } from 'app/components/AdvancedGrid/CellRenderers/ActivityFilesPanelCellRenderer/ActivityFilesPanelCellRenderer';
import BattleCardReferenceCellRenderer from 'app/components/AdvancedGrid/CellRenderers/BattleCardReferenceCellRenderer/BattleCardReferenceCellRenderer';

import { DataTableFileStatusType, FileType } from 'app/models';

import { formatMessage } from 'utils/messages/utils';

interface BuildActivityFilesPanelColumnDefProps {
  totalCount: number;
  rowRef: MutableRefObject<HTMLDivElement>;
  showStatus?: boolean;
  deleteActivityFileEnabled?: boolean;
}

const buildActivityFilesPanelColumnDef = ({
  totalCount,
  rowRef,
  showStatus = true,
  deleteActivityFileEnabled = false
}: BuildActivityFilesPanelColumnDefProps): ColDef[] => {
  const columnDefs: ColDef[] = [];

  columnDefs.push({
    headerName: formatMessage('NAME'),
    field: 'tableName',
    flex: 2,
    minWidth: 500,
    cellRendererFramework: getActivityFilesPanelCellRenderer(DataTableFileStatusType.DEFAULT, totalCount, rowRef)
  });

  columnDefs.push({
    headerName: formatMessage('TYPE'),
    field: 'tableType',
    flex: 1,
    minWidth: 150,
    valueFormatter: (params) => {
      if (params?.data?.tableType === 'Upload') {
        return params.data.tableDataType;
      }
    }
  });

  if (showStatus) {
    columnDefs.push({
      headerName: formatMessage('STATUS'),
      field: 'status',
      flex: 2,
      minWidth: 250,
      cellRendererFramework: getActivityFilesPanelCellRenderer(DataTableFileStatusType.STATUS, totalCount, rowRef)
    });
  }

  if (deleteActivityFileEnabled) {
    columnDefs.push({
      headerName: formatMessage('REFERENCES'),
      flex: 2,
      width: 200,
      cellRendererFramework: BattleCardReferenceCellRenderer,
      cellRendererParams: (params) => {
        if (params?.node?.data?.tableDataType === FileType.ACTIVITY) {
          return {
            items: params.node.data.battlecardReferences?.map((bc) => bc.battlecardName).sort() || []
          };
        }
        return {
          items: []
        };
      }
    });
  }

  return columnDefs;
};

export default buildActivityFilesPanelColumnDef;
