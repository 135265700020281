import { useQuery } from '@apollo/client';

import { GetPins, GetPinsVariables } from 'app/graphql/generated/graphqlApolloTypes';
import { handleError } from 'app/graphql/handleError';
import { GET_PINS } from 'app/graphql/queries/getPins';

// eslint-disable-next-line no-restricted-imports
import showToast from 'utils/helpers/showToast';
import { formatMessage } from 'utils/messages/utils';

export const useGetPins = (
  pinSetIds: number[]
): {
  data: GetPins;
  loading: boolean;
} => {
  const { data, previousData, loading } = useQuery<GetPins, GetPinsVariables>(GET_PINS, {
    fetchPolicy: 'cache-first',
    skip: pinSetIds.length === 0,
    variables: {
      pinSetIds
    },
    onError({ graphQLErrors, networkError }) {
      handleError(graphQLErrors, networkError);
      // eslint-disable-next-line deprecation/deprecation
      showToast(formatMessage('MAP_PINS_ERROR'), 'danger');
    }
  });

  if (pinSetIds.length === 0)
    return {
      data: null,
      loading: false
    };

  if (loading)
    // Show previous data while loading new data
    return { data: data ?? previousData, loading };

  return { data, loading };
};
